import React from "react";
import PropTypes from "prop-types";
import { InjectIntl } from "../common/InjectIntl";
import PermissionChecker from "../common/PermissionChecker";

const CalculationControlPanel = props => {
  function addCalcHandler() {
    props.addCalculation();
  }

  return (
    <div className="col-md-12">
      <div className="row sub-header">
        <div className="col-md-6" />
        <div className="col-md-6 text-right buttons-wrap">
          <button
            onClick={props.closeCalculation}
            title="Back to logic model"
            className="btn exit-from-calculation-page"
          >
            {props.intl("common.text.exit")}
          </button>
          &nbsp;
          <PermissionChecker hide={true}>
            <button className="btn btn-success" onClick={addCalcHandler}>
              <i className="fa fa-plus" />
              &nbsp;
              {props.intl("calculation.add_calculation")}
            </button>
          </PermissionChecker>
        </div>
      </div>
    </div>
  );
};

CalculationControlPanel.propTypes = {
  addCalculation: PropTypes.func.isRequired,
  measureName: PropTypes.string.isRequired,
  closeCalculation: PropTypes.func.isRequired
};

export default InjectIntl(CalculationControlPanel);
