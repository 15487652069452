export const SHOW_DELETE_MODAL_ITEM = '@@DELETE_MODAL_ITEM/SHOW_DELETE_MODAL_ITEM';
export const HIDE_DELETE_MODAL_ITEM = '@@DELETE_MODAL_ITEM/HIDE_DELETE_MODAL_ITEM';
export const SET_DELETE_PARAMS = '@@DELETE_MODAL_ITEM/SET_DELETE_PARAMS';

function showDeleteModalItem() {
  return {type: SHOW_DELETE_MODAL_ITEM}
}

function hideDeleteModalItem() {
  return {type: HIDE_DELETE_MODAL_ITEM}
}

function setDeleteParams(text, handleDelete, itemId) {
  return {type: SET_DELETE_PARAMS, text, handleDelete, itemId }
}

export default {
  showDeleteModalItem,
  hideDeleteModalItem,
  setDeleteParams
}