import { Observable } from "rxjs/Observable";
import { put } from "../api";
import { ADJUSTMENTS_UPDATE, SUCCESS_ADJUSTMENT_UPDATE, ADJUSTMENT_UPDATE_FAILURE } from "../actions/measure/adjustments";
import { HIDE_ADJUSTMENTS } from "../actions/stakeholder-items/financialProxy";
import { ADD_MEASURE_SUCCESS } from "../actions/measure/manage-measure";


export function adjustmentUpdateEpic(action$, store) {
  return action$.ofType(ADJUSTMENTS_UPDATE)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const {
        deadWeightDescription,
        deadWeight,
        dropOffDescription,
        dropOff,
        displacementDescription,
        displacement,
        attributionDescription,
        attribution,
        durationDescription,
        duration
      } = store.getState().adjustments;
      const { stakeholderId, measureId } = store.getState().measurePage;
      const name = "Unnamed Financial Proxy";

      const data = {
        attribution: attribution,
        attribution_description: attributionDescription,
        category: null,
        deadweight: deadWeight,
        deadweight_description: deadWeightDescription,
        displacement: displacement,
        displacement_description: displacementDescription,
        dropoff: dropOff,
        dropoff_description: dropOffDescription,
        duration: duration,
        duration_description: durationDescription,
        stakeholder_id: stakeholderId,
        name: name
      };

      return Observable.fromPromise(put(`/api/measures/${measureId}`, data, token))
        .flatMap(({ measure, stakeholder }) => {
          return Observable.concat(
            Observable.of({ type: SUCCESS_ADJUSTMENT_UPDATE, payload: { measure } }),
            Observable.of({ type: ADD_MEASURE_SUCCESS, payload: { stakeholder, measure } }),
            Observable.of({ type: HIDE_ADJUSTMENTS })
          )
        })
        .catch((errors) => Observable.of({ type: ADJUSTMENT_UPDATE_FAILURE, errors }))
    })
}

export default [
  adjustmentUpdateEpic
];