import {combineReducers} from 'redux';
import {routerReducer as routing} from 'react-router-redux';
import homePage from './home-page';
import {intlReducer} from 'react-intl-redux'
import {reducer as toastrReducer} from 'react-redux-toastr';
import application from './application';
import login from './login';
import logicModels from './logic-models';
import measurePage from './measure/measure';
import manageMeasure from './measure/manage-measure';
import dataSource from './calculations/data-source';
import adjustments from './measure/adjustments';
import inputs from './stakeholder-items/inputs';
import activities from './stakeholder-items/activities';
import outcomes from './stakeholder-items/outcomes';
import outcomeIndicator from './stakeholder-items/outcome-indicator';
import financialProxy from './stakeholder-items/financial-proxy';
import outputs from './stakeholder-items/outputs';
import ontology from './ontology';
import demographics from "./stakeholder-items/demographics";
import inviteCollaborators from "./members/invite-collaborators";
import inviteModal from "./members/invite-modal";
import errorsNotification from "./errors";
import loadingModal from "./loading-modal";
import calculations from "./calculations/calculation-page";
import spinner from "./spinner";
import modalDeleteItem from "./modal-delete-item";
import oneSourceFirstTab from "./calculations/modals/oneSourceFirstTab";
import oneSourceFinalTab from "./calculations/modals/oneSourceFinalTab";
import selectVariableTypeModals from "./calculations/modals/selectVariableType";
import selectCalculationType from "./calculations/modals/selectCalculationType";
import goodJobSweetAlert from "./goodJobSweetAlert"
import conditionPanel from "./calculations/modals/condition-panel";
import reports from "./reports";


export default combineReducers({
  intl: intlReducer,
  toastr: toastrReducer,
  routing,
  homePage,
  application,
  login,
  logicModels,
	measurePage,
  manageMeasure,
  dataSource,
  adjustments,
  inputs,
  activities,
  outcomes,
  outcomeIndicator,
  financialProxy,
  outputs,
  ontology,
  demographics,
  inviteCollaborators,
  inviteModal,
  errorsNotification,
  loadingModal,
  calculations,
  spinner,
  modalDeleteItem,
  oneSourceFirstTab,
  oneSourceFinalTab,
  selectVariableTypeModals,
  selectCalculationType,
  goodJobSweetAlert,
  conditionPanel,
  reports
})