import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SpinnerActions from "../actions/spinner";
import React from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import * as swal2  from 'sweetalert2';
import BrowserDetect from "../assets/sam-utils";
import { whiteLabel } from '../components/utils';

class RenderSpinnerSweetAlert extends React.Component {
  static propTypes = {
    showSpinner: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      hideSpinner: PropTypes.func.isRequired
    })
  };

  element=()=> {
    const verificationElement = document.createElement("div");
    const isWhiteLabel = whiteLabel()
    verificationElement.className = `sk-spinner sk-spinner-rotating-plane ${isWhiteLabel && `sk-spinner-${isWhiteLabel}`}`;
    return verificationElement;
  };

  renderSweetAlert = () => {
    const spinner = this.element();
    const isWhiteLabel = whiteLabel()
    if (BrowserDetect.browser === 'Explorer' && BrowserDetect.version < 11) {
      swal({
        content: {
          element: spinner
        },
        text: 'Working. Hangon.',
        buttons: {}
      })
    } else {
      swal2({
        title: `<div style="margin-top: 40px; margin-bottom: 40px"><div class="sk-spinner sk-spinner-rotating-plane ${isWhiteLabel && `sk-spinner-${isWhiteLabel}`}" style="font-size: 95px; padding-top: 10px; padding-bottom: 10px;"/></div>`,
        text: 'Working. Hangon...',
        showConfirmButton: false
      })
    }

  };

  componentDidUpdate(prevProps) {
    if (prevProps.showSpinner && !this.props.showSpinner) {
      if (BrowserDetect.browser === 'Explorer' && BrowserDetect.version < 11){
        swal.close();
        document.body.removeChild(document.getElementsByClassName('swal-overlay')[0])
      } else {
        swal2.close();
      }
    }
  }

  render() {

    return (
      <div>
        {this.props.showSpinner &&
        <div>
          {this.renderSweetAlert()}
        </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ...state.spinner
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(SpinnerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderSpinnerSweetAlert)