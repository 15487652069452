import merge from 'xtend';
import createReducer from './create-reducer';
import {
  INITIAL_REPORTS_QUERY,
  INITIAL_REPORTS_QUERY_SUCCESS,
  INITIAL_REPORTS_QUERY_FAILURE,
  CHANGE_LOGIC_MODEL,
  RESULTS_QUERY,
  RESULTS_QUERY_SUCCESS,
  CHANGE_TAB
} from '../actions/reports';

export const DASHBOARD = 'dashboard';
export const OUTCOMES = 'outcomes';
export const DEMOGRAPHICS = 'demographics';
export const ACTIVITIES = 'activities';
export const SUMMARY = 'summary';

export const TABS = [
  DASHBOARD,
  OUTCOMES,
  DEMOGRAPHICS,
  ACTIVITIES,
  SUMMARY
];

export const INITIAL_STATE = {
  logic_models: [],
  error: '',
  isLoading: false,
  results: {},
  resultLoading: false,
  tabs: TABS,
  activeTab: DASHBOARD
};

export default createReducer({
  [INITIAL_REPORTS_QUERY]: (state, action) => merge(state, { isLoading: true }),
  [INITIAL_REPORTS_QUERY_SUCCESS]: (state, action) => merge(state, { logic_models: action.logic_models, isLoading: false}),
  [INITIAL_REPORTS_QUERY_FAILURE]: (state, action) => merge(state, { error: action.error, isLoading: false}),
  [CHANGE_LOGIC_MODEL]: (state, action) => {
    const nextLogicModels = state.logic_models.map(logic_model => {
      logic_model.selected = parseInt(logic_model.id) === parseInt(action.logic_model_id);
      return logic_model;
    });
    return merge(state, {
      logic_models: nextLogicModels
    });
  },
  [RESULTS_QUERY]: (state, action) => merge(state, { results: {}, resultLoading: true }),
  [RESULTS_QUERY_SUCCESS]: (state, action) => merge(state, { results: action.results, resultLoading: false }),
  [CHANGE_TAB]: (state, action) => merge(state, { activeTab: action.nextTab })
}, INITIAL_STATE)