import React from 'react';
import Error from "./Error";
import {intlShape} from 'react-intl';

class NotFound extends React.Component {
  static contextTypes = {
    intl: intlShape.isRequired
  };

  intl = (messageId) => this.context.intl.formatMessage({id: messageId});

  render() {
    return (
      <Error errorCode="404"
             errorText={this.intl("notFoundPage.title")}
             errorDescription={this.intl("notFoundPage.description")}
             btnLink="/"
             btnText={this.intl("notFoundPage.button.home")}
      />
    );
  }
}

export default NotFound;