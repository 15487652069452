import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DataSourceActions from "../../../actions/calculation/data-source";
import SourcesWorkspaces from "../../../components/calculations/sources/SourcesWorkspaces";
import CalculationsActions from "../../../actions/calculation/calculation-page";
import ConditionsActions from "../../../actions/calculation/conditions";

function mapStateToProps(state, ownProps) {
  return {
    ...state.dataSource,
    workspace: state.calculations.workspace,
    workspaces: state.calculations.workspaces,
    activeVariable: state.calculations.activeVariable,
    intl: state.intl
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...DataSourceActions,
      ...CalculationsActions,
      setConditionInitialState: ConditionsActions.setConditionInitialState
    }, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SourcesWorkspaces)