import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CalculationsActions from "../../actions/calculation/calculation-page";
import VariableItemWrapper from "../../components/calculations/variables/VariableItemWrapper";

function mapStateToProps(state, ownProps) {
  return {
    ...state.calculations,
    ...ownProps
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      CalculationsActions,
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VariableItemWrapper);
