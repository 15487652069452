import React from "react";
import PropTypes from "prop-types";

export class Tooltip extends React.Component {
  constructor() {
    super();
    this.state = {
      isVisible: false,
      top: 0,
      left: 0
    };
    this.timer = null;
    this.tooltip = React.createRef();
    this.child = React.createRef();
  }

  componentDidMount() {
    this.recalculatePosition();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  recalculatePosition() {
    const childPosition = this.child.current.getBoundingClientRect();
    const tooltipPosition = this.tooltip.current.getBoundingClientRect();

    const top = Math.abs(childPosition.y - tooltipPosition.height);
    const left = Math.abs(childPosition.x - tooltipPosition.width);

    this.setState({ top, left });
  }

  handleMouseEnter = () => {
    this.timer = setTimeout(() => {
      this.setState({
        isVisible: true
      });
      this.recalculatePosition();
    }, this.props.delayTime);
  };

  handleMouseLeave = () => {
    clearTimeout(this.timer);
    this.setState({
      isVisible: false
    });
  };

  render() {
    const { wrapperClassname, tooltipElement, children } = this.props;

    const isVisible = this.state.isVisible ? "show" : "hide";
    const className = `custom-tooltip ${isVisible}`;

    return (
      <React.Fragment>
        <div
          ref={this.tooltip}
          style={{ top: this.state.top, left: this.state.left }}
          className={className}
        >
          {tooltipElement}
        </div>
        <div
          ref={this.child}
          className={wrapperClassname}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {children}
        </div>
      </React.Fragment>
    );
  }
}

Tooltip.defaultProps = {
  delayTime: 500
};

Tooltip.propTypes = {
  wrapperClassname: PropTypes.string,
  tooltipElement: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};

export default Tooltip;
