import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import manageMeasureActions from "../../../../actions/measure/manage-measure";
import outputActions from "../../../../actions/stakeholder-items/output";
import ontologyActions from "../../../../actions/ontology";
import measureActions from "../../../../actions/measure/measure";
import Outputs from "../../../../components/logic-models/stakeholder-items/add-output/Outputs";


function mapStateToProps(state, ownProps) {
  return {
    ...state.measurePage,
    ...state.activities,
    ...state.ontology,
    ...state.outputs,
    output: ownProps.output,
    stakeholder: ownProps.stakeholder,
    intl: state.intl
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...manageMeasureActions,
      ...measureActions,
      ...outputActions,
      ...ontologyActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Outputs)