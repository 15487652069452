import React from "react";
import Modal from "react-bootstrap-modal";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { InjectIntl } from "./common/InjectIntl";



const CalculationsSnapshotModalWindow = (props) => {
  const {
    snapshots,
    isCalculationShapshotsModalWindowOpen,
    actions,
    intl,
  } = props;
  const TABLE_HEADERS = [
    {
      keyField: "value",
      isEditable: false,
      name: intl("calculationPage.snapshots.table.value"),
    },
    {
      keyField: "date_calculated",
      isEditable: false,
      name: intl("calculationPage.snapshots.table.calculatedDate"),
    },
    {
      keyField: "date_coverage_start",
      isEditable: false,
      name: intl("calculationPage.snapshots.table.coverageStarted"),
    },
    {
      keyField: "date_coverage_end",
      isEditable: false,
      name: intl("calculationPage.snapshots.table.coverageEnded"),
    },
    {
      keyField: "comment",
      isEditable: true,
      name: intl("calculationPage.snapshots.table.comment"),
    },
  ];
  const onAfterSaveCell = (row, _, cellValue) => {
    if (row.comment !== cellValue) {
      actions.saveSnapshotComment(row.id, cellValue);
    }
  };
  const cellEditProp = {
    mode: "click",
    // blurToSave: true,
    beforeSaveCell: onAfterSaveCell,
  };
  return (
    <Modal
      dialogClassName={"modal-lg"}
      show={isCalculationShapshotsModalWindowOpen}
      id="foreground_display"
    >
      <Modal.Header>
        <Modal.Title>{intl("calculationPage.snapshots.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BootstrapTable data={snapshots} cellEdit={cellEditProp}>
          {TABLE_HEADERS.map(({ keyField, isEditable, name }, index) => (
            <TableHeaderColumn
              width="30"
              isKey={index === 0}
              dataField={keyField}
              editable={isEditable}
              editColumnClassName="edit-cell"
              tdStyle={{
                margin: 0,
              }}
            >
              {name}
              {isEditable && (
                <i
                  class="fa fa-pencil"
                  style={{ margin: " 0 0 0 10px" }}
                  aria-hidden="true"
                ></i>
              )}
            </TableHeaderColumn>
          ))}
        </BootstrapTable>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => actions.setCalculationsSnapshots(false)}
          className="btn btn-success btn-outline transparent"
        >
          {intl("common.text.close_button")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default InjectIntl(CalculationsSnapshotModalWindow);
