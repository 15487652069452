import {bindActionCreators} from "redux";
import { connect } from "react-redux";
import React from 'react';
import PropTypes from 'prop-types';
import SpinnerActions from "../actions/spinner";
import RenderSpinnerSweetAlert from "./RenderSpinnerSweetAlert";


class LoadingNotification extends React.Component {
  static propTypes = {
    showLoading: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      showSpinner: PropTypes.func.isRequired,
      hideSpinner: PropTypes.func.isRequired
    })
  };

  componentDidUpdate(prevProps) {
    if(this.props.showLoading) {
      this.props.actions.showSpinner()
    } else {
      this.props.actions.hideSpinner()
    }
  }

  render() {
    return (
      <div>
        <RenderSpinnerSweetAlert/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state.loadingModal
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(SpinnerActions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadingNotification)