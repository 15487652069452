import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DataSourceActions from "../../../actions/calculation/data-source";
import CalculationsActions from "../../../actions/calculation/calculation-page";
import SourceTypes from "../../../components/calculations/sources/SourceTypes";
import ErrorActions from "../../../actions/errors";
import ConditionsActions from "../../../actions/calculation/conditions";

function mapStateToProps(state) {
  return {
    ...state.dataSource,
    ...state.calculations,
    intl: state.intl
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...DataSourceActions,
      ...CalculationsActions,
      ...ErrorActions,
      setConditionInitialState: ConditionsActions.setConditionInitialState
    }, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SourceTypes)