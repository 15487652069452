import React from 'react';
import { InjectIntl } from "../common/InjectIntl";

class AnswersOnQuestions extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showAnswer: false,
			why: false,
			how: false
		}
	}

	handleChooseWhy = () => {
		this.setState({
			showAnswer: true,
			why: true,
			how: false
		})
	};

	handleChooseHow = () => {
		this.setState({
			showAnswer: true,
			why: false,
			how: true
		})
	};

	renderAnswer = () => {
		const {showAnswer, why, how} = this.state;
		if (showAnswer) {
			if (why) {
				return (
					<p>{this.props.intl('create_logic_model.answer.first_text')}</p>
				)
			} else if (how) {
				return (
					<p>{this.props.intl('create_logic_model.answer.second_text')}</p>
				)
			}
		}
	};

	render() {
		return (
			<div className="form-goup">
				<p>{this.props.intl('create_logic_model.questions')}</p>
				<div className="form-group">
					<label>
						<input
							type="radio"
							name="q0b"
							value="why"
							onChange={this.handleChooseWhy}
						/>
							&nbsp; {this.props.intl('create_logic_model.question.first_text')}
					</label>
				</div>
				<div className="form-group">
					<label>
						<input
							type="radio"
							name="q0b"
							value="how"
							onChange={this.handleChooseHow}
						/>
            &nbsp; {this.props.intl('create_logic_model.question.second_text')}
					</label>
				</div>

				{this.renderAnswer()}

			</div>
		)

	}

}

export default InjectIntl(AnswersOnQuestions);