import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import SelectVariableTypeModal from "../../../../containers/calculations/sources/modals/SelectVariableTypeModal.container";
import SelectPreexistingCalculation from "../../../../containers/calculations/sources/modals/SelectPreexistingCalculation.container";
import SourcesWorkspaces from "../../../../containers/calculations/sources/SourceWorkspaces.container";
import SourcesFormOrUpload from "../../../../containers/calculations/sources/SourceFormOrUpload.container";
import DataSetSelection from "./DataSetSelection";
import { InjectIntl } from "../../../common/InjectIntl";
import SourceModalFinalTab from "../../../../containers/calculations/sources/modals/SourceModalFinalTab.container";
import SelectSourceDetailsOptions from "./SelectSourceDetailsOptions";
import SourcesQuestionOrColumn from "../../../../containers/calculations/sources/SourceColumn.container";
import OneSourceTransformation from "./OneSourceTransformation";
import SourceModalIndexTable from "./SourceModalIndexTable";
import OneSourceModal from "./OneSourceModal";
import CalculationsSource from "../SourceTypesIndex";
import SourceArrayCalculation from "../../../../containers/calculations/sources/modals/SourceArrayCalculation.container";
import { has } from 'lodash';
import SourceModalArrayCalculationFinalTab from './SourceModalArrayCalculationFinalTab';
import { isArrayCalculationReady } from '../../../../assets/sam-utils';
import { COMPLETE } from '../source-table-layout/constants';
import { EDIT } from "../../../members/constants";


const IndexModals = ( props ) => {

  const { oneSourceModalVisible, currentDataSource, firstColumnVisible, finalDataIsLoading, intl, actions, showStandardCalculation, showArrayCalculation, activeVariable } = props;

  const [arrayCalculationReady, setArrayCalculationReady] = useState(false);

  useEffect(function() {
    const { activeVariable } = props;
    let arrayCalculationStatus = isArrayCalculationReady(activeVariable);
    setArrayCalculationReady(arrayCalculationStatus === COMPLETE);
  });

  const renderArrayCalculation = () => {
    if(showArrayCalculation) {
      return <SourceArrayCalculation {...props} />;
    }
    return null;
  }

  const renderStandardCalculationSourceDetails = () => {
    if(showStandardCalculation) {
      return (
        <SelectSourceDetailsOptions {...props} >
          <div className="row m-t">
            <div className="col-md-6">
              <SourcesQuestionOrColumn/>
            </div>
            <OneSourceTransformation {...props}/>
          </div>
        </SelectSourceDetailsOptions>
      );
    }
    return null;
  }

  const renderFinalTab = () => {

    if(currentDataSource.transformation) {
      return <SourceModalFinalTab />;
    }

    // may not need this afterall as for now we just want to save and exit
    if(activeVariable.use_array_calculation) {
      if(arrayCalculationReady) {
        return <SourceModalArrayCalculationFinalTab />
      }
    }

    return null;
  }
  const isReadOnlyPermission = localStorage.getItem("permission") !== EDIT;
  
  return (
    <div>
      <SelectVariableTypeModal/>
      <SelectPreexistingCalculation/>
      {oneSourceModalVisible &&
      <OneSourceModal {...props} arrayCalculationReady={arrayCalculationReady}>
        {firstColumnVisible ?
          <div>
            <DataSetSelection finalDataIsLoading={finalDataIsLoading}>
              <div className="row">
                <div className="m-l"><span>{intl('source.modal.select_the_dataset')}</span></div>
                <div className="col-md-12 m-l m-t">
                  <CalculationsSource currentDataSource={currentDataSource}>
                    <SourcesWorkspaces>
                      <SourcesFormOrUpload/>
                    </SourcesWorkspaces>
                  </CalculationsSource>
                </div>
              </div>
            </DataSetSelection>
            <SourceModalIndexTable {...props}>
              <div className="row m-t">
                <div className="col-md-6">
                  <ul className="nav nav-tabs" style={{ borderBottom: "none" }}>
                    <button style={{color:'#fff'}} className={`btn btn-default ${showStandardCalculation ? 'btn-warning' : ''}`} onClick={() => actions.selectStandardCalculation()} disabled={isReadOnlyPermission}>Standard</button>
                    <button style={{color:'#333'}} className={`btn btn-default ${showArrayCalculation ? 'btn-warning' : ''}`} onClick={() => actions.selectArrayCalculation()} disabled={isReadOnlyPermission}>Array</button>
                  </ul>
                </div>
              </div>
              {renderStandardCalculationSourceDetails()}
              {renderArrayCalculation()}
            </SourceModalIndexTable>
          </div> :
          <div>
            {renderFinalTab()}
          </div>
        }
      </OneSourceModal>
      }
    </div>
  );
};

IndexModals.propTypes = {
  oneSourceModalVisible: PropTypes.bool.isRequired,
  firstColumnVisible: PropTypes.bool.isRequired,
  finalDataIsLoading: PropTypes.bool.isRequired,
  currentDataSource: PropTypes.object.isRequired,
  intl: PropTypes.func.isRequired
};

export default InjectIntl(IndexModals)