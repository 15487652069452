import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import manageMeasureActions from "../../../actions/measure/manage-measure";
import ontologyActions from "../../../actions/ontology";
import StakeholderItems from "../../../components/measure/StakeholderItems";


function mapStateToProps(state, ownProps) {
  return {
    ...state.measurePage,
    ...state.dataSource,
    ...state.intl,
    ...state.ontology,
    stakeholder: ownProps.stakeholder,
    displayStakeholderLine: ownProps.displayStakeholderLine,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...manageMeasureActions,
      ...ontologyActions,
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StakeholderItems)