import React from "react";
import PropTypes from "prop-types";
import OutsideClickDetection from "../../../common/OutsideClickDetection";
import { EDIT } from "../../../members/constants";

const VariableNameInModal = props => {
  const {
    changeVariableName,
    saveChanges,
    variableNameInputShow,
    intl,
    name,
    closeInputField,
    clickVariableNameHandler
  } = props;
  const changeVariableNameClickHandler = e => changeVariableName(e);

  const saveChangesClickHandler = e => saveChanges(e);
  const isReadOnlyPermission = localStorage.getItem("permission") !== EDIT;
  
  return (
    <div className="final-modal-tab-variable-name m-t m-b">
      {variableNameInputShow ? (
        <OutsideClickDetection hideAction={closeInputField}>
          <div className="input-group">
            <input
              type="text"
              autoFocus="autoFocus"
              placeholder={intl("calculation.enter_variable_name")}
              className="form-control success"
              onChange={changeVariableNameClickHandler}
            />
            <span className="input-group-btn">
              <button
                type="button"
                title="Save"
                className="btn btn-success"
                onClick={saveChangesClickHandler}
              >
                <i className="fa fa-check" />
              </button>
            </span>
          </div>
        </OutsideClickDetection>
      ) : (
        <div
          disabled={isReadOnlyPermission}
          className="form-control form-control-lg"
          onClick={isReadOnlyPermission ? '' : clickVariableNameHandler}
        >
          {name}
        </div>
      )}
    </div>
  );
};

VariableNameInModal.propTypes = {
  variableNameInputShow: PropTypes.bool.isRequired,
  intl: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  closeInputField: PropTypes.func.isRequired,
  changeVariableName: PropTypes.func.isRequired,
  saveChanges: PropTypes.func.isRequired,
  clickVariableNameHandler: PropTypes.func.isRequired
};

export default VariableNameInModal;
