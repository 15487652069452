export const SHOW_GOOD_JOB = '@@GOOD_JOB_SWEET_ALERT/SHOW_GOOD_JOB';
export const HIDE_GOOD_JOB = '@@GOOD_JOB_SWEET_ALERT/HIDE_GOOD_JOB';

function showGoodJobAlert() {
  return {type: SHOW_GOOD_JOB}
}

function hideGoodJob() {
  return {type: HIDE_GOOD_JOB}
}

export default {
  showGoodJobAlert,
  hideGoodJob
}