import React from "react";
import PropTypes from "prop-types";
import Inputs from "../../containers/logic-models/stakeholders-items/add-input/Inputs.container";
import Activities from "../../containers/logic-models/stakeholders-items/add-activity/Activity.container";
import Outcomes from "../../containers/logic-models/stakeholders-items/add-outcome/Outcome.container";
import { InjectIntl } from "../common/InjectIntl";


class StakeholderItems extends React.Component {
  static propTypes = {
    displayStakeholderLine: PropTypes.string.isRequired,
    stakeholder: PropTypes.object.isRequired,
  };

  render() {
    if (!this.props.stakeholder) {
      return (<div className="sk-spinner sk-spinner-rotating-plane" />)    }
    const {
      displayStakeholderLine,
      stakeholder,
    } = this.props;

    const outcomes = stakeholder? Object.values(stakeholder.outcomes): [];
    const inputs = stakeholder? Object.values(stakeholder.inputs): [];
    const activities = stakeholder? Object.values(stakeholder.activities): [];

    return (
      <div className="ibox-content ibox-heading" style={{display: displayStakeholderLine }}>
        <div className="editable-measures-list-wrap">
          <div className="row">
            <div className="col-md-4" id="input"> <Inputs inputs={inputs} stakeholder={stakeholder}/> </div>
            <div className="col-md-4" id="activity"> <Activities activities={activities} stakeholder={stakeholder}/> </div>
            <div className="col-md-4" id="outcome"> <Outcomes outcomes={outcomes} stakeholder={stakeholder}/> </div>
          </div>
        </div>
      </div>
    )
  }
}

export default InjectIntl(StakeholderItems);