import React from "react";
import PropTypes from "prop-types";
import { InjectIntl } from "../common/InjectIntl";
import MathExpressions from "math-expressions";

import FormulaResult from "./FormulaResult";
import PermissionChecker from "../common/PermissionChecker";
import { has } from "lodash";
import CalculationsSnapshotModalWindow from "../../containers/CalculationsSnapshotModalWIndow.container"
// eslint-disable-next-line
const MathQuill = require("exports-loader?window.MathQuill!imports-loader?window.jQuery=jquery!mathquill/build/mathquill.js");

class FormulaEditor extends React.Component {
  constructor(props) {
    super(props);
    this.MQ = MathQuill.getInterface(2);
    this.MQFieldRef = null;
    this.state = {
      isButtonActive: false,
      showValue: true,
    };
  }

  static propTypes = {
    currentCalculation: PropTypes.shape({
      string: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      latex: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      condition: PropTypes.string,
      invalid: PropTypes.bool,
    }),
    actions: PropTypes.shape({
      changeFormula: PropTypes.func.isRequired,
      saveFormula: PropTypes.func.isRequired
    }),
    intl: PropTypes.func.isRequired,
    active: PropTypes.bool,
    invalid: PropTypes.bool,
  };

  static defaultProps = {
    active: true,
  }

  handleFormulaEdit = latex => {
    let string = "";
    latex = latex.replace(/\\cdot/g, "\\cdot ");
    const notAllowed = ["!", "<", ">", ",", ";", ":", "=", ":", "'"];
    if (new RegExp(notAllowed.join("|")).test(latex)) {
      this.props.actions.changeFormula(
        latex,
        this.props.intl("calculation.invalid_experssion")
      );
      return;
    }
    
    if (latex.length > 0) {
      this.props.actions.changeFormula(latex, string);
    } else if (!latex.length) {
      this.props.currentCalculation.string &&
        this.props.actions.changeFormula(latex, string);
      return;
    }
    try {
      let f = MathExpressions.fromLatex(latex);
      f = MathExpressions.math.parse(f.toString());

      /*
        In the scenario where the parser encounters
        A(B/C) We want this to output A*(B/C)

        In order to do this, we need to explicitly add this wrapping during string conversion
      */
     const formatStringOptions = {
      "implicit":"show",
      "notation": "fixed",
     }
      const implicitMultiplicationFormatter = (n,o) => {
        if (n.fn && n.fn.type && n.fn.type == "SymbolNode") {
            return `${n.fn} * (${n.args.map(arg => arg.toString(formatStringOptions)).join(' ')})`;
        }
      }
      const formatOptions = {
        "implicit":"show",
        "notation": "fixed",
        "handler": implicitMultiplicationFormatter,
      }
      string = f.toString(formatOptions);

      this.props.actions.changeFormula(latex, string);
    } catch (e) {
      this.props.actions.changeFormula(
        latex,
        this.props.intl("calculation.invalid_experssion")
      );
      this.setState({ isButtonActive: false });
    }
  };

  componentDidMount() {
    this.initMathQuill(this.props.currentCalculation);
    this.setState({ isButtonActive: false });
  }

  componentWillUnmount() {
    const clearData = "";
    this.setState({ isButtonActive: false });
    this.props.actions.changeFormula(clearData, clearData);
  }

  initMathQuill = calc => {
    const mathField = this.MQ.MathField(this.MQFieldRef, {
      spaceBehavesLikeTab: true,
      handlers: {
        edit: () => {
          this.handleFormulaEdit(mathField.latex());
        }
      }
    });
    mathField.latex(calc.latex ? calc.latex : "");
  };

  componentDidUpdate(prevProps) {
    if (this.props.currentCalculation.id !== prevProps.currentCalculation.id) {
      this.initMathQuill(this.props.currentCalculation);
      this.setState({ isButtonActive: false });
    } else if (
      this.props.currentCalculation.latex !== prevProps.currentCalculation.latex
    ) {
      this.setState({ isButtonActive: true });
      this.setState({ showValue: false });
    }
  }

  saveCalculationFormula = e => {
    this.props.actions.saveFormula();
    this.setState({ isButtonActive: false });
    this.setState({ showValue: true });
    e.target.blur();
  };

  render() {
    const { currentCalculation, intl, actions } = this.props;
    const invalid = currentCalculation.invalid || false;
    return (
      <div id="formula-editor row">
        <div className={`form-group ${invalid ? "has-error" : ""}`}>
          <div className="text-right row" style={{ marginRight: 0 }}>
            <div className="col-md-9 text-right m-t-7">
              {!invalid && (
                <FormulaResult
                  currentCalculation={currentCalculation}
                  showValue={this.state.showValue}
                />
              )}
            </div>
            <div className="col-md-1">
              <PermissionChecker hide={true}>
                <button
                  id="save_formula_btn"
                  className={`btn btn-success btn-outline ${
                    this.state.isButtonActive ? "" : "disable-hover transparent"
                  }`}
                  onClick={this.saveCalculationFormula}
                >
                  {intl("calculation.save_formula")}
                </button>
              </PermissionChecker>
            </div>
          </div>
          <label>{intl("calculation.enter_formula")}</label>
          <p>
            <span
              id="math-field"
              className={`form-control mq-editable-field mq-math-mode ${
                this.props.active ? "" : "content-disabled"
              } ${invalid ? "has-error" : ""}`}
              ref={(node) => (this.MQFieldRef = node)}
            />
            {invalid && (
              <span class="help-block pull-right">Invalid expression</span>
            )}
          </p>
        </div>
        <div className="form-group">
          <label>{intl("calculation.string")}</label>
          <p className="well">{currentCalculation.string}</p>
        </div>
        <CalculationsSnapshotModalWindow calculations={currentCalculation} />
      </div>
    );
  }
}

export default InjectIntl(FormulaEditor);
