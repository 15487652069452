export const SHOW_SPINNER = '@@SPINNER/SHOW_SPINNER';
export const HIDE_SPINNER = '@@SPINNER/HIDE_SPINNER';

function showSpinner() {
  return { type: SHOW_SPINNER }
}

function hideSpinner() {
  return { type: HIDE_SPINNER }
}

export default {
  hideSpinner,
  showSpinner
}