import { Observable } from "rxjs/Observable";
import 'rxjs';

import {
  HIDE_CONDITION_PANEL,
  SEND_CONDITION,
  SEND_CONDITION_FAILURE,
  CHANGE_APPLIED_CONDITIONS
} from "../../actions/calculation/conditions";
import { FETCH_FINAL_SOURCE_DATA } from "../../actions/calculation/source-modals";

function sendTransformationOptionsEpic(action$, store) {
  return action$.ofType(SEND_CONDITION)
    .mergeMap(() => {
      const { conditionPanel: { conditions }, dataSource: { currentDataSource } } = store.getState();
      const headerId = currentDataSource.headerId;
      return Observable.concat(
        Observable.of({ type: CHANGE_APPLIED_CONDITIONS, conditions: conditions }),
        Observable.of({ type: HIDE_CONDITION_PANEL }),
        Observable.of({ type: FETCH_FINAL_SOURCE_DATA, headerId })
      )
        .catch(errors => Observable.of({ type: SEND_CONDITION_FAILURE, errors }))
    })
}

export default [
  sendTransformationOptionsEpic
]