import React from "react";
import PropTypes from "prop-types";
import VariableStatus from "./VariableStatus";
import VariableTransformation from "./VariableTransformation";
import VariableItemWrapper from "../../../containers/calculations/VariableItemWrapper.container";
import { getPrimaryStatus } from "../../../assets/sam-utils";

const InnerVariableItem = ({ innerVariableItem }) => {
  const status = getPrimaryStatus(innerVariableItem);

  return (
    <VariableItemWrapper
      variableItem={innerVariableItem}
      status={status}
      isParent={false}
    >
      <td />
      <td>
        <div className="m-l-40">
          <strong>
            {innerVariableItem.variable}.&nbsp;{innerVariableItem.name}
          </strong>
        </div>
      </td>
      <VariableStatus status={status} />
      <VariableTransformation
        status={status}
        variableItem={innerVariableItem}
      />
      <td />
    </VariableItemWrapper>
  );
};

InnerVariableItem.propTypes = {
  innerVariableItem: PropTypes.object.isRequired
};

export default InnerVariableItem;
