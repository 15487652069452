import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import Creatable from "react-select/creatable";
import classNames from "classnames";

const CreatableSelectBox = ({
  conditionBlock,
  options,
  label,
  selectedOption,
  onHeaderChange,
  placeholder,
  selectClass,
  SelectBoxClassNames,
  labelClassName,
  children,
  multiSelect,
  disabled,
  handleCreateOption,
  isCreatable
}) => {
  const selectRowClassNames = classNames({
    row: !conditionBlock,
    "condition-flex": conditionBlock,
  });

  return (
    <div className={selectRowClassNames}>
      <div className={SelectBoxClassNames}>
        {isCreatable ? (
          <Creatable
            options={options}
            value={selectedOption}
            onChange={onHeaderChange}
            placeholder={placeholder}
            className={selectClass}
            isMulti={multiSelect}
            isDisabled={disabled}
            onCreateOption={handleCreateOption}
          />
        ) : (
          <Select
            options={options}
            value={selectedOption}
            onChange={onHeaderChange}
            clearable={false}
            placeholder={placeholder}
            className={selectClass}
            isDisabled={disabled}
            isMulti={multiSelect}
          />
        )}
      </div>
      {children}
    </div>
  );
};

export const rowPropTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  selectedOption: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
  onHeaderChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  selectClass: PropTypes.string,
  SelectBoxClassNames: PropTypes.string,
  labelClassName: PropTypes.string,
  multiSelect: PropTypes.bool,
  disabled: PropTypes.bool,
};

CreatableSelectBox.propTypes = rowPropTypes;

export default CreatableSelectBox;