import React from "react";
import PropTypes from "prop-types";
import AddInputParams from "../../../../containers/logic-models/stakeholders-items/add-input/InputItem.container"
import { InjectIntl } from "../../../common/InjectIntl";
import PermissionChecker from "../../../common/PermissionChecker";

class Inputs extends React.Component {
  static propTypes = {
    stakeholder: PropTypes.object.isRequired,
    inputs: PropTypes.array.isRequired,
    actions: PropTypes.shape({
      addInputsMeasure: PropTypes.func,
      changeStakeholderId: PropTypes.func,
      logicModelLoading: PropTypes.func,
    }.isRequired)
  };

  state = {
    isHovering : false,
  };


  handleMouseHover=()=> {
    this.setState({ isHovering : !this.state.isHovering });
  };

  handleMouseMove = () => {
    if(!this.state.isHovering) {
      this.setState({ isHovering : true });
    }
  };

  _shouldDisplay() {
    return (this.props.inputs.length > 0)
  }

  addInputHandlerClick = () => {
    this.props.actions.changeStakeholderId(this.props.stakeholder.id);
    this.props.actions.logicModelLoading();
    this.props.actions.addInputsMeasure();
  };


  render() {
    const visibility = this.state.isHovering ? "visible" : "hidden";

    return (
      <div className="editable-measures-list" onMouseEnter={this.handleMouseHover}
           onMouseLeave={this.handleMouseHover} onMouseMove={this.handleMouseMove} >
        <div className="text-right add-measure-button-wrap" style={{visibility: visibility}}>
          <PermissionChecker hide={true}>
            <button className="btn btn-success btn-outline" onClick={this.addInputHandlerClick}>
              {this.props.intl('stakeholder.items.add_input')}
            </button>
          </PermissionChecker>
        </div>
        { this._shouldDisplay() ?
          this.props.inputs.map((inputMeasure, index) =>
            <AddInputParams stakeholder={this.props.stakeholder} key={index} measure={inputMeasure}/>
          )
          :
          <PermissionChecker hide={true}>
            <div className="large-add-measure-button m-t" onClick={this.addInputHandlerClick}>
              <i className="fa fa-plus"/> {this.props.intl('stakeholder.items.add_input')}
            </div>
          </PermissionChecker>
        }
      </div>
    )
  }
}

export default InjectIntl(Inputs);