import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import LogicModelIndexTable from "./LogicModelIndexTable";
import { STYLEFORLINK } from "../utils";
import { browserHistory } from 'react-router';
import { InjectIntl } from "../common/InjectIntl";
import {EDIT, READ} from "../members/constants";
import ActionsDropDown from '../common/ActionsDropDown';


class LogicModels extends React.Component {
  static propTypes = {
    handelOpenCreateLogicModelsForm: PropTypes.func.isRequired,
    logicModels: PropTypes.array.isRequired,
    showInputField: PropTypes.bool.isRequired,
    logicModelId: PropTypes.number.isRequired,
    actions: PropTypes.shape({
      deleteLogicModel: PropTypes.func.isRequired,
      setDeleteParams: PropTypes.func.isRequired,
      showDeleteModalItem: PropTypes.func.isRequired
    })
  };

  deleteLogicModel = (logicModelId) => {
    const { actions, intl } = this.props;

    actions.setDeleteParams(intl('sweetAlert.delete_logic_model.hint_text'), actions.deleteLogicModel, logicModelId);
    actions.showDeleteModalItem();
  };

  editLogicModel = (logicModelId) => {
    browserHistory.push(`/logic-models/${logicModelId}/edit`);
  };

  exportJSON = (logicModelId, logicModelName) => {
    this.props.actions.exportJSON(logicModelId, logicModelName);
  }

  actionsFormat = (cell, row, caret, btn, style) => {
    const isViewOnlyPermission = row.permission === READ;
    const editBtnProps = {
      title: `common.text.${isViewOnlyPermission ? READ : EDIT}`,
      onClick: () => this.editLogicModel(row.id)
    };
    const deleteBtnProps = {
      disabled: isViewOnlyPermission,
      title: "common.text.delete",
      onClick: () => this.deleteLogicModel(row.id)
    };
    const exportJSONBtnProps = {
      disabled: false,
      title: "common.text.exportJSON",
      onClick: () => this.exportJSON(row.id, row.name),
    };

    return (
      <ActionsDropDown
        caretClassName={caret}
        btnClassName={btn}
        styleForLink={style}
        actions={[editBtnProps, deleteBtnProps, exportJSONBtnProps]}
      />
    );
  };


  wrapCellContentWithTip = (cellContent, cell, row) => {
    return <div data-tip={cellContent} data-event='mouseenter' data-event-off='mouseout click'>
      <div style={{ zIndex: -1, overflow: 'hidden', textOverflow: 'ellipsis' }}>{cellContent}<ReactTooltip/></div>
      {this.actionsFormat(cell, row, '', '', STYLEFORLINK)}</div>;
  };

  logicModelTitleFormat = (cell, row) => {
    const title = row.name;
    return this.wrapCellContentWithTip(title, cell, row);
  };

  createdDateFormat = (cellContent, cell, row) => {
    cellContent = moment(row.pivot.created_at).format('DD/MM/YYYY');

    return this.wrapCellContent(cellContent, cell, row);
  };

  wrapCellContent = (cellContent, cell, row) => {
    return <div>
      <div style={{ zIndex: -1 }}>{cellContent}</div>
      {this.actionsFormat(cell, row, '', '', STYLEFORLINK)}</div>;
  };

  columns = () => [
    {
      name: 'name',
      hasSort: true,
      title: this.props.intl('common.text.name'),
      className: 'allow-overflow',
      tdStyle: { position: 'relative' },
      localized: true,
      format: (cell, row) => this.logicModelTitleFormat(cell, row)
    },
    {
      name: 'has_sroi',
      hasSort: true,
      title: this.props.intl('logic_models_index_table.report_type'),
      className: 'allow-overflow',
      tdStyle: { position: 'relative' },
      format: (cell, row) => <div>
        <div style={{ zIndex: -1 }}>{cell === 0 ? "Outcomes" : "SROI"}</div>
        {this.actionsFormat(cell, row, '', '', STYLEFORLINK)}</div>
    },
    {
      name: 'created_at',
      hasSort: true,
      title: this.props.intl('logic_models_index_table.created'),
      className: 'allow-overflow',
      tdStyle: { position: 'relative' },
      localized: true,
      format: (cell, row) => this.createdDateFormat(row.created_at, row.created_at, row)
    },
    {
      title: this.props.intl('logic_models_index_table.actions'),
      noSearch: true,
      width: '75px',
      className: 'allow-overflow',
      tdStyle: { position: 'relative' },
      format: (cell, row) => this.actionsFormat(cell, row, 'caret', 'btn btn-default btn-xs')
    }
  ];

  prepareData = (logicModels) => {
    logicModels.forEach((curr) => {
      curr.created_at = moment(curr.created_at).format('DD/MM/YYYY');
    });
    return logicModels;
  };

  render() {
    return (
      <>
        <LogicModelIndexTable
          logicModels={this.prepareData(this.props.logicModels)}
          keyField="id"
          onReload={() => this.props.actions.fetchLogicModels()}
          columns={this.columns()}
          noDataText={this.props.intl("logic_models_index_table.noLogicModels")}
        />
        <a id="downloadAnchorElem" style={{display: "none"}}></a>
      </>
    );
  }
}

export default InjectIntl(LogicModels);