import React from 'react';
import PropTypes from 'prop-types';
import { InjectIntl } from "../common/InjectIntl";
import ErrorNotification from "../../containers/ErrorNotification.container";
import { browserHistory } from 'react-router';
import MeasureCalculationDetails from "../../containers/calculations/MeasureCalculationsDetails.container";
import IndexModals from "../../containers/calculations/sources/modals/IndexModals.container";
import RenderSpinnerSweetAlert from "../../containers/RenderSpinnerSweetAlert";
import GoodJobSweetAlert from "../../containers/GoodJobSweetAlert";
import ModalDeleteItem from "../../containers/ModalDeleteItem.container";
import ModalChangeMeasureName from "../../containers/ModalChangeMeasureName.container";

class CalculationsIndex extends React.Component {

  static propTypes = {
    calculations: PropTypes.array.isRequired,
    measureId: PropTypes.string.isRequired,
    logicModelId: PropTypes.string.isRequired,
    measureName: PropTypes.string.isRequired,
    measureTypeTitle: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fetchSourceIsLoading: PropTypes.bool.isRequired,
    currentCalculation: PropTypes.object.isRequired,
    activeVariable: PropTypes.object,
    actions: PropTypes.shape({
      getMeasureCalculations: PropTypes.func.isRequired,
      setMeasureId: PropTypes.func.isRequired,
      changeCalculationName: PropTypes.func.isRequired,
      dataSourceInitialState: PropTypes.func.isRequired,
      showSpinner: PropTypes.func.isRequired,
      hideSpinner: PropTypes.func.isRequired,
      deleteVariable: PropTypes.func.isRequired
    })
  };

  componentDidMount() {
    const { actions, measureId, isLoading, fetchSourceIsLoading } = this.props;
    actions.setMeasureId(measureId);
    actions.getMeasureCalculations(0);
    if (!isLoading && !fetchSourceIsLoading) actions.hideSpinner();
    actions.fetchSource();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.currentCalculation || this.props.isLoading || this.props.fetchSourceIsLoading)
      this.props.actions.showSpinner();
    else
      this.props.actions.hideSpinner();
  }

  closeCalculation = () => {
    this.props.actions.dataSourceInitialState();
    this.props.actions.setSourceInitialState();
    browserHistory.push(`/logic-models/${this.props.logicModelId}/edit`)
  };

  componentWillUnmount() {
    this.props.actions.dataSourceInitialState();
    this.props.actions.setSourceInitialState();
    this.props.actions.setSourceModalInitialState();
  }

  render() {
    const { measureName, measureTypeTitle, actions } = this.props;
    return (
      <div className="overlay">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-10 pull-left">
              <h1
                className="changeable"
                onClick={() => actions.setChangeMeasureNameModalOpen(true)}
              >
                <i className="fa fa-calculator fa-2x disable-hover" />{" "}
                {measureTypeTitle}
                <strong> - {this.props.measureName}</strong>
              </h1>
              <p>{this.props.measureDescription}</p>
            </div>
          </div>
          <MeasureCalculationDetails
            closeCalculation={this.closeCalculation}
            measureName={measureName}
          />
        </div>
        <ModalChangeMeasureName />
        <ModalDeleteItem />
        <ErrorNotification />
        <RenderSpinnerSweetAlert />
        <IndexModals />
        <GoodJobSweetAlert />
      </div>
    );
  }
}

export default InjectIntl(CalculationsIndex);