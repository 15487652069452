import merge from 'xtend';
import createReducer from '../create-reducer';
import {
  ADD_OUTCOME_INDICATOR_MEASURE,
  ADD_OUTCOME_INDICATOR_MEASURE_FAILURE,
  ADD_OUTCOME_INDICATOR_MEASURE_SUCCESS,
  HIDE_OUTCOME_INDICATOR_FIELD,
  SHOW_OUTCOME_INDICATOR_FIELD
} from "../../actions/stakeholder-items/outcomeIndicator";
import { SAVE_MEASURE_NAME_SUCCESS } from "../../actions/measure/measure";


const INITIAL_STATE = {
  measureIsLoading: false,
  errors: {},
  outcomeIndicatorMeasureNameFieldDisable: false
};

export default createReducer({
  [ADD_OUTCOME_INDICATOR_MEASURE]: (state, action) => merge(state, {measureIsLoading: true }),
  [ADD_OUTCOME_INDICATOR_MEASURE_SUCCESS]: (state, action) => merge(state, {measureIsLoading: false }),
  [ADD_OUTCOME_INDICATOR_MEASURE_FAILURE]: (state, action) => merge(state, {
    errors: action.payload.errors,
    measureIsLoading: false
  }),
  [SHOW_OUTCOME_INDICATOR_FIELD]: (state, actions) => merge(state, {outcomeIndicatorMeasureNameFieldDisable: true }),
  [HIDE_OUTCOME_INDICATOR_FIELD]: (state, action) => merge(state, {outcomeIndicatorMeasureNameFieldDisable: false }),
  [SAVE_MEASURE_NAME_SUCCESS]: (state, action) => merge(state, {outcomeIndicatorMeasureNameFieldDisable: false})
}, INITIAL_STATE)