import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MeasureActions from "../../../actions/measure/measure";
import Definitions from "../../../components/logic-models/stakeholder-items/definitions/Definitions";

function mapStateToProps(state, ownProps) {
  return {
    isDefinitionsShowed: state.measurePage.isDefinitionsShowed
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...MeasureActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Definitions);
