import React from 'react';
import PropTypes from 'prop-types';
import { InjectIntl } from "../../../common/InjectIntl";
import FinancialProxy from "../../../../containers/logic-models/stakeholders-items/add-financial-proxy/FinancialProxy.container";
import MeasureNameComponent from "../MeasureNameComponent";
import PermissionChecker from '../../../common/PermissionChecker';


const OutcomeIndicator = (props) => {

  const { actions, outcomeIndicator, stakeholder, outcomeIndicatorMeasureNameFieldDisable, intl } = props;

  const closeHandler = () => actions.hideMeasureNameOutcomeIndicatorField();

  const saveOutcomeIndicatorMeasureName = outcomeIndicatorName => actions.changeOutcomeIndicatorMeasureName(outcomeIndicatorName, outcomeIndicator.id);

  const changeOutcomeIndicatorName = () => actions.showMeasureNameOutcomeIndicatorField();

  const handleAddFinancialProxyClick = (e) => {
    e.preventDefault();
    actions.changeStakeholderId(stakeholder.id);
    actions.changeParentMeasureId(outcomeIndicator.id);
    actions.addFinancialProxyMeasure();
  };

  return (
    <div className="nested-measure-item">
      <MeasureNameComponent
        closeHandler={closeHandler}
        saveMeasureName={saveOutcomeIndicatorMeasureName}
        changeMeasure={changeOutcomeIndicatorName}
        measureNameFieldDisable={outcomeIndicatorMeasureNameFieldDisable}
        measureTypeTitle={intl('stakeholder.items.outcome_indicator')}
        placeholder={intl('stakeholder.items.outcome_indicator.placeholder')}
        collapseIconShow={false}
        measure={outcomeIndicator}
        {...props}
      />
      <div className="sub-nested-measure-item">
        {outcomeIndicator.financial_proxies.map((finProxy, index) =>
          <FinancialProxy financialProxy={finProxy} key={index} stakeholder={stakeholder} parentType="Outcome"/>
        )}
        <PermissionChecker hide={true}>
          <button className="btn btn-success btn-outline btn-block" onClick={handleAddFinancialProxyClick}>
            <i className="fa fa-plus"/>{intl('stakeholder.items.add_financial_proxy')}
          </button>
        </PermissionChecker>
      </div>
    </div>
  );
};

OutcomeIndicator.propTypes = {
  outcomeIndicator: PropTypes.object.isRequired,
  stakeholder: PropTypes.object.isRequired,
  outcomeIndicatorMeasureNameFieldDisable: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  ontologySearching: PropTypes.bool.isRequired,
  ontologyResult: PropTypes.array.isRequired,
  intl: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    changeStakeholderId: PropTypes.func,
    addFinancialProxyMeasure: PropTypes.func,
    changeParentMeasureId: PropTypes.func,
    showMeasureNameOutcomeIndicatorField: PropTypes.func,
    hideMeasureNameOutcomeIndicatorField: PropTypes.func,
    changeOutcomeIndicatorMeasureName: PropTypes.func,
  }.isRequired)
};

export default InjectIntl(OutcomeIndicator);