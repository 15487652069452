import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const LogicModelSummary = ({ stakeholders }) => {
  
  const renderNestedMeasures = (stakeholderIndex, measureType, childMeasureType) => {
    let parentMeasures = stakeholders[stakeholderIndex][measureType];
    return (
      <section className={measureType}>
        <div className="side-title">
          <p className="capitalize">{measureType}</p>
        </div>
        <ul className="list-group">
          {parentMeasures.map(parentMeasure => {
            return (
              <Fragment key={parentMeasure.id}>
                <li key={parentMeasure.id} className="list-group-item disabled">{parentMeasure.name}</li>
                {parentMeasure[childMeasureType].map(childMeasure => {
                  return (
                    <li key={childMeasure.id} className="list-group-item">{childMeasure.name}</li>
                  );
                })}
              </Fragment>
            );
          })}
        </ul>
      </section>
    );
  }

  const renderInputs = (stakeholderIndex) => {
    let inputs = stakeholders[stakeholderIndex].inputs;
    let measureType = 'inputs';
    return (
      <section className={measureType}>
        <div className="side-title">
          <p className="capitalize input-title">{measureType}</p>
        </div>
        <ul className="list-group">
          {inputs.map(input => {
            return (
              <li key={input.id} className="list-group-item disabled">{input.name}</li>
            );
          })}
        </ul>
      </section>
    );
  }

  const renderTabList = () => {
    return (
      <TabList>
        {stakeholders.map(stakeholder => {
          return <Tab key={stakeholder.id}>{stakeholder.name}</Tab>;
        })}
      </TabList>
    );
  }

  const renderTabPanels = () => {
    return stakeholders.map((stakeholder, i) => {
      return (
        <TabPanel key={stakeholder.id}>
          {renderNestedMeasures(i, 'outcomes', 'outcome_indicators')}
          {renderNestedMeasures(i, 'activities', 'outputs')}
          {renderInputs(i)}
        </TabPanel>
      );
    });
  }

  return (
    <div className="row logic-model-summary-wrap">
      <div className="col-md-12">
        <Tabs>
          {renderTabList()}
          {renderTabPanels()}
        </Tabs>
      </div>
    </div>
  );
}

LogicModelSummary.propTypes = {
  stakeholders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      inputs: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
      outcomes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          outcome_indicators: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              name: PropTypes.string.isRequired,
            })
          )
        })
      ).isRequired,
      activities: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          outputs: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              name: PropTypes.string.isRequired,
            })
          )
        })
      ).isRequired
    })
  ).isRequired
};

export default LogicModelSummary;