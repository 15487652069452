import React from "react";
import PropTypes from "prop-types";
import { InjectIntl } from "../common/InjectIntl";
import OutsideClickDetection from "../common/OutsideClickDetection";
import SourcePanel from "./sources/source-table-layout/SourcePanel";
import VariableTable from "../../containers/calculations/VariableTable.container";
import FormulaEditor from "../../containers/calculations/FormulaEditor.container";
import { PREEXISTING_DATASOURCE_TYPE_ID } from "./sources/source-table-layout/constants";
import PermissionChecker from "../common/PermissionChecker";

class CurrentCalculationTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevName: ""
    };
  }

  static propTypes = {
    changeNameInputShow: PropTypes.bool.isRequired,
    currentCalculation: PropTypes.object.isRequired,
    calculationVariable: PropTypes.object.isRequired,
    activeVariable: PropTypes.object,
    errors: PropTypes.object.isRequired,
    calculations: PropTypes.array.isRequired,
    newCalcName: PropTypes.string.isRequired,
    clickCalculationNameInputFieldHandler: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      fetchSource:  PropTypes.func.isRequired,
      getMeasureCalculations: PropTypes.func.isRequired,
      changeCalculationName: PropTypes.func.isRequired,
      changeHierarchy: PropTypes.func.isRequired,
      deleteCalculation: PropTypes.func.isRequired,
      save: PropTypes.func.isRequired,
      showSelectVariableModal: PropTypes.func.isRequired,
      changeCurrentSourceName: PropTypes.func.isRequired,
      setDeleteParams: PropTypes.func.isRequired,
      showDeleteModalItem: PropTypes.func.isRequired
    })
  };

  deleteCalculationHandler = () => {
    const { actions, currentCalculation, intl } = this.props;

    if (currentCalculation.parent !== 1) {
      actions.setDeleteParams(
        intl("calculation.remove_calculation_hint"),
        actions.deleteCalculation,
        currentCalculation.id
      );
      actions.showDeleteModalItem();
    } else {
      const emptyCountryField = intl("errors.calculations.delete_parent");
      actions.setError(emptyCountryField);
    }
  };

  calculationNameClickHandler = () => {
    this.props.clickCalculationNameInputFieldHandler();
    this.setState({
      prevName: this.props.currentCalculation.name
    });
  };

  cancelSaveCalcName = () => {
    this.props.actions.changeCalculationName(
      this.state.prevName,
      this.props.currentCalculation.id
    );
    this.saveClickHandler();
  };

  changeName = e => {
    this.props.actions.changeCalculationName(
      e.target.value,
      this.props.currentCalculation.id
    );
    this.setState({ newCalcName: e.target.value });
  };

  saveClickHandler = () => {
    this.props.actions.save();
    this.props.clickCalculationNameInputFieldHandler();
  };

  changeCalculationHierarchy = e => {
    const changeParent = e.target.id === "parent" ? 1 : 0;
    let canBeParent = true;
    this.props.calculations
      .filter(calc => calc.parent === 1)[0]
      .variables.forEach(variable => {
        if (variable.datasource_type_id === PREEXISTING_DATASOURCE_TYPE_ID) {
          canBeParent = false;
          return;
        }
      });
    if (canBeParent) this.props.actions.changeHierarchy(changeParent);
  };

  addVariableClickHandler = e => {
    this.props.actions.showSelectVariableModal();
    const clearActiveVariable = {};
    this.props.actions.changeActiveVariable(clearActiveVariable);
    this.props.actions.changeCurrentSourceName("");
    e.target.blur();
  };

  redoCalculationClickHandler = e => {
    this.props.actions.getMeasureCalculations(1);
    this.props.actions.fetchSource();
  }

  renderCalculationName = () => {
    const { changeNameInputShow, currentCalculation } = this.props;
    return (
      <div className="col-md-6">
        {changeNameInputShow ? (
          <OutsideClickDetection hideAction={this.cancelSaveCalcName}>
            <div className="input-group">
              <input
                autoFocus="autofocus"
                type="text"
                placeholder={this.props.intl(
                  "calculation.enter_calculation_name"
                )}
                value={this.props.currentCalculation.name}
                className="form-control success"
                onChange={this.changeName}
              />
              <span className="input-group-btn">
                <button
                  type="button"
                  title="Save"
                  className="btn btn-success"
                  onClick={this.saveClickHandler}
                >
                  <i className="fa fa-check" />
                </button>
              </span>
            </div>
          </OutsideClickDetection>
        ) : (
          <PermissionChecker>
            <h3
              className="editable_name"
              onClick={this.calculationNameClickHandler}
            >
              {currentCalculation.name}
            </h3>
          </PermissionChecker>
        )}
      </div>
    );
  };

  renderParentChildButtons = () => {
    const { currentCalculation } = this.props;
    return (
      <div className="col-md-6 text-right">
        <PermissionChecker blur={true}>
          <label htmlFor="parent" className="pointer">
            <input
              id="parent"
              type="radio"
              readOnly="readOnly"
              onClick={this.changeCalculationHierarchy}
              checked={currentCalculation.parent === 1}
            />
            &nbsp;
            {this.props.intl("calculation.parent")}
          </label>
          <label htmlFor="child" className="disable-hover">
            <input
              id="child"
              type="radio"
              readOnly="readOnly"
              checked={currentCalculation.parent !== 1}
              onClick={this.changeCalculationHierarchy}
            />
            &nbsp;
            {this.props.intl("calculation.child")}
          </label>
        </PermissionChecker>
        <PermissionChecker hide={true}>
          <a
            href="#!"
            title="Delete Calculation"
            className="text-danger pointer"
            onClick={this.deleteCalculationHandler}
          >
            <i className="fa fa-times" />
          </a>
        </PermissionChecker>
      </div>
    );
  };

  render() {
    return (
      <div className="col-md-10 wrap">
        <div className="ibox">
          <div id="calculation-header" className="row">
            {this.renderCalculationName()}

            {this.renderParentChildButtons()}
          </div>
          <div className="row">
          <div className="col-md-10 text-right" style={{marginLeft: '4%', marginBottom: "10px"}}>
           <PermissionChecker hide={true}>
                  <button
                    id="save_formula_btn"
                    className={`btn btn-success`}
                    style={{margin: "0 35px 0 0"}}
                    onClick={() =>
                      this.props.actions.setCalculationsSnapshots(true)
                    }
                  >
                    {this.props.intl("calculation.display_snapshots")}
                  </button>
                </PermissionChecker>
          <button className="btn btn-success btn-outline" onClick={this.redoCalculationClickHandler}>
            {this.props.intl("calculation.redo_calculations")}
          </button>
          </div>
            <div className="col-md-1 text-right">
              <PermissionChecker hide={true}>
                <button
                  className="btn btn-success btn-outline"
                  onClick={this.addVariableClickHandler}
                >
                  <i className="fa fa-plus" />
                  {this.props.intl("calculation.add_variable")}
                </button>
              </PermissionChecker>
            </div>
            <VariableTable />
            <SourcePanel
              activeVariable={this.props.activeVariable}
              calculations={this.props.calculations}
            />
          </div>
          <FormulaEditor />
        </div>
      </div>
    );
  }
}

export default InjectIntl(CurrentCalculationTab);
