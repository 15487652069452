
export const ADD_OUTPUT_MEASURE = '@@OUTPUT/ADD_OUTPUT_MEASURE';
export const ADD_OUTPUT_MEASURE_SUCCESS = '@@OUTPUT/ADD_OUTPUT_MEASURE_SUCCESS';
export const ADD_OUTPUT_MEASURE_FAILURE = '@@OUTPUT/ADD_OUTPUT_MEASURE_FAILURE';
export const SHOW_OUTPUT_FIELD = '@@OUTPUT/SHOW_OUTPUT_FIELD';
export const HIDE_OUTPUT_FIELD = '@@OUTPUT/HIDE_OUTPUT_FIELD';
export const CHANGE_OUTPUT_MEASURE_NAME = '@@OUTPUT/CHANGE_OUTPUT_MEASURE_NAME';

function addOutputMeasure() {
  return { type: ADD_OUTPUT_MEASURE }
}

function showMeasureNameOutputField() {
  return { type: SHOW_OUTPUT_FIELD }
}

function hideMeasureNameOutputField() {
  return { type: HIDE_OUTPUT_FIELD }
}

function changeOutputMeasureName(measureName, measureId) {
  return { type: CHANGE_OUTPUT_MEASURE_NAME, measureName, measureId }
}

export default {
  addOutputMeasure,
  showMeasureNameOutputField,
  hideMeasureNameOutputField,
  changeOutputMeasureName
}