import { InjectIntl } from "../common/InjectIntl";
import React from "react";
import PropTypes from "prop-types";

import EmailModal from "./EmailModal";

const MembersInviteModal = props => {
  const handleMessageChange = e => props.actions.changeMessage(e.target.value, true);
  const handleSubjectChange = e => props.actions.changeSubject(e.target.value);
  const isValidEmail = email => {
    const template = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>().,;\s@"]+\.{0,1})+([^<>().,;:\s@"]{2,}|[\d.]+))$/;
    return template.test(email);
  };

  const handleInvite = () => {
    let hasIncorrectEmail = false;
    props.recipients.forEach(recipient => {
      if (!isValidEmail(recipient)) {
        hasIncorrectEmail = true;
        return;
      }
    });

    if (!hasIncorrectEmail) {
      props.actions.invite();
    } else {
      props.actions.setError(props.intl("inviteMembersModal.incorrectEmail"));
    }
  };

  return (
    <EmailModal
      recipients={props.recipients}
      onSubmit={handleInvite}
      onClose={props.actions.hideCollaboratorModal}
      intlPrefix="inviteMembersModal"
      isOpen={props.collaboratorModalShow}
      message={props.message}
      subject={props.subject}
      errors={props.errors}
      onMessageChange={handleMessageChange}
      onSubjectChange={handleSubjectChange}
      onRecipientChange={props.actions.changeRecipients}
      isSaving={props.isSaving}
      isSuccess={props.isSuccess}
      emails={props.emails}
    />
  );
};

MembersInviteModal.propTypes = {
  recipients: PropTypes.array.isRequired,
  message: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  collaboratorModalShow: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  emails: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    hideCollaboratorModal: PropTypes.func.isRequired,
    invite: PropTypes.func.isRequired,
    changeRecipients: PropTypes.func.isRequired,
    changeMessage: PropTypes.func.isRequired,
    changeSubject: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired
  }).isRequired
};

export default InjectIntl(MembersInviteModal);
