import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import SearchField from "../../../common/SearchField";
import ShowTotal from "../../../common/ShowTotal";
import PropTypes from "prop-types";
import { InjectIntl } from "../../../common/InjectIntl";
import { getFormTitle } from "../../../../assets/sam-utils";

const SourceAnswersDataTable = props => {
  const {
    intlLocale,
    data,
    onSearchChange,
    searchTxt,
    headers,
    sizePerPage,
    currentPage,
    totalDataSize,
    onPageChange,
    onSizePerPageList,
    remote
  } = props;

  const tableOptions = () => {
    return {
      sizePerPageList: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      onSearchChange,
      sizePerPage,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: "<",
      firstPage: "<<",
      nextPage: ">",
      lastPage: ">>",
      paginationPosition: "top",
      onPageChange,
      page: currentPage,
      onSizePerPageList,
      searchField: props => (
        <SearchField {...props} value={searchTxt} placeholder={"Search"} />
      ),
      paginationShowsTotal: () => <ShowTotal total={totalDataSize} />
    };
  };

  return (
    <div>
      {
        <BootstrapTable
          fetchInfo={{ dataTotalSize: totalDataSize }}
          bodyStyle={{ overflow: "auto" }}
          data={data}
          options={tableOptions()}
          striped
          hover
          pagination={ true }
          condensed
          search
          remote={remote}
        >
          {headers.map((header, index) => (
            <TableHeaderColumn
              key={index}
              width="150"
              isKey={index === 0}
              dataField={header.id.toString()}
              dataAlign="left"
            >
              {header.label ? header.label : getFormTitle(header, intlLocale)}
            </TableHeaderColumn>
          ))}
        </BootstrapTable>
      }
    </div>
  );
};

SourceAnswersDataTable.propTypes = {
  searchTxt: PropTypes.string,
  headers: PropTypes.array,
  data: PropTypes.array,
  sizePerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalDataSize: PropTypes.number,
  intlLocale: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSizePerPageList: PropTypes.func.isRequired
};

export default InjectIntl(SourceAnswersDataTable);
