import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router";
import { InjectIntl } from "../common/InjectIntl";

const NavLink = ({to, className, activeClassName, children, textClassName }) => (
  <div className={`${className} ${window.location.pathname === to ? activeClassName : ''}`}>
    <Link to={to} className={`${textClassName} ${window.location.pathname === to ? activeClassName : 'gray-color'}`}>
      {children}
    </Link>
  </div>
);

class EditAndCollaboratorNav extends React.Component {
  static propTypes = {
    logicModel: PropTypes.object.isRequired
  };

  render() {
    return (
      <div className="col-md-6 text-left">
        <ul className="nav nav-tabs" style={{borderBottom: "none"}}>
          <NavLink to={`/logic-models/${this.props.logicModel.id}/edit`} className="btn btn-default"
                   activeClassName="btn-warning" textClassName={'white'}>{this.props.intl('edit_page.logic_model')}</NavLink>
          <NavLink to={`/logic-models/${this.props.logicModel.id}/collaborators`} className="btn btn-default"
                   activeClassName="btn-warning" textClassName={'white'}>{this.props.intl('collaborators.index.collaborators')}</NavLink>
        </ul>
      </div>
    );
  }
}

export default InjectIntl(EditAndCollaboratorNav);