import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { parseValue, checkIsBroken } from "../../assets/sam-utils";
import { InjectIntl } from "../common/InjectIntl";
import { PREEXISTING_DATASOURCE_TYPE_ID } from "./sources/source-table-layout/constants";
import { has } from "lodash";

const FormulaResult = ({ currentCalculation, showValue, intl }) => {
  let obj;
  let transformationValue;
  if(!has(currentCalculation, 'variables')) {
    currentCalculation = {
      ...currentCalculation,
      variables: [],
    }
  }
  if (!!currentCalculation.condition) {
    let trans = "f(x)";
    currentCalculation.variables.forEach(variable => {
      if (
        variable.is_used &&
        variable.transformation === "AVG DATE OF RESPONSE"
      ) {
        trans = "AVG DATE OF RESPONSE";
      }
    });

    obj = {
      transformation: trans,
      value: currentCalculation.value
    };
    transformationValue = parseValue(obj);

    return currentCalculation.condition === "invalid_formula" ||
      currentCalculation.condition === "invalid_date_formula" ? (
      <div>
        <i className="fa fa-warning text-danger disable-hover" />
        &nbsp;
        <span className="text-danger">
          {intl(`${currentCalculation.condition}_hint`)}
        </span>
      </div>
    ) : (
      <div>
        <span className="text-danger">
          {intl(`${currentCalculation.condition}_hint`)}
        </span>
        <span className="m-l">
          f(x)&nbsp;&nbsp;
          <strong className="gray-box light-gray formula-result-value">
            {transformationValue}
          </strong>
        </span>
      </div>
    );
  } else if (
    currentCalculation.value &&
    currentCalculation.string &&
    showValue
  ) {
    let trans = "f(x)";
    if (!currentCalculation.is_day_value) {
      currentCalculation.variables.forEach(variable => {
        if (
          (variable.is_used &&
            variable.transformation === "AVG DATE OF RESPONSE") ||
          (variable.datasource_type_id === PREEXISTING_DATASOURCE_TYPE_ID &&
            variable.is_used &&
            !!variable.tableLayout &&
            variable.tableLayout.transformation === "AVG DATE OF RESPONSE")
        ) {
          trans = "AVG DATE OF RESPONSE";
        }
      });
    }
    obj = {
      transformation: trans,
      value: currentCalculation.value
    };
    transformationValue = parseValue(obj);

    let hasBrokenVariables = false;
    currentCalculation.variables.forEach(variable => {
      if (variable.is_used && checkIsBroken(variable)) {
        hasBrokenVariables = true;
        return;
      }
    });

    const className = classNames("gray-box formula-result-value", {
      "light-gray": hasBrokenVariables
    });

    return (
      <span className="formula-result">
        f(x)&nbsp;&nbsp;
        <strong className={className}>{transformationValue}</strong>
      </span>
    );
  } else {
    return null;
  }
};

FormulaResult.propTypes = {
  currentCalculation: PropTypes.object.isRequired,
  showValue: PropTypes.bool.isRequired,
  intl: PropTypes.func.isRequired
};

export default InjectIntl(FormulaResult);
