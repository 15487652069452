import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InjectIntl } from "../../../common/InjectIntl";
import { getFormTitle } from "../../../../assets/sam-utils";
import debounce from 'lodash/debounce';
import BootstrapTableFinalTab from "./BootstrapTableFinalTab";
import VariableNameInModal from "./VariableNameInModal";
import TransformationLabelAndValue from "./TransformationLabelAndValue";
import { tableOptions } from './BootstrapTableFinalTab';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

const SourceModalArrayCalculationFinalTab = (props) => {

  const [items, setItems] = useState([
    {
      value: '1',
    },
    {
      value: '2'
    }
  ]);

  useEffect(function() {
    const { activeVariable } = props;
    
  });

  const tableOptions = () => {
    return {
      sizePerPageList: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 }
      ],
      //onSearchChange,
      //sizePerPage: finalPageSizePerPage,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: "<",
      firstPage: "<<",
      nextPage: ">",
      lastPage: ">>",
      paginationPosition: "top",
      //onPageChange,
      //page: finalPageCurrentPage,
      //onSizePerPageList,
      // searchField: props => (
      //   <SearchField {...props} value={searchTxt} placeholder={"Search"} />
      // ),
      // paginationShowsTotal: () => <ShowTotal total={finalPageTotalDataSize} />
    };
  };
  
  return (
    <BootstrapTable
      bodyStyle={{ overflow: "auto" }}
      data={items}
      options={tableOptions()}
      // striped
      // hover
      // pagination
      // condensed
      // search
    >
      <TableHeaderColumn width="150" isKey dataField="value" dataAlign="left">
        Foo
      </TableHeaderColumn>
    </BootstrapTable>
  );
}

export default InjectIntl(SourceModalArrayCalculationFinalTab);