export const SAVE_MEASURE_NAME ='@@MEASURE/SAVE_MEASURE_NAME';
export const SAVE_MEASURE_NAME_SUCCESS = '@INPUT/SAVE_MEASURE_NAME_SUCCESS';
export const SAVE_MEASURE_NAME_FAILURE = '@INPUT/SAVE_MEASURE_NAME_FAILURE';

export const FETCH_MEASURES = '@@MEASURE/FETCH_MEASURES';
export const FETCH_MEASURES_SUCCESS = '@@MEASURE/FETCH_MEASURES_SUCCESS';
export const FETCH_MEASURES_FAILURE = '@@MEASURE/FETCH_MEASURES_FAILURE';

export const FETCH_LOGIC_MODEL = '@@MEASURE/FETCH_LOGIC_MODEL';
export const FETCH_LOGIC_MODEL_SUCCESS = '@@MEASURE/FETCH_LOGIC_MODEL_SUCCESS';
export const FETCH_LOGIC_MODEL_FAILURE = '@@MEASURE/FETCH_LOGIC_MODEL_FAILURE';

export const DELETE_STAKEHOLDER = '@@MEASURE/DELETE_STAKEHOLDER';
export const DELETE_STAKEHOLDER_SUCCESS = '@@MEASURE/DELETE_STAKEHOLDER_SUCCESS';
export const DELETE_STAKEHOLDER_FAILURE = '@@MEASURE/DELETE_STAKEHOLDER_FAILURE';

export const ADD_STAKEHOLDER = '@@MEASURE/ADD_STAKEHOLDER';
export const ADD_STAKEHOLDER_SUCCESS = '@@MEASURE/ADD_STAKEHOLDER_SUCCESS';
export const ADD_STAKEHOLDER_FAILURE = '@@MEASURE/ADD_STAKEHOLDER_FAILURE';

export const CHANGE_STAKEHOLDER_NAME_INPUT_SHOW = '@@MEASURE/CHANGE_STAKEHOLDER_NAME_INPUT_SHOW';
export const CHANGE_STAKEHOLDER_NAME_INPUT_HIDE = '@@MEASURE/CHANGE_STAKEHOLDER_NAME_INPUT_HIDE';

export const CHANGE_STAKEHOLDER_NAME = '@@MEASURE/CHANGE_STAKEHOLDER_NAME';
export const STAKEHOLDER_RECOMMENDATIONS_SUCCESS = '@@MEASURE/STAKEHOLDER_RECOMMENDATIONS_SUCCESS';
export const STAKEHOLDER_RECOMMENDATIONS_FAILURE = '@@MEASURE/STAKEHOLDER_RECOMMENDATIONS_FAILURE';

export const SAVE_STAKEHOLDER_NAME = '@@MEASURE/SAVE_STAKEHOLDER_NAME';
export const SAVE_STAKEHOLDER_NAME_SUCCESS = '@@MEASURE/SAVE_STAKEHOLDER_NAME_SUCCESS';
export const SAVE_STAKEHOLDER_NAME_FAILURE = '@@MEASURE/SAVE_STAKEHOLDER_NAME_FAILURE';

export const CHANGE_STAKEHOLDER = '@@MEASURE/CHANGE_STAKEHOLDER';

export const LOGIC_MODEL_IS_LOADING = '@@MEASURE/LOGIC_MODEL_IS_LOADING';
export const CHANGE_PARENT_MEASURE_ID = '@@MEASURE/CHANGE_PARENT_MEASURE_ID';
export const SET_INITIAL_STATE = '@@MEASURE/SET_INITIAL_STATE';

export const SHOW_DEFINITIONS = '@@LOGIC_MODELS/SHOW_DEFINITIONS';
export const HIDE_DEFINITIONS = '@@LOGIC_MODELS/HIDE_DEFINITIONS';

export const SET_MEASURE_THEME = '@@MEASURE/SET_MEASURE_THEME'

function fetchLogicModel(id) {
  return { type: FETCH_LOGIC_MODEL, id }
}

function changeStakeholderNameInputFieldShow() {
  return { type: CHANGE_STAKEHOLDER_NAME_INPUT_SHOW }
}

function changeStakeholderNameInputFieldHide() {
  return { type: CHANGE_STAKEHOLDER_NAME_INPUT_HIDE }
}

function deleteStakeholder(id) {
  return ({type: DELETE_STAKEHOLDER, id})
}

function addStakeHolder() {
	return { type: ADD_STAKEHOLDER }
}

function changeStakeholderName(name, stakeholderId) {
	return { type: CHANGE_STAKEHOLDER_NAME, name, stakeholderId }
}

function saveStakeholderName() {
	return { type: SAVE_STAKEHOLDER_NAME }
}

function changeStakeholderId(stakeholderId) {
	return { type: CHANGE_STAKEHOLDER, stakeholderId }
}

function logicModelLoading() {
	return { type: LOGIC_MODEL_IS_LOADING }
}

function measureNameSave(category=null) {
  return { type: SAVE_MEASURE_NAME, category }
}

function changeParentMeasureId(parentMeasureId) {
  return { type: CHANGE_PARENT_MEASURE_ID, parentMeasureId }
}

function setMeasureInitialState() {
  return { type: SET_INITIAL_STATE }
}

function showDefinitions() {
  return { type: SHOW_DEFINITIONS }
}

function hideDefinitions() {
  return { type: HIDE_DEFINITIONS }
}

function setMeasureTheme(themes){
  return { type: SET_MEASURE_THEME, themes };
}

export default {
	fetchLogicModel,
	deleteStakeholder,
  changeStakeholderNameInputFieldShow,
  changeStakeholderNameInputFieldHide,
  changeStakeholderId,
  logicModelLoading,
  changeStakeholderName,
  addStakeHolder,
  saveStakeholderName,
  measureNameSave,
  changeParentMeasureId,
  setMeasureInitialState,
  showDefinitions,
  hideDefinitions,
  setMeasureTheme
};