import React from 'react';
import PropTypes from 'prop-types';
import Spinner from "./Spinner";

const SpinnerWrapper = props => (
  <div className="ibox m-n">
    <div className={`ibox-content ${props.isActive ? 'sk-loading' : ''} no-borders no-padding ${props.className}`}>
      <Spinner {...props}/>
      {props.children}
    </div>
  </div>
);

SpinnerWrapper.propTypes = {
  isActive: PropTypes.bool.isRequired,
  color: PropTypes.string,
  className: PropTypes.string
};
SpinnerWrapper.defaultProps = {
  className: ''
};

export default SpinnerWrapper;
