import React from 'react';
import PropTypes from "prop-types";
import { InjectIntl } from "../common/InjectIntl";
import OutsideClickDetection from "../common/OutsideClickDetection";
import Input from "../common/Input";
import Adjustments from "../../containers/Adjustments.container";
import Demographic from "../../containers/logic-models/stakeholders-items/add-demographic/Demographic.container";
import Definitions from "../../containers/logic-models/stakeholders-items/Definitions.container";
import ErrorNotification from "../../containers/ErrorNotification.container";
import PermissionChecker from '../common/PermissionChecker';


class MeasurePage extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    showDemographic: PropTypes.bool.isRequired,
    showAdjustments: PropTypes.bool.isRequired,
    isDefinitionsShowed: PropTypes.bool.isRequired,
    showInputField: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      changeLogicModelName: PropTypes.func,
      saveLogicModelName: PropTypes.func,
      showChangeLogicModelNameInput: PropTypes.func,
      hideChangeLogicModelNameInput: PropTypes.func,
      setMeasureInitialState: PropTypes.func,
      setError: PropTypes.func,
    }.isRequired)
  };

  componentDidMount() {
    this.props.actions.fetchLogicModel(this.props.id);
  }

  componentWillUnmount() {
    this.props.actions.setMeasureInitialState();
  }

  editLogicModelName = () => {
    this.props.actions.showChangeLogicModelNameInput(this.props.id);
    this.props.actions.changeLogicModelName(this.props.logicModel.name, this.props.id);
  };

  changeName = (name) => this.props.actions.changeLogicModelName(name, this.props.id);

  saveName = () => {
    if (this.props.newLogicModelName === '') {
      const emptyNameFieldError = this.props.intl('errors.create_logic_model.name_empty');
      this.props.actions.setError(emptyNameFieldError);
    } else {
      this.props.actions.saveLogicModelName();
    }
  };

  moveCaretAtTheEnd = (e) => {
    let temp_value = e.target.value;
    e.target.value = '';
    e.target.value = temp_value;
  };


  renderLogicModelName = () => {
    const { showInputField, newLogicModelName, logicModel, actions } = this.props;

    return (
      <div className="row m-b">
        <div className="col-md-12">
          {showInputField ? (
            <div className="col-lg-4 col-md-4 col-xs-12">
              <OutsideClickDetection hideAction={actions.hideChangeLogicModelNameInput}>
                <div className="input-group">
                  <Input
                    autoFocus={true}
                    changeName={this.changeName}
                    onFocus={this.moveCaretAtTheEnd}
                    name={newLogicModelName}
                    placeholder="Enter logic model name"
                    save={this.saveName}
                  />
                  <span className="input-group-btn">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={this.saveName}
                    >
                      {this.props.intl("common.text.save_button")}
                    </button>
                  </span>
                </div>
              </OutsideClickDetection>
            </div>
          ) : (
            <PermissionChecker>
              <h3 className="editable_name" onClick={this.editLogicModelName}>
                {logicModel.name}
              </h3>
            </PermissionChecker>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { showDemographic, showAdjustments, isDefinitionsShowed } = this.props;

    return (
      <div className='ibox-content'>
        {this.renderLogicModelName()}
        <div className="panel-body">
          {this.props.children}
        </div>
        <ErrorNotification/>
        {showAdjustments && <Adjustments/>}
        {showDemographic && <Demographic/>}
        {isDefinitionsShowed && <Definitions/>}
      </div>
    )
  }
}

export default InjectIntl(MeasurePage);