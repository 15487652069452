import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap-modal";
import { InjectIntl } from "../../../common/InjectIntl";
import AdjustmentItem from "./AdjustmentItem";
import PermissionChecker from "../../../common/PermissionChecker";
import { whiteLabel } from '../../../utils';

class Adjustments extends React.Component {
  static propTypes = {
    showAdjustments: PropTypes.bool.isRequired,
    measureId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    deadWeightDescription: PropTypes.string,
    deadWeight: PropTypes.string,
    dropOffDescription: PropTypes.string,
    dropOff: PropTypes.string,
    displacementDescription: PropTypes.string,
    displacement: PropTypes.string,
    attributionDescription: PropTypes.string,
    attribution: PropTypes.string,
    durationDescription: PropTypes.string,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stakeholderMeasureId: PropTypes.string,
    actions: PropTypes.shape(
      {
        showAdjustments: PropTypes.func,
        hideAdjustments: PropTypes.func,
        fetchAdjustmentsData: PropTypes.func,

        changeDeadWeightDescription: PropTypes.func,
        changeDeadWeight: PropTypes.func,
        changeDropOffDescription: PropTypes.func,
        changeDropOff: PropTypes.func,
        changeDisplacementDescription: PropTypes.func,
        changeDisplacement: PropTypes.func,
        changeAttributionDescription: PropTypes.func,
        changeAttribution: PropTypes.func,
        changeDurationDescription: PropTypes.func,
        changeDuration: PropTypes.func,
        adjustmentsUpdate: PropTypes.func
      }.isRequired
    )
  };

  componentDidMount() {
    this.props.logicModel.stakeholders.forEach((stakeholder, index) => {
      if (stakeholder.id === this.props.stakeholderId) {
        stakeholder.measures.forEach(measure => {
          if (measure.id === this.props.measureId) {
            this.props.actions.fetchAdjustmentsData(measure);
          }
        });
      }
    });
  }

  changeDeadWeightDescription = e => {
    e.preventDefault();
    this.props.actions.changeDeadWeightDescription(e.target.value);
  };

  changeDeadWeight = e => {
    e.preventDefault();
    this.props.actions.changeDeadWeight(e.target.value);
  };

  changeDropOffDescription = e => {
    e.preventDefault();
    this.props.actions.changeDropOffDescription(e.target.value);
  };

  changeDropOffValue = e => {
    e.preventDefault();
    this.props.actions.changeDropOff(e.target.value);
  };

  changeDisplacementDescription = e => {
    e.preventDefault();
    this.props.actions.changeDisplacementDescription(e.target.value);
  };

  changeDisplacementValue = e => {
    e.preventDefault();
    this.props.actions.changeDisplacement(e.target.value);
  };

  changeAttributionDescription = e => {
    e.preventDefault();
    this.props.actions.changeAttributionDescription(e.target.value);
  };

  changeAttributionValue = e => {
    e.preventDefault();
    this.props.actions.changeAttribution(e.target.value);
  };

  changeDurationDescription = e => {
    e.preventDefault();
    this.props.actions.changeDurationDescription(e.target.value);
  };

  changeDurationValue = e => {
    e.preventDefault();
    this.props.actions.changeDuration(e.target.value);
  };

  update = e => {
    e.preventDefault();
    this.props.actions.adjustmentsUpdate();
  };

  handleCloseModal = () => this.props.actions.hideAdjustments();

  renderBody = () => {
    return (
      <form>
        <AdjustmentItem
          name="deadweight"
          description={this.props.deadWeightDescription}
          onChangeDescription={this.changeDeadWeightDescription}
          value={this.props.deadWeight}
          onChangeValue={this.changeDeadWeight}
        />
        <AdjustmentItem
          name="dropoff"
          description={this.props.dropOffDescription}
          onChangeDescription={this.changeDropOffDescription}
          value={this.props.dropOff}
          onChangeValue={this.changeDropOffValue}
        />
        <AdjustmentItem
          name="displacement"
          description={this.props.displacementDescription}
          onChangeDescription={this.changeDisplacementDescription}
          value={this.props.displacement}
          onChangeValue={this.changeDisplacementValue}
        />
        <AdjustmentItem
          name="attribution"
          description={this.props.attributionDescription}
          onChangeDescription={this.changeAttributionDescription}
          value={this.props.attribution}
          onChangeValue={this.changeAttributionValue}
        />
        <AdjustmentItem
          name="duration"
          description={this.props.durationDescription}
          onChangeDescription={this.changeDurationDescription}
          value={this.props.duration}
          onChangeValue={this.changeDurationValue}
          isNumOfRecords={true}
        />
      </form>
    );
  };

  render() {
    const { showAdjustments, name, intl } = this.props;

    return (
      <Modal
        dialogClassName={"modal-lg"}
        show={showAdjustments}
        id={"foreground_display"}
        data-brand={whiteLabel()}
      >
        <Modal.Header>
          <Modal.Title id="ModalHeader">
            <span>
              <i className="fa fa-gear disable-hover" />
              &nbsp;{name}&nbsp;
              {intl("stakeholder.items.adjustment")}
            </span>
            <i
              className="fa fa-close pull-right"
              onClick={this.handleCloseModal}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br />
          {this.renderBody()}
        </Modal.Body>
        <Modal.Footer>
          <div className="row m-r">
            <button
              type="button"
              className="btn btn-default"
              onClick={this.handleCloseModal}
            >
              {intl("common.text.cancel_button")}
            </button>
            <PermissionChecker hide={true}>
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.update}
              >
                {intl("common.text.save_button")}
              </button>
            </PermissionChecker>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default InjectIntl(Adjustments);
