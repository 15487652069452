export const CHANGE_SIZE_PER_PAGE = '@@SOURCE_MODALS/CHANGE_SIZE_PER_PAGE';
export const CHANGE_CURRENT_PAGE = '@@SOURCE_MODALS/CHANGE_CURRENT_PAGE';
export const CHANGE_TOTAL_DATA_SIZE = '@@SOURCE_MODALS/CHANGE_TOTAL_DATA_SIZE';
export const CHANGE_SEARCH_TEXT = '@@SOURCE_MODALS/CHANGE_SEARCH_TEXT';
export const CHANGE_TABLE_DATA = '@@SOURCE_MODALS/CHANGE_TABLE_DATA';
export const CHANGE_DATA_FOR_PAGE = '@@SOURCE_MODALS/CHANGE_DATA_FOR_PAGE';
export const CHANGE_DATA_FOR_FINAL_PAGE = '@@SOURCE_MODALS/CHANGE_DATA_FOR_FINAL_PAGE';
export const RESET_SEARCH_TEXT = '@@SOURCE_MODALS/RESET_SEARCH_TEXT';
export const SEARCH_IN_UPLOAD = '@@SOURCE_MODALS/SEARCH_IN_UPLOAD';

export const CHANGE_NAV_BUTTONS_MODAL = '@@SOURCE_MODALS/CHANGE_NAV_BUTTONS_MODAL';
export const SET_QUESTIONS_AND_ANSWERS = '@@SOURCE_MODALS/SET_QUESTIONS_AND_ANSWERS';

export const FETCH_FINAL_SOURCE_DATA = '@@SOURCE_MODALS/FETCH_FINAL_SOURCE_DATA';
export const FETCH_FINAL_SOURCE_DATA_SUCCESS = '@@SOURCE_MODALS/FETCH_FINAL_SOURCE_DATA_SUCCESS';
export const FETCH_FINAL_SOURCE_DATA_FAILURE = '@@SOURCE_MODALS/FETCH_FINAL_SOURCE_DATA_FAILURE';

export const SAVE_FINAL_SOURCE = '@@SOURCE_MODALS/SAVE_FINAL_SOURCE';
export const SAVE_FINAL_SOURCE_SUCCESS = '@@SOURCE_MODALS/SAVE_FINAL_SOURCE_SUCCESS';
export const SAVE_FINAL_SOURCE_FAILURE = '@@SOURCE_MODALS/SAVE_FINAL_SOURCE_FAILURE';

export const FINAL_VARIABLE_NAME = '@@SOURCE_MODALS/FINAL_VARIABLE_NAME';
export const SET_SOURCE_MODAL_INITIAL_STATE = '@@SOURCE_MODALS/SET_SOURCE_MODAL_INITIAL_STATE';

export const SET_NEW_TRANSFORMATION_VALUE = '@@/SET_NEW_TRANSFORMATION_VALUE';

function setFinalData(payload) {
  return { type: FETCH_FINAL_SOURCE_DATA_SUCCESS, payload }
}

function changeSizePerPage(pageSize) {
  return { type: CHANGE_SIZE_PER_PAGE, pageSize }
}

function changeDataForPage(page, sizePerPage, fileId, searchTxt) {
  return { type: CHANGE_DATA_FOR_PAGE, page, sizePerPage, fileId, searchTxt }
}

function resetSearchTxt() {
  return { type: RESET_SEARCH_TEXT }
}

function changeDataForFinalTab(page, sizePerPage, fileId, searchTxt) {
  return { type: CHANGE_DATA_FOR_FINAL_PAGE, page, sizePerPage, fileId, searchTxt }
}

function search(text, fileId, sizePerPage) {
  return { type: SEARCH_IN_UPLOAD, text, fileId, sizePerPage }
}

function changeOneSourceNavButtons(name) {
  return { type: CHANGE_NAV_BUTTONS_MODAL, name }
}

function fetchOneSourceFinalResultData(headerId) {
  return { type: FETCH_FINAL_SOURCE_DATA, headerId }
}

function saveSource() {
  return { type: SAVE_FINAL_SOURCE }
}

function saveFinalVariableName(name) {
  return { type: FINAL_VARIABLE_NAME, name }
}

function setSourceModalInitialState() {
  return { type: SET_SOURCE_MODAL_INITIAL_STATE }
}


export default {
  changeSizePerPage,
  changeDataForPage,
  resetSearchTxt,
  search,
  changeOneSourceNavButtons,
  fetchOneSourceFinalResultData,
  saveSource,
  saveFinalVariableName,
  setSourceModalInitialState,
  changeDataForFinalTab,
  setFinalData
}
