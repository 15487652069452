import createReducer from '../create-reducer';
import merge from 'xtend';

import {
  ADD_CALCULATION,
  ADD_CALCULATION_FAILURE,
  ADD_CALCULATION_SUCCESS,
  ADD_CALCULATION_VARIABLES_SUCCESS,
  CHANGE_CALCULATION_NAME,
  FETCH_CALCULATIONS,
  FETCH_CALCULATIONS_FAILURE,
  FETCH_CALCULATIONS_SUCCESS,
  FETCH_SOURCE_FALIURE,
  FETCH_SOURCE_SUCCESS,
  FETCH_SOURCE,
  SET_CURRENT_CALCULATION,
  SET_WORKSPACE,
  SET_SOURCE_INITIAL_STATE,
  CHANGE_ACTIVE_VARIABLE,
  DELETE_VARIABLE_SUCCESS,
  UPDATE_CALCULATION_FAILURE,
  UPDATE_CALCULATION,
  DELETE_CALCULATION_SUCCESS,
  UPDATE_CALCULATION_DATA_SUCCESS,
  CHANGE_CALCULATION_FORMULA,
  SET_PREEXISTING_CALCULATIONS,
  SET_CURRENT_PREEXISTING_CALCULATION,
  CREATE_ARRAY_CALCULATION_VARIABLE_WITH_NEW_CALCULATION_VARIABLE,
  UPDATE_ARRAY_CALCULATION,
  UPDATE_ARRAY_CALCULATION_SUCCESS,
  UPDATE_ARRAY_CALCULATION_FAILURE,
  CREATE_ARRAY_CALCULATION_VARIABLE,
  DELETE_ARRAY_CALCUATION_VARIABLE,
  UPDATE_ARRAY_CALCULATION_VARIABLE,
  UPDATE_ARRAY_CALCULATION_VARIABLE_SYMBOL,
  SET_CALCULATIONS_SNAPSHOTS,
  SAVE_SNAPSHOT_COMMENT,
  SAVE_SNAPSHOT_COMMENT_SUCCESS,
  SAVE_SNAPSHOT_COMMENT_FAILURE,
} from "../../actions/calculation/calculation-page";
import {
  CHANGE_CURRENT_FORM_ID, CHANGE_CURRENT_UPLOAD_ID, CHANGE_CURRENT_WORKSPACE_ID
} from "../../actions/calculation/data-source";
import { has } from 'lodash';

export const ACTIVE_VARIABLE_INITIAL_STATE = {
  source_statuses: []
};

export const CURRENT_CALCULATION_INITIAL_STATE = {
  variables: []
};

export const INITIAL_STATE = {
  isLoading: false,
  calculations: [],
  currentCalculation: CURRENT_CALCULATION_INITIAL_STATE,
  errors: {},
  calculationVariable: {},
  workspaces: [],
  hasWorkspaces: false,
  workspace: {},
  form: {},
  upload: {},
  activeVariable: ACTIVE_VARIABLE_INITIAL_STATE,
  newCalcName: '',
  preexistingCalculations: [],
  currentPreexistingCalculation: {},
  fetchSourceIsLoading: false,
  isCalculationShapshotsModalWindowOpen: false,
};

function changeCalculationName(calculations, newName, id) {
  return calculations.reduce((acc, calculation) => {
    if (calculation.id === id) {
      calculation.name = newName;
    }
    acc.push(calculation);
    return acc
  }, [])
}

function updateMeasureCalculations(calculations, updatedCalculation) {
  return calculations.reduce((acc, calculation) => {
    if (calculation.id === updatedCalculation.id) {
      calculation = updatedCalculation;
    }
    if (updatedCalculation.parent === 1) {
      if (calculation.id !== updatedCalculation.id) {
        calculation.parent = 0;
      }
    }
    acc.push(calculation);
    return acc
  }, [])
}

function setCurrentCalculation(currentCalculation, calculations) {
  const calculationsIds = calculations.map(calc => calc.id);
  let current = CURRENT_CALCULATION_INITIAL_STATE;
  if (currentCalculation && calculationsIds.length > 1) {
    if (calculationsIds.includes(currentCalculation.id)) {
      calculations.forEach(calc => {
        if (currentCalculation.id === calc.id) {
          current = calc;
        }
      });
    } else {
      current = calculations[0];
    }
  } else {
    current = calculations[0];
  }
  return current;
}

function setCurrentObject(listOfObjects, itemId) {
  let currentObject = {};
  if (listOfObjects.length > 0) {
    listOfObjects.forEach((obj) => {
      if (obj.id === parseInt(itemId)) {
        currentObject = obj;
      }
    });
  }
  return currentObject
}

function setActiveVariable(currentCalc, currentActiveVariable) {
  if (currentActiveVariable && Object.values(currentActiveVariable).length > 0) {
    const activeVariableId = currentActiveVariable.id;
    let currentVariable = ACTIVE_VARIABLE_INITIAL_STATE;
    currentCalc.variables.forEach(variable => {
      if (variable.id === activeVariableId)
        currentVariable = variable
    });
    return currentVariable
  } else {
    return currentCalc.variables[0]
  }
}

function removeCalculation(calculations, deletedCalculationId) {
  return calculations.reduce((acc, calc) => {
    if (calc.id === deletedCalculationId) {
      console.log("remove calculation");
    } else {
      acc.push(calc)
    }
    return acc
  }, [])
}

function changeCurrentCalculation(currentCalculation, dataKeyString, dataValueString, dataKeyLatex, dataValueLatex) {
  return {
    ...currentCalculation,
    [dataKeyString]: dataValueString,
    [dataKeyLatex]: dataValueLatex
  };
}

function getPreexistingCalculations(calculations) {
  return calculations.filter(calc => calc.parent !== 1);
};

function changeActiveVariable(variable) {
  if (typeof variable === "object") {
    variable.source_statuses = Array.isArray(variable.source_statuses)
      ? variable.source_statuses
      : [];
    return variable;
  } else {
    return ACTIVE_VARIABLE_INITIAL_STATE;
  }
}

function updateArrayCalculation(arrayCalculation, activeVariable) {
  if(has(activeVariable, 'array_calculation')) {
    const { latex, string, conditions } = arrayCalculation;

    return {
      ...activeVariable,
      array_calculation: {
        ...activeVariable.array_calculation,
        latex,
        string,
        conditions,
        invalid: false,
      }
    }
  } // has activeVariable.array_calculation
  return activeVariable;
}

function updateArrayCalculationFailure(activeVariable, payload) {
  if(has(activeVariable, 'array_calculation')) {
    const { array_calculation } = activeVariable;
    return {
      ...activeVariable,
      array_calculation: {
        ...array_calculation,
        invalid: true,
        latex: payload.latex,
        string: payload.string,
      }
    };
  }
  
  return activeVariable;
}

export default createReducer({
  [FETCH_CALCULATIONS]: (state) => merge(state, { isLoading: true }),
  [FETCH_CALCULATIONS_SUCCESS]: (state, action) => merge(state, {
    isLoading: false,
    calculations: action.payload.calculations,
    currentCalculation: setCurrentCalculation(state.currentCalculation, action.payload.calculations),
    dataSourceTypes: action.payload.datasourceTypes,
    activeVariable: setActiveVariable(setCurrentCalculation(state.currentCalculation, action.payload.calculations), state.activeVariable)
  }),
  [FETCH_CALCULATIONS_FAILURE]: (state, action) => merge(state, {
    isLoading: false,
    errors: action.errors
  }),
  [CHANGE_CALCULATION_NAME]: (state, action) => merge(state, {
    calculations: changeCalculationName(state.calculations, action.name, action.calculationId)
  }),
  [UPDATE_CALCULATION_DATA_SUCCESS]: (state, action) => merge(state, {
    calculations: updateMeasureCalculations(state.calculations, action.payload.calculation),
    isLoading: false,
    currentCalculation: action.payload.calculation
  }),
  [ADD_CALCULATION]: (state, action) => merge(state, { isLoading: true }),
  [ADD_CALCULATION_SUCCESS]: (state, action) => merge(state, {
    isLoading: false,
    calculations: state.calculations.concat([action.payload.calculation]),
    activeVariable: ACTIVE_VARIABLE_INITIAL_STATE,
    currentCalculation: action.payload.calculation,
  }),
  [ADD_CALCULATION_FAILURE]: (state, action) => merge(state, { isLoading: false, errors: action.errors }),
  [SET_CURRENT_CALCULATION]: (state, action) => merge(state, {
    currentCalculation: action.calculation,
    activeVariable: changeActiveVariable(action.calculation.variables[0])
  }),
  [ADD_CALCULATION_VARIABLES_SUCCESS]: (state, action) => merge(state, {
    activeVariable: changeActiveVariable(action.calculationVariable),
    currentPreexistingCalculation: {}
  }),
  [DELETE_VARIABLE_SUCCESS]: (state, action) => merge(state, { activeVariable: ACTIVE_VARIABLE_INITIAL_STATE }),
  [FETCH_SOURCE]: (state) => merge(state, { fetchSourceIsLoading: true }),
  [FETCH_SOURCE_SUCCESS]: (state, action) => merge(state, {
    workspaces: action.payload.workspaces,
    hasWorkspaces: true,
    fetchSourceIsLoading: false
  }),
  [FETCH_SOURCE_FALIURE]: (state, action) => merge(state, { errors: action.errors, hasWorkspaces: false, fetchSourceIsLoading: false }),
  [SET_WORKSPACE]: (state, action) => merge(state, {
    workspace: setCurrentObject(state.workspaces, action.workspaceId)
  }),
  [CHANGE_CURRENT_WORKSPACE_ID]: (state, action) => merge(state, {
    workspace: setCurrentObject(state.workspaces, action.workspaceId)
  }),
  [CHANGE_CURRENT_FORM_ID]: (state, action) => merge(state, {
    form: setCurrentObject(state.workspace.forms ? state.workspace.forms : [], action.formId)
  }),
  [CHANGE_CURRENT_UPLOAD_ID]: (state, action) => merge(state, {
    upload: setCurrentObject(state.workspace.upload_files ? state.workspace.upload_files : [], action.uploadId)
  }),
  [CHANGE_ACTIVE_VARIABLE]: (state, action) => merge(state, { activeVariable: changeActiveVariable(action.calculationVariable) }),
  [UPDATE_CALCULATION]: (state, action) => merge(state, { isLoading: true}),
  [UPDATE_CALCULATION_FAILURE]: (state, action) => merge(state, { isLoading: false, errors: action.errors}),
  [DELETE_CALCULATION_SUCCESS]: (state, action) => merge(state, {
    calculations: removeCalculation(state.calculations, action.deletedCalculationId),
    currentCalculation: state.calculations[0],
    activeVariable: changeActiveVariable(state.calculations[0].variables[0])
  }),
  [CHANGE_CALCULATION_FORMULA]: (state, action) => merge(state, {
    currentCalculation: changeCurrentCalculation(state.currentCalculation, 'string', action.string, 'latex', action.latex),
  }),
  [SET_SOURCE_INITIAL_STATE]: (state, action) => merge(state, INITIAL_STATE),
  [SET_PREEXISTING_CALCULATIONS]: (state, action) => merge(state, { preexistingCalculations: getPreexistingCalculations(state.calculations) }),
  [SET_CURRENT_PREEXISTING_CALCULATION]: (state, action) => merge(state, { currentPreexistingCalculation: action.calculation }),

  /** Array calculations */
  [CREATE_ARRAY_CALCULATION_VARIABLE_WITH_NEW_CALCULATION_VARIABLE]: (state, action) => merge(state, { isLoading: true}),

  [CREATE_ARRAY_CALCULATION_VARIABLE]: (state, action) => merge(state, { isLoading: true}),
  [DELETE_ARRAY_CALCUATION_VARIABLE]: (state, action) => merge(state, { isLoading: true}),
  [UPDATE_ARRAY_CALCULATION_VARIABLE]: (state, action) => merge(state, { isLoading: true}),
  [UPDATE_ARRAY_CALCULATION_VARIABLE_SYMBOL]: (state, action) => merge(state, { isLoading: true}),

  [UPDATE_ARRAY_CALCULATION]: (state, action) => merge(state, { isLoading: true}),
  [UPDATE_ARRAY_CALCULATION_SUCCESS]: (state, action) => merge(state, { 
    isLoading: false, 
    activeVariable: updateArrayCalculation(action.arrayCalculation, state.activeVariable)
  }),
  [UPDATE_ARRAY_CALCULATION_FAILURE]: (state, action) => merge(state, { 
    isLoading: false, 
    errors: action.errors,
    activeVariable: updateArrayCalculationFailure(state.activeVariable, action.payload)
  }),
  [SET_CALCULATIONS_SNAPSHOTS]: (state, action) => merge(state, {
    isCalculationShapshotsModalWindowOpen: action.status,
  }),
  [SAVE_SNAPSHOT_COMMENT]: (state) => merge(state, {
    isLoading: true
  }),
  [SAVE_SNAPSHOT_COMMENT_SUCCESS]: (state) => merge(state, {
    isLoading: false
  }),
  [SAVE_SNAPSHOT_COMMENT_FAILURE]: (state, action) => merge(state, {
    isLoading: false,
    errors: action.errors
  })
}, INITIAL_STATE)