export const SHOW_DEMOGRAPHIC = '@@DEMOGRAPHICS/SHOW_DEMOGRAPHIC';
export const HIDE_DEMOGRAPHIC = '@@DEMOGRAPHICS/HIDE_DEMOGRAPHIC';

export const SHOW_DEMOGRAPHICS_MEASURE_NAME = '@@DEMOGRAPHICS/SHOW_DEMOGRAPHICS_MEASURE_NAME';
export const HIDE_DEMOGRAPHICS_MEASURE_NAME = '@@DEMOGRAPHICS/HIDE_DEMOGRAPHICS_MEASURE_NAME';


export const ADD_DEMOGRAPHIC_MEASURE = '@@DEMOGRAPHICS/ADD_DEMOGRAPHIC_MEASURE';
export const ADD_DEMOGRAPHIC_MEASURE_SUCCESS = '@@DEMOGRAPHICS/ADD_DEMOGRAPHIC_MEASURE_SUCCESS';
export const ADD_DEMOGRAPHIC_MEASURE_FAILURE = '@@DEMOGRAPHICS/ADD_DEMOGRAPHIC_MEASURE_FAILURE';

export const CHANGE_DEMOGRAPHIC_MEASURE_NAME = '@@DEMOGRAPHIC/CHANGE_DEMOGRAPHIC_MEASURE_NAME';


function addDemographicMeasure() {
  return { type: ADD_DEMOGRAPHIC_MEASURE }
}

function showDemographic() {
  return { type: SHOW_DEMOGRAPHIC }
}

function hideDemographic() {
  return { type: HIDE_DEMOGRAPHIC }
}

function showMeasureNameDemographicField() {
  return { type: SHOW_DEMOGRAPHICS_MEASURE_NAME }
}

function hideMeasureNameDemographicField() {
  return { type: HIDE_DEMOGRAPHICS_MEASURE_NAME }
}

function changeDemographicMeasureName(measureName, measureId) {
  return { type: CHANGE_DEMOGRAPHIC_MEASURE_NAME, measureName, measureId }
}

export default {
  showDemographic,
  hideDemographic,
  addDemographicMeasure,
  showMeasureNameDemographicField,
  hideMeasureNameDemographicField,
  changeDemographicMeasureName
}