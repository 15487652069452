import * as React from "react";
import {connect} from "react-redux";
import "../assets/scss/style.scss";
import "../assets/sam-utils";
import Raven from 'raven-js';
import PropTypes from "prop-types";
// import Intercom from 'react-intercom';
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

export class App extends React.Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
    user_email: PropTypes.string.isRequired,
    user_id: PropTypes.string,
    name: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Raven.captureException(error, { extra: errorInfo });
  }

  render() {
    // const {name, user_email, user_id } = this.props;
    // const userId = !!user_id ? user_id : localStorage.getItem('user_id');
    // const email = !!user_email ? user_email : localStorage.getItem('email')
    // const user = { email, name };

    return (
      <div className="app">
        {this.props.children}
        {/* {userId && <Intercom appID={process.env.REACT_APP_INTERCOM_APP_ID} {...user} />} */}
        {
          <TawkMessengerReact
            propertyId={process.env.REACT_APP_TAWKIO_PROPERTY_ID}
            widgetId={process.env.REACT_APP_TAWKIO_WIDGET_ID || "default"}
          />
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state.login;
}

export default connect(mapStateToProps)(App)