import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ModalDeleteItem from "../components/common/ModalDeleteItem";
import modalDeleteItemActions from '../actions/modal-delete-item';

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    ...state.measurePage,
    ...state.modalDeleteItem
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...modalDeleteItemActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteItem)
