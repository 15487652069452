export const SELECT_STANDARD_CALCULATION = '@@CALCULATION_TYPE/STANDARD';
export const SELECT_ARRAY_CALCULATION = '@@CALCULATION_TYPE/ARRAY';


function selectStandardCalculation() {
  return { type: SELECT_STANDARD_CALCULATION };
}

function selectArrayCalculation() {
    return { type: SELECT_ARRAY_CALCULATION }
}

export default {
    selectStandardCalculation,
    selectArrayCalculation,
};
