import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CalculationsActions from "../../actions/calculation/calculation-page";
import VariableTable from "../../components/calculations/variables/VariableTable";


function mapStateToProps(state) {
  return {
    currentCalculation: state.calculations.currentCalculation,
    intl: state.intl
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...CalculationsActions,
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VariableTable)