import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import EditFrameworkModal from "../components/measure/EditFrameworkModal";
import manageMeasureActions from "../actions/measure/manage-measure";
import measureActions from "../actions/measure/measure";
import StakeholderActions from "../actions/stakeholder-items/inputs";
import logicModelsActions from "../actions/logic-models";

function mapStateToProps(state, ownProps) {
  const { measureId, logicModel } = state.measurePage;
  let selectedThemes = [];
  let stakeholderId;
  if (measureId) {
    const stakeholder = logicModel.stakeholders.find((x) =>
      x.measures.find((y) => y.id == measureId)
    );
    if (stakeholder) {
      stakeholderId = stakeholder.id
    }
    const outcome = stakeholder.outcomes.find((x) => x.id === measureId);
    if (outcome) selectedThemes = outcome.themes;
  }
  return {
    ...state.manageMeasure,
    ...state.logicModels,
    measureName: state.measurePage.newMeasureName,
    measureDescription: state.measurePage.newMeasureDescription,
    measureId: state.measurePage.measureId,
    stakeholderId,
    selectedThemes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...manageMeasureActions,
        ...measureActions,
        ...StakeholderActions,
        ...logicModelsActions,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditFrameworkModal);
