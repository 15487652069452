import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DataSourceActions from "../../../actions/calculation/data-source";
import CalculationsActions from "../../../actions/calculation/calculation-page";
import SourceColumn from "../../../components/calculations/sources/SourceColumn";
import ConditionsActions from "../../../actions/calculation/conditions";

function mapStateToProps(state, ownProps) {
  return {
    ...state.dataSource,
    activeVariable: state.calculations.activeVariable,
    headers: state.oneSourceFirstTab.headers,
    answers: state.oneSourceFirstTab.answers,
    intl: state.intl
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...DataSourceActions,
      ...CalculationsActions,
      setConditionInitialState: ConditionsActions.setConditionInitialState
    }, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SourceColumn)