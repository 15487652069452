import merge from "xtend";
import createReducer from '../../create-reducer';
import {
  HIDE_CONDITION_PANEL,
  SHOW_CONDITION_PANEL,
  CHANGE_CURRENT_CONDITION,
  ADD_CONDITION_ROW,
  REMOVE_CONDITION_ROW,
  CHANGE_CONDITION_DATE,
  SET_CURRENT_CONDITION_ROW,
  CLEAR_ERROR_HINTS,
  SHOW_ERROR_HINTS, CANCEL_NOT_APPLIED_CONDITION, CHANGE_APPLIED_CONDITIONS, SET_CONDITION_INITIAL_STATE,
  SET_EXISTING_VARIABLE_CONDITIONS, CLEAR_ALL_CONDITIONS, ERASE_ALL_CONDITIONS, SET_EXISTING_ARRAY_CALCULATION_CONDITIONS,
} from "../../../actions/calculation/conditions";

export const ERROR_HINTS_INITIAL_STATE = {
  numberField: "numberField",
  optionsField: "optionsField",
  notation: "notation",
  dateField: "dateField",
  columnHeader: "columnHeader"
};


export const CONDITION_INITIAL_STATE = {
  columnHeader: {
    name: "",
    value: null,
    obj: {},
  },
  optionValue: {
    value: "",
    label: "",
    startDate: null,
    options: [],
    inputValue: "",
    name: "",
    notation: ""
  },
  chosenData: "",
  id: null,
  errorHints: ERROR_HINTS_INITIAL_STATE,
  transformationForCondition: {}
};

const INITIAL_STATE = {
  showCondition: false,
  conditionColumn: null,
  filter: null,
  conditionValues: null,
  conditions: [],
  appliedConditions: [],
  currentCondition: CONDITION_INITIAL_STATE,
  errors: {},
  conditionsLoaded: false,
  conditionsLoading: false,
  showError: false
};

export const FIRST_CONDITION_ROW = {
  ...CONDITION_INITIAL_STATE,
  id: 1
};

export function removeCondition(conditions, conditionIdToRemove) {
  if (conditions.length > 1) {
    return conditions.reduce((acc, item) => {
      if (item.id !== conditionIdToRemove)
        acc.push(item);
      return acc
    }, []);
  } else
    return [FIRST_CONDITION_ROW]
}

export function createCondition(condition, conditionLastId) {
  return {
    ...condition,
    id: conditionLastId + 1
  }
}

export function getLastElementId(conditions) {
  return conditions.slice(conditions.length - 1)[0].id
}

export function changeCondition(conditions, conditionToChange) {
  return conditions.reduce((acc, item) => {
    if (item.id === conditionToChange.id) {
      acc.push(conditionToChange)
    }
    else
      acc.push(item);
    return acc
  }, [])
}

export function changeErrorHints(currentCondition, errorKey, conditions) {
  return conditions.reduce((acc, condition) => {
    if (condition.id === currentCondition.id) {
      condition.errorHints = {
        ...condition.errorHints,
        [errorKey]: ERROR_HINTS_INITIAL_STATE[errorKey]
      };
      acc.push(condition);
    } else {
      acc.push(condition);
    }
    return acc
  }, [])
}

export function removeOnlyUnAppliedConditions(conditions, appliedConditions) {
  if (conditions === appliedConditions) {
    return conditions
  } else if (appliedConditions.length > 0) {
    return appliedConditions.reduce((acc, condition) => {
      if (conditions.includes(condition))
        acc.push(condition);
      return acc
    }, [])
  }
  const condition = {
    ...CONDITION_INITIAL_STATE,
    id: 1
  };
  return [condition]
}

function setFixedDate(condition) {
  if (
    condition.chosenData === "date" &&
    condition.optionValue.startDate &&
    condition.optionValue.startDate.getFullYear
  ) {
    let { startDate } = condition.optionValue;

    let date = startDate.getDate();
    if (date.toString().length === 1)
      date = "0" + date;

    let month = startDate.getMonth() + 1;
    if (month.toString().length === 1)
      month = "0" + month;

    const year = startDate.getFullYear();

    condition.optionValue.startDate = `${year}-${month}-${date}`;
  }

  return condition;
}

export default createReducer({
  [SHOW_CONDITION_PANEL]: (state, action) => merge(state, {
    showCondition: true,
    conditions: state.conditions.length === 0 ? state.conditions.concat([createCondition(state.currentCondition, 0)]) : state.conditions
  }),
  [HIDE_CONDITION_PANEL]: (state, action) => merge(state, {
    showCondition: false,
    currentCondition: CONDITION_INITIAL_STATE,
    showError: false
  }),
  [CHANGE_CURRENT_CONDITION]: (state, action) => merge(state, {
    currentCondition: setFixedDate(action.condition),
    conditions: changeCondition(state.conditions, action.condition)
  }),
  [ADD_CONDITION_ROW]: (state, action) => merge(state, {
    conditions: state.conditions.concat([createCondition(CONDITION_INITIAL_STATE, getLastElementId(state.conditions)),]),
    showError: false
  }),
  [REMOVE_CONDITION_ROW]: (state, action) => merge(state, {
    conditions: removeCondition(state.conditions, action.conditionId),
    currentCondition: state.conditions.length > 0 ? state.conditions[0] : CONDITION_INITIAL_STATE,
    showError: false
  }),
  [CHANGE_CONDITION_DATE]: (state, action) => merge(state, {
    startDate: action.date
  }),
  [SET_CURRENT_CONDITION_ROW]: (state, action) => merge(state, {
    currentCondition: action.condition
  }),
  [CLEAR_ERROR_HINTS]: (state, action) => merge(state, {
    conditions: changeErrorHints(state.currentCondition, action.errorKey, state.conditions),
    showError: false
  }),
  [SHOW_ERROR_HINTS]: (state, action) => merge(state, {
    showError: action.status
  }),
  [CANCEL_NOT_APPLIED_CONDITION]: (state, action) => merge(state, {
    conditions: removeOnlyUnAppliedConditions(state.conditions, state.appliedConditions),
    currentCondition: state.conditions.length > 0 ? state.conditions[0] : CONDITION_INITIAL_STATE,
    showCondition: false,
    showError: false
  }),
  [CHANGE_APPLIED_CONDITIONS]: (state, action) => merge(state, {
    appliedConditions: action.conditions
  }),
  [SET_CONDITION_INITIAL_STATE]: (state, action) => merge(state, INITIAL_STATE),
  [SET_EXISTING_VARIABLE_CONDITIONS]: (state, action) => merge(state, {
    conditions: action.conditions,
    appliedConditions: action.conditions,
    currentCondition: action.conditions[0]
  }),
  [CLEAR_ALL_CONDITIONS]: (state, action) => merge(state, {
    conditions: [],
    currentCondition: FIRST_CONDITION_ROW,
    showCondition: false
  }),
  [ERASE_ALL_CONDITIONS]: (state, action) => merge(state, INITIAL_STATE),
  [SET_EXISTING_ARRAY_CALCULATION_CONDITIONS]: (state, action) => merge(state, {
    conditions: action.conditions,
    appliedConditions: action.conditions,
    currentCondition: action.conditions[0]
  }),
}, INITIAL_STATE)