import React from "react";
import PropTypes from "prop-types";
import { InjectIntl } from "../../../../common/InjectIntl";
import SelectBox from "../../../../common/SelectBox";
import DatePicker from "react-datepicker";
import {
  NUMBER_OR_DATE_TYPES,
  CHECKBOXES_TYPES,
  MATH_CONDITIONS
} from "../../../../../constants";
import { EDIT } from "../../../../members/constants";

const SELECT_CONDITION_VALUES = MATH_CONDITIONS.map((option, index) => {
  return { value: index, label: option };
});

const renderErrors = (chosenData, errorHints, validationType, intl) => (
  <div id="error-hints-container">
    <div>
      {NUMBER_OR_DATE_TYPES.includes(chosenData) &&
        !!errorHints.notation &&
        errorHints.notation.length > 1 && (
          <small> {intl("condition_error_notification.notation")} </small>
        )}
      {CHECKBOXES_TYPES.includes(chosenData) &&
        checkMultiSelectQuestionType(validationType.validation) &&
        errorHints.notation.length > 1 && (
          <small> {intl("condition_error_notification.notation")} </small>
        )}
    </div>
    <div>
      {CHECKBOXES_TYPES.includes(chosenData) &&
        !!errorHints.optionsField &&
        errorHints.optionsField.length > 1 && (
          <small> {intl("condition_error_notification.optionsField")} </small>
        )}
      {NUMBER_OR_DATE_TYPES.includes(chosenData) &&
        chosenData !== "date" &&
        !!errorHints.numberField &&
        errorHints.numberField.length > 1 && (
          <small> {intl("condition_error_notification.numberField")} </small>
        )}
      {chosenData === "date" &&
        !!errorHints.dateField &&
        errorHints.dateField.length > 1 && (
          <small> {intl("condition_error_notification.dateField")} </small>
        )}
    </div>
  </div>
);

export const checkMultiSelectQuestionType = questionValidation => {
  let disabledNotation = !!questionValidation;
  if (disabledNotation) disabledNotation = questionValidation.type === "number";
  return disabledNotation;
};

export const checkMultiSelectNumberType = (conditionObject) => {
  let disabledNotation = !!conditionObject.validation && !!conditionObject.question_type;
  if (disabledNotation) disabledNotation = conditionObject.question_type.type === "checkbox" && conditionObject.validation.type === "number";
  return disabledNotation;
};

const ConditionFilter = props => {
  const {
    intlLocale,
    optionValue,
    changeHandler,
    inputChangeHandler,
    chosenData,
    options,
    showError,
    handleDateChange,
    intl,
    condition: { errorHints, columnHeader }
  } = props;

  let fixedDate = null;

  if (chosenData === "date" && optionValue.startDate) {
    if (typeof optionValue.startDate === "string") {
      const dateParams = optionValue.startDate.split("-");

      fixedDate = new Date(+dateParams[0], +dateParams[1] - 1, +dateParams[2]);
    } else {
      fixedDate = optionValue.startDate;
    }
  }
  const isReadOnlyPermission = localStorage.getItem("permission") !== EDIT;
  const isMultipleChoiceOfNumberType = checkMultiSelectQuestionType(columnHeader.obj.validation);

  return (
    <div id="select-condition-notation">
      {chosenData.length === 0 && (
        <div className="content-disabled" style={{ display: "none" }}>
          <input type="text" className="form-control col-md-12" />
        </div>
      )}

      {(NUMBER_OR_DATE_TYPES.includes(chosenData) || isMultipleChoiceOfNumberType) && (
        <SelectBox
          options={SELECT_CONDITION_VALUES}
          onHeaderChange={e => changeHandler(e)}
          selectedOption={optionValue.value}
          locale={intlLocale}
          placeholder={intl("source.condition_notation")}
          SelectBoxClassNames={"math-condition-filter"}
          conditionBlock
          disabled={isReadOnlyPermission}
        >
          {chosenData === "date" ? (
            <div style={{ width: "70%" }}>
              <DatePicker
                selected={fixedDate}
                onChange={handleDateChange}
                className={"form-control success col-lg-4"}
                placeholderText={"DD/MM/YYYY"}
                disabled={isReadOnlyPermission}
              />
            </div>
          ) : (
            <div id="input-condition-field">
              <input
                type="text"
                className="form-control success col-md-12"
                onChange={e => inputChangeHandler(e)}
                value={optionValue.inputValue}
                placeholder={intl("source.select_condition_filter_by_number")}
                disabled={isReadOnlyPermission}
              />
            </div>
          )}
        </SelectBox>
      )}

      {CHECKBOXES_TYPES.includes(chosenData) && !isMultipleChoiceOfNumberType && (
        <SelectBox
          options={[]}
          onHeaderChange={e => changeHandler(e)}
          selectedOption={optionValue.value}
          locale={intlLocale}
          placeholder="N/A"
          SelectBoxClassNames="math-condition-filter content-disabled"
          disabled={true}
          conditionBlock
        >
          <div id="input-condition-field">
            <SelectBox
              options={options}
              onHeaderChange={e => changeHandler(e)}
              selectedOption={optionValue.options}
              locale={intlLocale}
              placeholder={intl("source.select_condition_filter_by_options")}
              SelectBoxClassNames={"col-md-12"}
              multiSelect
              disabled={isReadOnlyPermission}
            />
          </div>
        </SelectBox>
      )}
      {showError &&
        renderErrors(chosenData, errorHints, columnHeader.obj, intl)}
    </div>
  );
};

ConditionFilter.propTypes = {
  optionValue: PropTypes.object.isRequired,
  changeHandler: PropTypes.func.isRequired,
  inputChangeHandler: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  startDate: PropTypes.string,
  handleDateChange: PropTypes.func.isRequired
};

export default InjectIntl(ConditionFilter);
