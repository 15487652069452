import React from "react";
import PropTypes from "prop-types";
import { InjectIntl } from "../../../common/InjectIntl";
import PermissionChecker from "../../../common/PermissionChecker";

const AdjustmentItem = ({
  name,
  description,
  onChangeDescription,
  value,
  onChangeValue,
  isNumOfRecords,
  intl
}) => {
  return (
    <div className={`row ${name}`}>
      <div className="col-md-2">
        <label>{intl(`stakeholder.items.adjustment_${name}`)}</label>
      </div>
      <div className="col-md-8">
        <PermissionChecker>
          <textarea
            rows="2"
            placeholder="Enter description"
            name={`${name}_description`}
            className="form-control success"
            value={description}
            onChange={onChangeDescription}
          />
        </PermissionChecker>
      </div>
      <div className="col-md-2">
        {!isNumOfRecords ? (
          <div className="input-group">
            <PermissionChecker>
              <input
                type="text"
                name={name}
                className="form-control success"
                value={value}
                onChange={onChangeValue}
              />
            </PermissionChecker>
            <span className="input-group-addon">%</span>
          </div>
        ) : (
          <div className="form-group">
            <label className="duration_label">No. of periods</label>
            <PermissionChecker>
              <input
                type="text"
                name="duration"
                className="form-control success"
                value={value}
                onChange={onChangeValue}
              />
            </PermissionChecker>
          </div>
        )}
      </div>
    </div>
  );
};

AdjustmentItem.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  onChangeDescription: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChangeValue: PropTypes.func.isRequired,
  isNumOfRecords: PropTypes.bool,
  intl: PropTypes.func.isRequired
};

export default InjectIntl(AdjustmentItem);
