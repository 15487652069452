import { connect } from 'react-redux';
import TopMenu from '../components/TopMenu';
import { bindActionCreators } from 'redux';
import { changeLocale } from '../actions/application';
import LoginActions from '../actions/login';
import ServicesActions from '../actions/services';

function mapStateToProps(state) {
  return {
    ...state.application,
    ...state.login
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      changeLocale,
      ...LoginActions,
      ...ServicesActions,
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);