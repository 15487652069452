import React from "react";
import PropTypes from "prop-types";
import { capitalize, parseValue } from "../../../assets/sam-utils";
import {
  INCOMPLETE,
  BROKEN,
  PREEXISTING_DATASOURCE_TYPE_ID
} from "../sources/source-table-layout/constants";

const VariableTransformation = ({ variableItem, status }) => {
  let transformation =
    variableItem.transformation &&
    capitalize(variableItem.transformation.toLowerCase());

  let transformationValue = parseValue(variableItem);
  if (variableItem.datasource_type_id === PREEXISTING_DATASOURCE_TYPE_ID) {
    transformation = "f(x)";

    if (
      variableItem.preexisting_variables &&
      !variableItem.datasource_object.is_day_value
    ) {
      variableItem.preexisting_variables.forEach(variable => {
        if (
          variable.is_used &&
          variable.transformation === "AVG DATE OF RESPONSE"
        ) {
          transformation = "AVG DATE OF RESPONSE";
        }
      });
    }

    // not array_calculation
    if(!!variableItem.datasource_object) {
      transformationValue = parseValue({
        transformation: transformation,
        value: variableItem.datasource_object.value
      });
    }
    
    transformation = "f(x)";
  } else if (transformation && !transformation.endsWith("of response")) {
    transformation = `${transformation} of responses`;
  } else if(!!variableItem.use_array_calculation) {
    transformation = "f(x)";
  }

  return (
    <td className="align-middle transformation-column">
      {transformation ? (
        <div className="transformation-container">
          <div className="transformation-left">
            <span>{transformation}</span>
          </div>
          <div className="transformation-right">
            {variableItem.datasource_object &&
            variableItem.datasource_object.condition === "invalid_formula" ? (
              <i className="fa fa-warning invalid-formula" />
            ) : (
              <strong
                className={`gray-box ${
                  status === INCOMPLETE || status === BROKEN ? "light-gray" : ""
                }`}
              >
                {transformationValue === "0.00" ? 0 : transformationValue}
              </strong>
            )}
          </div>
        </div>
      ) : (
        <div className="vcenter pull-right">
          <strong className="gray-box light-gray">0</strong>
        </div>
      )}
    </td>
  );
};

VariableTransformation.propTypes = {
  variableItem: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired
};

export default VariableTransformation;
