import React from "react";
import PropTypes from "prop-types";
import { InjectIntl } from "../../../common/InjectIntl";
import OneSource from "./OneSource";
import PreexistingSource from "./PreexistingSource";
import { PREEXISTING_DATASOURCE_TYPE_ID } from "./constants";

export const SourcePanel = props => {
  const { activeVariable, intl } = props;

  const renderBody = () => {
    if (activeVariable.datasource_type_id !== PREEXISTING_DATASOURCE_TYPE_ID) {
      return <OneSource {...props} />;
    } else {
      return <PreexistingSource {...props} />;
    }
  };

  return (
    <div className="col-md-4">
      <div>
        <label>{intl("calculation.source")} </label>
        <div className="well add-source-block">
          {!!activeVariable && (
            <div>
              {activeVariable.tableLayout ? (
                renderBody()
              ) : (
                <div>
                  <h2 className="inner-title">{activeVariable.name}</h2>
                  <p>{intl("source.incomplete_variable_hint")}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SourcePanel.propTypes = {
  activeVariable: PropTypes.object,
  intl: PropTypes.func.isRequired
};

export default InjectIntl(SourcePanel);
