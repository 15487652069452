import React from 'react';
import PropTypes from 'prop-types';

const LogicModelDropdown = ({ logic_models, changeLogicModel }) => {

  const selectedLogicModel = () => {
    let selected = logic_models.find(logic_model => logic_model.selected === true);
    return selected ? selected.id : '';
  }

  return (
    <div className="row logic_model_dropdown">
      <div className="col-md-12">
        <label>Logic Model</label>
        <select 
          className="form-control" 
          value={selectedLogicModel()}
          onChange={e => changeLogicModel(e.target.value)}
        >
          {
            logic_models.map(logic_model => 
              <option
                key={logic_model.id}
                value={logic_model.id}
              >{logic_model.name}</option>
            )
          }
        </select>
      </div>
    </div>
  );
}

LogicModelDropdown.propTypes = { 
  logic_models: PropTypes.arrayOf(PropTypes.shape({
     id: PropTypes.number.isRequired,
     name: PropTypes.string.isRequired,
   })).isRequired,
  changeLogicModel: PropTypes.func.isRequired
}

export default LogicModelDropdown;
