import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const WarningMessage = ({ children, danger, icon }) => {
  const className = classNames("variable_warning", {
    "text-danger": danger,
    "row": icon
  });

  if (icon) {
    return (
      <div className={className}>
        <div className="col-md-1">
          <i className="fa fa-warning source-warning-icon" />
        </div>
        <div className="col-md-10 p-5-0">{children}</div>
      </div>
    );
  } else {
    return <div className={className}>{children}</div>;
  }
};

WarningMessage.propTypes = {
  children: PropTypes.node.isRequired,
  danger: PropTypes.bool,
  icon: PropTypes.bool
};

export default WarningMessage;
