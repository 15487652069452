import React from "react";
import PropTypes from "prop-types";
import { InjectIntl } from "../common/InjectIntl";
import MembersTable from "../members/MembersTable";
import InviteCollaboratorsModal from "../../containers/collaborators/InviteCollaboratorsModal.container";
import EditAndCollaboratorNav from "../measure/EditAndCollaboratorNav";
import ErrorNotification from "../../containers/ErrorNotification.container";
import LoadingNotification from "../../containers/LoadingNotification.container";


class ManageCollaborators extends React.Component {
  static propTypes = {
    collaboratorModalShow: PropTypes.bool.isRequired,
    logicModel: PropTypes.object.isRequired,
    members: PropTypes.array.isRequired,
    invitations: PropTypes.array.isRequired,
    actions: PropTypes.shape({
      showCollaboratorModal: PropTypes.func,
      changeMessage: PropTypes.func,
      changeSubject: PropTypes.func,
      fetchMembers: PropTypes.func,
      removeUser: PropTypes.func,
      updateUserPermission: PropTypes.func,
    }.isRequired)
  };

  openRemoveMembersModal = e => this.props.actions.removeUser(e);

  saveUserPermission = userId => {
    var membersData = this.props.members;
    var member = membersData.find(
      member => member.id === userId
    ) 
    this.props.actions.updateUserPermission(userId, member.permission)
  };

  handlePermissionChange = (userId, e) => {
    var membersData = this.props.members;
    membersData.find(
      (member) => {
        if(member.id === userId){
          member.permission = e.value;
          return true;
        }
      }
    ) 
    this.setState({members: membersData});
  };

  componentDidMount() {
    this.props.actions.fetchLogicModel(this.props.logicModelId);
    this.props.actions.fetchMembers();
  }

  setInitialMessage = () => {
    const firstName = localStorage.getItem('first_name');
    const lastName = localStorage.getItem('last_name');
    const logicModelName = this.props.logicModel.name;
    const name = `${firstName} ${lastName}`;
    const msg = this.props.intl('inviteMembersModal.initialMsg', { name, logicModelName });
    const defaultMsgs = this.props.intl('inviteMembersModal.defaultMsgs', { firstName });
    const subject = this.props.intl('inviteMembersModal.initialSubject', { name });
    this.props.actions.changeMessage(msg, false);
    this.props.actions.changeDefaultMessages(defaultMsgs);
    this.props.actions.changeSubject(subject);
  };

  openInviteModal = (e) => {
    e.preventDefault();
    this.setInitialMessage();
    this.props.actions.showCollaboratorModal();
  };

  getCurrentMember = () =>
    this.props.members.find(
      member => String(member.id) === localStorage.getItem("user_id")
    ) || {};

  render() {
    const { invitations, intl, members, logicModel } = this.props;

    return (
      <div>
        <div className="row  m-b-lg">
          <div className="col-md-12 text-right">
            <EditAndCollaboratorNav logicModel={logicModel}/>
            <button className="btn btn-success" onClick={this.openInviteModal}>
              <i className="fa fa-plus"/>&nbsp;{intl('collaborators.index.invite_collaborator')}
            </button>
          </div>
        </div>
        <hr/>
        <div className="row">
          <div className="col-md-12">
            <h2>{intl('collaborators.index.collaborators')}</h2>
            <MembersTable
              members={members}
              logicModel={logicModel}
              onPermissionChange={this.handlePermissionChange}
              onSavePermission={this.saveUserPermission}
              onDeleteMember={this.openRemoveMembersModal}
              currentCollaborator={this.getCurrentMember()}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 m-t-lg">
            <h2>{intl('invitations_table.title')}</h2>
            <MembersTable
              members={invitations}
              logicModel={logicModel}
              forInvitations={true}
              currentCollaborator={this.getCurrentMember()}
            />
          </div>
        </div>
        <ErrorNotification/>
        <LoadingNotification/>

        <InviteCollaboratorsModal/>
      </div>
    )
  }
}

export default InjectIntl(ManageCollaborators);