export const QUESTION_FORM_TYPES = {
  1: "radio",
  2: "checkbox",
  3: "text",
  4: "file_upload",
  5: "multiple_fields",
  6: "anonymous_respondent"
};

export const UPLOAD_DATA_ID_TYPES = {
  1: "string",
  2: "integer",
  3: "float",
  4: "date",
  5: "boolean",
  6: "multiple_choice",
  7: "email"
};

export const CHECKBOXES_TYPES = ['radio', 'checkbox', 'multiple_choice'];

export const NUMBER_OR_DATE_TYPES = ["integer", "float", "number", "date"];

export const MATH_CONDITIONS = ['>', '<', '=', '≥', '≤'];

export const MATH_EXPRESSION_SYMBOLS = [
  { label: '+', value: 'add' }, 
  { label: '-', value: 'subtract' }, 
  { label: '×', value: 'multiply' }, 
  { label: '÷', value: 'divide' }
];

export const DEMOGRAPHIC = "Demographic";

export const OUTCOME_INDICATOR = "Outcome Indicator";

export const OUTPUT = "Output";

export const INPUT = "Input";

export const FINANCIAL_PROXY = "Financial Proxy";

export const ACTIVITY = "Activity";

export const OUTCOME = "Outcome";

export const SOURCE = "source";

export const TARGET = "target";

export const QUESTION_TYPE_TEXT = "text";

export const QUESTION_TYPE_RADIO = "radio";

export const VALIDATION_TYPE_NUMBER = "number";