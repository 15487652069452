import { Observable } from "rxjs/Observable";
import { post} from "../../api";
import 'rxjs';
import { ADD_DEMOGRAPHIC_MEASURE, ADD_DEMOGRAPHIC_MEASURE_FAILURE, ADD_DEMOGRAPHIC_MEASURE_SUCCESS } from "../../actions/stakeholder-items/demographics";
import { FETCH_LOGIC_MODEL } from "../../actions/measure/measure";

export function addDemographicMeasureEpic(action$, store) {
  return action$.ofType(ADD_DEMOGRAPHIC_MEASURE)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { logicModelId, stakeholderId } = store.getState().measurePage;
      const DEMOGRAPHIC_MEASURE_ID = 1;
      const payload = {name: "Unnamed Demographic", measure_type_id: DEMOGRAPHIC_MEASURE_ID, stakeholder_id: stakeholderId};
      const id = logicModelId;

      return Observable.fromPromise(post(`/api/measures`, payload, token))
        .flatMap(( { data }) => {
          const measure = data.measure;
          const logic_model = data.logic_model;

          return Observable.concat(
            Observable.of({ type: ADD_DEMOGRAPHIC_MEASURE_SUCCESS, payload: { logic_model, measure } } ),
            Observable.of({type: FETCH_LOGIC_MODEL, id })
          )
        })
        .catch(errors => Observable.of({ type: ADD_DEMOGRAPHIC_MEASURE_FAILURE, errors }))
    })
}

export default [addDemographicMeasureEpic]