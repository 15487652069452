import { get } from '../api';
import 'rxjs';
import { Observable } from 'rxjs/Observable';


import { GET_SERVICES_BASE_URLS, GET_SERVICES_BASE_URLS_SUCCESS, GET_SERVICES_BASE_URLS_FAILURE } from '../actions/application';

function getServicesBaseUrlsEpic(action$, store) {
  return action$.ofType(GET_SERVICES_BASE_URLS)
    .mergeMap((action) => {
      return Observable.fromPromise(get('/api/getAtlasBaseUrl'))
        .flatMap((base_url) => {

          const atlas_base_url = base_url.atlas;
          const uniform_base_url = base_url.uniform;
          const compass_base_url = base_url.compass;
          localStorage.setItem('atlas_base_url', atlas_base_url);
          localStorage.setItem('uniform_base_url', uniform_base_url);
          localStorage.setItem('compass_base_url', compass_base_url);
          return Observable.of({type: GET_SERVICES_BASE_URLS_SUCCESS, payload: {atlas_base_url, uniform_base_url}})
        })
        .catch(errors => Observable.of({ type: GET_SERVICES_BASE_URLS_FAILURE, payload: { errors }}))
    })
}


export default [getServicesBaseUrlsEpic];