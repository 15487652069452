import React from "react";
import PropTypes from "prop-types";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import SearchField from "../../../common/SearchField";
import ShowTotal from "../../../common/ShowTotal";
import { getFormTitle } from "../../../../assets/sam-utils";

const BootstrapTableFinalTab = props => {
  const {
    onSearchChange,
    finalPageSizePerPage,
    finalPageTotalDataSize,
    onPageChange,
    finalPageCurrentPage,
    onSizePerPageList,
    searchTxt,
    finalData,
    intlLocale
  } = props;

  const tableOptions = () => {
    return {
      sizePerPageList: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 }
      ],
      onSearchChange,
      sizePerPage: finalPageSizePerPage,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: "<",
      firstPage: "<<",
      nextPage: ">",
      lastPage: ">>",
      paginationPosition: "top",
      onPageChange,
      page: finalPageCurrentPage,
      onSizePerPageList,
      searchField: props => (
        <SearchField {...props} value={searchTxt} placeholder={"Search"} />
      ),
      paginationShowsTotal: () => <ShowTotal total={finalPageTotalDataSize} />
    };
  };

  const getHeaderName = () => {
    if (finalData.name) {
      return finalData.name;
    } else {
      return getFormTitle(finalData, intlLocale);
    }
  };

  return (
    <BootstrapTable
      fetchInfo={{ dataTotalSize: finalPageTotalDataSize }}
      bodyStyle={{ overflow: "auto" }}
      data={props.finalData.items}
      options={tableOptions()}
      striped
      hover
      pagination
      condensed
      search
      remote={true}
    >
      <TableHeaderColumn width="150" isKey dataField="value" dataAlign="left">
        {getHeaderName()}
      </TableHeaderColumn>
    </BootstrapTable>
  );
};

BootstrapTableFinalTab.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  onSizePerPageList: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  searchTxt: PropTypes.string.isRequired,
  finalData: PropTypes.shape({
    total: PropTypes.number.isRequired,
    items: PropTypes.array.isRequired,
    name: PropTypes.string
  }),
  finalPageSizePerPage: PropTypes.number.isRequired,
  finalPageCurrentPage: PropTypes.number.isRequired,
  finalPageTotalDataSize: PropTypes.number.isRequired,
  currentDataSource: PropTypes.object.isRequired,
  intlLocale: PropTypes.string.isRequired
};

export default BootstrapTableFinalTab;
