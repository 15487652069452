import React from "react";
import PropTypes from "prop-types";

const Input = props => {
  const handler = e => {
    e.preventDefault();
    const name = e.target.value;
    props.changeName(name);
  };

  const keyPressedHandler = e => {
    if (e.key === "Enter") {
      props.save(e.target.value);
    }
  };

  return (
    <input
      type="search"
      className="form-control success"
      placeholder={props.placeholder}
      autoFocus={true}
      onChange={handler}
      onKeyPress={keyPressedHandler}
      onFocus={props.onFocus}
      value={props.name}
      disabled={props.disabled}
    />
  );
};

Input.propTypes = {
  autoFocus: PropTypes.bool.isRequired,
  changeName: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default Input;
