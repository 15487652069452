import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { InjectIntl } from "../../../common/InjectIntl";
import ConditionIndex from "../../../../containers/calculations/sources/modals/Conditions-panel";
import TransformationLabelAndValue from "./TransformationLabelAndValue";
import { EDIT } from "../../../members/constants";

const SelectSourceDetailsOptions = props => {
  const {
    conditions,
    newTransformationValue,
    currentDataSource: { transformation },
    activeVariable,
    intl,
    actions: { showConditionPanel, eraseAllConditions },
    showCondition,
    children
  } = props;

  const conditionButtonClass = classNames({
    "btn btn-success pull-right m-r-lg": true,
    "content-disabled": !(
      !!transformation &&
      !!transformation.label &&
      !showCondition
    )
  });

  const conditionClassName = classNames({
    show: showCondition,
    hide: !showCondition
  });

  const eraseConditionsButtonClass = classNames({
    "btn btn-success pull-right m-r": true,
    show: showCondition,
    hide: !showCondition
  });

  const isReadOnlyPermission = localStorage.getItem("permission") !== EDIT;
  return (
    <div>
      {children}
      <div className="row">
        <button className={conditionButtonClass} onClick={showConditionPanel}>
          {isReadOnlyPermission ? intl("source.modal.view_condition") : intl("source.modal.add_condition")}
        </button>
        {!isReadOnlyPermission &&
          <button
            className={eraseConditionsButtonClass}
            onClick={eraseAllConditions}
          >
            {intl("source.modal.clear_all")}
          </button>
        }
      </div>
      <div className={conditionClassName}>
        {showCondition && <ConditionIndex />}
      </div>
      <label className="m-l">{intl("calculation.value")}</label>
      <div className="form-control form-control-lg">
        {!!activeVariable.id && !!transformation && (
          <TransformationLabelAndValue
            {...{
              transformation,
              newTransformationValue,
              conditions
            }}
          />
        )}
      </div>
    </div>
  );
};

SelectSourceDetailsOptions.propTypes = {
  activeVariable: PropTypes.object.isRequired,
  currentDataSource: PropTypes.shape({
    transformation: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  }),
  showCondition: PropTypes.bool.isRequired,
  newTransformationValue: PropTypes.number,
  conditions: PropTypes.array,
  actions: PropTypes.shape({
    showConditionPanel: PropTypes.func.isRequired,
    eraseAllConditions: PropTypes.func.isRequired
  })
};

export default InjectIntl(SelectSourceDetailsOptions);
