import React from 'react';
import PropTypes from 'prop-types';
import { InjectIntl } from "../../../common/InjectIntl";
import { getFormTitle } from "../../../../assets/sam-utils";
import debounce from 'lodash/debounce';
import BootstrapTableFinalTab from "./BootstrapTableFinalTab";
import VariableNameInModal from "./VariableNameInModal";
import TransformationLabelAndValue from "./TransformationLabelAndValue";

class SourceModalFinalTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      variableNameInputShow: false,
      name: props.activeVariable.name,
      id: '',
      header: {},
      arrayCalculationReady: false,
    }
  }

  static propTypes = {
    finalData: PropTypes.object.isRequired,
    activeVariable: PropTypes.object,
    currentDataSource: PropTypes.shape({
      headerId: PropTypes.number,
      uploadId: PropTypes.number,
      sourceName: PropTypes.string,
      transformation: PropTypes.shape({
        label: PropTypes.string,
      }).isRequired
    }).isRequired,
    headers: PropTypes.array.isRequired,
    finalPageCurrentPage: PropTypes.number.isRequired,
    finalPageSizePerPage: PropTypes.number.isRequired,
    sizePerPage: PropTypes.number.isRequired,
    totalDataSize: PropTypes.number.isRequired,
    newTransformationValue: PropTypes.number,
    searchTxt: PropTypes.string,
    intlLocale: PropTypes.string,
    intl: PropTypes.func.isRequired,
    conditions: PropTypes.array,
    actions: PropTypes.shape({
      setVariable: PropTypes.func.isRequired,
      saveFinalVariableName: PropTypes.func.isRequired,
      fetchOneSourceFinalResultData: PropTypes.func.isRequired,
      changeDataForFinalTab: PropTypes.func.isRequired,
    })
  };

  closeInputField = () => {
    this.setState({ variableNameInputShow: false })
  };

  changeVariableName = (e) => {
    e.preventDefault();
    const variableName = e.target.value;
    this.setState({ name: variableName });
  };

  saveChanges = (e) => {
    e.preventDefault();
    this.setState({ id: '', variableNameInputShow: false });
    this.props.actions.saveFinalVariableName(this.state.name);
  };

  clickVariableNameHandler = () => {
    this.setState({ variableNameInputShow: true })
  };

  componentWillUnmount() {
    this.setState({ name: '' })
  }

  getHeaderColumnName = () => {
    let headerName = '';
    this.props.headers.forEach(header => {
      if (header.id === this.props.currentDataSource.headerId)
        if (this.props.currentDataSource.sourceName === 'Survey')
          headerName = getFormTitle(header, this.props.intlLocale);
        else
          headerName = header.label;
    });
    return (<h4>{headerName}</h4>)
  };

  onPageChange(page, sizePerPage) {
    this.props.actions.changeDataForFinalTab(page, sizePerPage, this.props.currentDataSource.uploadId, this.props.searchTxt);
    this.props.actions.fetchOneSourceFinalResultData(this.props.currentDataSource.headerId);
  }

  onSizePerPageList(sizePerPage) {
    this.props.actions.changeDataForFinalTab(this.props.finalPageCurrentPage, sizePerPage, this.props.currentDataSource.uploadId, this.props.searchTxt);
    this.props.actions.fetchOneSourceFinalResultData(this.props.currentDataSource.headerId);
  }

  onSearchChange(searchText, colInfos, multiColumnSearch) {
    const text = searchText.trim();
    if (text === '') {
      this.props.actions.changeDataForFinalTab(1, this.props.sizePerPage, this.props.currentDataSource.uploadId);
      this.props.actions.resetSearchTxt();
    }
    if (this.props.currentDataSource.uploadId)
      this.props.actions.search(text, this.props.currentDataSource.uploadId, this.props.finalPageSizePerPage);
  }

  render() {
    const { finalData, currentDataSource: { transformation }, intl, newTransformationValue, conditions } = this.props;

    return (
      <div>
        <p>{intl('source.modal.selected_transformed_value')}</p>
        <div className="form-control form-control-lg m-b">
          <TransformationLabelAndValue
            {...{
              transformation,
              newTransformationValue,
              conditions
            }}
          />
        </div>
        <span className="text-muted">{intl('source.modal.source_column_name')}</span>
        {this.getHeaderColumnName()}

        <div className="wrap">
          {finalData.items &&

          <BootstrapTableFinalTab
            onPageChange={this.onPageChange.bind(this)}
            onSizePerPageList={this.onSizePerPageList.bind(this)}
            onSearchChange={debounce(this.onSearchChange.bind(this), 1000)}
            {...this.props}
          />
          }
        </div>
        <div className="m-t">
          <span className="text-muted">{intl('source.modal.variable_name_hint')}</span>
        </div>

        <VariableNameInModal
          closeInputField={this.closeInputField}
          saveChanges={this.saveChanges}
          variableNameInputShow={this.state.variableNameInputShow}
          intl={intl} name={this.state.name}
          changeVariableName={this.changeVariableName}
          clickVariableNameHandler={this.clickVariableNameHandler}
        />
      </div>
    );
  }
}

export default InjectIntl(SourceModalFinalTab);