import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import TopMenuDropDown from './TopMenuDropDown';
import {EN_LOCALE, FR_LOCALE} from "../actions/application";
import {intlShape} from 'react-intl';

class TopMenu extends React.Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    language: PropTypes.string,
    actions: PropTypes.shape({
      changeLocale: PropTypes.func.isRequired,
      goToChangePassword: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
      selectService: PropTypes.func.isRequired
    }).isRequired
  };

  static contextTypes = {
    intl: intlShape.isRequired
  };

  intl = (messageId) => this.context.intl.formatMessage({id: messageId});

  handleLogoutClick = (event) => {
    event.preventDefault();
    this.props.actions.logout();
  };

  renderLanguageMenu = () => {
    const {language} = this.props;

    return (
      <TopMenuDropDown name={language} id={"language-switcher"}>
        <li><a href="#!" onClick={() => this.props.actions.changeLocale(EN_LOCALE)}>English</a></li>
        <li><a href="#!" onClick={() => this.props.actions.changeLocale(FR_LOCALE)}>Français</a></li>
      </TopMenuDropDown>

    )
  };

  clickUniformService=(e)=>{
    e.preventDefault();
    this.props.actions.selectService('uniform');
  };

  clickReportService=(e)=>{
    e.preventDefault();
    this.props.actions.selectService('compass');
  };

  clickSametricaButton=(e)=>{
    e.preventDefault();
    this.props.actions.selectService('atlas');
  };

  renderAuthenticatedMenuItems = () => {
    const {name} = this.props;
    return (
      <div className="navbar-collapse collapse" id="navbar">
        <ul className="nav navbar-nav">
          <li className="warning no-bottom-border active">
            <Link to="logic-models">{this.intl("topMenu.items.logicModelBuilder")}</Link>
          </li>
          <li className="warning no-bottom-border">
            <a href="#!" onClick={this.clickUniformService}>{this.intl("topMenu.items.complexDataCollector")}</a>
          </li>
          <li className="warning no-bottom-border">
            <a href="#!" onClick={this.clickReportService}>{this.intl("topMenu.items.reportAndAnalysis")}</a>
          </li>
          <li className="success">
            <a href="#!" tabIndex="-1" className="disable-hover"> | </a>
          </li>
          <li className="success">
            <Link to="/logic-models">{this.intl("topMenu.items.logicModels")}</Link>
          </li>
        </ul>
        <ul className="nav navbar-top-links navbar-right">
          <li><a href="http://help.sametri.ca" target="_blank"><span className="glyphicon glyphicon-question-sign"/></a></li>
          {this.renderLanguageMenu()}
          <TopMenuDropDown name={`${name}`}>
            <li><a href="#!" onClick={this.props.actions.goToChangePassword}>{this.intl('topMenu.items.changePassword')}</a></li>
            <li className="divider"/>
            <li>
              <a href="#!" onClick={this.handleLogoutClick}>
                <i className="fa fa-sign-out m-r-xs"/>
                {this.intl('topMenu.items.logout')}
              </a>
            </li>
          </TopMenuDropDown>
        </ul>
      </div>
    )
  };

  renderAnonymousMenuItems = () => {
    return (
      <div className="navbar-collapse collapse" id="navbar">
        <ul className="nav navbar-top-links navbar-right">
          {this.renderLanguageMenu()}
        </ul>
      </div>
    )
  };

  render() {
    const {isAuthenticated} = this.props;

    const menuItems = isAuthenticated ? this.renderAuthenticatedMenuItems() : this.renderAnonymousMenuItems();

    return (
      <nav className="navbar navbar-static-top" role="navigation">
        <div className="navbar-header">
          <button aria-controls="navbar" aria-expanded="false" data-target="#navbar"
                  data-toggle="collapse" className="navbar-toggle collapsed" type="button">
            <i className="fa fa-reorder"/>
          </button>
          <a href="#!"  onClick={this.clickSametricaButton} className="navbar-brand navbar-brand-success">SAMETRICA</a>
        </div>
        {menuItems}
      </nav>
    );
  }
}

export default TopMenu;
