import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  BROKEN,
  NO_RESPONSES,
  UNPUBLISHED
} from "../sources/source-table-layout/constants";

const VariableItemWrapper = ({
  activeVariable,
  variableItem,
  actions,
  status,
  isParent,
  children
}) => {
  const changeToActive = () => {
    if (activeVariable.id !== variableItem.id)
      actions.changeActiveVariable(variableItem);
  };

  const className = classNames("variable-row", {
    active: activeVariable.id === variableItem.id,
    "broken-variable":
      [BROKEN, NO_RESPONSES, UNPUBLISHED].includes(status) && isParent
  });

  return (
    <tr className={className} onClick={changeToActive}>
      {children}
    </tr>
  );
};

VariableItemWrapper.propTypes = {
  activeVariable: PropTypes.object.isRequired,
  variableItem: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  status: PropTypes.string.isRequired,
  isParent: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    changeActiveVariable: PropTypes.func.isRequired
  }).isRequired
};

export default VariableItemWrapper;
