import React from 'react';

export const ModalNavLink = ({ to, className, activeClassName, children, textClassName, actionClick }) => {
  return (
    <button className={`${className} ${to ? activeClassName : ''}`} onClick={actionClick}>
      <a href="#!" className={`${textClassName} ${to ? activeClassName : 'gray-color'}`}>
        {children}
      </a>
    </button>
  )
};
