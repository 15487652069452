import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import manageMeasureActions from "../../../../actions/measure/manage-measure";
import outcomesActions from "../../../../actions/stakeholder-items/outcomes";
import ontologyActions from "../../../../actions/ontology";
import measureActions from "../../../../actions/measure/measure";
import AddOutcomeParams from "../../../../components/logic-models/stakeholder-items/add-outcomes/OutcomeItem";
import outcomeIndicatorsActions from "../../../../actions/stakeholder-items/outcomeIndicator";


function mapStateToProps(state, ownProps) {
  return {
    ...state.measurePage,
    ...state.dataSource,
    ...state.outcomes,
    ...state.ontology,
    ...state.manageMeasure,
    outcomes: ownProps.outcomes,
    stakeholder: ownProps.stakeholder,
    measure: ownProps.measure,
    intl: state.intl
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...manageMeasureActions,
      ...measureActions,
      ...outcomesActions,
      ...ontologyActions,
      ...outcomeIndicatorsActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOutcomeParams)