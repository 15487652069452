import React from "react";
import PropTypes from "prop-types";

const CalculationsNamesTabs = ({ calculations, currentCalculation, setCalculation }) => {
  return (
    <div className="col-md-2 tab-wrap">
      {calculations.map((calculation, index) => (
        <div
          key={index}
          className={`${
            calculation.id === currentCalculation.id ? "active" : ""
          } pointer calculation-nav-item`}
          onClick={() => setCalculation(calculation)}
        >
          <span>{calculation.name}</span>
        </div>
      ))}
    </div>
  );
};

CalculationsNamesTabs.propTypes = {
  calculations: PropTypes.array.isRequired,
  currentCalculation: PropTypes.object.isRequired,
  setCalculation: PropTypes.func.isRequired
};

export default CalculationsNamesTabs;
