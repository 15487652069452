import merge from "xtend";
import createReducer from "../../create-reducer";
import {
    SELECT_STANDARD_CALCULATION,
    SELECT_ARRAY_CALCULATION
} from "../../../actions/calculation/selectCalculationType";
import { SHOW_SELECT_VARIABLE_TYPE_MODAL } from "../../../actions/calculation/selectVariableType";

const INITIAL_STATE = {
    showStandardCalculation: true,
    showArrayCalculation: false,
};

export default createReducer(
  {
    [SELECT_STANDARD_CALCULATION]: (state, action) =>
      merge(state, {
        isLoading: true,
        showStandardCalculation: true,
        showArrayCalculation: false,
      }),
    [SELECT_ARRAY_CALCULATION]: (state, action) =>
      merge(state, {
        isLoading: true,
        showStandardCalculation: false,
        showArrayCalculation: true,
      }),
    [SHOW_SELECT_VARIABLE_TYPE_MODAL]: (state, action) =>
      merge(state, {
        showStandardCalculation: true,
        showArrayCalculation: false,
      }),
  },
  INITIAL_STATE
);
