import merge from 'xtend' ;
import createReducer from '../create-reducer';

import {
  ADJUSTMENTS_UPDATE,
  CHANGE_ATTRIBUTION,
  CHANGE_ATTRIBUTION_DESCRIPTION,
  CHANGE_DEADWEIGHT_VALUE, CHANGE_DEADWEIGHT_DESCRIPTION, CHANGE_DISPLACEMENT, CHANGE_DISPLACEMENT_DESCRIPTION,
  CHANGE_DROPOFF,
  CHANGE_DROPOFF_DESCRIPTION, CHANGE_DURATION, CHANGE_DURATION_DESCRIPTION, CHANGE_STAKEHOLDER_MEASURE_ID,
  FETCH_ADJUSTMENTS
} from "../../actions/measure/adjustments";
import { HIDE_ADJUSTMENTS } from "../../actions/stakeholder-items/financialProxy";

const INITIAL_STATE = {
  deadWeightDescription: '',
  deadWeight: '',
  dropOffDescription: '',
  dropOff: '',
  displacementDescription: '',
  displacement: '',
  attributionDescription: '',
  attribution: '',
  durationDescription: '',
  duration: '',
  stakeholderMeasureId: null,
  update: false
};


export default createReducer({
  [CHANGE_DEADWEIGHT_DESCRIPTION]: (state, action) => merge(state, {deadWeightDescription: action.description}),
  [CHANGE_DEADWEIGHT_VALUE]: (state, action) => merge(state, {deadWeight: action.deadWeight}),
  [CHANGE_DROPOFF_DESCRIPTION]: (state, action) => merge(state, {dropOffDescription: action.dropOffDescription}),
  [CHANGE_DROPOFF]: (state, action) => merge(state, {dropOff: action.dropOff}),
  [CHANGE_DISPLACEMENT_DESCRIPTION]: (state, action) => merge(state, {displacementDescription: action.displacementDescription}),
  [CHANGE_DISPLACEMENT]: (state, action) => merge(state, {displacement: action.displacement}),
  [CHANGE_ATTRIBUTION_DESCRIPTION]: (state, action) => merge(state, {attributionDescription: action.attributionDescription}),
  [CHANGE_ATTRIBUTION]: (state, action) => merge(state, {attribution: action.attribution}),
  [CHANGE_DURATION_DESCRIPTION]: (state, action) => merge(state, {durationDescription: action.durationDescription}),
  [CHANGE_DURATION]: (state, action) => merge(state, {duration: action.duration}),
  [CHANGE_STAKEHOLDER_MEASURE_ID]: (state, action) => merge(state, {stakeholderMeasureId: action.stakeholderMeasureId}),
  [ADJUSTMENTS_UPDATE]: (state, action) => merge(state, {update: true}),
  [FETCH_ADJUSTMENTS]: (state, action) => merge(state, {
    deadWeightDescription: action.measure.deadweight_description ? action.measure.deadweight_description : '',
    deadWeight: action.measure.deadweight ? action.measure.deadweight: '',
    dropOffDescription: action.measure.dropoff_description ? action.measure.dropoff_description: '',
    dropOff: action.measure.dropoff ? action.measure.dropoff: '',
    displacementDescription: action.measure.displacement_description ? action.measure.displacement_description: '',
    displacement: action.measure.displacement ? action.measure.displacement: '',
    attributionDescription: action.measure.attribution_description ? action.measure.attribution_description: '',
    attribution: action.measure.attribution ? action.measure.attribution: '',
    durationDescription: action.measure.duration_description ? action.measure.duration_description: '',
    duration: action.measure.duration ? action.measure.duration : ''
  }),
  [HIDE_ADJUSTMENTS]: (state, action) => merge(state, {})
}, INITIAL_STATE)