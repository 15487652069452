import React from 'react';

class DataSetSelection extends React.Component {
  static propTypes = {};

  render() {

    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}

export default DataSetSelection;