import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import classNames from "classnames";

const SelectBox = ({
  conditionBlock,
  options,
  label,
  selectedOption,
  onHeaderChange,
  placeholder,
  selectClass,
  SelectBoxClassNames,
  labelClassName,
  children,
  multiSelect,
  disabled
}) => {
  const selectRowClassNames = classNames({
    "row": !conditionBlock,
    "condition-flex": conditionBlock
  });

  const selectedOptionObj = options.find(option => option.value === selectedOption);

  return (
    <div className={selectRowClassNames}>
      <div className={SelectBoxClassNames}>
        {multiSelect ? (
          <Select
            options={options}
            value={multiSelect ? selectedOption : selectedOptionObj}
            onChange={onHeaderChange}
            placeholder={placeholder}
            className={selectClass}
            isMulti
            disabled={disabled}
          />
        ) : (
          <Select
            options={options}
            value={selectedOptionObj}
            onChange={onHeaderChange}
            clearable={false}
            placeholder={placeholder}
            className={selectClass}
            disabled={disabled}
          />
        )}
      </div>
      {children}
    </div>
  );
};

export const rowPropTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  selectedOption: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array
  ]),
  onHeaderChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  selectClass: PropTypes.string,
  SelectBoxClassNames: PropTypes.string,
  labelClassName: PropTypes.string,
  multiSelect: PropTypes.bool,
  disabled: PropTypes.bool
};

SelectBox.propTypes = rowPropTypes;

export default SelectBox;
