import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CalculationsSnapshotModalWindow from "../components/CalculationsSnapshotModalWindow";
import CalculationsActions from "../actions/calculation/calculation-page";

function mapStateToProps(state, ownProps) {
  return {
    ...state.calculations,
    snapshots: ownProps.calculations.snapshots,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...CalculationsActions,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculationsSnapshotModalWindow);
