import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import EditIndexPage from "../components/measure/EditPageIndex";
import measureActions from '../actions/measure/measure';
import manageMeasureActions from '../actions/measure/manage-measure';
import logicModalActions from '../actions/logic-models';
import dataSourceActions from '../actions/calculation/data-source';


function mapStateToProps(state, ownProps) {
  return {
    ...state.measurePage,
    ...state.manageMeasure,
    ...state.dataSource,
    ...state.inputs,
    ...state.outcomes,
    ...state.activities,
    ...state.logicModels,
    showDemographic: state.demographics.showDemographic,
    showAdjustments: state.financialProxy.showAdjustments,
    id: ownProps.params.id,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...measureActions,
      ...manageMeasureActions,
      ...dataSourceActions,
      ...logicModalActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditIndexPage)