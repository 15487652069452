import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ModalChangeMeasureName from "../components/common/ModalChangeMeasureName";
import StakeholderActions from "../actions/stakeholder-items/inputs";
import ManageMeasureActions from "../actions/measure/manage-measure";
import MeasureActions from "../actions/measure/measure";

function mapStateToProps(state, ownProps) {
  const { measureId } = state.measurePage;
  const stakeholder = state.measurePage.logicModel.stakeholders.find((x) =>
    x.measures.find((y) => y.id === measureId)
  );
  let stakeholderId = stakeholder ? stakeholder.id : null
  return {
    ...ownProps,
    ...state.measurePage,
    stakeholderId: stakeholderId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...StakeholderActions,
        ...ManageMeasureActions,
        ...MeasureActions,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalChangeMeasureName);
