import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import { InjectIntl } from "../../../common/InjectIntl";
import DemographicItem from "../../../../containers/logic-models/stakeholders-items/add-demographic/DemographicItem.container";
import PermissionChecker from "../../../common/PermissionChecker";
import { whiteLabel } from '../../../utils';

class Demographics extends React.Component {
  static propTypes = {
    showDemographic: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      showDemographic: PropTypes.func,
      hideDemographic: PropTypes.func,
      addDemographicMeasure: PropTypes.func,
    }.isRequired)
  };

  state = {
    demographics: []
  };

  handleCloseModal = () => {
    this.props.actions.hideDemographic();
  };

  handleClickAddDemographic = (e) => {
    e.preventDefault();
    this.props.actions.addDemographicMeasure();
  };

  getDemographics(stakeholders) {
    let demographics = [];
    stakeholders.map((stakeholder) => {
      if (stakeholder.id == this.props.stakeholderId) {
        stakeholder.measures.map((measure) => {
          if (measure.measure_type_id === 1) {
            demographics.unshift(measure)
          }
        })
      }
    });
    this.setState({ demographics: demographics })
  }

  componentDidMount() {
    this.getDemographics(this.props.logicModel.stakeholders)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.logicModel.stakeholders !== nextProps.logicModel.stakeholders) {
      this.getDemographics(nextProps.logicModel.stakeholders)
    }
  }

  renderBody = () => {
    return (
      <div>
        {this.state.demographics.map((demographic, index) =>
          <DemographicItem demographic={demographic} key={index}/>
        )}
        <PermissionChecker hide={true}>
          <button className="btn btn-success btn-outline btn-block" onClick={this.handleClickAddDemographic}>
            <i className="fa fa-id-card"/>
            &nbsp;
            {this.props.intl('stakeholder.items.add_demographics')}
          </button>
        </PermissionChecker>
      </div>
    )
  };

  render() {
    const { showDemographic } = this.props;
    return (
      <Modal
        dialogClassName={"modal-lg"}
        show={showDemographic}
        id={'foreground_display'}
        data-brand={whiteLabel()}
      >
        <Modal.Header>
          <Modal.Title id='ModalHeader'>
            <span>
              <i className="fa fa-id-card disable-hover"/>
              &nbsp;
              {this.props.intl('stakeholder.items.demographics')}
            </span>
            <i className="fa fa-close pull-right" onClick={this.handleCloseModal}/>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={"modal-body"}>
          {this.renderBody()}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-success" onClick={this.props.actions.hideDemographic}>
            {this.props.intl('common.text.close_button')}</button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default InjectIntl(Demographics);