import { post } from '../api';
import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { push } from 'react-router-redux';
import {
  REQUEST, REQUEST_SUCCESS, REQUEST_FAILURE, LOGOUT, ALREADY_LOGGED_IN,
  UNAUTHORIZED_REQUEST
} from '../actions/login';
import { REGISTER_REQUEST, REGISTER_REQUEST_FAILURE, REGISTER_REQUEST_SUCCESS } from "../actions/register";
import {UPDATE as LANGUAGE_CHANGE} from 'react-intl-redux';
import { changeLocale } from "../actions/application";

function loginEpic(action$, store) {
  return action$.ofType(REQUEST)
    .mergeMap((action) => {
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');
      const redirectPath = localStorage.getItem('redirectPath');
      const invitationToken = localStorage.getItem('invitationToken');
      const lang = localStorage.getItem('locale');
      return Observable.fromPromise(post('/api/verifyToken', { token: accessToken }))
        .flatMap(({ user, atlas_base_url }) => {
          const id = user.id;
          const first_name = user.first_name;
          const last_name = user.last_name;
          const email = user.email;
          localStorage.setItem('token', accessToken);
          localStorage.setItem('refreshToken', refreshToken);
          localStorage.setItem('user_id', id);
          localStorage.setItem('first_name', first_name);
          localStorage.setItem('last_name', last_name);
          localStorage.setItem('email', email);
          const name = `${first_name} ${last_name}`;
          const changedLocale = changeLocale(lang);
          const payload = changedLocale.payload;
          let redirectTo = '/logic-models';
          if(redirectPath) {
            redirectTo = `/${redirectPath}`;
          }
          return Observable.concat(
            Observable.of({ type: LANGUAGE_CHANGE, payload }),
            Observable.of({ type: REQUEST_SUCCESS, payload: { name, id, email, atlas_base_url } }),
            Observable.of(push(redirectTo)) 
          );
        })
        .catch(errors => {
          return Observable.concat(
            errors.global ?
              Observable.of({ type: UNAUTHORIZED_REQUEST })
            :
              Observable.of({ type: REQUEST_FAILURE, errors })
          )
        });
    })
}

function redirectToMainPageEpic(action$, store) {
  return action$.ofType(ALREADY_LOGGED_IN)
    .mergeMap((action) => {
      return Observable.of(push('/logic-models'))
    })
}

function logoutEpic(action$) {
  return action$.ofType(LOGOUT)
    .mergeMap((action) => {
      const atlasBaseUrl = localStorage.getItem('atlas_base_url');
      localStorage.clear();
      const redirectUrl = `${atlasBaseUrl}/logout`;
      window.location.assign(redirectUrl)
    })
}

function registerEpic(action$, store) {
  return action$.ofType(REGISTER_REQUEST)
    .mergeMap((action) => {
      const { registerName, email, password, passwordConfirmation } = store.getState().registerPage.toJS();
      return Observable.fromPromise(post('/api/register', {
        name: registerName,
        email,
        password,
        password_confirmation: passwordConfirmation
      }))
        .flatMap(({ api_token, user_id, name, email }) => {
          localStorage.setItem('token', api_token);
          localStorage.setItem('user_id', user_id);
          localStorage.setItem('name', name);
          localStorage.setItem('email', email);
          return Observable.concat(
            Observable.of({ type: REGISTER_REQUEST_SUCCESS }),
            Observable.of(push('/'))
          )
        })
        .catch((errors) => Observable.of({ type: REGISTER_REQUEST_FAILURE, errors }))
    })
}

function redirectLoginPageEpic(action$, store) {
  return action$.ofType(UNAUTHORIZED_REQUEST)
    .mergeMap(action => {
      const atlasBaseUrl = localStorage.getItem('atlas_base_url');
      const params = new URLSearchParams(
        {
          locale: localStorage.getItem('locale'),
          redirectTo: 'rucksack'
        });
      const redirect = window.location.pathname+window.location.search;
      const email = localStorage.getItem('email');
      if (email) params.append('email', email);
      if (redirect && redirect !== '/') params.append('redirectPath', redirect.slice(1));   // Other places in the code send without / prefix, login method epic adds it as prefix
      window.location.assign(`${atlasBaseUrl}/login?${params.toString()}`);
      return Observable.empty()
    })
}

export default [loginEpic, logoutEpic, registerEpic, redirectToMainPageEpic, redirectLoginPageEpic]