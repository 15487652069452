export const REGISTER_REQUEST = '@@REGISTER_PAGE/REGISTER_REQUEST';
export const REGISTER_REQUEST_SUCCESS = '@@REGISTER_PAGE/REGISTER_REQUEST_SUCCESS';
export const REGISTER_REQUEST_FAILURE = '@@REGISTER_PAGE/REGISTER_REQUEST_FAILURE';

export const CHANGE_NAME = '@@REGISTER_PAGE/CHANGE_NAME';
export const CHANGE_PASSWORD = '@@REGISTER_PAGE/CHANGE_PASSWORD';
export const CHANGE_EMAIL = '@@REGISTER_PAGE/CHANGE_EMAIL';
export const CHANGE_PASSWORD_CONFIRMATION = '@@REGISTER_PAGE/CHANGE_PASSWORD_CONFIRMATION';

function changeRegisterName(name) {
	return {type: CHANGE_NAME, name}
}

function changeRegisterEmail(email) {
	return {type: CHANGE_EMAIL, email}
}

function changeRegisterPassword(password) {
	return {type: CHANGE_PASSWORD, password}
}

function changeRegisterPasswordConfirmation(password) {
	return {type: CHANGE_PASSWORD_CONFIRMATION, password}
}

function createAccount() {
	return {type: REGISTER_REQUEST}
}

export default {
	changeRegisterName,
	changeRegisterEmail,
	changeRegisterPassword,
	changeRegisterPasswordConfirmation,
	createAccount
}