export const ONTOLOGY_CHANGE_QUERY = 'ONTOLOGY/ONTOLOGY_CHANGE_QUERY';

export const ONTOLOGY_SEARCH = '@@ONTOLOGY/ONTOLOGY_SEARCH';
export const ONTOLOGY_SEARCH_SUCCESS = '@@ONTOLOGY/ONTOLOGY_SEARCH_SUCCESS';
export const ONTOLOGY_SEARCH_FAILURE = '@@ONTOLOGY/ONTOLOGY_SEARCH_FAILURE';
export const ONTOLOGY_SET_INITIAL_STATE = '@@ONTOLOGY/ONTOLOGY_SET_INITIAL_STATE';


function changeOntologyQuery(query) {
  return { type: ONTOLOGY_CHANGE_QUERY, query }
}

function setOntologyInitialState() {
  return { type: ONTOLOGY_SET_INITIAL_STATE }
}

function searchForOntology() {
  return { type: ONTOLOGY_SEARCH }
}

export default {
  changeOntologyQuery,
  searchForOntology,
  setOntologyInitialState,
}