import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CalculationsActions from "../../../../actions/calculation/calculation-page";
import SelectVariableTypeActions from "../../../../actions/calculation/selectVariableType";
import SelectPreexistingCalculation from "../../../../components/calculations/sources/modals/SelectPreexistingCalculation";

function mapStateToProps(state, ownProps) {
  return {
    ...state.selectVariableTypeModals,
    ...state.calculations,
    intl: state.intl,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...CalculationsActions,
      ...SelectVariableTypeActions
    }, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SelectPreexistingCalculation)