import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import { InjectIntl } from "../../../common/InjectIntl";
import { ModalNavLink } from "../../../common/NavLinks";
import { has } from 'lodash';
import { EDIT } from "../../../members/constants";
import { whiteLabel } from '../../../utils';


class OneSourceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      variableName: 'Unnamed Variable'
    }
  }

  static propTypes = {
    activeVariable: PropTypes.object.isRequired,
    oneSourceModalVisible: PropTypes.bool.isRequired,
    finalSourceColumnVisible: PropTypes.bool.isRequired,
    firstColumnVisible: PropTypes.bool.isRequired,
    finalData: PropTypes.object.isRequired,
    currentDataSource: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      hideOneSourceModal: PropTypes.func,
      changeOneSourceNavButtons: PropTypes.func,
      saveSource: PropTypes.func,
      changeDataSetName: PropTypes.func,
      showGoodJobAlert: PropTypes.func,
      setDataSourceShouldUpdate: PropTypes.func
    }.isRequired),
    arrayCalculationReady: PropTypes.bool.isRequired
  };

  clickNavHandler = (name) => {
    if (!!this.props.activeVariable && (!!this.props.currentDataSource.transformation) || this.props.arrayCalculationReady)
      this.props.actions.changeOneSourceNavButtons(name);
  };

  componentDidMount() {
    if (!!this.props.activeVariable && this.props.activeVariable.name)
      this.setState({ variableName: this.props.activeVariable.name });
    this.props.actions.changeOneSourceNavButtons('first');
  }

  renderBody = () => {

    const isContentEnabled = () => {
      if(!!this.props.activeVariable) {
        const { activeVariable } = this.props;
        return (!!this.props.currentDataSource.transformation 
          && Object.keys(this.props.currentDataSource.transformation).length > 0);
      }
  
      return false;
    }

    const isSourceDone = isContentEnabled() || this.props.arrayCalculationReady;
    return (
      <div>
        <div>
          <div className="row">
            <div className="col-md-6 text-left">
              <ul className="nav nav-tabs" style={{ borderBottom: "none" }}>
                <ModalNavLink to={this.props.firstColumnVisible} className="btn btn-default"
                         activeClassName="btn-warning" textClassName={'white'}
                         actionClick={this.clickNavHandler.bind(null, 'first')}>Select column</ModalNavLink>
                <ModalNavLink to={this.props.finalSourceColumnVisible} className={`btn btn-default ${isSourceDone ? '' : 'content-disabled'}`}
                         activeClassName="btn-warning" textClassName={'white'}
                         actionClick={this.clickNavHandler.bind(null, 'final')}>Final Source Column</ModalNavLink>
              </ul>
            </div>
          </div>
        </div>
          { this.props.children }
      </div>
    )
  };

  renderFooter = () => {
    const { oneSourceModalVisible, activeVariable, currentDataSource, arrayCalculationReady } = this.props;
    const isSourceDone = !!activeVariable && 
    ((!!currentDataSource.transformation && Object.keys(currentDataSource.transformation).length > 0) || arrayCalculationReady);
    const isReadOnlyPermission = localStorage.getItem("permission") !== EDIT;

    const renderFirstColumnButton = () => {
      if(!!activeVariable.use_array_calculation) {
        if(arrayCalculationReady) {
          return <button type="submit" className={`btn btn-success ${isSourceDone && isReadOnlyPermission ? 'disabled' : ''}`} style={{ display: isReadOnlyPermission ? 'none': '' }} onClick={this.saveHandler}>{this.props.intl('common.text.save_button')}</button>;
        }
      }
      return <button type="submit" className={`btn btn-success ${isSourceDone ? '' : 'disabled'}`} onClick={this.changePageHandler}>{this.props.intl('source.modal.first_button')}</button>;
    }

    return (
      <div className="row m-r">
        <button type="button" className="btn btn-default" onClick={this.handleCloseModal}>{this.props.intl('common.text.close_button')}</button>
        {this.props.firstColumnVisible 
          ? renderFirstColumnButton()
          : (
            <div className="m-l" style={{ display: 'inline' }}>
              <button type="submit" className={`btn btn-success ${isSourceDone ? '' : 'disabled'}`}
                      onClick={this.previousPageHandler}> {this.props.intl('common.text.previous_button')}</button>
              <div className="m-l" style={{ display: 'inline' }}>
                <button type="submit" className={`btn btn-success ${isSourceDone && isReadOnlyPermission ? 'disabled' : ''}`}
                        style={{ display: isReadOnlyPermission ? 'none': '' }}
                        onClick={this.saveHandler}>{this.props.intl('common.text.save_button')}</button>
              </div>
            </div>
          )
        }
      </div>
    );
  }

  handleCloseModal = () => {
    const { actions: { hideOneSourceModal, changeOneSourceNavButtons, markHeaderAsReChanged }, isHeaderReChanged } = this.props;
    hideOneSourceModal();
    changeOneSourceNavButtons('first');
    if (isHeaderReChanged)
      markHeaderAsReChanged(false);
  };

  saveHandler = () => {
    const { actions: {changeDataSetName, editedVariableSave, saveSource, showGoodJobAlert, saveArrayCalculationFinal }, isHeaderReChanged, activeVariable, arrayCalculationReady } = this.props;
    // check for array calculation
    // since we want to exist on the first modal itself
    if(!!activeVariable.use_array_calculation) {
      if(arrayCalculationReady) {
        saveArrayCalculationFinal()
      }
    }
    changeDataSetName("Data source one");
    if (isHeaderReChanged)
      editedVariableSave();
    else {
      saveSource();
      showGoodJobAlert();
    }
  };

  changePageHandler = () => {
    if (!!this.props.activeVariable && 
      ((!!this.props.currentDataSource.transformation 
        && Object.keys(this.props.currentDataSource.transformation).length > 0) 
          || this.props.arrayCalculationReady
    )) {
      this.clickNavHandler('final');
    } else {
      console.log('not ready', this.props.arrayCalculationReady);
    }
      
  };

  previousPageHandler = (e) => {
    e.preventDefault();
    this.props.actions.setDataSourceShouldUpdate(false);
    this.clickNavHandler('first')
  };

  render() {
    const { oneSourceModalVisible, activeVariable } = this.props;
    const variableName = !!activeVariable && this.props.activeVariable.name ? activeVariable.name : this.state.variableName;

    return (
      <Modal
        dialogClassName={"modal-lg"}
        show={oneSourceModalVisible}
        id={'foreground_display'}
        data-brand={whiteLabel()}
      >
        <Modal.Header className="well">
          <Modal.Title id='ModalHeader'>
            Variable - {variableName}
            <i className="fa fa-close pull-right" onClick={this.handleCloseModal}/>
          </Modal.Title>

        </Modal.Header>
        <Modal.Body>
          {this.renderBody()}
        </Modal.Body>
        <Modal.Footer className="well" style={{ marginBottom: "0px" }}>
          {this.renderFooter()}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default InjectIntl(OneSourceModal);