import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import LogicModelsActions from '../../actions/logic-models';
import LogicModels from "../../components/logic-models/LogicModels";
import modalDeleteItemActions from "../../actions/modal-delete-item";


function mapStateToProps(state, ownProps) {
  return {
    ...state.logicModels,
    ...state.intl,
    handelOpenCreateLogicModelsForm: ownProps.handelOpenCreateLogicModelsForm,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...LogicModelsActions,
      ...modalDeleteItemActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogicModels)