import React from 'react';
import PropTypes from 'prop-types';
import { InjectIntl } from "./common/InjectIntl";

class Invitation extends React.Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    invitationId: PropTypes.string.isRequired,
    invitationEmail: PropTypes.string.isRequired,
    isAuth: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      alreadyLoggedIn: PropTypes.func,
      addToLogicModel: PropTypes.func,
      serviceRedirect: PropTypes.func,
    }.isRequired)
  };

  componentDidMount() {
    const { accessToken, isAuth } = this.props;
    if (isAuth) {
      this.props.actions.addToLogicModel(accessToken);
    }
  }

  atlasRedirect = (page) => {
    const { accessToken, invitationId, invitationEmail } = this.props;
    localStorage.setItem('invitationToken', accessToken);
    localStorage.setItem('invitationId', invitationId);
    this.props.actions.serviceRedirect(invitationId, accessToken, page, invitationEmail);
  };

  render() {

    return (
      <div className="row">
        <div className="col-md-4 col-md-offset-4">
          <div className="panel panel-body text-center">
            <h2>Please
              <a href="#!" onClick={this.atlasRedirect.bind(null, 'login')}> login </a>
              or
              <a href="#!" onClick={this.atlasRedirect.bind(null, 'register')}> register </a>
               to accept invitation.</h2>
          </div>
        </div>
      </div>
    );
  }
}

export default InjectIntl(Invitation);