export const REQUEST = "@@INVITE_COLLABORATOR/REQUEST";
export const REQUEST_SUCCESS = "@@INVITE_COLLABORATOR/REQUEST_SUCCESS";
export const REQUEST_FAILURE = "@@INVITE_COLLABORATOR/REQUEST_FAILURE";
export const CHANGE_SUBJECT = "@@INVITE_COLLABORATOR/CHANGE_SUBJECT";
export const CLOSE_EMAIL_MODAL = "@@INVITE_COLLABORATORS/CLOSE_EMAIL_MODAL";
export const CHANGE_RECIPIENTS = "@@INVITE_COLLABORATORS/CHANGE_RECIPIENTS";
export const CHANGE_MESSAGE = "@@INVITE_COLLABORATORS/CHANGE_MESSAGE";
export const CHANGE_DEFAULT_MESSAGES = "@@INVITE_COLLABORATORS/CHANGE_DEFAULT_MESSAGES";
export const SET_ERROR = "@@INVITE_COLLABORATORS/SET_ERROR";

function close() {
  return { type: CLOSE_EMAIL_MODAL };
}

function changeRecipients(recipients) {
  return { type: CHANGE_RECIPIENTS, recipients };
}
function changeMessage(msg, isCustomMessage) {
  return { type: CHANGE_MESSAGE, msg, isCustomMessage };
}
function changeDefaultMessages(msgs) {
  return { type: CHANGE_DEFAULT_MESSAGES, msgs };
}

function invite() {
  return { type: REQUEST };
}

function changeSubject(subject) {
  return { type: CHANGE_SUBJECT, subject };
}

function setError(error) {
  return { type: SET_ERROR, error };
}

export default {
  invite,
  changeSubject,
  close,
  changeRecipients,
  changeMessage,
  changeDefaultMessages,
  setError
};
