import React from "react";
import PropTypes from "prop-types";
import {
  capitalize,
  parseValueForVariable
} from "../../../../assets/sam-utils";

const TransformationLabelAndValue = ({
  transformation,
  newTransformationValue,
  conditions
}) => {
  return (
    <div>
      {!!transformation.label && capitalize(transformation.label.toLowerCase())}
      &nbsp;
      {(newTransformationValue || newTransformationValue === 0) &&
      conditions.length > 0 ? (
        <span className="label label-default">
          {parseValueForVariable({
            ...transformation,
            optionValue: newTransformationValue
          })}
        </span>
      ) : (
        <span className="label label-default">
          {!!transformation.label && parseValueForVariable(transformation)}
        </span>
      )}
    </div>
  );
};

TransformationLabelAndValue.propTypes = {
  transformation: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  newTransformationValue: PropTypes.number,
  conditions: PropTypes.array.isRequired
};

export default TransformationLabelAndValue;
