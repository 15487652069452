import React, {Fragment} from 'react';
import { InjectIntl } from "../../common/InjectIntl";
import SourceTypes from "../../../containers/calculations/sources/SourceTypes.container";
import PropTypes from 'prop-types';

const SourceTypesIndex = ({ currentDataSource, children }) => {

  return (
    <Fragment>
      <div className="col-md-3">
        <SourceTypes/>
      </div>
      { !!currentDataSource.sourceName && <div className="col-md-offset-1 col-md-8">
        {children}
        </div>
      }
    </Fragment>
  );
};

SourceTypesIndex.propTypes = {
  currentDataSource: PropTypes.object.isRequired,
};

export default InjectIntl(SourceTypesIndex);