import React from "react";
import PropTypes from "prop-types";
import StakeholderItems from "../../containers/logic-models/logic-model-edit/StakeholderItems.container";
import { InjectIntl } from "../common/InjectIntl";
import Input from "../common/Input";
import OutsideClickDetection from "../common/OutsideClickDetection";
import ModalDeleteItem from "../../containers/ModalDeleteItem.container";
import Tooltip from "../common/Tooltip/Tooltip";
import TooltipElement from "../common/Tooltip/TooltipElement";
import PermissionChecker from "../common/PermissionChecker";
import ModalCopyPaste from "../../containers/ModalCopyPaste.container"

class Stakeholder extends React.Component {
  static propTypes = {
    stakeholder: PropTypes.object.isRequired,
    changeNameInputShow: PropTypes.bool.isRequired,
    recommendedStakeholders: PropTypes.array.isRequired,
    query: PropTypes.string.isRequired,
    ontologySearching: PropTypes.bool.isRequired,
    ontologyResult: PropTypes.array.isRequired,
    actions: PropTypes.shape(
      {
        changeStakeholderNameInputFieldShow: PropTypes.func,
        changeStakeholderNameInputFieldHide: PropTypes.func,
        fetchLogicModel: PropTypes.func,
        logicModelLoading: PropTypes.func,
        saveStakeholderName: PropTypes.func,
        showDemographic: PropTypes.func,
        changeOntologyQuery: PropTypes.func,
        searchForOntology: PropTypes.func,
        setOntologyInitialState: PropTypes.func,
        showDeleteModalItem: PropTypes.func,
        changeStakeholderId: PropTypes.func,
        deleteStakeholder: PropTypes.func,
        setDeleteParams: PropTypes.func.isRequired,
        showDefinitions: PropTypes.func.isRequired
      }.isRequired
    )
  };

  state = {
    showStakeholderLine: true,
    displayStakeholderLine: "",
    stakeholderName: "",
    newStakeholderName: "",
    stakeholderId: null
  };

  handleDeleteStakeholder = () => {
    const { stakeholder, actions, intl } = this.props;

    actions.setDeleteParams(
      intl("sweetAlert.delete_stakeholder.hint_text"),
      actions.deleteStakeholder,
      stakeholder.id
    );
    actions.showDeleteModalItem();
    actions.changeStakeholderId(stakeholder.id);
  };

  openOrHideDetail = e => {
    e.preventDefault();
    if (this.state.showStakeholderLine) {
      this.setState({
        showStakeholderLine: false,
        displayStakeholderLine: "none"
      });
    } else {
      this.setState({ showStakeholderLine: true, displayStakeholderLine: "" });
    }
  };

  showInputChangeName = () => {
    this.props.actions.changeStakeholderNameInputFieldShow();
    this.setState({
      stakeholderName: this.props.stakeholder.name,
      stakeholderId: this.props.stakeholder.id
    });
  };

  changeName = name => {
    this.setState({ stakeholderName: name, newStakeholderName: name });
    this.props.actions.changeOntologyQuery(name);
    window.samUtils.ontologyRequest(this.props.actions.searchForOntology);
  };

  saveName = stakeholderName => {
    this.props.actions.changeStakeholderName(
      stakeholderName,
      this.props.stakeholder.id
    );
    this.props.actions.logicModelLoading();
    this.props.actions.saveStakeholderName();
    this.props.actions.changeStakeholderNameInputFieldHide();
    this.setState({ stakeholderId: null });
  };

  moveCaretAtTheEnd = e => {
    let temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
  };

  clickOutsideInputField = () => {
    this.props.actions.changeStakeholderNameInputFieldHide();
    this.props.actions.setOntologyInitialState();
    this.setState({ stakeholderId: null });
  };

  renderStakeholderName = () => {
    const { stakeholderId, stakeholderName } = this.state;
    const {
      changeNameInputShow,
      stakeholder,
      ontologySearching,
      query
    } = this.props;

    return (
      <div className="col-md-6">
        {changeNameInputShow && stakeholderId === stakeholder.id ? (
          <OutsideClickDetection hideAction={this.clickOutsideInputField}>
            <div className="edit-stakeholder-name-form-wrap">
              <div className="input-group m-b">
                <span className="input-group-addon">
                  <i className="fa fa-user" />
                </span>
                <Input
                  autoFocus={true}
                  onFocus={this.moveCaretAtTheEnd}
                  changeName={this.changeName}
                  name={stakeholderName === this.props.intl("stakeholder.items.unnamed_stakeholder") ? "" : stakeholderName}
                  placeholder={"Enter stakeholder"}
                  save={this.saveName}
                />
                <span className="input-group-btn">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.saveName.bind(null, query)}
                  >
                    <i className="fa fa-check" />
                  </button>
                </span>
              </div>
              <div className="search-results">
                {ontologySearching && (
                  <div className="sk-spinner sk-spinner-rotating-plane" />
                )}

                {this.props.ontologyResult.map((name, index) => (
                  <div
                    className="action-name"
                    style={{ cursor: "pointer" }}
                    onClick={this.saveName.bind(null, name)}
                    key={index}
                  >
                    {name}
                  </div>
                ))}
              </div>
            </div>
          </OutsideClickDetection>
        ) : (
          <div className="stakeholder_name_wrap">
            <i className="fa fa-user disable-hover" /> &nbsp;
            <PermissionChecker>
              <h3 className="editable_name" onClick={this.showInputChangeName}>
                {stakeholder.name}
              </h3>
            </PermissionChecker>
          </div>
        )}
      </div>
    );
  };

  handleClickDemographic = () => {
    this.props.actions.showDemographic();
    this.props.actions.changeStakeholderId(this.props.stakeholder.id);
  };

  handleClickDefinitions = () => {
    this.props.actions.showDefinitions();
  };

  renderDemographicsTooltipElement = () => {
    let {
      stakeholder: { demographics },
      intl
    } = this.props;

    demographics =
      demographics.length > 15 ? demographics.slice(0, 16) : demographics;

    return demographics.reverse().map((demographic, index) =>
      index < 15 ? (
        <strong key={index} className="blue-color d-block crop-text">
          {demographic.name}
        </strong>
      ) : (
        <span key={index} className="text-muted d-block crop-text">
          {intl("common.text.more")}
        </span>
      )
    );
  };

  renderStakeholderIconsMenu = () => (
    <div className="col-md-6">
      <div className="ibox-tools" style={{ display: "block" }}>
        <Tooltip
          tooltipElement={
            <TooltipElement title={this.props.intl("definitions.title")}>
              <strong className="blue-color">
                {this.props.intl("definitions.tooltip_body")}
              </strong>
            </TooltipElement>
          }
          wrapperClassname="d-inline"
        >
          <a href="#!" onClick={this.handleClickDefinitions}>
            <i className="fa fa-question-circle" />
          </a>
        </Tooltip>
        <Tooltip
          tooltipElement={
            <TooltipElement
              title={this.props.intl("stakeholder.items.demographics")}
            >
              {this.renderDemographicsTooltipElement()}
            </TooltipElement>
          }
          wrapperClassname="d-inline"
        >
          <a href="#!">
            <i
              className="fa fa-id-card"
              onClick={this.handleClickDemographic}
            />
          </a>
        </Tooltip>
        <a href="#!" className="collapse-link">
          <i
            className={
              this.state.showStakeholderLine
                ? "fa fa-chevron-up"
                : "fa fa-chevron-down"
            }
            aria-hidden="true"
            onClick={this.openOrHideDetail}
          />
        </a>
        <PermissionChecker hide={true}>
          <a
            href="#!"
            className="close-link"
            onClick={this.handleDeleteStakeholder}
          >
            <i className="fa fa-times" />
          </a>
        </PermissionChecker>
      </div>
    </div>
  );

  render() {
    return (
      <div>
        <div className="ibox-title">
          <div className="row">
            {this.renderStakeholderName()}
            {this.renderStakeholderIconsMenu()}
            <ModalDeleteItem />
            <ModalCopyPaste />
          </div>
        </div>

        <StakeholderItems
          stakeholder={this.props.stakeholder}
          displayStakeholderLine={this.state.displayStakeholderLine}
        />
      </div>
    );
  }
}

export default InjectIntl(Stakeholder);
