import createReducer from './create-reducer';
import merge from 'xtend';

import {
  DELETE_LOGIC_MODEL_FAILURE,
  DELETE_LOGIC_MODEL_SUCCESS,
  FETCH_LOGIC_MODELS,
  FETCH_LOGIC_MODELS_SUCCESS,
  SHOW_CHANGE_NAME_INPUT,
  HIDE_CHANGE_NAME_INPUT,
  CHANGE_LOGIC_MODEL_NAME,
  CREATE_LOGIC_MODEL_DIALOG_PAGE_SHOW,
  CREATE_LOGIC_MODEL_DIALOG_PAGE_HIDE,
  CHANGE_LOGIC_MODEL_NAME_FORM_CREATE,
  ADD_LOGIC_MODEL_FAILURE,
  ADD_LOGIC_MODEL,
  ADD_LOGIC_MODEL_SUCCESS,
  CREATE_LOGIC_MODEL_OPTIONS_SAVE,
  EXPORT_JSON,
  EXPORT_JSON_SUCCESS,
  EXPORT_JSON_SUCCESS_FAILURE,
  FETCH_FRAMEWORKS,
  FETCH_FRAMEWORKS_SUCCESS,
  FETCH_FRAMEWORKS_FAILURE,
  FETCH_THEMES,
  FETCH_THEMES_SUCCESS,
  FETCH_THEMES_FAILURE,
  CREATE_FRAMEWORK,
  CREATE_FRAMEWORK_SUCCESS,
  CREATE_FRAMEWORK_FAILURE,
  CREATE_THEME,
  CREATE_THEME_SUCCESS,
  CREATE_THEME_FAILURE,
  SHOW_LOOKUP_MODAL,
  HIDE_LOOKUP_MODAL,
  FETCH_WORKSPACE_RESOURCES,
  FETCH_WORKSPACE_RESOURCES_SUCCESS,
  FETCH_WORKSPACE_RESOURCES_FAILURE,
  FETCH_DATA_RESOURCES,
  FETCH_SOURCE_DATA_RESOURCES_SUCCESS,
  FETCH_TARGET_DATA_RESOURCES_SUCCESS,
  FETCH_DATA_RESOURCES_FAILURE,
  SUBMIT_LOOKUP_QUESTION,
  SUBMIT_LOOKUP_QUESTION_SUCCESS,
  SUBMIT_LOOKUP_QUESTION_FAILURE
} from "../actions/logic-models";

const INITIAL_ERRORS = {
	name: []
};

const INITIAL_STATE = {
  logicModels: [],
  logicModelId: 0,
  isLogicModelLoading: false,
  confirmDelete: false,
  errors: {},
  categories: [],
  countries: [],
  newLogicModelName: '',
  showInputField: false,
	creationFormOpen: false,
	name: '',
	categoryId: null,
	sroi: false,
	createLogicModelErrors: INITIAL_ERRORS,
	isProcessing: false,
  logicModelOptions: {
    country: '',
    province: '',
    cities: [],
    city: ''
  },
  frameworks: [],
  themes: [],
  isLookupModalOpen: false,
  workspaces: [],
  sourceFormData: null,
  targetFormData: null
};


export default createReducer({
  [FETCH_LOGIC_MODELS]: (state, action) => merge(state, {
    isLogicModelLoading: true,
    errors: {}
  }),
  [FETCH_LOGIC_MODELS_SUCCESS]: (state, action) => merge(state, {
    isLogicModelLoading: false,
    logicModels: action.payload.logic_models,
    categories: action.payload.categories,
    countries: action.payload.countries,
	  creationFormOpen: action.payload.logic_models.length === 0
  }),
  [DELETE_LOGIC_MODEL_SUCCESS]: (state, action) => merge(state, {confirmDelete: false}),
  [DELETE_LOGIC_MODEL_FAILURE]: (state, action) => merge(state, {errors: action.errors}),
  [SHOW_CHANGE_NAME_INPUT]: (state, action) => merge(state, {
    showInputField: true,
    logicModelId: action.logicModelId
  }),
  [HIDE_CHANGE_NAME_INPUT]: (state, action) => merge(state, {showInputField: false}),
  [CHANGE_LOGIC_MODEL_NAME]: (state, action) => merge(state, {
    newLogicModelName: action.logicModelName,
    logicModelId: action.logicModelId
  }),
	[CREATE_LOGIC_MODEL_DIALOG_PAGE_SHOW]: (state, action) => merge(state, {creationFormOpen: true}),
	[CREATE_LOGIC_MODEL_DIALOG_PAGE_HIDE]: (state, action) => merge(state, {creationFormOpen: false}),
	[CHANGE_LOGIC_MODEL_NAME_FORM_CREATE]: (state, action) => merge(state, {
		name: action.name,
		createLogicModelErrors: INITIAL_ERRORS
	}),
	[ADD_LOGIC_MODEL]: (state, action) => merge(state, {
		isProcessing: true,
		createLogicModelErrors: INITIAL_ERRORS
	}),
	[ADD_LOGIC_MODEL_FAILURE]: (state, action) => merge(state, {
		isProcessing: false,
		errors: action.errors
	}),
	[ADD_LOGIC_MODEL_SUCCESS]: (state, action) => merge(state, {
		isProcessing: false,
		createLogicModelErrors: INITIAL_ERRORS,
		creationFormOpen: false,
		name: '',
		categoryId: '1',
		sroi: false,
		logicModelId: action.id
	}),
  [CREATE_LOGIC_MODEL_OPTIONS_SAVE]: (state, action) => merge(state, {
    logicModelOptions: action.options
  }),
  [EXPORT_JSON]: (state, action) => merge(state, {
    // isProcessing: true,
  }),
  [EXPORT_JSON_SUCCESS]: (state, action) => merge(state, {
    // isProcessing: false
  }),
  [EXPORT_JSON_SUCCESS_FAILURE]: (state, action) => merge(state, {
    // isProcessing: false,
    errors: action.errors
  }),
  [FETCH_FRAMEWORKS]: (state) => merge(state, {
    isProcessing: true,
  }),
  [FETCH_FRAMEWORKS_SUCCESS]: (state, action) => {
    return merge(state, {
      isProcessing: false,
      frameworks: action.payload.frameworks,
    });
  } ,
  [FETCH_FRAMEWORKS_FAILURE]: (state) => merge(state, {
    isProcessing: false,
  }),
  [FETCH_THEMES]: (state) => merge(state, {
    isProcessing: true,
  }),
  [FETCH_THEMES_SUCCESS]: (state, action) => merge(state, {
    isProcessing: false,
    themes: action.payload.themes
  }),
  [FETCH_THEMES_FAILURE]: (state) => merge(state, {
    isProcessing: false,
  }),
  [CREATE_FRAMEWORK]: (state, action) => merge(state,{
    isProcessing: true,
  }),
  [CREATE_FRAMEWORK_SUCCESS]: (state, action) => merge(state,{
    isProcessing: false,
  }),
  [CREATE_FRAMEWORK_FAILURE]: (state, action) => merge(state,{
    isProcessing: false,
  }),
  [CREATE_THEME]: (state) => merge(state,{
    isProcessing: true
  }),
  [CREATE_THEME_SUCCESS]: (state) => merge(state,{
    isProcessing: false
  }),
  [CREATE_THEME_FAILURE]: (state, action) => merge(state,{
    isProcessing: false,
    errors: action.errors
  }),
  [SHOW_LOOKUP_MODAL]: (state, action) => merge(state,{
    isLookupModalOpen: true
  }),
  [HIDE_LOOKUP_MODAL]: (state, action) => merge(state,{
    isLookupModalOpen: false
  }),
  [FETCH_WORKSPACE_RESOURCES]: (state) => merge(state, {
    isProcessing: true,
  }),
  [FETCH_WORKSPACE_RESOURCES_SUCCESS]: (state, action) => merge(state,{
    isProcessing: false,
    workspaces: action.payload.workspaces 
  }),
  [FETCH_WORKSPACE_RESOURCES_FAILURE]: (state, action) => merge(state,{
    isProcessing: false,
    errors: action.errors
  }),
  [FETCH_DATA_RESOURCES]: (state) => merge(state, {
    isProcessing: true,
  }),
  [FETCH_SOURCE_DATA_RESOURCES_SUCCESS]: (state, action) => merge(state,{
    isProcessing: false,
    sourceFormData: action.payload 
  }),
  [FETCH_TARGET_DATA_RESOURCES_SUCCESS]: (state, action) => merge(state,{
    isProcessing: false,
    targetFormData: action.payload  
  }),
  [FETCH_DATA_RESOURCES_FAILURE]: (state, action) => merge(state,{
    isProcessing: false,
    errors: action.errors
  }),
  [SUBMIT_LOOKUP_QUESTION]: (state, action) => merge(state, {
    isProcessing: true,
  }),
  [SUBMIT_LOOKUP_QUESTION_SUCCESS]: (state, action) => merge(state, {
    isProcessing: false,
    isLookupModalOpen: false
  }),
  [SUBMIT_LOOKUP_QUESTION_FAILURE]: (state, action) => merge(state, {
    isProcessing: false,
    errors: action.errors
  }),
}, INITIAL_STATE)