import merge from 'xtend';
import createReducer from '../create-reducer';
import {
  ADD_ACTIVITY_MEASURE, ADD_ACTIVITY_MEASURE_SUCCESS, ADD_ACTIVITY_MEASURE_FAILURE, SHOW_ACTIVITY_FIELD,
  HIDE_ACTIVITY_FIELD
} from "../../actions/stakeholder-items/activities";
import { SAVE_MEASURE_NAME_SUCCESS } from "../../actions/measure/measure";


const INITIAL_STATE = {
  measureIsLoading: false,
  errors: {},
  activityMeasureNameFieldDisable: false
};

export default createReducer({
  [ADD_ACTIVITY_MEASURE]: (state, action) => merge(state, { measureIsLoading: true }),
  [ADD_ACTIVITY_MEASURE_SUCCESS]: (state, action) => merge(state, { measureIsLoading: false }),
  [ADD_ACTIVITY_MEASURE_FAILURE]: (state, action) => merge(state, {
    measureIsLoading: false,
    error: action.payload.errors
  }),
  [SHOW_ACTIVITY_FIELD]: (state, action) => merge(state, { activityMeasureNameFieldDisable: true }),
  [HIDE_ACTIVITY_FIELD]: (state, action) => merge(state, { activityMeasureNameFieldDisable: false }),
  [SAVE_MEASURE_NAME_SUCCESS]: (state, action) => merge(state, {activityMeasureNameFieldDisable: false})
}, INITIAL_STATE)