export const ADD_MEASURE = "@@MANAGE_MEASURE/ADD_MEASURE";
export const ADD_MEASURE_SUCCESS = "@@/MEASURE/ADD_MEASURE_SUCCESS";
export const ADD_MEASURE_FAILURE = "@@/MEASURE/ADD_MEASURE_FAILURE";

export const DELETE_MEASURE = "@@MANAGE_MEASURE/DELETE_MEASURE";
export const DELETE_MEASURE_SUCCESS = "@@MANAGE_MEASURE/DELETE_MEASURE_SUCCESS";
export const DELETE_MEASURE_FAILURE = "@@MANAGE_MEASURE/DELETE_MEASURE_FAILURE";

export const SHOW_MEASURE = "@@MANAGE_MEASURE/SHOW_MEASURE";
export const SET_MEASURE_NAME = "@@MANAGE_MEASURE/SET_MEASURE_NAME";
export const SET_MEASURE_ID = "@@MANAGE_MEASURE/SET_MEASURE_ID";

export const OPEN_MEASURE_MODAL = "@@MANAGE_MEASURE/OPEN_MEASURE_MODAL";
export const CLOSE_MEASURE_MODAL = "@@MANAGE_MEASURE/CLOSE_MEASURE_MODAL";

export const COPY_MEASURES = "@@MANAGE_MEASURE/COPY_MEASURES";
export const SUBMIT_COPY_MEASURE = "@@MANAGE_MEASURE/SUBMIT_COPY_MEASURE";
export const SUBMIT_COPY_MEASURE_SUCCESS =
  "@@MANAGE_MEASURE/SUBMIT_COPY_MEASURE_SUCCESS";
export const SUBMIT_COPY_MEASURE_FAILURE =
  "@@MANAGE_MEASURE/SUBMIT_COPY_MEASURE_FAILURE";
export const SET_CHANGE_MEASURE_NAME_MODAL_OPEN = "@@MANAGE_MEASURE/SET_CHANGE_MEASURE_NAME_MODAL_OPEN";
export const SET_OUTCOME_FRAMEWORK_MODAL = "@@MANAGE_MEASURE/SET_OUTCOME_FRAMEWORK_MODAL";

function addMeasure(measure, parentId) {
  return { type: ADD_MEASURE, measure, parentId };
}

function deleteMeasure(measureId) {
  return { type: DELETE_MEASURE, measureId };
}

function showMeasure(status, measureId) {
  return { type: SHOW_MEASURE, status, measureId };
}

function changeMeasure(measureId, name) {
  return { type: SET_MEASURE_NAME, measureId, name };
}

function setMeasureId(measureId) {
  return { type: SET_MEASURE_ID, measureId };
}

function openMeasureModal(measureId, isNotIndicator) {
  const data = { measureId, isNotIndicator };
  return { type: OPEN_MEASURE_MODAL, data };
}

function closeMeasureModal() {
  return { type: CLOSE_MEASURE_MODAL };
}

function copyMeasure(data) {
  return { type: COPY_MEASURES, data };
}

function submitCopyLogicModel() {
  return { type: SUBMIT_COPY_MEASURE };
}

function setChangeMeasureNameModalOpen(status) {
  return { type: SET_CHANGE_MEASURE_NAME_MODAL_OPEN, status };
}

function setOutcomeFrameworkModal(status) {
  return { type: SET_OUTCOME_FRAMEWORK_MODAL, status };
}

export default {
  setChangeMeasureNameModalOpen,
  addMeasure,
  deleteMeasure,
  showMeasure,
  changeMeasure,
  setMeasureId,
  openMeasureModal,
  closeMeasureModal,
  copyMeasure,
  submitCopyLogicModel,
  setOutcomeFrameworkModal,
};