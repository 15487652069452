import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import TruncateMarkup from 'react-truncate-markup';
import Input from "../../common/Input";
import OutsideClickDetection from "../../common/OutsideClickDetection";
import MeasureIcons from "../../../containers/logic-models/stakeholders-items/common/MeasureIcons.container";
import FrameworkThemeTitle from "../../../containers/logic-models/stakeholders-items/common/FrameworkThemeTitle.container";
import PermissionChecker from "../../common/PermissionChecker";

const IS_DEMOGRAPHIC_EXIST= ["Démographie", "Demographics"];
const PARENT_MEASURES = ["Input", "Activity", "Outcome"];

class MeasureNameComponent extends React.Component {
  static propTypes = {
    measure: PropTypes.object.isRequired,
    measureTypeTitle: PropTypes.string,
    measureNameClassName: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    query: PropTypes.string.isRequired,
    measureNameFieldDisable: PropTypes.bool.isRequired,
    ontologySearching: PropTypes.bool.isRequired,
    ontologyResult: PropTypes.array.isRequired,
    closeHandler: PropTypes.func.isRequired,
    saveMeasureName: PropTypes.func.isRequired,
    adjustmentModalClickHandler: PropTypes.func,
    changeMeasure: PropTypes.func.isRequired,
    collapseIconShow: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      searchForOntology: PropTypes.func,
      deleteMeasure: PropTypes.func,
      logicModelLoading: PropTypes.func,
      changeInputMeasureName: PropTypes.func,
      changeOntologyQuery: PropTypes.func,
      measureNameSave: PropTypes.func,
      setOntologyInitialState: PropTypes.func,
      changeStakeholderId: PropTypes.func
    }.isRequired)
  };

  state = {
    measureName: '',
    measureId: null,
    show: true
  };

  closeHandlerClick = () => {
    this.setState({ measureId: null, measureName: "" });
    this.props.closeHandler();
    this.props.actions.setOntologyInitialState();
  };

  saveMeasureNameClickHandler = (measureName) => {
    this.closeHandlerClick();
    this.props.saveMeasureName(measureName);
    this.props.actions.measureNameSave();
  };

  changeMeasureNameHandler = (name) => {
    this.setState({ measureName: name });
    this.props.actions.changeOntologyQuery(name);
    window.samUtils.ontologyRequest(this.props.actions.searchForOntology);
  };

  changeMeasureNameClickHandler = () => {
    this.setState({ measureId: this.props.measure.id, measureName: this.props.measure.name });
    if (!IS_DEMOGRAPHIC_EXIST.includes(this.props.measureTypeTitle))
      this.props.actions.changeStakeholderId(this.props.stakeholder.id);
    this.props.changeMeasure();
  };

  deleteMeasure = () => {
    this.props.actions.logicModelLoading();
    if (!IS_DEMOGRAPHIC_EXIST.includes(this.props.measureTypeTitle))
      this.props.actions.changeStakeholderId(this.props.stakeholder.id);
    this.props.actions.deleteMeasure(this.props.measure.id);
  };

  render() {
    const {
      query,
      measureNameFieldDisable,
      measure,
      ontologySearching,
      ontologyResult,
      measureTypeTitle,
      collapseIconShow,
      placeholder,
      measureNameClassName,
      adjustmentModalClickHandler,
      adjustment
    } = this.props;

    const measureClassNames = classNames({
      "row measure-name-wrap": true,
      'm-b': IS_DEMOGRAPHIC_EXIST.includes(measureTypeTitle) || measureTypeTitle === 'Financial Proxy'
    });
    return (
      <div className={measureNameClassName}>
        {measureNameFieldDisable && measure.id === this.state.measureId ? (
          <OutsideClickDetection hideAction={this.closeHandlerClick}>
            <div>
              <div className="input-group m-b">
                <span className="input-group-addon">{measureTypeTitle}</span>
                <Input
                  autoFocus={true}
                  changeName={this.changeMeasureNameHandler}
                  name={
                    this.state.measureName.includes(
                      this.props.intl("stakeholder.items.unnamed_measure")
                    )
                      ? ""
                      : this.state.measureName
                  }
                  placeholder={placeholder}
                  save={this.saveMeasureNameClickHandler}
                />
                <span className="input-group-btn">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.saveMeasureNameClickHandler.bind(null, query)}
                  >
                    <i className="fa fa-check" />
                  </button>
                </span>
              </div>
              {query.length > 0 && (
                <div className="search-results">
                  {ontologySearching && (
                    <div className="sk-spinner sk-spinner-rotating-plane" />
                  )}
                  {ontologyResult.map((name, index) => (
                    <div
                      className="action-name"
                      style={{ cursor: "pointer" }}
                      onClick={this.saveMeasureNameClickHandler.bind(
                        null,
                        name
                      )}
                      key={index}
                    >
                      {name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </OutsideClickDetection>
        ) : (
          <div className={measureClassNames}>
            <div className="col-md-8">
              <h5 className="measure-name">
                <span className="text-muted">{measureTypeTitle} - </span>
                <TruncateMarkup
                  lines={
                    PARENT_MEASURES.includes(measure.measure_type.name) ? 5 : 3
                  }
                >
                  <PermissionChecker>
                    <strong
                      className="editable_name"
                      style={{display: "inline-flex", gap: "2px"}}
                      onClick={this.changeMeasureNameClickHandler}
                    >
                      {measure.name}{" "}
                      <FrameworkThemeTitle
                        measureId={measure.id}
                        measureTypeTitle={measureTypeTitle}
                      />
                    </strong>
                  </PermissionChecker>
                </TruncateMarkup>
              </h5>
            </div>
            <MeasureIcons
              adjustment={!!adjustment}
              measureId={measure.id}
              deleteHandler={this.deleteMeasure}
              collapseIconShow={collapseIconShow}
              measureTypeTitle={measureTypeTitle}
              adjustmentModalClickHandler={adjustmentModalClickHandler}
              intl={this.props.intl}
            />
          </div>
        )}
      </div>
    );
  }
}

export default MeasureNameComponent;