import React, {useState, useEffect} from 'react';
import { InjectIntl } from "../../../common/InjectIntl";
import FormulaEditor from '../../FormulaEditor';
import formulaImage from '../../../../assets/array_calc_formula.png';
import SelectBox from '../../../common/SelectBox';
import { getFormTitle, nextCol, variableLetters } from '../../../../assets/sam-utils';
import { isEmpty, has } from 'lodash';
import classNames from 'classnames';
import ConditionIndex from "../../../../containers/calculations/sources/modals/Conditions-panel";
import { EDIT } from "../../../members/constants";

const SourceArrayCalculation = props => {

    const { activeVariable, actions } = props;

    // Activate the formula editor if atleast one variable has a header selected
    useEffect(function() {
        if(has(activeVariable, 'array_calculation.array_calculation_variables')) {
            setVariables(activeVariable.array_calculation.array_calculation_variables);
            const { array_calculation } = activeVariable;
            const { array_calculation_variables } = array_calculation;
            setCurrentCalculation(array_calculation);
            const variableWithSourceColumnExists = array_calculation_variables.filter(
                variable => !isEmpty(variable.datasource_object)
            ).length > 0;
            if(variableWithSourceColumnExists) {
                setFormulaEditorActive(true);
                setCanAddCondition(true);
            }
            // variable symbols
            const usedSymbols = array_calculation_variables.map(variable => variable.symbol);
            const availableSymbols = symbols.filter(symbol => !usedSymbols.includes(symbol));
            setSymbols(availableSymbols);
            
            // set conditions
            if(!!array_calculation && array_calculation.conditions.length > 0) {
                actions.setArrayCalculationConditions(array_calculation.conditions);
            }
        } // if array calculation variables exists
    }, [activeVariable]);

    const [isFormulaEditorActive, setFormulaEditorActive] = useState(false);

    const [symbols, setSymbols] = useState('abcdefghijklmnopqrstuvwxyz'.split(''));

    const [variables, setVariables] = useState([]);

    const [currentCalculation, setCurrentCalculation] = useState({
        id: 'a',
        string: '',
        latex: '',
        value: '',
        conditions: '',
    });

    const [canAddCondition, setCanAddCondition] = useState(false);

    const renderInfo = () => {
        return <p>The custom formula can be built using letters corresponding to the columns (A, B, C...). The output of this formula will be added across all rows to generate the final value of the variable. For e.g. - if the formula is A*B, the variable value will be <img width="100" src={formulaImage} alt="Array calculation formula" /></p>;
    }

    const renderHelpLink = () => {
        return <p><a href="http://help.sametri.ca/en/?q=array+formula" target="_blank">Click here for more information</a></p>;
    }

    const renderVariables = () => {
        const { headers, currentDataSource } = props;

        const filteredOptions = headers.filter(
            header => { 
                if (currentDataSource.sourceName === "Survey") {
                return !(header.question_type &&
                    header.question_type.type === "file_upload") && 
                    (has(header, 'validation.title_en') ? (header.validation.title_en === "Number" && header.question_type.title_en === "Text") : false)
                } else {
                    return header.data_type && ['integer', 'float'].includes(header.data_type.name);
                }
            }
        );

        const selectOptions = filteredOptions.map(option => {
            if (currentDataSource.sourceName === "Survey") {
                let disabled = true;
                if(option.validation) {
                    if(has(option, 'validation.title_en')) {
                        if(option.validation.title_en === "Number" && option.question_type.title_en === "Text") {
                            disabled = false;
                        }
                    }
                }
                return {
                    value: option.id,
                    label: getFormTitle(option, props.intlLocale),
                    disabled
                };
            } else if (currentDataSource.sourceName === "Upload") {
                return { value: option.id, label: option.label };
            }
        });

        let firstArrCalculationVariableSymbol = 'a';

        return (
            <>
                <label>Select columns for calculation</label>
                {variables.length > 0 ? variables.map((variable, index) => {
                    let selectedOption = currentDataSource.sourceName === "Survey" 
                        ? has(variable, 'datasource_object.question_id') ? variable.datasource_object.question_id : null
                        : has(variable, 'datasource_object.column_id') ? variable.datasource_object.column_id : null; 
                    const availableSymbols = symbols.concat(variable.symbol).sort();
                    return (<div className="row m-t" key={`array-calculation-variable-${variable.id}`}>
                        <div className="col-md-2">
                            <select className="form-control" value={variable.symbol} onChange={e => props.actions.updateArrayCalculationVariableSymbol({symbol: e.target.value}, variable.id)}>
                                {availableSymbols.map(symbol => {
                                    return <option key={symbol} value={symbol}>{symbol}</option>
                                })}
                            </select>
                        </div>
                        <div className="col-md-8">
                            <SelectBox
                                options={selectOptions}
                                label={props.intl("source.column")}
                                onHeaderChange={format => { props.actions.updateArrayCalculationVariable({ columnId: format.value }, variable.id) }}
                                locale={props.intlLocale}
                                selectedOption={selectedOption}
                                placeholder={props.intl("source.select_column")}
                                SelectBoxClassNames={"col-md-12"}
                            />
                        </div>
                        <div className="col-md-2">
                            <button className="btn btn-default m-r" style={{borderRadius: '50%'}} onClick={() => props.actions.createArrayCalculationVariable()}>+</button>
                            {(variables.length === 1 || index === 0) 
                                ? <button className="btn btn-default m-r disabled" disabled style={{borderRadius: '50%'}}>-</button> 
                                : <button className="btn btn-default m-r" style={{borderRadius: '50%'}} onClick={() => props.actions.deleteArrayCalculationVariable(variable.id)}>-</button>
                            }
                            
                        </div>
                    </div>);
                }) : (
                <div className="row m-t">
                    <div className="col-md-2">
                        <select className="form-control" onChange={e => firstArrCalculationVariableSymbol = e.target.value}>
                            {symbols.map(symbol => {
                                return <option key={symbol} value={symbol}>{symbol}</option>
                            })}
                        </select>
                    </div>
                    <div className="col-md-8">
                        <SelectBox
                            options={selectOptions}
                            label={props.intl("source.column")}
                            onHeaderChange={format => props.actions.createArrayCalculationVariableWithNewCalculationVariable({symbol: firstArrCalculationVariableSymbol, columnId: format.value, columnName: format.label })}
                            locale={props.intlLocale}
                            selectedOption={null}
                            placeholder={props.intl("source.select_column")}
                            SelectBoxClassNames={"col-md-12"}
                        />
                    </div>
                    <div className="col-md-2">
                        <button className="btn btn-default m-r" style={{borderRadius: '50%'}}>+</button>
                        <button className="btn btn-default m-r disabled" disabled style={{borderRadius: '50%'}}>-</button>
                    </div>
                </div>
            )}
            </>
        );
    }

    const renderFormulaEditor = () => {

        const formulaEditorActions = {
            saveFormula: () => {
                const { latex, string, conditions } = currentCalculation;
                if(currentCalculation.id) {
                    props.actions.updateArrayCalculation({
                        latex,
                        string,
                        // conditions saved in the epic
                        //conditions
                    }, currentCalculation.id)
                }
            },
            changeFormula: (latex, string) => {
                setCurrentCalculation({
                    ...currentCalculation,
                    latex,
                    string,
                    invalid: false,
                });
            }
        }

        return <FormulaEditor currentCalculation={currentCalculation} actions={formulaEditorActions} intl={props.intl} active={isFormulaEditorActive}  />
    }

    const renderConditions = () => {

        const { showCondition, intl, actions: { showConditionPanel, eraseAllConditions } } = props;

        const conditionButtonClass = classNames({
            "btn btn-success pull-right m-r-sm": true,
            "content-disabled": !canAddCondition
          });
        
          const conditionClassName = classNames({
            show: showCondition,
            hide: !showCondition
          });
        
          const eraseConditionsButtonClass = classNames({
            "btn btn-success pull-right m-r": true,
            show: showCondition,
            hide: !showCondition
          });

          const isReadOnlyPermission = localStorage.getItem("permission") !== EDIT;
        return (
            <>
                <div className="row">
                    <button className={conditionButtonClass} onClick={showConditionPanel}>
                        {isReadOnlyPermission ? intl("source.modal.view_condition") : intl("source.modal.add_condition")}
                    </button>
                    {!isReadOnlyPermission &&
                        <button
                            className={eraseConditionsButtonClass}
                            onClick={eraseAllConditions}
                        >
                            {intl("source.modal.clear_all")}
                        </button>
                    }
                </div>
                <div className={conditionClassName}>
                    {showCondition && <ConditionIndex />}
                </div>
            </>
        );
    }

    return (
        <>
            <div className="row">
                {renderInfo()}
                {renderHelpLink()}
                {renderVariables()}
                {renderConditions()}
                {renderFormulaEditor()}
            </div>
        </>
    );
}

export default InjectIntl(SourceArrayCalculation)