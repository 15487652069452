import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LoginActions from '../actions/login';
import Login from "../components/Login";

function mapStateToProps(state, ownProps) {
  const {access_token, refresh_token, redirect, locale } = ownProps.location.query;
  return {
    access_token,
    refresh_token,
    redirect,
    locale,
    ...state.login,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(LoginActions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)