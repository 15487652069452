import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap-modal";
import { InjectIntl } from "../common/InjectIntl";
import MultiEmailBox from "./MultiEmailBox/MultiEmailBox";
import { whiteLabel } from '../utils';

const EmailModal = ({
  isOpen,
  onClose,
  subject,
  onSubjectChange,
  message,
  onMessageChange,
  children,
  intlPrefix,
  sendCopy,
  onSendCopyChange,
  recipients,
  onRecipientChange,
  errors,
  onSubmit,
  intl
}) => {
  const intlWithPrefix = (msgId, values = {}) =>
    intl(`${intlPrefix}.${msgId}`, values);

  return (
    <Modal show={isOpen} id={"foreground_display"}  data-brand={whiteLabel()}>
      <Modal.Header>
        <Modal.Title>
          {intlWithPrefix("title")}
          <i className="fa fa-close pull-right" onClick={onClose} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>{intlWithPrefix("headers.message")}</h3>
        <div className="m-b-sm">{intlWithPrefix("helpText")}</div>
        <label>{intl("emailModal.subject")}</label>
        <input
          className="form-control success"
          value={subject}
          onChange={onSubjectChange}
        />
        <label className="m-t-sm">{intl("common.text.email")}</label>
        <textarea
          className="form-control success no-resize"
          value={message}
          onChange={onMessageChange}
          rows="3"
        />
        {children ? (
          children
        ) : (
          <div className="bg-muted b-r-md m-t-sm m-b-sm p-sm">
            <div>{message}</div>
            <a href="#!">{intlWithPrefix("link")}</a>
          </div>
        )}
        <hr />
        <h3 className="m-b">{intlWithPrefix("headers.recipients")}</h3>
        {sendCopy !== undefined && (
          <div className="form-group">
            <div className="checkbox">
              <input
                type="checkbox"
                id={`${intlPrefix}-sendCopy`}
                checked={sendCopy}
                onChange={onSendCopyChange}
              />
              <label htmlFor={`${intlPrefix}-sendCopy`}>
                {intlWithPrefix("labels.sendCopy")}
              </label>
            </div>
          </div>
        )}
        <div className="form-group">
          <div className="m-t">
            <span>{intlWithPrefix("instruction")}</span>
          </div>
          <label className="m-t">{intlWithPrefix("labels.recipients")}</label>
          <MultiEmailBox emails={recipients} onChange={onRecipientChange} />
          {!!errors[0] && (
            <div className="alert alert-danger email-error text-left">
              {errors[0]}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-default" onClick={onClose}>
          {intl("common.text.cancel_button")}
        </button>
        <button
          className="btn btn-success"
          disabled={recipients.length === 0}
          onClick={onSubmit}
        >
          {intlWithPrefix("buttons.submit")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

EmailModal.propTypes = {
  intlPrefix: PropTypes.oneOf(["inviteMembersModal"]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  message: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  onMessageChange: PropTypes.func.isRequired,
  onSubjectChange: PropTypes.func.isRequired,
  sendCopy: PropTypes.bool,
  onSendCopyChange: PropTypes.func,
  recipients: PropTypes.array.isRequired,
  onRecipientChange: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  emails: PropTypes.array.isRequired
};

EmailModal.defaultProps = {
  emails: []
};

export default InjectIntl(EmailModal);
