import React from 'react';
import PropTypes from "prop-types";

import { InjectIntl } from "../../../common/InjectIntl";
import SelectBox from "../../../common/SelectBox";
import { EDIT } from "../../../members/constants";

const EMPTY_TRANSFORMATION = {};

class OneSourceTransformation extends React.Component {
  static propTypes = {
    isHeaderReChanged: PropTypes.bool.isRequired,
    activeVariable: PropTypes.object.isRequired,
    conditions: PropTypes.array.isRequired,
    transformationForCondition: PropTypes.object.isRequired,
    allowGetTransformation: PropTypes.bool.isRequired,
    intl: PropTypes.func.isRequired,
    currentDataSource: PropTypes.shape({
      headerId: PropTypes.number,
      headerTransformations: PropTypes.array,
      transformation: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
      ])
    }),
    actions: PropTypes.shape({
      setConditionInitialState: PropTypes.func.isRequired,
      setDataSourceShouldUpdate: PropTypes.func.isRequired,
      setExistingConditions: PropTypes.func.isRequired,
      sendConditions: PropTypes.func.isRequired,
      changeCurrentTransformation: PropTypes.func.isRequired,
      fetchOneSourceFinalResultData: PropTypes.func.isRequired,
      setFinalData: PropTypes.func.isRequired
    }),
  }

  constructor(props) {
    super(props);
    this.state = {
      transformation: {}
    }
  }

  componentDidUpdate(prevProps) {
    const { isHeaderReChanged, activeVariable, currentDataSource: { headerId, headerTransformations, transformation} } = this.props;
    let trans = {};
    if (!!activeVariable.id
      && !!headerTransformations && typeof transformation === 'string') {
      headerTransformations.forEach((item) => {
        if (item.name === activeVariable.transformation) {
          trans = {
            optionValue: item.value,
            value: item.id,
            label: item.name
          }
        }
      });

      if (!isHeaderReChanged)
        this.changeTransformation(trans);
    }
    if (headerId !== prevProps.currentDataSource.headerId){
      this.setState({ transformation: EMPTY_TRANSFORMATION });
    }
    else
      this.changeConditionStateIfExists();
  }

  changeConditionStateIfExists=()=>{
    const { conditions, actions, transformationForCondition, activeVariable, currentDataSource: { transformation}, allowGetTransformation } = this.props;
    if (!!transformation && this.state.transformation !== transformation && !!activeVariable.id){
      const newTrans = !!this.state.transformation.label ? this.state.transformation : transformation;
      if (typeof newTrans === 'object' && newTrans !== null){
        this.changeTransformation(newTrans);
        if (!!transformationForCondition.label && transformationForCondition.label !== newTrans.label) {
          actions.setDataSourceShouldUpdate(false);
        } else if (!!activeVariable.conditions && activeVariable.conditions.length > 0 && allowGetTransformation && !!transformation &&
        conditions.length === 0) {
          actions.setExistingConditions(activeVariable.conditions);
          actions.sendConditions();
        }
      }
    }
  };

  getConditionTransformationIfExists = () => {
    return this.props.conditions.length > 0 && this.props.conditions[0].transformationForCondition;
  };

  changeTransformationIfDiff = (transformation) => {
    if (this.state.transformation.label !== transformation.label){
      this.props.actions.changeCurrentTransformation(transformation);
      this.props.actions.fetchOneSourceFinalResultData(this.props.currentDataSource.headerId);
      this.setState({ transformation });
    }
  };

  resetConditionsIfTransformationChanged = (transformation) => {
    const conditionTransformation = this.getConditionTransformationIfExists();
    if (!!conditionTransformation && conditionTransformation.label && conditionTransformation.label !== transformation.label) {
      this.props.actions.setConditionInitialState();
      const payload = JSON.parse(localStorage.getItem('final_page_items'));
      this.props.actions.setFinalData(payload)
    }
  };

  changeTransformation = (transformation) => {
    this.changeTransformationIfDiff(transformation);
    this.resetConditionsIfTransformationChanged(transformation);
  };

  render() {
    const { currentDataSource } = this.props;
    const hasOptionForTransformation = !!currentDataSource.headerTransformations ? '' : 'content-disabled';

    const selectOptions = !!currentDataSource.headerTransformations ? currentDataSource.headerTransformations.map((trans) => {
      return { value: trans.id, label: trans.name, optionValue: trans.value };
    }) : [];

    return (
      <div className="col-md-6" id='transformation-select'>
        <SelectBox
          options={selectOptions}
          label={'Transformation'}
          onHeaderChange={this.changeTransformation}
          selectedOption={this.state.transformation.value}
          className={`form-control success ${hasOptionForTransformation}`}
          selectClass={hasOptionForTransformation}
          placeholder={this.props.intl('common.text.none')}
          labelClassName={"col-md-4"}
          SelectBoxClassNames={"col-md-8"}
          disabled={localStorage.getItem("permission") !== EDIT}
        />
      </div>
    );
  }
}

export default InjectIntl(OneSourceTransformation);