import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CalculationsActions from "../../actions/calculation/calculation-page";
import ManageMeasureActions from "../../actions/measure/manage-measure";
import CurrentCalculationTab from "../../components/calculations/CurrentCalculationTab";
import ErrorNotificationActions from "../../actions/errors";
import SourceModalsActions from "../../actions/calculation/source-modals";
import SelectVariableTypeActions from "../../actions/calculation/selectVariableType"
import DataSourceActions from "../../actions/calculation/data-source";
import modalDeleteItemActions from "../../actions/modal-delete-item";


function mapStateToProps(state, ownProps) {
  return {
    ...state.calculations,
    changeNameInputShow: ownProps.changeNameInputShow,
    clickCalculationNameInputFieldHandler: ownProps.clickCalculationNameInputFieldHandler,
    measureName: state.measurePage.newMeasureName,
    ...state.dataSource,
    intl: state.intl
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...CalculationsActions,
      ...ManageMeasureActions,
      ...ErrorNotificationActions,
      ...SourceModalsActions,
      ...DataSourceActions,
      ...SelectVariableTypeActions,
      ...modalDeleteItemActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentCalculationTab)