import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap-modal";
import { InjectIntl } from "../common/InjectIntl";
import SelectBox from "../common/SelectBox";
import {
  DEMOGRAPHIC,
  OUTCOME_INDICATOR,
  OUTPUT,
  INPUT,
  FINANCIAL_PROXY,
  ACTIVITY,
  OUTCOME,
} from "../../../src/constants";

const MEASURES_TYPES = {
  [OUTPUT]: [DEMOGRAPHIC, OUTCOME_INDICATOR, OUTPUT],
  [OUTCOME_INDICATOR]: [DEMOGRAPHIC, OUTCOME_INDICATOR, OUTPUT],
  [DEMOGRAPHIC]: [DEMOGRAPHIC, OUTCOME_INDICATOR, OUTPUT],
  [FINANCIAL_PROXY]: [INPUT, OUTCOME_INDICATOR, FINANCIAL_PROXY],
};

const MEASURES = {
  [OUTPUT]: [ACTIVITY],
  [OUTCOME_INDICATOR]: [OUTCOME],
  [FINANCIAL_PROXY]: [INPUT, OUTCOME_INDICATOR],
  [DEMOGRAPHIC]: null,
};

const ModalCopyPaste = (props) => {
  const {
    intl,
    actions,
    isModalOpen,
    logicModel,
    measure_id,
    measureName,
    errorsMeasures,
    isNotIndicator,
  } = props;
  const [selectedStakeholder, setStakeholder] = useState(undefined);
  const [selectedMeasure, setMeasure] = useState(undefined);
  const [selectedMeasureType, setMeasureType] = useState(undefined);
  const [newMeasureName, setMeasureName] = useState(undefined);
  const [isMeasureTypeSelectHidden, setMeasureTypeSelect] = useState(false);
  useEffect(() => {
    if (measureName) {
      setMeasureName(`${measureName.name} copy`);
    }
  }, [measure_id]);
  useEffect(() => {
    if (
      measureName &&
      selectedStakeholder &&
      measureName.measure_type.name === FINANCIAL_PROXY
    ) {
      setMeasureType({
        label: FINANCIAL_PROXY,
        value: {
          measure_type: {
            id: null,
            name: FINANCIAL_PROXY,
          },
        },
      });
      setMeasureTypeSelect(true);
    } else {
      setMeasureTypeSelect(false);
    }
  }, [measureName, selectedStakeholder]);
  const selectStakeholderOptions = logicModel.stakeholders.map((items) => {
    return {
      value: { ...items },
      label: items.name,
    };
  });
  const stakeholderMeasures =
    selectedStakeholder &&
    selectedStakeholder.value.measures.map(({ measure_type }) => {
      return measure_type.name;
    });
  const measureTypes =
    selectedMeasureType &&
    stakeholderMeasures &&
    MEASURES[selectedMeasureType.value.measure_type.name] &&
    MEASURES[selectedMeasureType.value.measure_type.name].filter((item) =>
      stakeholderMeasures.includes(item)
    );
  const copyMeasureTypes =
    selectedStakeholder && MEASURES_TYPES[measureName.measure_type.name];
  const selectMeasureTypeOptions =
    selectedMeasureType &&
    measureTypes &&
    selectedStakeholder.value.measures
      .filter(({ measure_type }) => {
        return measureTypes.includes(measure_type.name);
      })
      .map((items) => {
        return {
          value: { ...items },
          label: items.name,
        };
      });
  const copyMeasureTypeOptions =
    copyMeasureTypes &&
    selectedStakeholder.value.measures
      .filter(({ measure_type }) => {
        return copyMeasureTypes.includes(measure_type.name);
      })
      .map((items) => {
        return {
          value: { ...items },
          label: items.measure_type.name,
        };
      })
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.label === value.label)
      );
  const handleStakeholderChange = (data) => {
    setStakeholder(data);
  };
  const handleMeasureChange = (data) => {
    setMeasure(data);
  };
  const handleMeasureTypeChange = (data) => {
    let measureTypeDate = data;
    if (data.value.measure_type.id === measureName.measure_type.id) {
      measureTypeDate.value.measure_type.id = null;
    }
    setMeasureType(measureTypeDate);
  };
  const submitChanges = () => {
    actions.copyMeasure({
      targetStakeholderId: selectedStakeholder.value.id,
      targetParentMeasureId: selectedMeasure ? selectedMeasure.value.id : null,
      newMeasureName: newMeasureName,
      copy_measure_type_id: selectedMeasureType
        ? selectedMeasureType.value.measure_type.id
        : null,
      measureId: measure_id,
    });
    actions.submitCopyLogicModel();
    setStakeholder(undefined);
    setMeasure(undefined);
    setMeasureType(undefined);
  };
  const handleMeasureName = (e) => {
    setMeasureName(e.target.value);
  };
  const closeModal = () => {
    setStakeholder(undefined);
    setMeasure(undefined);
    actions.closeMeasureModal();
  };
  const isSubmitButtonDisabled = isNotIndicator
    ? !selectedStakeholder
    : !selectedMeasure;
  useEffect(() => {
    if (isModalOpen) {
      actions.hideDemographic();
    }
  }, [isModalOpen]);
  return (
    <Modal show={isModalOpen} id={"foreground_display"}>
      <Modal.Header>
        <Modal.Title>
          {intl("modal.copy_measure_title")} {measureName && measureName.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SelectBox
          placeholder={intl("modal.select_stakeholder")}
          selectedOption={selectedStakeholder}
          options={selectStakeholderOptions}
          onHeaderChange={handleStakeholderChange}
          conditionBlock
          SelectBoxClassNames={"measures-select"}
        />
        {copyMeasureTypes && !isNotIndicator && !isMeasureTypeSelectHidden && (
          <SelectBox
            placeholder={intl("modal.select_measure_type")}
            selectedOption={selectedMeasureType}
            options={copyMeasureTypeOptions}
            onHeaderChange={handleMeasureTypeChange}
            conditionBlock
            SelectBoxClassNames={"measures-select"}
          />
        )}
        {measureTypes && !isNotIndicator && (
          <SelectBox
            placeholder={intl("modal.select_measure")}
            selectedOption={selectedMeasure}
            options={selectMeasureTypeOptions}
            onHeaderChange={handleMeasureChange}
            conditionBlock
            SelectBoxClassNames={"measures-select"}
          />
        )}

        <input
          className="form-control success measures-input"
          placeholder={intl("modal.input_measure_name")}
          type="text"
          value={newMeasureName}
          onChange={handleMeasureName}
        />
        {errorsMeasures &&
          Object.entries(errorsMeasures).map(([key, value]) => (
            <React.Fragment key={key}>
              <p className="text-danger errors-text-modal">
                {key}: {JSON.stringify(value)}
              </p>
            </React.Fragment>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-default" onClick={closeModal}>
          {intl("common.text.cancel_button")}
        </button>
        <button
          disabled={isSubmitButtonDisabled}
          className="btn btn-success"
          onClick={submitChanges}
        >
          {intl("common.text.save_button")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default InjectIntl(ModalCopyPaste);
