import { Observable } from "rxjs/Observable";
import 'rxjs';
import { CLEAR_ERROR, SHOW_ERROR } from "../actions/errors";
import { HIDE_SPINNER } from "../actions/spinner";


export function errorClickEpic(action$, store) {
  return action$.ofType(CLEAR_ERROR).mergeMap(action => {
    const { showSpinner } = store.getState().spinner;
    if (showSpinner) {
      return Observable.of({ type: HIDE_SPINNER });
    } else {
      return Observable.empty();
    }
  })
}

export function errorNotificationEpic(action$, store) {
  return action$.ofType(SHOW_ERROR).mergeMap(action => {
    const { showSpinner } = store.getState().spinner;
    if (showSpinner) {
      return Observable.of({ type: HIDE_SPINNER });
    } else {
      return Observable.empty();
    }
  })
}

export default [
  errorClickEpic,
  errorNotificationEpic
]