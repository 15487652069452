import React from 'react';
import PropTypes from 'prop-types';
import ConditionItem from "./ConditionItem";
import { InjectIntl } from "../../../../common/InjectIntl";
import { getFormTitle } from "../../../../../assets/sam-utils";
import {
  QUESTION_FORM_TYPES,
  UPLOAD_DATA_ID_TYPES,
  NUMBER_OR_DATE_TYPES,
  CHECKBOXES_TYPES
} from "../../../../../constants";
import { CONDITION_INITIAL_STATE, ERROR_HINTS_INITIAL_STATE } from "../../../../../reducers/calculations/modals/condition-panel"

const ConditionList = (props) => {

  const { intl, showError, conditions, intlLocale, currentDataSource, headers, currentCondition,
    actions, currentCondition: { columnHeader, chosenData },
    actions : { changeCurrentCondition, showErrorHints, clearErrorHints, setConditionRow }
  } = props;

  const changeColumnHandler = (e, objId) => {
    let chosenData = '';
    if (e.obj.upload_data_type_id) {
      chosenData = UPLOAD_DATA_ID_TYPES[e.obj.upload_data_type_id];
    }
    else if (e.obj.question_type_id !== 'text') {
      if (QUESTION_FORM_TYPES[e.obj.question_type_id] !== 'text')
        chosenData = QUESTION_FORM_TYPES[e.obj.question_type_id];
      else
        chosenData = e.obj.validation.type;
    }

    const condition = {
      ...CONDITION_INITIAL_STATE,
      id: objId,
      columnHeader: {
        name: e.label,
        value: e.value,
        obj: e.obj
      },
      chosenData: chosenData,
      errorHints: {
        ...ERROR_HINTS_INITIAL_STATE,
        columnHeader: ""
      },
      transformationForCondition: currentDataSource.transformation
    };
    changeCurrentCondition(condition);
    showErrorHints(false);
  };

  const changeHandler = (e) => {
    if (Array.isArray(e)) {
      if (e.length > 0)
        setValue("options", e, "optionsField");
      else {
        setValue("options", e, "optionsField");
        clearErrorHints("optionsField");
      }
    } else {
      changeNotation(e);
    }
  };

  const changeNotation = (e) => {
    const condition = {
      ...currentCondition,
      optionValue: {
        ...currentCondition.optionValue,
        value: e.value,
        notation: e.label
      },
      errorHints: {
        ...currentCondition.errorHints,
        notation: ""
      }
    };
    changeCurrentCondition(condition)
  };

  const setValue = (optionKey, optionValue, errorKey) => {
    changeCurrentCondition({
      ...currentCondition,
      optionValue: {
        ...currentCondition.optionValue,
        [optionKey]: optionValue
      },
      errorHints: {
        ...currentCondition.errorHints,
        [errorKey]: ""
      }
    })
  };

  const checkIsNumber=(str)=> {
    let pattern = /([-])+([-\d]+)|([-\d.\d])+/g;
    return pattern.exec(str);
  };

  const inputChangeHandler = (e) => {
    const inputValue = e.target.value;
    let isNumber = checkIsNumber(inputValue);
    if (inputValue.length > 0 && !!isNumber) {
      if (!isNaN(isNumber[0]) || isNumber[0] === '-')
        setValue("inputValue", isNumber[0], "numberField");
      else
        showErrorHints()
    } else {
      setValue("inputValue", "");
      clearErrorHints("numberField");
    }
  };

  const handleDateChange = (date) => {
    setValue("startDate", date, "dateField");
  };

  const changeCurrentConditionRow = (condition) => {
    if (condition.id !== currentCondition.id)
      setConditionRow(condition)
  };

  const filteredOptions = headers.reduce((acc, header) => {
    if (!!header.question_type_id) {
      if (!['text', 'multiple_fields', 'file_upload', 'anonymous_respondent'].includes(QUESTION_FORM_TYPES[header.question_type_id]))
        acc.push(header);
      if (QUESTION_FORM_TYPES[header.question_type_id] === 'text') {
        if (NUMBER_OR_DATE_TYPES.includes(header.validation.type)) {
          acc.push(header);
        }
      }
    }
    else if (!["string", "email"].includes(UPLOAD_DATA_ID_TYPES[header.upload_data_type_id]))
      acc.push(header);
    return acc
  }, []);

  const selectOptions = filteredOptions.map((option) => {
    if (currentDataSource.sourceName === 'Survey')
      return { value: option.id, label: getFormTitle(option, intlLocale), obj: option };
    else if (currentDataSource.sourceName === 'Upload')
      return { value: option.id, label: option.label, obj: option };
  });

  let options = [];

  if (CHECKBOXES_TYPES.includes(chosenData)) {
    options = columnHeader.obj.options.map((option) => {
      if (currentDataSource.sourceName === 'Survey')
        return { value: option.id, label: getFormTitle(option, intlLocale), obj: option };
      else
        return { value: option.id, label: option.title, obj: option };
    })
  }

  const lastConditionId = conditions.length > 0 ? conditions.slice(conditions.length -1)[0].id : 1;


  return (
    <React.Fragment>
      <div className="condition-block" style={{display: "none"}}>
        <div style={{flex: '1'}}><label style={{paddingLeft: "35px"}}>{intl('source.modal.condition_column')}</label></div>
        <div style={{flex: '1'}}><label>{intl('source.modal.filter')}</label></div>
      </div>
      <div>
        {conditions.map((condition, index) =>
          <div onClick={changeCurrentConditionRow.bind(null, condition)} key={index}>
            <ConditionItem
              condition={condition}
              actions={actions}
              options={options}
              selectOptions={selectOptions}
              changeColumnHandler={changeColumnHandler.bind(this)}
              changeHandler={changeHandler.bind(this)}
              inputChangeHandler={inputChangeHandler}
              handleDateChange={handleDateChange}
              chosenData={condition.chosenData}
              optionValue={condition.optionValue}
              startDate={condition.startDate}
              showError={showError}
              lastConditionId={lastConditionId}
              conditionsLength={conditions.length}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

ConditionList.propTypes = {
  intl: PropTypes.func.isRequired,
  showError: PropTypes.bool.isRequired,
  currentCondition: PropTypes.shape({
    columnHeader: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      obj: PropTypes.object.isRequired,
    }).isRequired,
    optionValue: PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      label: PropTypes.string,
      options: PropTypes.array,
      startDate: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
      ]),
      inputValue: PropTypes.string
    }).isRequired,
    chosenData: PropTypes.string.isRequired
  }).isRequired,
  actions: PropTypes.shape({
    hideConditionPanel: PropTypes.func.isRequired,
    conditionRowRemove: PropTypes.func.isRequired,
    conditionRowAdd: PropTypes.func.isRequired,
    changeConditionDate: PropTypes.func.isRequired,
    setConditionRow: PropTypes.func.isRequired,
    clearErrorHints: PropTypes.func.isRequired,
    changeCurrentCondition: PropTypes.func.isRequired,
    showErrorHints: PropTypes.func.isRequired,
  })
};

export default InjectIntl(ConditionList);