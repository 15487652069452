export default {
  chart: {
    type: 'column'
  },
  credits: {
    enabled: false
  },
  lang: {
      noData: "No outcomes achieved"
  },
  noData: {
    style: {"color": "#dddddd", "fontSize": "24px", "fontWeight": "400"}
  },
  title: {
    text: '',
    style: {"color": "#dddddd", "fontSize": "16px"}
  },
  yAxis: {
    title: {
      text: 'Value',
      style: {"color": "#dddddd", "fontSize": "16px"}
    },
  },
  xAxis: {
    title: {
      text: 'Categories',
      style: {"color": "#dddddd", "fontSize": "16px"}
    },
  },
  series: [{
    name: 'Outcome indicators',
    showInLegend: false,
    data: []
  }]
}