import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import GoodJobActions from "../actions/good-job-alert";
import React from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import * as swal2  from 'sweetalert2';
import BrowserDetect from "../assets/sam-utils";

class GoodJobSweetAlert extends React.Component {
  static propTypes = {
    showGoodJob: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      hideGoodJob: PropTypes.func.isRequired
    })
  };

  renderGoodJob = () => {
    const goodJobElement = this.goodJobElement();
    if (BrowserDetect.browser === 'Explorer' && BrowserDetect.version < 11) {
      swal({
        content: {
          element: goodJobElement
        },
        buttons: {},
        timer: 1500
      });
    } else {
      swal2({
        html: goodJobElement,
        showConfirmButton: false,
        timer: 1500
      });
    }
  };

  goodJobElement = () => {
    const divEl = document.createElement("div");
    divEl.className = "expertise-assessment-sweet-alert";
    const checkIcon = document.createElement("i");
    checkIcon.className = "fa fa-check fa-2x";
    const goodJob = document.createElement("div");
    goodJob.innerHTML = 'Good Job!';
    goodJob.id = "good-job";
    const sweetAlertText = document.createElement("span");
    sweetAlertText.innerHTML = 'The variable has been successfully saved';
    sweetAlertText.id = "sweet-alert-text";
    divEl.appendChild(checkIcon);
    divEl.appendChild(goodJob);
    divEl.appendChild(sweetAlertText);
    return divEl
  };

  componentDidUpdate(prevProps) {
    if(prevProps.showGoodJob && !this.props.showGoodJob) {
      if (BrowserDetect.browser === 'Explorer' && BrowserDetect.version < 11){
        swal.close();
        document.body.removeChild(document.getElementsByClassName('swal-overlay')[0])
      } else {
        swal2.close();
      }
    }
  }

  render() {
    return (
      <div>
        {this.props.showGoodJob &&
        <div>
          {this.renderGoodJob()}
        </div>
        }
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ...state.goodJobSweetAlert
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(GoodJobActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GoodJobSweetAlert)