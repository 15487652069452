import React from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import * as swal2 from 'sweetalert2';
import {InjectIntl} from "../common/InjectIntl";
import BrowserDetect from "../../assets/sam-utils";


const ModalDeleteItem = (props) => {
  const { intl, text, actions, handleDelete, showModal, itemId } = props;

  const renderSweetAlert = () => {
    const title = intl('sweetAlert.title');
    const deleteButton = intl('sweetAlert.delete_button');
    const cancelButton = intl('common.text.cancel_button');

    if (BrowserDetect.browser === 'Explorer' && BrowserDetect.version < 11) {
      swal({
        title: title,
        icon: "warning",
        text: text,
        closeOnClickOutside: false,
        closeOnEsc: false,
        className: "internet-explorer",
        allowOutsideClick: false,
        buttons: {
          cancel: cancelButton,
          confirm: deleteButton,
        }
      }).then((value) => {
        actions.hideDeleteModalItem();
        if (value) {
          handleDelete(itemId);
        }
        document.body.removeChild(document.getElementsByClassName('swal-overlay')[0]);
      });
    } else {
      swal2({
        title: title,
        text: text,
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#C1C1C1',
        confirmButtonColor: 'rgb(140, 212, 245)',
        reverseButtons: true,
        cancelButtonText: cancelButton,
        confirmButtonText: deleteButton,
        allowOutsideClick: false
      }).then((result) => {
        actions.hideDeleteModalItem();
        if (result.value) {
          handleDelete(itemId);
        }
      });
    }
  };

  return (
    <div>
      { showModal && renderSweetAlert()}
    </div>
  );
};

ModalDeleteItem.propTypes = {
  showModal: PropTypes.bool.isRequired,
  itemId: PropTypes.number,
  text: PropTypes.string,
  handleDelete: PropTypes.func,
  actions: PropTypes.shape({
    hideDeleteModalItem: PropTypes.func.isRequired
  })
};

export default InjectIntl(ModalDeleteItem);