export const ADD_ACTIVITY_MEASURE = '@@ACTIVITY/ADD_ACTIVITY_MEASURE';
export const ADD_ACTIVITY_MEASURE_SUCCESS = '@@ACTIVITY/ADD_ACTIVITY_MEASURE_SUCCESS';
export const ADD_ACTIVITY_MEASURE_FAILURE = '@@ACTIVITY/ADD_ACTIVITY_MEASURE_FAILURE';

export const SHOW_ACTIVITY_FIELD = '@@ACTIVITY/SHOW_ACTIVITY_FIELD';
export const HIDE_ACTIVITY_FIELD = '@@ACTIVITY/HIDE_ACTIVITY_FIELD';

export const CHANGE_ACTIVITY_MEASURE_NAME = '@@ACTIVITY/CHANGE_ACTIVITY_MEASURE_NAME';

function addActivityMeasure() {
  return { type: ADD_ACTIVITY_MEASURE}
}

function showMeasureNameActivityField() {
  return { type: SHOW_ACTIVITY_FIELD }
}

function hideMeasureNameActivityField() {
  return { type: HIDE_ACTIVITY_FIELD }
}

function changeActivityMeasureName(measureName, measureId) {
  return { type: CHANGE_ACTIVITY_MEASURE_NAME, measureName, measureId }
}

export default {
  addActivityMeasure,
  showMeasureNameActivityField,
  hideMeasureNameActivityField,
  changeActivityMeasureName,
}