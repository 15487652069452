import { combineEpics } from 'redux-observable';
import logicModelsEpic from "./logicModel";
import fetchLogicModelEpic from "./measure";
import inviteCollaboratorsEpic from "./collaborators"
import loginEpic from './login';
import ajustments from './adjustments';
import applicationEpic from './application';

import inputs from './stakeholder-items/inputsMeasure';
import activities from './stakeholder-items/activitiesMeasure';
import outcomes from './stakeholder-items/outcomesMeasure';
import outputs from './stakeholder-items/outputMeasure';
import financialProxy from './stakeholder-items/financilaProxy';
import outcomeIndicator from './stakeholder-items/outcomeIndicator';
import demographic from './stakeholder-items/demographicMeasure';
import calculations from "./calculations/calculations";
import calculationSource from "./calculations/calculation-source";
import conditionPanel from "./calculations/condition-panel";
import commonEpic from "./common"
import reports from "./reports"

export default combineEpics (
	...applicationEpic,
  ...logicModelsEpic,
  ...loginEpic,
	...fetchLogicModelEpic,
	...inviteCollaboratorsEpic,
	...ajustments,
	...activities,
	...inputs,
	...outcomes,
	...outputs,
	...financialProxy,
	...outcomeIndicator,
	...demographic,
	...calculations,
	...calculationSource,
	...commonEpic,
	...conditionPanel,
	...reports
);