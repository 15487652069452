import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CalculationsActions from "../../actions/calculation/calculation-page";
import MeasureCalculationsDetails from "../../components/calculations/MeasureCalculationsDetails";


function mapStateToProps(state) {
  return {
    ...state.calculations,
    measureName: state.measurePage.newMeasureName,
    intl: state.intl
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...CalculationsActions,
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeasureCalculationsDetails)