import { Observable } from "rxjs/Observable";
import { post, get, put, remove, patch } from "../../api";
import 'rxjs';
import { concatMap } from "rxjs/operator/concatMap";
import {
  FETCH_CALCULATIONS,
  FETCH_CALCULATIONS_SUCCESS,
  UPDATE_CALCULATION,
  ADD_CALCULATION, EDIT_CALCULATION_VARIABLE,
  ADD_CALCULATION_SUCCESS, ADD_CALCULATION_VARIABLES_SUCCESS,
  SET_VARIABLE, DELETE_VARIABLE, DELETE_CALCULATION, DELETE_VARIABLE_SUCCESS,
  DELETE_CALCULATION_SUCCESS, CHANGE_HIERARCHY, UPDATE_CALCULATION_DATA_SUCCESS,
  UPDATE_CALCULATION_DATA, CHANGE_ACTIVE_VARIABLE, SAVE_FORMULA,
  SET_VARIABLE_SUCCESS,
  CREATE_CALCULATION_VARIABLE,
  SET_PREEXISTING_CALCULATIONS,
  ADD_PREEXISTING_CALCULATION_VARIABLE,
  SET_VARIABLE_NAME,
  SET_CALCULATION_VARIABLE,
  SET_CURRENT_CALCULATION,
  CREATE_ARRAY_CALCULATION_VARIABLE_WITH_NEW_CALCULATION_VARIABLE,
  CREATE_ARRAY_CALCULATION,
  CREATE_ARRAY_CALCULATION_VARIABLE,
  CREATE_ARRAY_CALCULATION_SUCCESS,
  UPDATE_ARRAY_CALCULATION,
  UPDATE_ARRAY_CALCULATION_SUCCESS,
  UPDATE_ARRAY_CALCULATION_FAILURE,
  DELETE_ARRAY_CALCUATION_VARIABLE,
  UPDATE_ARRAY_CALCULATION_VARIABLE,
  UPDATE_ARRAY_CALCULATION_VARIABLE_SYMBOL,
  SAVE_ARRAY_CALCULATION_FINAL,
  SAVE_SNAPSHOT_COMMENT,
  SAVE_SNAPSHOT_COMMENT_SUCCESS,
  SAVE_SNAPSHOT_COMMENT_FAILURE,
} from "../../actions/calculation/calculation-page";
import {
  SHOW_SELECT_VARIABLE_TYPE_MODAL,
  HIDE_SELECT_VARIABLE_TYPE_MODAL,
  SHOW_SELECT_PREEXISTING_CALCULATION_MODAL,
  HIDE_SELECT_PREEXISTING_CALCULATION_MODAL,
  HIDE_ONE_SOURCE_MODAL
} from "../../actions/calculation/selectVariableType";
import { SET_MEASURE_NAME } from "../../actions/measure/manage-measure";
import { SHOW_ERROR } from "../../actions/errors";
import {
  SAVE_DATA_SOURCE, SAVE_DATA_SOURCE_SUCCESS, UPDATE_CURRENT_DATA_SOURCE, SAVE_CALCULATIONS_DATA_SOURCE,
  GET_TRANSFORMATION_VALUES, SAVE_HEADER_TRANSFORMATION, EDITED_VARIABLE_SAVE, MARK_AS_RECHANGED_HEADER,
  SAVE_CALCULATIONS_DATA_SOURCE_SUCCESS
} from "../../actions/calculation/data-source";
import {
  FETCH_FINAL_SOURCE_DATA,
  SAVE_FINAL_SOURCE,
} from "../../actions/calculation/source-modals";
import { SHOW_ONE_SOURCE_MODAL } from "../../actions/calculation/selectVariableType";
import { typeSources } from "../epic-constants";
import { HIDE_SPINNER, SHOW_SPINNER } from "../../actions/spinner";
import { SHOW_GOOD_JOB } from "../../actions/good-job-alert";
import { COMPLETE, DELETED } from "../../components/calculations/sources/source-table-layout/constants";
import { isArrayCalculationReady, isSourceDeleted } from "../../assets/sam-utils";
import { has } from "lodash";
import { SELECT_ARRAY_CALCULATION, SELECT_STANDARD_CALCULATION } from "../../actions/calculation/selectCalculationType";
import {EDIT, READ} from "../../components/members/constants";

export function getMeasureCalculationsEpic(action$, store) {
  return action$.ofType(FETCH_CALCULATIONS)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const {
        measureId,
        newMeasureName,
      } = store.getState().measurePage;
      let shouldRedoCalculations = action.shouldRedoCalculations ? action.shouldRedoCalculations : 0;
      return Observable.fromPromise(get(`/api/measures/${measureId}/calculations?shouldRedoCalculations=${shouldRedoCalculations}`, token))
        .flatMap(({ calculations, datasourceTypes, measureName, measureDescription }) => {
          const name = measureName;
          const description = measureDescription;
          return Observable.concat(
            newMeasureName !== name ? Observable.of({ type: SET_MEASURE_NAME, measureId, name, description })
            : Observable.empty(),
            Observable.of({ type: FETCH_CALCULATIONS_SUCCESS, payload: { calculations, datasourceTypes } })
          )
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.of({ type: SHOW_ERROR, errorText })
        })
    })
}

export function changeHierarchyCalculationEpic(action$, store) {
  return action$.ofType(CHANGE_HIERARCHY)
    .mergeMap((action) => {
      const { measureId } = store.getState().measurePage;
      const { currentCalculation } = store.getState().calculations;

      const payload = {
        measure_id: measureId,
        name: currentCalculation.name,
        parent: action.changeParent
      };
      return Observable.of({ type: UPDATE_CALCULATION_DATA, payload })
    })
}

export function saveCalculationFormulaEpic(action$, store) {
  return action$.ofType(SAVE_FORMULA)
    .mergeMap((action) => {
      const { measureId } = store.getState().measurePage;
      const { currentCalculation } = store.getState().calculations;

      if (currentCalculation.latex === "") {
        const errorText = "Formula field cannot be empty. Please provide a valid mathematical expression.";
        return Observable.of({ type: SHOW_ERROR, errorText });
      }

      let payload = {
        measure_id: measureId,
        name: currentCalculation.name,
        latex: currentCalculation.latex,
        string: typeof(currentCalculation.string) !== "string" ? currentCalculation.string : currentCalculation.string.split(/([a-zA-Z])[ ]([a-zA-Z])/).join('')
      }

      if (currentCalculation.parent === 1)
        payload.parent = 1;
      return Observable.concat(
        Observable.of({ type: UPDATE_CALCULATION_DATA, payload }),
        Observable.of({ type: SHOW_SPINNER }),
      )
    })
}

export function updateCalculationEpic(action$, store) {
  return action$.ofType(UPDATE_CALCULATION_DATA)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { currentCalculation } = store.getState().calculations;
      const formulaString = action.payload.string;

      return Observable.fromPromise(put(`/api/calculations/${currentCalculation.id}`, action.payload, token))
        .flatMap(({ calculation }) => {
          calculation.available_variable_letters = currentCalculation.available_variable_letters;
          return Observable.concat(
            Observable.of({ type: UPDATE_CALCULATION_DATA_SUCCESS, payload: { calculation } }),
            Observable.of({ type: HIDE_SPINNER }),
            Observable.of({ type: FETCH_CALCULATIONS }),
          )
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;

          if (formulaString === 'There is an invalid expression in your formula.') {
            return Observable.of({ type: SHOW_ERROR, errorText: formulaString })
          } else if (errorText === "Dividing by zero. Please change and resave the formula, or change the source of the divisor.") {
            return Observable.concat(
              Observable.of({ type: SHOW_ERROR, errorText: errorText }),
              Observable.of({ type: FETCH_CALCULATIONS }),
            )
          } else
            return Observable.of({ type: SHOW_ERROR, errorText })
        })
    })
}

export function saveCalculationNameEpic(action$, store) {
  return action$.ofType(UPDATE_CALCULATION)
    .mergeMap((action) => {
      const { measureId } = store.getState().measurePage;
      const { currentCalculation, calculations } = store.getState().calculations;
      const changedCalculation = calculations.find(calc => calc.id === currentCalculation.id);

      const payload = {
        measure_id: measureId,
        name: changedCalculation.name
      };
      return Observable.of({ type: UPDATE_CALCULATION_DATA, payload })
    })
}

export function addMeasureCalculation(action$, store) {
  return action$.ofType(ADD_CALCULATION)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { measureId } = store.getState().measurePage;
      const { calculations } = store.getState().calculations;
      let name = getDefaultName(calculations);

      const payload = { measure_id: measureId, name };
      return Observable.fromPromise(post(`/api/calculations`, payload, token))
        .flatMap(({ data }) => {
          const calculation = data.calculation;
          return Observable.concat(
            Observable.of({ type: ADD_CALCULATION_SUCCESS, payload: { calculation } })
          )
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.of({ type: SHOW_ERROR, errorText })
        })
    });

    function getDefaultName(calculations) {
      const name =
        calculations.length > 0
          ? `unnamed calculation ${calculations.length+1}`
          : "unnamed calculation";

      return name;
    }
}

export function addPreexistingCalculationVariableEpic(action$, store) {
  return action$.ofType(ADD_PREEXISTING_CALCULATION_VARIABLE)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      let payload = {
        calculation_id: action.payload.calculation_id
      };

      return Observable.fromPromise(post("/api/calculation_variables", action.payload, token))
        .flatMap(({ data }) => {
          const calculationVariable = data.calculation_variable;
          payload = {
            ...action.payload,
            calculation_id: action.payload.preexisting_calculation_id,
            calculation_variable_id: calculationVariable.id
          };

          return Observable.concat(
            Observable.of({ type: ADD_CALCULATION_VARIABLES_SUCCESS, calculationVariable }),
            Observable.of({ type: SAVE_CALCULATIONS_DATA_SOURCE, payload }),
            Observable.of({ type: HIDE_SELECT_VARIABLE_TYPE_MODAL })
          )
        })
        .catch(errors => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.of({ type: SHOW_ERROR, errorText });
        });
    });
}

export function saveCalculationDataSourceSuccessEpic(action$, store) {
  return action$.ofType(SAVE_CALCULATIONS_DATA_SOURCE_SUCCESS)
    .mergeMap((action) => Observable.of({ type: FETCH_CALCULATIONS }))
}

export function fetchCalculationAfterChangeCurrentCalculationEpic(action$, store) {
  return action$.ofType(SET_CURRENT_CALCULATION)
    .mergeMap((action) => Observable.of({ type: FETCH_CALCULATIONS }))
}

export function createCalculationVariableEpic(action$, store) {
  return action$.ofType(CREATE_CALCULATION_VARIABLE)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { currentCalculation } = store.getState().calculations;

      return Observable.fromPromise(post("/api/calculation_variables", { calculation_id: currentCalculation.id }, token))
        .flatMap(({ data }) => {
          const calculationVariable = data.calculation_variable;

          return Observable.concat(
            Observable.of({ type: SET_VARIABLE, payload: { name: action.variableName }, id: calculationVariable.id })
          )
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.of({ type: SHOW_ERROR, errorText })
        })
    })
}

export function saveSnapshotComment(action$){
  return action$.ofType(SAVE_SNAPSHOT_COMMENT)
    .mergeMap((action) => {
      const token = localStorage.getItem("token");
      return Observable.fromPromise(
        put(`/api/calculations_snapshots/${action.snapshotId}`,{"calculation_snapshot_id": action.snapshotId, "comment": action.comment}, token)
      ).flatMap(()=>{
        return Observable.concat(
          Observable.of({ type: SAVE_SNAPSHOT_COMMENT_SUCCESS})
        );
      }).catch((errors) => {
        return Observable.of({ type: SAVE_SNAPSHOT_COMMENT, errors });
      })
    })
}

export function editCalculationVariableEpic(action$, store) {
  return action$.ofType(EDIT_CALCULATION_VARIABLE).mergeMap(action => {
    const calculationVariable = store.getState().calculations.activeVariable;

    return Observable.concat(
      
      has(calculationVariable, 'array_calculation.array_calculation_variables')
        ? Observable.of({type: SELECT_ARRAY_CALCULATION})
        : Observable.of({type: SELECT_STANDARD_CALCULATION }),

      Observable.of({type: CHANGE_ACTIVE_VARIABLE, calculationVariable}),
      Observable.of({ type: UPDATE_CURRENT_DATA_SOURCE }),
      Observable.of({ type: SHOW_ONE_SOURCE_MODAL }),
      Observable.of({ type: HIDE_SELECT_VARIABLE_TYPE_MODAL })
    );
  });
}

export function editedVariableSaveEpic(action$, store) {
  return action$.ofType(EDITED_VARIABLE_SAVE)
    .mergeMap(action => {
      return Observable.concat(
        Observable.of({ type: SAVE_DATA_SOURCE }),
        Observable.of({ type: SAVE_FINAL_SOURCE }),
        Observable.of({ type: SHOW_GOOD_JOB }),
        Observable.of({ type: MARK_AS_RECHANGED_HEADER, status: false }),
      )
    })
}

export function setCalculationVariableEpic(action$, store) {
  return action$.ofType(SET_CALCULATION_VARIABLE)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      return Observable.fromPromise(put(`/api/calculation_variables/${action.id}`, action.payload, token))
        .flatMap(({ calculationVariable }) => {
          return Observable.concat(
            Observable.of({ type: CHANGE_ACTIVE_VARIABLE, calculationVariable }),
            Observable.of({ type: SET_VARIABLE_SUCCESS, calculationVariable }),
            Observable.of({ type: FETCH_CALCULATIONS }),
            Observable.of({ type: HIDE_SELECT_PREEXISTING_CALCULATION_MODAL })
          )
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.of({ type: SHOW_ERROR, errorText })
        })
    })
}

export function getTransformationValuesEpic(action$, store) {
  return action$.ofType(GET_TRANSFORMATION_VALUES)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { activeVariable } = store.getState().calculations;
      const { currentDataSource, allowGetTransformation } = store.getState().dataSource;
      const payload = {
        column_id: currentDataSource.columnId,
        question_id: currentDataSource.questionId,
        form_id: currentDataSource.formId,
        upload_id: currentDataSource.uploadId,
        workspace_id: currentDataSource.workspaceId,
        type: currentDataSource.sourceName
      };

      if (
        isSourceDeleted(activeVariable) &&
        !allowGetTransformation
      )
        return Observable.empty();

      return Observable.fromPromise(post(`/api/get-transformation-values`, payload, token))
        .flatMap(({ payload }) => {
          return Observable.concat(
            Observable.of({ type: SAVE_HEADER_TRANSFORMATION, payload }),
            Observable.of({ type: FETCH_FINAL_SOURCE_DATA, headerId: currentDataSource.headerId }),
            Observable.of({ type: FETCH_CALCULATIONS })
          )
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.of({ type: SHOW_ERROR, errorText })
        })
    })
}

export function setVariableName(action$, store) {
  return action$.ofType(SET_VARIABLE_NAME)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      return Observable.fromPromise(put(`/api/calculation_variables/${action.id}`, action.payload, token))
        .flatMap(({ calculationVariable }) => {
          return Observable.concat(
            Observable.of({ type: SET_VARIABLE_SUCCESS, calculationVariable }),
            Observable.of({ type: FETCH_CALCULATIONS })
        )
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.of({ type: SHOW_ERROR, errorText })
        })
    })
  })
}

function getCalculationVariablePromises(calculationVariable, store){
  const { currentDataSource, isHeaderReChanged, allowGetDatasources } = store.getState().dataSource;
  const sourceName = currentDataSource.sourceName;
  const dataSourceTypeId = typeSources[sourceName];
  const datasourceType = {
    id: dataSourceTypeId,
    name: sourceName
  };
  return Observable.concat(
    Observable.of({ type: CHANGE_ACTIVE_VARIABLE, calculationVariable }),
    Observable.of({
      type: SAVE_DATA_SOURCE_SUCCESS,
      payload: { datasource: currentDataSource, sourceName, datasourceType }
    }),
    !!sourceName && !isHeaderReChanged ?
      Observable.concat(
        calculationVariable.source_statuses.includes(DELETED) && !allowGetDatasources ?
          Observable.empty()
          :
          Observable.of({ type: SAVE_DATA_SOURCE })
      )
      :
      Observable.of({ type: GET_TRANSFORMATION_VALUES })
  )
}

export function setVariableEpic(action$, store) {
  return action$.ofType(SET_VARIABLE)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');

      if (localStorage.getItem('permission') === READ){
          return Observable.fromPromise(get(`/api/calculation_variables/${action.id}`, token))
          .flatMap(({ calculationVariable }) => {
            return getCalculationVariablePromises(calculationVariable, store);
          })
          .catch((errors) => {
            const errorText = errors.message ? errors.message : errors.error;
            return Observable.of({ type: SHOW_ERROR, errorText })
          })
      } else {
        return Observable.fromPromise(put(`/api/calculation_variables/${action.id}`, action.payload, token))
        .flatMap(({ calculationVariable }) => {
          return getCalculationVariablePromises(calculationVariable, store);
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.of({ type: SHOW_ERROR, errorText })
        })
      }

    })
}

function deleteVariableEpic(action$, store) {
  return action$.ofType(DELETE_VARIABLE)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      return Observable.fromPromise(remove(`/api/calculation_variables/${action.variableId}`, token))
        .flatMap(() => {
          return Observable.concat(
            Observable.of({ type: DELETE_VARIABLE_SUCCESS }),
            Observable.of({ type: FETCH_CALCULATIONS })
          )
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.of({ type: SHOW_ERROR, errorText })
        })
    })
}


export function deleteCalculationEpic(action$, store) {
  return action$.ofType(DELETE_CALCULATION)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const deletedCalculationId = action.calcId;
      return Observable.fromPromise(remove(`/api/calculations/${action.calcId}`, token))
        .flatMap(() => {
          return Observable.of({ type: DELETE_CALCULATION_SUCCESS, deletedCalculationId })
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.of({ type: SHOW_ERROR, errorText })
        })
    })
}

export function setPreexistingCalculationsEpic(action$, store) {
  return action$.ofType(SHOW_SELECT_VARIABLE_TYPE_MODAL, SHOW_SELECT_PREEXISTING_CALCULATION_MODAL)
    .mergeMap((action) => Observable.of({ type: SET_PREEXISTING_CALCULATIONS }))
}

export function createArrayCalculationEpic(action$, store) {
  return action$.ofType(CREATE_ARRAY_CALCULATION)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { activeVariable } = store.getState().calculations;
      const payload = {
        ...action.payload,
        calculation_variable_id: activeVariable.id
      }
      return Observable.fromPromise(post("/api/array_calculations", payload, token))
        .flatMap(({ data }) => {
          return Observable.concat(
            Observable.of({ type: FETCH_CALCULATIONS, payload: { name: action.variableName }, id: activeVariable.id })
          )
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.of({ type: SHOW_ERROR, errorText })
        })
    });
}

export function createArrayCalculationVariableEpic(action$, store) {
  return action$.ofType(CREATE_ARRAY_CALCULATION_VARIABLE)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { activeVariable } = store.getState().calculations;
      if(!has(activeVariable, 'array_calculation')) {
        return Observable.of({ type: SHOW_ERROR, errorText: 'Array calculation not found' });
      }
      const payload = {
        array_calculation_id: activeVariable.array_calculation.id
      }

      return Observable.fromPromise(post("/api/array_calculation_variables", payload, token))
        .flatMap(({ data }) => {
          return Observable.concat(
            Observable.of({ type: FETCH_CALCULATIONS, payload: { name: action.variableName }, id: activeVariable.id })
          )
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.of({ type: SHOW_ERROR, errorText })
        })
    });
}

export function deleteArrayCalculationVariableEpic(action$, store) {
  return action$.ofType(DELETE_ARRAY_CALCUATION_VARIABLE)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { activeVariable } = store.getState().calculations;
      // dont let them delete the last variable
      if(has(activeVariable, 'array_calculation.array_calculation_variables')) {
        if(activeVariable.array_calculation.array_calculation_variables.length === 1) {
          return Observable.of({ type: SHOW_ERROR, errorText: "Array calculation must have atleast one variable" });
        }
      }

      return Observable.fromPromise(remove(`/api/array_calculation_variables/${action.id}`, token))
        .flatMap((resp) => {
          return Observable.concat(
            Observable.of({ type: FETCH_CALCULATIONS, payload: {}, id: activeVariable.id })
          )
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.of({ type: SHOW_ERROR, errorText })
        })
    });
}

export function updateArrayCalculationVariableEpic(action$, store) {
  return action$.ofType(UPDATE_ARRAY_CALCULATION_VARIABLE)
    .switchMap((action) => {
      const token = localStorage.getItem('token');
      const { columnId, symbol } = action.payload;

      const {
        dataSource: { currentDataSource },
        calculations: { activeVariable }
      } = store.getState();
  
      const sourceName = currentDataSource.sourceName;
      const dataSourceTypeId = typeSources[sourceName];
      const payload = {
        // dont send calculation_variable_id as we are not saving the datasource
        // for a calculation variable but a array calculation
        //calculation_variable_id: calculationVariableId,
        datasource_type_id: dataSourceTypeId,
        type: sourceName,
        workspace_id: parseInt(currentDataSource.workspaceId)
      };
  
      if (currentDataSource.sourceName === "Survey") {
        payload.question_id = parseInt(columnId);
        payload.form_id = parseInt(currentDataSource.formId);
      } else {
        payload.column_id = parseInt(columnId);
        payload.upload_id = parseInt(currentDataSource.uploadId);
      }
      
      return Observable.fromPromise(post("/api/datasources", payload, token))
        .mergeMap(({datasource}) => {
          return Observable.of({
            type: SAVE_DATA_SOURCE_SUCCESS,
            payload: { datasource, sourceName, datasourceType: {
              id: dataSourceTypeId,
              name: sourceName
            }, calculationVariable: activeVariable, symbol}
          })
        })
        .catch(errors => {
          const errorText = errors.message;
          return Observable.of({ type: SHOW_ERROR, errorText });
        });
    }, (action, createDatasourceResponse) => ([createDatasourceResponse, action]))
    .switchMap(([createDatasourceResponse, action]) => {
      const token = localStorage.getItem('token');
      const { calculationVariable, datasource, datasourceType, symbol} = createDatasourceResponse.payload;
      return Observable.fromPromise(patch(`/api/array_calculation_variables/${action.id}`, {
        datasource_object_id: datasource.id,
        datasource_type_id: datasourceType.id
      }, token))
        .flatMap((resp) => {
          return Observable.concat(
            Observable.of({ type: FETCH_CALCULATIONS, payload: {}, id: calculationVariable.id })
          )
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.of({ type: SHOW_ERROR, errorText })
        })
    });
}

export function updateArrayCalculationEpic(action$, state) {
  return action$.ofType(UPDATE_ARRAY_CALCULATION)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { latex, string } = action.payload;
      const {
        conditionPanel: { conditions }
      } = state.getState();
      const payload = {
        latex,
        string,
        conditions,
      };

      return Observable.fromPromise(patch(`/api/array_calculations/${action.id}`, payload, token))
        .flatMap((resp) => {
          const { arrayCalculation } = resp;
          return Observable.concat(
            //Observable.of({type: UPDATE_ARRAY_CALCULATION_SUCCESS, arrayCalculation}),
            Observable.of({type: FETCH_CALCULATIONS })
          );
          //return Observable.of({type: UPDATE_ARRAY_CALCULATION_SUCCESS, arrayCalculation});
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.concat(
            Observable.of({ type: SHOW_ERROR, errorText }),
            Observable.of({ type: UPDATE_ARRAY_CALCULATION_FAILURE, payload }),
          );
        });
    });
}

export function updateArrayCalculationVariableSymbolEpic(action$, state) {
  return action$.ofType(UPDATE_ARRAY_CALCULATION_VARIABLE_SYMBOL)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { symbol } = action.payload;

      const payload = {
        symbol
      };

      return Observable.fromPromise(patch(`/api/array_calculation_variables/${action.id}`, payload, token))
        .flatMap((resp) => {
          return Observable.concat(
            Observable.of({ type: FETCH_CALCULATIONS })
          );
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.concat(
            Observable.of({ type: SHOW_ERROR, errorText }),
          );
        });
    });
}

/**
 * 
 * This epic is used when selecting a datasource column on an array clculation variable when
 * ADDING a calculation variable, meaning at this point we dont have a calculation variable,
 * hence we need to create all dependencies in their order which is:
 * calculation_variable > datasource > array_calculation > array_calculation_variable
 */
export function createArrayCalculationVariableWithNewCalculationVariableEpic(action$, store) {

  return action$.ofType(CREATE_ARRAY_CALCULATION_VARIABLE_WITH_NEW_CALCULATION_VARIABLE)
    /** 1. Create a new calculation variable */
    .switchMap((action) => {
      const token = localStorage.getItem('token');
      const { currentCalculation } = store.getState().calculations;
      const { columnName } = action.payload;

      const payload = { 
        calculation_id: currentCalculation.id,
        use_array_calculation: true,
        name: columnName,
      };
      
      return Observable.fromPromise(post("/api/calculation_variables", payload, token))
        .mergeMap(({ data }) => {
          const calculationVariable = data.calculation_variable;
          return  Observable.merge(
            Observable.of({ type: CHANGE_ACTIVE_VARIABLE, calculationVariable })
          );
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.of({ type: SHOW_ERROR, errorText })
        })
    }, (action, createVariableResponse) => ([createVariableResponse, action]))
    /** 2. Create a new datasource for the new calculation variable */
    .switchMap(([createVariableResponse, action]) => {
      const token = localStorage.getItem('token');
      const calculationVariableId = createVariableResponse.calculationVariable.id;
      const { columnId, symbol } = action.payload;
      const {
        dataSource: { currentDataSource }
      } = store.getState();
  
      const sourceName = currentDataSource.sourceName;
      const dataSourceTypeId = typeSources[sourceName];
      const payload = {
        // dont send calculation_variable_id as we are not saving the datasource
        // for a calculation variable but a array calculation
        //calculation_variable_id: calculationVariableId,
        datasource_type_id: dataSourceTypeId,
        type: sourceName,
        workspace_id: parseInt(currentDataSource.workspaceId)
      };
  
      if (currentDataSource.sourceName === "Survey") {
        payload.question_id = parseInt(columnId);
        payload.form_id = parseInt(currentDataSource.formId);
      } else {
        payload.column_id = parseInt(columnId);
        payload.upload_id = parseInt(currentDataSource.uploadId);
      }
      
      return Observable.fromPromise(post("/api/datasources", payload, token))
        .mergeMap(({datasource}) => {
          return Observable.of({
            type: SAVE_DATA_SOURCE_SUCCESS,
            payload: { datasource, sourceName, datasourceType: {
              id: dataSourceTypeId,
              name: sourceName
            }, calculationVariable: createVariableResponse.calculationVariable, symbol}
          })
        })
        .catch(errors => {
          const errorText = errors.message;
          return Observable.of({ type: SHOW_ERROR, errorText });
        });
    }, (action, createDatasourceResponse) => ([createDatasourceResponse, action]))
    /** Create array calculation */
    .switchMap(([createDatasourceResponse, action]) => {
      const token = localStorage.getItem('token');
      const { calculationVariable, datasource, datasourceType, symbol} = createDatasourceResponse.payload;
      const payload = {
        calculation_variable_id: calculationVariable.id
      }
      return Observable.fromPromise(post(`/api/array_calculations`, payload, token))
        .mergeMap(({data, message}) => {
          return Observable.of({
            type: CREATE_ARRAY_CALCULATION_SUCCESS,
            payload: {
              array_calculation: data.array_calculation,
              datasource,
              datasourceType,
              symbol,
              calculationVariable
            }
          })
        }).catch(errors => {
          const errorText = errors.message;
          return Observable.of({ type: SHOW_ERROR, errorText });
        });
    }, (action, arrayCalculationResponse) => ([arrayCalculationResponse, action]))
    /** Create the array calculation variable */
    .switchMap(([arrayCalculationResponse, action]) => {
      const token = localStorage.getItem('token');
      const {array_calculation, datasource, datasourceType, symbol, calculationVariable} = arrayCalculationResponse.payload;

      const payload = {
        array_calculation_id: array_calculation.id,
        datasource_object_id: datasource.id,
        datasource_type_id: datasourceType.id,
        symbol
      }

      return Observable.fromPromise(post(`/api/array_calculation_variables`, payload, token))
        .mergeMap((response) => {
          return Observable.concat(
            Observable.of({ type: CHANGE_ACTIVE_VARIABLE, calculationVariable }),
            Observable.timer(1000).flatMap(() =>
              Observable.of({ type: FETCH_CALCULATIONS })
            )
          );
        }).catch(errors => {
          const errorText = errors.message;
          return Observable.of({ type: SHOW_ERROR, errorText });
        });
    });
}

export function selectStandardSourceForCalculationVariableEpic(action$, state) {
  return action$.ofType(SELECT_STANDARD_CALCULATION)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { activeVariable } = state.getState().calculations;
      
      if((!activeVariable)) {
        return Observable.empty();
      }

      if(!activeVariable.use_array_calculation) {
        return Observable.empty();
      }

      const payload = {
        use_array_calculation: false
      };

      return Observable.fromPromise(patch(`/api/calculation_variables/${activeVariable.id}`, payload, token))
        .flatMap((resp) => {
          return Observable.concat(
            Observable.of({ type: FETCH_CALCULATIONS })
          );
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.concat(
            Observable.of({ type: SHOW_ERROR, errorText }),
          );
        });
    });
}

export function selectArrayCalculationForCalculationVariableEpic(action$, state) {
  return action$.ofType(SELECT_ARRAY_CALCULATION)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { activeVariable } = state.getState().calculations;
      
      if((!activeVariable)) {
        return Observable.empty();
      }
      
      if(activeVariable.use_array_calculation) {
        return Observable.empty();
      }

      if(!has(activeVariable, 'id')) {
        return Observable.empty();
      }

      const payload = {
        use_array_calculation: true
      };

      return Observable.fromPromise(patch(`/api/calculation_variables/${activeVariable.id}`, payload, token))
        .flatMap((resp) => {
          return Observable.concat(
            Observable.of({ type: FETCH_CALCULATIONS })
          );
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.concat(
            Observable.of({ type: SHOW_ERROR, errorText }),
          );
        });
    });
}

// save conditions and latex/string of array calculation 
// and close modal
export function saveArrayCalculationFinalEpic(action$, state) {
  return action$.ofType(SAVE_ARRAY_CALCULATION_FINAL)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { 
        conditionPanel: { conditions },
        calculations: { activeVariable }
      } = state.getState();

      if(isArrayCalculationReady(activeVariable) !== COMPLETE) {
        return Observable.concat(
          Observable.of({ type: HIDE_ONE_SOURCE_MODAL }),
          Observable.of({ type: SHOW_GOOD_JOB }),
        );
      }
      const { array_calculation: { latex, string, id } } = activeVariable;

      const payload = {
        latex,
        string,
        conditions,
      }

      return Observable.fromPromise(patch(`/api/array_calculations/${id}`, payload, token))
        .flatMap((resp) => {
          return Observable.concat(
            Observable.of({ type: HIDE_ONE_SOURCE_MODAL }),
            Observable.of({ type: SHOW_GOOD_JOB }),
          );
        })
        .catch((errors) => {
          const errorText = errors.message ? errors.message : errors.error;
          return Observable.concat(
            Observable.of({ type: SHOW_ERROR, errorText }),
          );
        });
    });
}

export default [
  getMeasureCalculationsEpic,
  saveCalculationNameEpic,
  addMeasureCalculation,
  addPreexistingCalculationVariableEpic,
  saveCalculationDataSourceSuccessEpic,
  editCalculationVariableEpic,
  setVariableEpic,
  setCalculationVariableEpic,
  setVariableName,
  deleteVariableEpic,
  deleteCalculationEpic,
  changeHierarchyCalculationEpic,
  updateCalculationEpic,
  saveCalculationFormulaEpic,
  setPreexistingCalculationsEpic,

  createCalculationVariableEpic,
  getTransformationValuesEpic,
  editedVariableSaveEpic,

  fetchCalculationAfterChangeCurrentCalculationEpic,

  createArrayCalculationEpic,
  createArrayCalculationVariableEpic,
  createArrayCalculationVariableWithNewCalculationVariableEpic,
  updateArrayCalculationEpic,
  deleteArrayCalculationVariableEpic,
  updateArrayCalculationVariableEpic,
  updateArrayCalculationVariableSymbolEpic,
  selectArrayCalculationForCalculationVariableEpic,
  selectStandardSourceForCalculationVariableEpic,
  saveArrayCalculationFinalEpic,
  saveSnapshotComment,
];