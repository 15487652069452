import React from "react";
import PropTypes from "prop-types";
import { UIDropdownMenu, UIDropdownMenuItem } from "./UIDropdownMenu";
import { InjectIntl } from "./InjectIntl";

const ActionsDropDown = ({
  actions,
  intl,
  caretClassName,
  btnClassName,
  styleForLink
}) => (
  <UIDropdownMenu
    caretClassName={caretClassName}
    btnClassName={btnClassName}
    styleForLink={styleForLink}
  >
    {actions.map(action => (
      <UIDropdownMenuItem
        key={action.title}
        disabled={action.disabled}
        onClick={action.onClick}
        data-for={action.dataFor}
        data-tip={action.dataTip}
      >
        {intl(action.title)}
      </UIDropdownMenuItem>
    ))}
  </UIDropdownMenu>
);

ActionsDropDown.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
      dataTip: PropTypes.string,
      dataFor: PropTypes.string
    })
  ),
  intl: PropTypes.func.isRequired,
  caretClassName: PropTypes.string,
  btnClassName: PropTypes.string,
  styleForLink: PropTypes.string
};

ActionsDropDown.defaultProps = {
  caretClassName: "caret",
  btnClassName: "btn btn-default btn-xs"
};

export default InjectIntl(ActionsDropDown);
