import React from 'react';
import PropTypes from 'prop-types';
import NumericWidget from './NumericWidget';

const Dashboard = ({ sample_size, total_input, total_impact, sroi }) => {
  return (
    <div className="row meta-section">

      <div className="col-md-12 sample_size">
        <div className="row">
          <div className="col-md-4 col-md-offset-4">
            <NumericWidget 
              value={sample_size} 
              label="Sample size" 
              money={false} 
              large={true} 
            />
          </div>
        </div>
      </div>

      <div className="col-md-12 impact_row">
        <div className="row">
          <div className="col-md-4">
            <NumericWidget 
              value={total_input} 
              label="Input" 
              money={true} 
              large={true} 
            />
          </div>
          <div className="col-md-4">
            <NumericWidget 
              value={total_impact} 
              label="Impact" 
              money={true} 
              large={true} 
            />
          </div>
          <div className="col-md-4 sroi">
            <NumericWidget 
              value={sroi} 
              label="ROI" 
              money={true} 
              large={true} 
            />
          </div>
        </div>
      </div>
      
    </div>
  );
}

Dashboard.propTypes = {
  sample_size: PropTypes.number.isRequired,
  total_input: PropTypes.number.isRequired,
  total_impact: PropTypes.number.isRequired,
  sroi: PropTypes.number.isRequired
}

export default Dashboard;