import React from 'react';
import chunk from 'lodash/chunk';
import merge from 'xtend';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import chartOptions from './common/chartOptions';
import {getColor} from './common/chartColors';

const Demographics = ({demographics}) => {

  const renderChartTitle = (name) => {
    return (
      <div className="col-md-12 measure_title">{name}</div>
    );
  }

  const renderPieChart = (demographic) => {
    let chartData = demographic.data.map((dataPoint, i) => ({
      x: i+1,
      y: dataPoint.value,
      name: dataPoint.name,
      color: getColor(i)
    }));
    let nextChartOptions = merge(chartOptions, {
      series: [{
        name: demographic.name,
        showInLegend: false,
        data: chartData
      }],
      // title: merge(chartOptions.title, {text: demographic.name}),
      chart: {
        type: 'pie',
        height: '250px',
      },
      lang: {
          noData: "No responses received"
      },
      plotOptions: {
        pie: {
          size: "100%",
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: false,
          }
        }
      }
    });
    return (
      <div key={demographic.id} className="col-md-6">
        <div className="row measures_chart_wrapper">
          {renderChartTitle(demographic.name)}
          <div className="col-md-6">
            <HighchartsReact
              highcharts={Highcharts}
              options={nextChartOptions}
            />
          </div>
          <div className="col-md-6">
            <ul className="measures_legend_list">
              {demographic.data.map((dataPoint, i) => {
                let color = getColor(i);
                let iStyle = {
                  color: color
                }
                return (
                  <li className="measures_legend_list_item" key={i}>
                    <i style={iStyle} className="fa fa-square" aria-hidden="true"></i> {`${i+1} - ${dataPoint.name}`}
                    <span className="pull-right"><strong>{dataPoint.value}</strong></span>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  const renderColumnChart = (demographic) => {
    let chartData = demographic.data.map((dataPoint, i) => ({
      x: i+1,
      y: dataPoint.value,
      name: dataPoint.name,
      color: getColor(i)
    }));
    let nextChartOptions = merge(chartOptions, {
      lang: {
          noData: "No responses received"
      },
      series: [{
        name: demographic.name,
        showInLegend: false,
        data: chartData
      }]
    });
    return (
      <div key={demographic.id} className="row measures_chart_wrapper">
        {renderChartTitle(demographic.name)}
        <div className="col-md-6">
          <HighchartsReact
            highcharts={Highcharts}
            options={nextChartOptions}
          />
        </div>
        <div className="col-md-6">
          <ul className="measures_legend_list">
            {demographic.data.map((dataPoint, i) => {
              let color = getColor(i);
              let iStyle = {
                color: color
              }
              return (
                <li className="measures_legend_list_item" key={i}>
                  <i style={iStyle} className="fa fa-square" aria-hidden="true"></i> {`${i+1} - ${dataPoint.name}`}
                  <span className="pull-right"><strong>{dataPoint.value}</strong></span>
                </li>
              )
            })}
          </ul>
        </div>
        
      </div>
    );
  }

  const renderDemographics = () => {

    const pieDemographics = demographics.filter(demographic => demographic.chartType === 'pie');

    const columnDemographics = demographics.filter(demographic => demographic.chartType === 'column');

    const chunkedPieDemographics = chunk(pieDemographics, 2);

    return (
      <div className="row measure_section_wrap demographics">
        <div className="col-md-12">
          {chunkedPieDemographics.map((chunkedPieDemographicsRow, rowIndex) => {
            return (
              <div key={rowIndex} className="row">
                {chunkedPieDemographicsRow.map(demographic => {
                  return renderPieChart(demographic);
                })}
              </div>
            );
          })}
          {columnDemographics.map((demographic) => {
            return renderColumnChart(demographic);
          })}
        </div>
      </div>
    );
  }
  return renderDemographics();
}

Demographics.propTypes = {
  demographics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      chartType: PropTypes.oneOf(['pie', 'column']).isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired
}

export default Demographics;