import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Demographics from './Demographics';
import NestedMeasures from './NestedMeasures';
import LogicModelSummary from './LogicModelSummary';
import Dashboard from './Dashboard';
import SubDashboard from './SubDashboard';
import {
  DASHBOARD,
  OUTCOMES,
  DEMOGRAPHICS,
  ACTIVITIES,
  SUMMARY
} from '../../reducers/reports';

const Results = ({ results, resultLoading, tabs, activeTab, changeTab }) => {
  
  const { inputs, activities, outcomes, demographics, summary, total_impact, sroi, sample_size } = results;

  const renderDashboard = () => {
    return (
      <Dashboard  
        sample_size={sample_size}
        total_input={inputs.total}
        total_impact={total_impact}
        sroi={sroi}
      />
    );
  }

  const renderSubDashboard = () => {
    return <SubDashboard  
        sample_size={sample_size}
        total_input={inputs.total}
        total_impact={total_impact}
        sroi={sroi}
      />;
  }

  const renderActivities = () => {
    if(activities.length === 0) {
      return renderSubDashboard();
    }

    return (
      <Fragment>
        {renderSubDashboard()}
        <NestedMeasures 
          parentMeasures={activities} 
          parentMeasureTitle='activities'
          childMeasureType='outputs'
          hasCharts={false}
        />
      </Fragment>
    );
  }

  const renderOutcomes = () => {
    if(outcomes.length === 0) {
      return renderSubDashboard();
    }

    return (
      <Fragment>
        {renderSubDashboard()}
        <NestedMeasures 
          parentMeasures={outcomes} 
          parentMeasureTitle='outcomes'
          childMeasureType='outcome_indicators'
          hasCharts={true}
        />
      </Fragment>
    );
  }

  const renderDemographics = () => {
    if(demographics.length === 0) {
      return renderSubDashboard();
    }
    return (
      <Fragment>
      {renderSubDashboard()}
      <Demographics demographics={demographics} />
      </Fragment>
    );
  }

  const renderTabsNav = () => {
    return (
      <div className="row tab_nav_wrap">
        <div className="col-md-12">
          <ul className="list-inline">
            {
              tabs.map(tab => {
                return (
                  <li 
                    key={tab}
                    className={activeTab === tab ? 'active' : ''}
                    onClick={() => changeTab(tab)}
                  >{tab}</li>
                );
              })
            }
          </ul>
        </div>
      </div>
    );
  }
  
  const renderSummary = () => {
    return (
      <Fragment>
        {renderSubDashboard()}
        <LogicModelSummary stakeholders={summary.stakeholders} />
      </Fragment>
    )
  }

  const renderResults = () => {
    
    if(activeTab === DASHBOARD) {
      return renderDashboard();
    } else if(activeTab === OUTCOMES) {
      return renderOutcomes();
    } else if(activeTab === DEMOGRAPHICS) {
      return renderDemographics();
    } else if(activeTab === ACTIVITIES) {
      return renderActivities();
    } else if(activeTab === SUMMARY) {
      return renderSummary();
    }
    return renderDashboard();
  }

  return (
    <div>
      {renderTabsNav()}
      {renderResults()}
    </div>
  );
}

Results.propTypes = {
  results: PropTypes.shape({
    inputs: PropTypes.shape({
      total: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired
    }).isRequired,
    activities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        outputs: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired
          })
        ).isRequired
      })
    ).isRequired,
    outcomes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        outcome_indicators: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired
          })
        ).isRequired
      })
    ).isRequired,
    demographics: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        chartType: PropTypes.oneOf(['pie', 'column']).isRequired,
        data: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired
          })
        ).isRequired
      })
    ).isRequired,
    summary: PropTypes.shape({
      stakeholders: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          inputs: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              name: PropTypes.string.isRequired,
            })
          ).isRequired,
          outcomes: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              name: PropTypes.string.isRequired,
              outcome_indicators: PropTypes.arrayOf(
                PropTypes.shape({
                  id: PropTypes.number.isRequired,
                  name: PropTypes.string.isRequired,
                })
              )
            })
          ).isRequired,
          activities: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              name: PropTypes.string.isRequired,
              outputs: PropTypes.arrayOf(
                PropTypes.shape({
                  id: PropTypes.number.isRequired,
                  name: PropTypes.string.isRequired,
                })
              )
            })
          ).isRequired
        })
      ).isRequired
    }).isRequired,
    total_impact: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    sroi: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    sample_size: PropTypes.number.isRequired
  }).isRequired,
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired
}

export default Results;