import { Observable } from "rxjs/Observable";
import { post } from "../../api";
import 'rxjs';
import { ADD_OUTCOMES_MEASURE, ADD_OUTCOMES_MEASURE_SUCCESS, ADD_OUTCOMES_MEASURE_FAILURE } from "../../actions/stakeholder-items/outcomes";
import { FETCH_LOGIC_MODEL } from "../../actions/measure/measure";

export function addOutcomesMeasureEpic(action$, store) {
  return action$.ofType(ADD_OUTCOMES_MEASURE)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { logicModelId, stakeholderId } = store.getState().measurePage;
      const OUTCOME_MEASURE_ID = 5;
      const payload = {name: "Unnamed Outcome", measure_type_id: OUTCOME_MEASURE_ID, stakeholder_id: stakeholderId};
      const id = logicModelId;

      return Observable.fromPromise(post(`/api/measures`, payload, token))
        .flatMap(({ data }) => {
          const measure = data.measure;
          const logic_model = data.logic_model;

          return Observable.concat(
            Observable.of({type: ADD_OUTCOMES_MEASURE_SUCCESS, payload: { logic_model, measure }}),
            Observable.of({type: FETCH_LOGIC_MODEL, id })
          )
        })
        .catch(errors => Observable.of({type: ADD_OUTCOMES_MEASURE_FAILURE, payload: { errors }}))
    })
}

export default [addOutcomesMeasureEpic]