import merge from 'xtend';
import createReducer from './create-reducer';

import {SHOW_GOOD_JOB, HIDE_GOOD_JOB} from "../actions/good-job-alert";


const INITIAL_STATE = {
  showGoodJob: false
};

export default createReducer({
  [SHOW_GOOD_JOB]: (state, action) => merge(state, { showGoodJob: true }),
  [HIDE_GOOD_JOB]: (state, action) => merge(state, { showGoodJob: false })
}, INITIAL_STATE)