import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ErrorsActions from "../actions/errors";
import React from 'react';
import PropTypes from 'prop-types';
import swal from "sweetalert";
import BrowserDetect from "../assets/sam-utils";


const ErrorNotification = (props) => {
  const { showErrors, errors, actions, } = props;

  const renderErrorsAlert = () => {

    swal({
      title: "Uh oh",
      icon: 'error',
      text: typeof(errors)==="object" ? errors.message : errors,
      closeOnClickOutside: false,
      buttons: {
        confirm: {
          visible: true,
          className: "verification-button-submit"
        }
      }
    }).then((result) => {
      if (result) {
        actions.clearErrors();
        swal.close();
        if (BrowserDetect.browser === 'Explorer' && BrowserDetect.version < 11)
          document.body.removeChild(document.getElementsByClassName('swal-overlay')[0])
      }
    })
  };

  return (
    <div>
      {showErrors &&
      <div>
        {renderErrorsAlert()}
      </div>
      }
    </div>
  );
};

ErrorNotification.propTypes = {
  errors: PropTypes.string.isRequired,
  showErrors: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    clearErrors: PropTypes.func.isRequired
  })
};

function mapStateToProps(state) {
  return state.errorsNotification
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ErrorsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorNotification)