import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import manageMeasureActions from "../../../../actions/measure/manage-measure";
import measureActions from "../../../../actions/measure/measure";
import demographicsActions from "../../../../actions/stakeholder-items/demographics";
import Demographics from "../../../../components/logic-models/stakeholder-items/add-demographics/Demographics";

function maopStateToProps(state) {
  return {
    ...state.measurePage,
    ...state.demographics,
    ...state.manageMeasure
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...manageMeasureActions,
      ...measureActions,
      ...demographicsActions
    }, dispatch)
  }
}

export default connect(maopStateToProps, mapDispatchToProps)(Demographics)