import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import HomePage from "../components/HomePage";
import LogicModelsActions from '../actions/logic-models';

function mapStateToProps(state) {
  return state.logicModels
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(LogicModelsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)