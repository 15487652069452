
export const FETCH_COLLABORATORS = '@@MENAGE_COLLABORATOR/FETCH_COLLABORATORS';
export const FETCH_COLLABORATORS_SUCCESS = '@@MENAGE_COLLABORATOR/FETCH_COLLABORATORS_SUCCESS';
export const FETCH_COLLABORATORS_FAILURE = '@@MENAGE_COLLABORATOR/FETCH_COLLABORATORS_FAILURE';

export const ADD_USER_TO_LOGIC_MODEL = '@@MENAGE_COLLABORATOR/ADD_USER_TO_LOGIC_MODEL';
export const ADD_USER_TO_LOGIC_MODEL_SUCCESS = '@@MENAGE_COLLABORATOR/ADD_USER_TO_LOGIC_MODEL_SUCCESS';
export const ADD_USER_TO_LOGIC_MODEL_FAILURE = '@@MENAGE_COLLABORATOR/ADD_USER_TO_LOGIC_MODEL_FAILURE';
export const DELETE_USER_FROM_LOGIC_MODEL = '@@MENAGE_COLLABORATOR/DELETE_USER_FROM_LOGIC_MODEL';
export const DELETE_USER_FROM_LOGIC_MODEL_SUCCESS = '@@MENAGE_COLLABORATOR/DELETE_USER_FROM_LOGIC_MODEL_SUCCESS';
export const DELETE_USER_FROM_LOGIC_MODEL_FAILURE = '@@MENAGE_COLLABORATOR/DELETE_USER_FROM_LOGIC_MODEL_FAILURE';

export const UPDATE_COLLABORATING_USER_PERMISSION = '@@MENAGE_COLLABORATOR/UPDATE_COLLABORATING_USER_PERMISSION';

function addToLogicModel(token) {
  return { type: ADD_USER_TO_LOGIC_MODEL, token }
}

function removeUser(userId) {
  return { type: DELETE_USER_FROM_LOGIC_MODEL, userId }
}

function updateUserPermission(userId, permission) {
  return { type: UPDATE_COLLABORATING_USER_PERMISSION, userId, permission }
}

function fetchMembers() {
	return { type: FETCH_COLLABORATORS }
}

export default {
  updateUserPermission,
  fetchMembers,
  addToLogicModel,
  removeUser
}