import * as React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { IntlProvider } from 'react-intl-redux'
import routes from '../routes';

export default (props) => (
  <Provider store={props.store}>
    <IntlProvider>
      <Router history={props.history} routes={routes} />
    </IntlProvider>
  </Provider>
)