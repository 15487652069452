import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CalculationsActions from "../../actions/calculation/calculation-page";
import FormulaEditor from "../../components/calculations/FormulaEditor";


function mapStateToProps(state, ownProps) {
  return {
    ...state.calculations,
    intl: state.intl
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...CalculationsActions,
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormulaEditor)
