import merge from "xtend";
import createReducer from "../create-reducer";
import {
  DELETE_MEASURE,
  SHOW_MEASURE,
  OPEN_MEASURE_MODAL,
  CLOSE_MEASURE_MODAL,
  SUBMIT_COPY_MEASURE_SUCCESS,
  SUBMIT_COPY_MEASURE_FAILURE,
  COPY_MEASURES,
  SET_OUTCOME_FRAMEWORK_MODAL
} from "../../actions/measure/manage-measure";


const INITIAL_STATE = {
  isModalOpen: false,
  measure_id: null,
  measure_title: null,
  deleteMeasureId: null,
  errors: {},
  collapsed: false,
  collapsedMeasures: [],
  copyLogicModel: {},
  isOutcomeSettingsModalOpen: false
};

function collapsedHandler(collapsedMeasures, status, measureId) {
  if (collapsedMeasures.includes(measureId)) {
    const deleteIndex = collapsedMeasures.indexOf(measureId);
    collapsedMeasures.splice(deleteIndex, 1);
  } else {
    collapsedMeasures.push(measureId);
  }
  return collapsedMeasures;
}
export default createReducer(
  {
    [DELETE_MEASURE]: (state, action) =>
      merge(state, {
        deleteMeasureId: action.measureId,
      }),
    [SHOW_MEASURE]: (state, action) =>
      merge(state, {
        collapsed: action.status,
        collapsedMeasures: collapsedHandler(
          state.collapsedMeasures,
          action.status,
          action.measureId
        ),
      }),
    [OPEN_MEASURE_MODAL]: (state, action) =>
      merge(state, {
        isModalOpen: true,
        measure_id: action.data.measureId,
        isNotIndicator: action.data.isNotIndicator,
      }),
    [CLOSE_MEASURE_MODAL]: (state, action) =>
      merge(state, {
        isModalOpen: false,
        errors: {},
      }),
    [SUBMIT_COPY_MEASURE_SUCCESS]: (state) =>
      merge(state, {
        isModalOpen: false,
      }),
    [SUBMIT_COPY_MEASURE_FAILURE]: (state, action) => {
      return merge(state, {
        isModalOpen: true,
        errors: action.err,
      });
    },
    [COPY_MEASURES]: (state, action) =>
      merge(state, {
        copyLogicModel: action.data,
      }),
    [SET_OUTCOME_FRAMEWORK_MODAL]: (state, action) => merge(state, {
      isOutcomeSettingsModalOpen: action.status
    })      
  },
  INITIAL_STATE
);
