import React from "react";

class SearchField extends React.Component {
  getValue() {
    return this.refs.searchInput.value;
  }

  setValue(value) {
    this.refs.searchInput.value = value;
  }

  render() {
    const formGroupClassNames = `form-group has-feedback has-feedback-left`;
    const inputGroupClassNames = `input-group input-group-sm`;
    return (
      <div>
        <div className={formGroupClassNames}>
          <div className={inputGroupClassNames}>
            <span className="input-group-addon">
              <i className="fa fa-search" />
            </span>
            <input
              ref="searchInput"
              className={`form-control success`}
              type="text"
              defaultValue={this.props.defaultValue}
              placeholder={this.props.placeholder}
              onKeyUp={this.props.search}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SearchField;
