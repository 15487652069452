import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CalculationsActions from "../../actions/calculation/calculation-page";
import VariableItem from "../../components/calculations/variables/VariableItem";
import DataSourceActions from "../../actions/calculation/data-source";
import SelectVariableTypeActions from "../../actions/calculation/selectVariableType";
import ErrorActions from "../../actions/errors";
import modalDeleteItemActions from "../../actions/modal-delete-item";


function mapStateToProps(state, ownProps) {
  return {
    ...state.calculations,
    measureId: ownProps.measureId,
    variables: ownProps.variables,
    variableItem: ownProps.variableItem,
    ...state.dataSource
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...CalculationsActions,
      ...DataSourceActions,
      ...modalDeleteItemActions,
      ...SelectVariableTypeActions,
      ...ErrorActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VariableItem)