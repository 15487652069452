import { Observable } from "rxjs/Observable";
import { post} from "../../api";
import 'rxjs';
import { ADD_OUTPUT_MEASURE, ADD_OUTPUT_MEASURE_FAILURE, ADD_OUTPUT_MEASURE_SUCCESS } from "../../actions/stakeholder-items/output";
import { FETCH_LOGIC_MODEL } from "../../actions/measure/measure";

export function addOutputMeasureEpic(action$, store) {
  return action$.ofType(ADD_OUTPUT_MEASURE)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { logicModelId, stakeholderId, parentMeasureId } = store.getState().measurePage;
      const OUTPUT_MEASURE_ID = 4;
      const payload = {
        measure_type_id: OUTPUT_MEASURE_ID,
        name: "Unnamed Output",
        parent_id: parentMeasureId,
        stakeholder_id: stakeholderId
      };
      const id = logicModelId;

      return Observable.fromPromise(post(`/api/measures`, payload, token))
        .flatMap(({ data }) => {
          const measure = data.measure;
          const logic_model = data.logic_model;

          return Observable.concat(
            Observable.of({type: ADD_OUTPUT_MEASURE_SUCCESS, payload: { logic_model, measure }}),
            Observable.of({type: FETCH_LOGIC_MODEL, id })
          )
        })
        .catch(errors => Observable.of({type: ADD_OUTPUT_MEASURE_FAILURE, payload: { errors }}))
    })
}

export default [addOutputMeasureEpic]