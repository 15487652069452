import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import manageMeasureActions from "../../../../actions/measure/manage-measure";
import outcomeIndicatorsActions from "../../../../actions/stakeholder-items/outcomeIndicator";
import financialProxyActions from "../../../../actions/stakeholder-items/financialProxy";
import ontologyActions from "../../../../actions/ontology";
import measureActions from "../../../../actions/measure/measure";
import OutcomeIndicator from "../../../../components/logic-models/stakeholder-items/add-outcome-indicator/OutcomeIndicator";


function mapStateToProps(state, ownProps) {
  return {
    ...state.measurePage,
    ...state.dataSource,
    ...state.outcomes,
    ...state.ontology,
    ...state.outcomeIndicator,
    outcomeIndicator: ownProps.outcomeIndicator,
    stakeholder: ownProps.stakeholder,
    intl: state.intl
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...manageMeasureActions,
      ...measureActions,
      ...outcomeIndicatorsActions,
      ...financialProxyActions,
      ...ontologyActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OutcomeIndicator)