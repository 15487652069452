import React from 'react';
import PropTypes from 'prop-types';
import TopMenu from "../containers/TopMenu.container";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LoginActions from '../actions/login';


function mapStateToProps(state) {
  return state.application
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(LoginActions, dispatch)}
}

class Layout extends React.Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired
  };

  render() {
    const whiteLabels = ['rbc']
    const fullYear = new Date().getFullYear();
    const whiteLabel = whiteLabels.find(brand => window.location.href.includes(brand)) 
    return (
      <div id="wrapper" className="top-navigation">
        <div id="page-wrapper" className="gray-bg" data-brand={`${whiteLabel}`}>
          <div className="row border-bottom white-bg">
            <TopMenu/>
          </div>
          <div className="wrapper wrapper-content">
            {this.props.children}
          </div>
          <div className="footer">
            <div className="pull-left">
              <strong>Copyright</strong> SAMETRICA &copy; {fullYear}<br/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);