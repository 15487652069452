import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CalculationsActions from "../../../../actions/calculation/calculation-page";
import DataSourceActions from "../../../../actions/calculation/data-source";
import IndexModals from "../../../../components/calculations/sources/modals/IndexModals";
import SourceModalsActions from "../../../../actions/calculation/source-modals";
import SelectVariableActions from "../../../../actions/calculation/selectVariableType";
import selectCalculationType from "../../../../actions/calculation/selectCalculationType";
import ConditionsActions from "../../../../actions/calculation/conditions";
import ManageMeasureActions from "../../../../actions/measure/manage-measure";
import GoodJobSweetAlert from "../../../../actions/good-job-alert";

function mapStateToProps(state, ownProps) {
  return {
    ...state.calculations,
    ...state.dataSource,
    ...state.oneSourceFirstTab,
    ...state.oneSourceFinalTab,
    ...state.selectVariableTypeModals,
    ...state.selectCalculationType,
    conditions: state.conditionPanel.conditions,
    showCondition: state.conditionPanel.showCondition,
    transformationForCondition: state.conditionPanel.currentCondition.transformationForCondition,
    intl: state.intl,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...CalculationsActions,
      ...ManageMeasureActions,
      ...DataSourceActions,
      ...SourceModalsActions,
      ...GoodJobSweetAlert,
      ...SelectVariableActions,
      ...selectCalculationType,
      ...ConditionsActions
    }, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(IndexModals)