import React from 'react';
import PropTypes from 'prop-types';
import { InjectIntl } from "../../../common/InjectIntl";
import MeasureNameComponent from "../MeasureNameComponent";


const FinancialProxy = (props) => {

  const { actions, financialProxy, stakeholder, financialProxyMeasureNameFieldDisable, intl, parentType } = props;

  const closeHandler = () => actions.hideMeasureNameFinancialProxyField();

  const saveFinancialProxyMeasureName = (measureName) => actions.changeFinancialProxyMeasureName(measureName, financialProxy.id);

  const changeFinancialProxyName = (e) => actions.showMeasureNameFinancialProxyField();

  const adjustmentModalClickHandler = (e) => {
    e.preventDefault();
    actions.changeStakeholderId(stakeholder.id);
    actions.changeMeasure(financialProxy.id, financialProxy.name);
    actions.showAdjustments()
  };

  return (
    <MeasureNameComponent
      closeHandler={closeHandler}
      saveMeasureName={saveFinancialProxyMeasureName}
      changeMeasure={changeFinancialProxyName}
      measureNameFieldDisable={financialProxyMeasureNameFieldDisable}
      measureTypeTitle={intl('stakeholder.items.financial_proxy')}
      placeholder={intl('stakeholder.items.financial_proxy.placeholder')}
      collapseIconShow={false}
      measure={financialProxy}
      adjustmentModalClickHandler={adjustmentModalClickHandler}
      adjustment={parentType !== 'Input'}
      {...props}
    />
  );
};

FinancialProxy.propTypes = {
  financialProxy: PropTypes.object.isRequired,
  financialProxyMeasureNameFieldDisable: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  ontologySearching: PropTypes.bool.isRequired,
  ontologyResult: PropTypes.array.isRequired,
  parentType: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    changeStakeholderId: PropTypes.func,
    showMeasureNameFinancialProxyField: PropTypes.func,
    hideMeasureNameFinancialProxyField: PropTypes.func,
    changeFinancialProxyMeasureName: PropTypes.func,
    changeMeasure: PropTypes.func,
  }.isRequired)
};

export default InjectIntl(FinancialProxy);