import merge from 'xtend';
import createReducer from './create-reducer';

import {
  ONTOLOGY_CHANGE_QUERY,
  ONTOLOGY_SEARCH_SUCCESS,
  ONTOLOGY_SEARCH_FAILURE,
  ONTOLOGY_SET_INITIAL_STATE, ONTOLOGY_SEARCH
} from '../actions/ontology';

const INITIAL_STATE = {
  query: '',
  ontologyResult: [],
  ontologySearching: false,
  errors: {}
};

export default createReducer({
  [ONTOLOGY_SEARCH]: (state, action) => merge(state, {ontologySearching: true}),
  [ONTOLOGY_CHANGE_QUERY]: (state, action) => merge(state, {query: action.query}),
  [ONTOLOGY_SEARCH_SUCCESS]: (state, action) => merge(state, {ontologyResult: action.payload.response, ontologySearching: false}),
  [ONTOLOGY_SEARCH_FAILURE]: (state, action) => merge(state, {errors: action.errors, ontologySearching: false}),
  [ONTOLOGY_SET_INITIAL_STATE]: (state, action) => merge(state, INITIAL_STATE)
}, INITIAL_STATE)