export const ADD_FINANCIAL_PROXY_MEASURE = '@@FINANCIAL_PROXY/ADD_FINANCIAL_PROXY_MEASURE';
export const ADD_FINANCIAL_PROXY_MEASURE_SUCCESS = '@@FINANCIAL_PROXY/ADD_FINANCIAL_PROXY_MEASURE_SUCCESS';
export const ADD_FINANCIAL_PROXY_MEASURE_FAILURE = '@@FINANCIAL_PROXY/ADD_FINANCIAL_PROXY_MEASURE_FAILURE';
export const SHOW_FINANCIAL_PROXY_FIELD = '@@FINANCIAL_PROXY/SHOW_FINANCIAL_PROXY_FIELD';
export const HIDE_FINANCIAL_PROXY_FIELD = '@@FINANCIAL_PROXY/HIDE_FINANCIAL_PROXY_FIELD';
export const CHANGE_FINANCIAL_PROXY_MEASURE_NAME = '@@FINANCIAL_PROXY/CHANGE_FINANCIAL_PROXY_MEASURE_NAME';
export const SHOW_ADJUSTMENTS = '@@FINANCIAL_PROXY/SHOW_ADJUSTMENTS';
export const HIDE_ADJUSTMENTS = '@@FINANCIAL_PROXY/HIDE_ADJUSTMENTS';

function addFinancialProxyMeasure() {
  return { type: ADD_FINANCIAL_PROXY_MEASURE }
}

function showMeasureNameFinancialProxyField() {
  return { type: SHOW_FINANCIAL_PROXY_FIELD }
}

function hideMeasureNameFinancialProxyField() {
  return { type: HIDE_FINANCIAL_PROXY_FIELD }
}

function changeFinancialProxyMeasureName(measureName, measureId) {
  return { type: CHANGE_FINANCIAL_PROXY_MEASURE_NAME, measureName, measureId }
}

function showAdjustments() {
  return { type: SHOW_ADJUSTMENTS }
}

function hideAdjustments() {
  return { type: HIDE_ADJUSTMENTS }
}

export default {
  addFinancialProxyMeasure,
  showMeasureNameFinancialProxyField,
  hideMeasureNameFinancialProxyField,
  changeFinancialProxyMeasureName,
  showAdjustments,
  hideAdjustments
}