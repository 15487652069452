import React from "react";
import PropTypes from "prop-types";
import VariableItem from "../../../containers/calculations/VariableItem.container";

const Variables = ({ variables }) => {
  return (
    <tbody>
      {variables.map((item, index) => (
        <VariableItem variableItem={item} key={index} />
      ))}
    </tbody>
  );
};

Variables.propTypes = {
  variables: PropTypes.array.isRequired
};

export default Variables;
