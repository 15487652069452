import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import measureActions from "../../../../actions/measure/measure";
import demographicsActions from "../../../../actions/stakeholder-items/demographics";
import manageMeasureActions from "../../../../actions/measure/manage-measure";
import DemographicItem from "../../../../components/logic-models/stakeholder-items/add-demographics/DemographicItem";
import ontologyActions from "../../../../actions/ontology";


function mapStateToProps(state, ownProps) {
  return {
    ...state.demographics,
    ...state.manageMeasure,
    ...state.measurePage,
    ...state.ontology,
    demographic: ownProps.demographic,
    intl: state.intl
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...manageMeasureActions,
      ...measureActions,
      ...demographicsActions,
      ...ontologyActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DemographicItem)