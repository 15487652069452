import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import manageMeasureActions from "../../../../actions/measure/manage-measure";
import measureActions from "../../../../actions/measure/measure";
import inputsActions from "../../../../actions/stakeholder-items/inputs";
import Inputs from "../../../../components/logic-models/stakeholder-items/add-input/Inputs";


function mapStateToProps(state, ownProps) {
  return {
    ...state.measurePage,
    ...state.dataSource,
    ...state.inputs,
    inputs: ownProps.inputs,
    stakeholder: ownProps.stakeholder
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...manageMeasureActions,
      ...measureActions,
      ...inputsActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Inputs)