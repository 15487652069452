import React from 'react';
import PropTypes from "prop-types";


const emailMaxLength = 320;

class EmailAddr extends React.PureComponent{

    state = {
        isEdit: false,
        newEmail: ''
    };

    setInputSize = ()=>{
        const {emailDiv, emailInput} = this.refs;
        emailInput.style.width = emailDiv.clientWidth + 1 +'px';
        emailInput.style.height = emailDiv.clientHeight +'px';
    };

    sendEmail = ()=>{
        const {onChange, email} = this.props;
        const {newEmail} = this.state;
        if(email !== newEmail){
            onChange(newEmail);
        }
    };

    setFocus =()=>{
        const {emailInput} = this.refs;
        const {email} = this.props;
        emailInput.focus();
        emailInput.selectionStart = `${email}`.length;
        emailInput.selectionEnd = `${email}`.length;
    };

    addEventListener () {
        document.addEventListener('click', this.handleOutsideClick, false);
    }

    removeEventListener () {
        document.removeEventListener('click', this.handleOutsideClick, false);
    }

    handleOutsideClick = (e)=>{
        const {emailInput} = this.refs;
        const {onEdit} = this.props;
        if(emailInput && !emailInput.contains(e.target)){
            onEdit(false);
            this.removeEventListener();
            this.sendEmail();
            this.setView();
        }
    };

    highlightingError(email){
        if(this.isValidEmail(email)) return '';
        return 'error';
    }

    isValidEmail(email){
        const template = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/;
        return template.test(email) && email.length < emailMaxLength;
    }

    onChange = (e)=>{
        const {value} = e.target;
        this.setState(()=>({newEmail: `${value}`.replace(/\s/g,'')}), this.setInputSize)
    };

    onEnter = (e)=>{
        if(e.key === 'Enter'){
            this.sendEmail();
            this.setView();
        }
    };

    onRemove = (e)=>{
        e.stopPropagation();
        const {onRemove} = this.props;
        onRemove();
    };

    setEdit = (e)=>{
        e.stopPropagation();
        const {onEdit} = this.props;
        this.setState((state, props)=>({isEdit:  true, newEmail: props.email}), ()=>{
            this.addEventListener();
            this.setInputSize();
            this.setFocus();
            onEdit(true);
        });
    };

    stopEvent = (e)=>{
        e.stopPropagation();
    };

    setView = ()=>{
        this.setState((state, props)=>({isEdit:  false}));
    };

    renderViewEmail(email){
        return <div className={`email ${this.highlightingError(email)}`}>
            <span className='button-close'
                  onClick={this.onRemove}>x</span>
            <span className='value'
                  onDoubleClick={this.setEdit}>
                {email}
            </span>
        </div>;
    }

    renderEditEmail(email){
        return <div className={`email ${this.highlightingError(email)}`} onClick={this.stopEvent}>
            <span className='button-close'
                  onClick={this.onRemove}>x</span>
            <input value={email} ref='emailInput'
                   onKeyPress={this.onEnter}
                   onChange={this.onChange}/>
            <div className='hidden-slave' ref='emailDiv'>{email}</div>
        </div>;
    }

    render() {
        const {email} = this.props;
        const {isEdit, newEmail} = this.state;
        return isEdit ? this.renderEditEmail(newEmail) : this.renderViewEmail(`${email}`);
    }

}

EmailAddr.propTypes = {
    email: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default EmailAddr;