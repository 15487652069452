import React from "react";
import swal from 'sweetalert2';
import { InjectIntl } from "../common/InjectIntl";


class LogicModelForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      country: 'Canada',
      province: '',
      cities: [],
      city: ""
    }
  }

  handleChangeCountry = (e) => {
    this.setState({ country: e.target.value, province: '', cities: [], hasError: false })
  };

  handleClickNext = () => {
    this.props.saveLogicModelOptions(this.state);
    const emptyCountryField = this.props.intl('errors.create_logic_model.country_empty');
    if (this.state.country === '') {
      swal("Uh oh", emptyCountryField, "error");
    } else {
      this.props.openForm()
    }
  };

  handleChangeProvince = (event, provinces) => {
    let towns;
    provinces.forEach((province) => {
      if (province.name === event.target.value) {
        towns = province.cities
      }
    });
    this.setState({ province: event.target.value, cities: towns })
  };

  renderProvinceOptions = (countries) => {
    if (this.state.country.length > 1) {
      let provinces = this.countryProvinces(countries);
      if (provinces.length > 0) {
        return (
          <select
            className="form-control success"
            v-model="lmaf.province"
            onChange={(event) => this.handleChangeProvince(event, provinces)}
          >
            <option value="">
              {this.props.intl('create_logic_model.select_province.placeholder')}
            </option>
            {provinces.map((province, index) => (
              <option key={index}>
                {province.name}
              </option>
            ))}
          </select>
        )
      }
    }
  };

  countryProvinces = (countries) => {
    let province;
    countries.forEach((country) => {
      if (country.name === this.state.country) {
        province = country.provinces
      }
    });
    return province
  };

  changeCity=(city)=> {
    this.setState({city: city})
  };

  renderCitiesOptions = () => {
    if (this.state.cities.length > 0) {
      return (
        <select className="form-control success" v-model="lmaf.city">
          <option value="">
            {this.props.intl('create_logic_model.select_city.placeholder')}
          </option>
          {this.state.cities.map((city, index) => (
            <option key={index} onClick={this.changeCity.bind(null, city)}>
              {city}
            </option>
          ))}
        </select>
      )
    }
  };

  render() {
    const { countries } = this.props;

    return (
      <div className="form-group" v-show="lmaf.steps.q1">
        <label>
          {this.props.intl('create_logic_model.select_country')}
        </label>
        <hr/>
        <select className="form-control success" v-model="lmaf.country" onChange={this.handleChangeCountry}>
          {/*<option value="">*/}
            {/*{this.props.intl('create_logic_model.select_country.placeholder')}*/}
          {/*</option>*/}
          {countries.map((country, index) => (
            <option key={index}>
              {country.name}
            </option>
          ))}
        </select>
        <br/>
        {this.renderProvinceOptions(countries)}
        <br/>
        {this.renderCitiesOptions()}
        <div className="text-right">
          <button onClick={this.handleClickNext} className="btn btn-success m-t">
            {this.props.intl('create_logic_model.next_button')}
          </button>
        </div>
      </div>

    )
  }
}

export default InjectIntl(LogicModelForm);