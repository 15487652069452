import React from "react";
import PropTypes from "prop-types";
import { InjectIntl } from "../../common/InjectIntl";
import SelectBox from "../../common/SelectBox";
import { getFormTitle } from "../../../assets/sam-utils";
import { DELETED } from "./source-table-layout/constants";
import { has, head } from "lodash";
import { EDIT } from "../../members/constants";

class SourceFormOrUpload extends React.Component {
  static propTypes = {
    workspace: PropTypes.object.isRequired,
    currentDataSource: PropTypes.object.isRequired,
    activeVariable: PropTypes.object.isRequired,
    shouldGetUpdate: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      changeCurrentFormId: PropTypes.func.isRequired,
      changeCurrentUploadId: PropTypes.func.isRequired,
      getDatasourceResources: PropTypes.func.isRequired,
      setShouldGetDatasources: PropTypes.func.isRequired
    })
  };

  state = {
    isSelectedOptionHidden: false
  };

  componentDidMount() {
    const {
      currentDataSource,
      activeVariable,
      shouldGetUpdate,
      actions
    } = this.props;
    // check if a datasource_object exists
    // if the active variable has array calculations
    // then get from the array_calculation_variables
    let datasourceObject = activeVariable.datasource_object;
    if(has(activeVariable, 'array_calculation.array_calculation_variables')) {
      const arrayCalculationVariables = activeVariable.array_calculation.array_calculation_variables;
      if(arrayCalculationVariables.length > 0) {
        const arrayCalculationVariable = head(arrayCalculationVariables);
        if(!!arrayCalculationVariable.datasource_object) {
          datasourceObject = arrayCalculationVariable.datasource_object;
        }
      }
    }
    if (datasourceObject && shouldGetUpdate) {
      if (currentDataSource.sourceName === "Survey") {
        actions.changeCurrentFormId(datasourceObject.form_id);
      } else {
        actions.changeCurrentUploadId(
          datasourceObject.upload_id
        );
      }

      if (!activeVariable.source_statuses.includes(DELETED)) {
        actions.getDatasourceResources();
      } else {
        this.setState({ isSelectedOptionHidden: true });
        actions.setShouldGetDatasources(false);
      }
    }
  }

  changeFormOrUpload = format => {
    const objId = format.value;
    this.props.actions.clearSourceDataExcept("form");
    if (this.props.currentDataSource.sourceName === "Survey") {
      this.props.actions.changeCurrentFormId(objId);
    } else if (this.props.currentDataSource.sourceName === "Upload") {
      this.props.actions.changeCurrentUploadId(objId);
    }
    this.props.actions.getDatasourceResources();
    if (this.state.isSelectedOptionHidden)
      this.setState({ isSelectedOptionHidden: false });
    this.props.actions.setConditionInitialState();
  };

  getPlaceholder = () => {
    const sourceName = this.props.currentDataSource.sourceName;
    if (
      sourceName === "Survey" ||
      (this.props.workspace.forms && sourceName === "Form")
    )
      return this.props.intl("source.select_form").toString();
    else if (sourceName === "Upload")
      return this.props.intl("source.select_upload").toString();
    else return "";
  };

  render() {
    const { currentDataSource, workspace, activeVariable } = this.props;
    const sourceName = currentDataSource.sourceName;
    const intlName =
      sourceName === "Survey" ? "form" : sourceName.toLowerCase();
    const sourceMessage = `source.type.${intlName}`;
    const options =
      sourceName === "Survey" ? workspace.forms : workspace.upload_files;

    const selectOptions =
      options &&
      options.map(option => {
        if (currentDataSource.sourceName === "Survey")
          return {
            value: option.id,
            label: getFormTitle(option, this.props.intlLocale)
          };
        else if (currentDataSource.sourceName === "Upload")
          return { value: option.id, label: option.title };
      });

    const label = currentDataSource.sourceName
      ? this.props.intl(sourceMessage)
      : "";
    let objId =
      currentDataSource.sourceName === "Survey"
        ? currentDataSource.formId
        : currentDataSource.uploadId;
    objId = this.state.isSelectedOptionHidden ? null : objId;

    const isReadOnlyPermission = localStorage.getItem("permission") !== EDIT;

    return (
      <div className="source-select-form" id="source-select-form">
        {selectOptions && (
          <div className={isReadOnlyPermission ? "hide" : ""}>
            <SelectBox
              options={selectOptions}
              label={label}
              onHeaderChange={this.changeFormOrUpload}
              locale={this.props.intlLocale}
              selectedOption={objId}
              placeholder={this.getPlaceholder()}
              labelClassName={"col-md-4"}
              disabled={isReadOnlyPermission }
            />
          </div>
        )}
        {isReadOnlyPermission &&
          <input
            value={activeVariable.tableLayout && activeVariable.tableLayout.datasetName && activeVariable.tableLayout.datasetName.title_en ? activeVariable.tableLayout.datasetName.title_en : ''}
            className={"form-control success"}
            disabled={isReadOnlyPermission}
          />
        }
        {workspace && <div>{this.props.children}</div>}
      </div>
    );
  }
}

export default InjectIntl(SourceFormOrUpload);
