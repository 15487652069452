import React from 'react';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import NumericWidget from './NumericWidget';
import {getColor} from './common/chartColors';

NoDataToDisplay(Highcharts);

const NestedMeasures = ({ parentMeasures, parentMeasureTitle, childMeasureType, hasCharts }) => {

  const renderCharts = (measures) => {
    if(measures.length === 0) {
      return '';
    }
    let validateArr = measures.map((measure, i) => {
      return measure.monetized_value;
    });
    let filteredData = validateArr.filter(el => el !== 0);
    if(filteredData.length > 0) {
      filteredData = measures.map((m, i) => {

        return {
          x: i+1,
          y: m.monetized_value,
          name: m.name,
          color: getColor(i)
        }
      })
    }
    const options = {
      chart: {
        type: 'column'
      },
      credits: {
        enabled: false
      },
      lang: {
          noData: "No outcomes achieved"
      },
      noData: {
        style: {"color": "#dddddd", "fontSize": "24px", "fontWeight": "400"}
      },
      title: {
        text: 'Total monetized impact by outcome indicator',
        style: {"color": "#dddddd", "fontSize": "16px"}
      },
      yAxis: {
        title: {
          text: 'Monetized Social Value',
          style: {"color": "#dddddd", "fontSize": "16px"}
        },
      },
      xAxis: {
        title: {
          text: 'Outcome Indicators',
          style: {"color": "#dddddd", "fontSize": "16px"}
        },
      },
      series: [{
        name: 'Outcome indicators',
        showInLegend: false,
        data: filteredData
      }]
    }
    return (
      <div className="row measures_chart_wrapper">
        <div className="col-md-8">
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          />
        </div>
        <div className="col-md-4">
          <ul className="measures_legend_list">
            {measures.map((measure, i) => {
              let color = getColor(i);
              let iStyle = {
                color: color
              }
              return (
                <li className="measures_legend_list_item" key={measure.id}>
                  <i className="fa fa-square" aria-hidden="true" style={iStyle}></i> 
                  {`${i+1} - ${measure.name}`}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    );
  }

  const renderChildMeasures = (childMeasures) => {
    if(childMeasures.length === 0) {
      return (
        <p className="text-center text-muted child_measures_message">Measures not defined</p>
      );
    }
    const chunkChildMeasures = chunk(childMeasures, 3);
    return chunkChildMeasures.map((chunkChildMeasuresRow, rowIndex) => {
      let childMeasureClassName = 'col-md-4';
      if(chunkChildMeasuresRow.length === 2) {
        childMeasureClassName = 'col-md-4';
      } else if(chunkChildMeasuresRow.length === 1) {
        childMeasureClassName = 'col-md-4 col-md-offset-4';
      }
      return (
        <div key={rowIndex} className="row">
          {chunkChildMeasuresRow.map(childMeasure => {
            return (
              <div key={childMeasure.id} className={childMeasureClassName}>
                <NumericWidget 
                  value={childMeasure.value} 
                  label={childMeasure.name} 
                  money={false} 
                  large={true} 
                  message={childMeasure.message === undefined ? null : childMeasure.message}
                />
              </div>
            );
          })}
        </div>
      );
    })
  }

  const sectionTypeClassName = parentMeasureTitle.toLowerCase();
  const parentMeasureNameClassName = `${sectionTypeClassName}_item_name`;
  const parentMeasurePrefix = parentMeasureTitle === 'outcomes' ? 'Outcome' : 'Activity';
  return (
    <div className={`row measure_section_wrap ${sectionTypeClassName}`}>
      <div className="col-md-12">
      {
        parentMeasures.map(parentMeasure => {
          if(parentMeasure[childMeasureType.toLowerCase()].length === 0) {
            return '';
          }
          return (
            <div key={parentMeasure.id} className="row measure_item_wrap">
              <div className={`col-md-12 measure_section_parent_measure_name measure_title ${parentMeasureNameClassName}`}>
                {`${parentMeasurePrefix} - ${parentMeasure.name}`}
              </div>
              {hasCharts ? renderCharts(parentMeasure[childMeasureType.toLowerCase()]) : ''}
              <div className="col-md-12 child_measures">
                {renderChildMeasures(parentMeasure[childMeasureType.toLowerCase()])}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

NestedMeasures.propTypes = {
  parentMeasures: PropTypes.array.isRequired,
  parentMeasureTitle: PropTypes.string.isRequired,
  childMeasureType: PropTypes.string.isRequired,
  hasCharts: PropTypes.bool.isRequired
}

export default NestedMeasures;