import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ConditionsActions from "../../../../actions/calculation/conditions";
import ConditionIndex from "../../../../components/calculations/sources/modals/conditions/ConditionIndex"


function mapStateToProps(state) {
  const { dataSource: { currentDataSource }, oneSourceFirstTab: { headers }, calculations: { activeVariable } } = state;
  return {
    headers,
    currentDataSource,
    ...state.conditionPanel,
    activeVariable: activeVariable,
    intl: state.intl,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...ConditionsActions
    }, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ConditionIndex)