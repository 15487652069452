export const SHOW_CONDITION_PANEL = '@@CONDITIONS/SHOW_CONDITION_PANEL';
export const HIDE_CONDITION_PANEL = '@@CONDITIONS/HIDE_CONDITION_PANEL';
export const CHANGE_CURRENT_CONDITION = '@@CONDITIONS/CHANGE_CURRENT_CONDITION';
export const REMOVE_CONDITION_ROW = '@@CONDITIONS/REMOVE_CONDITION_ROW';
export const ADD_CONDITION_ROW = '@@CONDITIONS/ADD_CONDITION_ROW';
export const SET_CURRENT_CONDITION_ROW = '@@CONDITIONS/SET_CURRENT_CONDITION_ROW';
export const CHANGE_CONDITION_DATE = '@@CONDITIONS/CHANGE_CONDITION_DATE';
export const SEND_CONDITION = '@@CONDITIONS/SEND_CONDITION';
export const SEND_CONDITION_SUCCESS = '@@CONDITIONS/SEND_CONDITION_SUCCESS';
export const SEND_CONDITION_FAILURE = '@@CONDITIONS/SEND_CONDITION_FAILURE';
export const CLEAR_ERROR_HINTS = '@@CONDITIONS/CLEAR_ERROR_HINTS';
export const SHOW_ERROR_HINTS = '@@CONDITIONS/SHOW_ERROR_HINTS';
export const CANCEL_NOT_APPLIED_CONDITION = '@@CONDITIONS/CANCEL_NOT_APPLIED_CONDITION';
export const CHANGE_APPLIED_CONDITIONS = '@@CONDITIONS/CHANGE_APPLIED_CONDITIONS';
export const SET_CONDITION_INITIAL_STATE = '@@CONDITIONS/SET_CONDITION_INITIAL_STATE';
export const SET_EXISTING_VARIABLE_CONDITIONS = '@@CONDITIONS/SET_EXISTING_VARIABLE_CONDITIONS';
export const CHANGE_TRANFORMATION_FOR_CONDITION = '@@CONDITIONS/CHANGE_TRANFORMATION_FOR_CONDITION';
export const CLEAR_ALL_CONDITIONS = '@@CONDITIONS/CLEAR_ALL_CONDITIONS';
export const ERASE_ALL_CONDITIONS = '@@CONDITIONS/ERASE_ALL_CONDITIONS';
export const SET_EXISTING_ARRAY_CALCULATION_CONDITIONS = '@@CONDITIONS/SET_EXISTING_ARRAY_CALCULATION_CONDITIONS';

function eraseAllConditions() {
  return { type: ERASE_ALL_CONDITIONS }
}

function clearAllConditions() {
  return { type: CLEAR_ALL_CONDITIONS }
}

function changeTransformationForCondition(transformation) {
  return { type: CHANGE_TRANFORMATION_FOR_CONDITION, transformation }
}

function setExistingConditions(conditions) {
  return { type: SET_EXISTING_VARIABLE_CONDITIONS, conditions }
}

function changeAppliedConditions(conditions) {
  return { type: CHANGE_APPLIED_CONDITIONS, conditions }
}

function setConditionInitialState() {
  return { type: SET_CONDITION_INITIAL_STATE }
}

function cancelNotAppliedConditions() {
  return { type: CANCEL_NOT_APPLIED_CONDITION }
}

function showErrorHints(status) {
  return { type: SHOW_ERROR_HINTS, status }
}

function clearErrorHints(errorKey) {
  return { type: CLEAR_ERROR_HINTS, errorKey }
}

function sendConditions() {
  return { type: SEND_CONDITION }
}

function changeConditionDate(date) {
  return { type: CHANGE_CONDITION_DATE, date }
}
function setConditionRow(condition) {
  return { type: SET_CURRENT_CONDITION_ROW, condition }
}

function conditionRowAdd() {
  return { type: ADD_CONDITION_ROW }
}

function conditionRowRemove(conditionId) {
  return { type: REMOVE_CONDITION_ROW, conditionId }
}


function changeCurrentCondition(condition) {
  return { type: CHANGE_CURRENT_CONDITION, condition }
}
function hideConditionPanel() {
  return { type: HIDE_CONDITION_PANEL }
}

function showConditionPanel() {
  return { type: SHOW_CONDITION_PANEL }
}

function setArrayCalculationConditions(conditions) {
  return { type: SET_EXISTING_ARRAY_CALCULATION_CONDITIONS, conditions }
}


export default {
  hideConditionPanel,
  showConditionPanel,
  changeCurrentCondition,
  conditionRowRemove,
  conditionRowAdd,
  changeConditionDate,
  setConditionRow,
  sendConditions,
  clearErrorHints,
  showErrorHints,
  cancelNotAppliedConditions,
  setConditionInitialState,
  setExistingConditions,
  changeAppliedConditions,
  changeTransformationForCondition,
  clearAllConditions,
  eraseAllConditions,
  setArrayCalculationConditions
}