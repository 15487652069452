export const SHOW_SELECT_VARIABLE_TYPE_MODAL = '@@SOURCE_MODALS/SHOW_SELECT_VARIABLE_TYPE_MODAL';
export const HIDE_SELECT_VARIABLE_TYPE_MODAL = '@@SOURCE_MODALS/HIDE_SELECT_VARIABLE_TYPE_MODAL';
export const SHOW_SELECT_PREEXISTING_CALCULATION_MODAL = '@@SOURCE_MODALS/SHOW_SELECT_PREEXISTING_CALCULATION_MODAL';
export const HIDE_SELECT_PREEXISTING_CALCULATION_MODAL = '@@SOURCE_MODALS/HIDE_SELECT_PREEXISTING_CALCULATION_MODAL';
export const CHANGE_DATA_SET_NAME = '@@SOURCE_MODALS/CHANGE_DATA_SET_NAME';
export const SHOW_ONE_SOURCE_MODAL = '@@SOURCE_MODALS/SHOW_ONE_SOURCE_MODAL';
export const HIDE_ONE_SOURCE_MODAL = '@@SOURCE_MODALS/HIDE_ONE_SOURCE_MODAL';


function showSelectVariableModal() {
  return { type: SHOW_SELECT_VARIABLE_TYPE_MODAL };
}

function hideSelectVariableModal() {
  return { type: HIDE_SELECT_VARIABLE_TYPE_MODAL };
}

function showSelectPreexistingCalculationModal() {
  return { type: SHOW_SELECT_PREEXISTING_CALCULATION_MODAL };
}

function hideSelectPreexistingCalculationModal() {
  return { type: HIDE_SELECT_PREEXISTING_CALCULATION_MODAL };
}

function changeDataSetName(name) {
  return { type: CHANGE_DATA_SET_NAME, name }
}

function showOneSourceModal() {
  return { type: SHOW_ONE_SOURCE_MODAL }
}

function hideOneSourceModal() {
  return { type: HIDE_ONE_SOURCE_MODAL }
}


export default {
  showSelectVariableModal,
  hideSelectVariableModal,
  showSelectPreexistingCalculationModal,
  hideSelectPreexistingCalculationModal,
  changeDataSetName,
  showOneSourceModal,
  hideOneSourceModal,
};
