import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap-modal";

import { InjectIntl } from "../../../common/InjectIntl";
import SourcesCalculations from "../SourcesCalculations";
import { whiteLabel } from '../../../utils';

const SelectPreexistingCalculation = props => {
  const {
    isSelectPreexistingCalculationModalShowed,
    currentPreexistingCalculation,
    preexistingCalculations,
    activeVariable,
    actions,
    intl
  } = props;

  const editSourceClickHandler = () => {
    const payload = {
      datasource_object_id: currentPreexistingCalculation.id,
      datasource_type_id: 3
    };
    const id = activeVariable.id;

    actions.setCalculationVariable(payload, id);
  };

  const handleClose = () => {
    actions.setCurrentPreexistingCalculation({});
    actions.hideSelectPreexistingCalculationModal();
  };

  return (
    <Modal
      dialogClassName="modal-md"
      show={isSelectPreexistingCalculationModalShowed}
      id="foreground_display"
      data-brand={whiteLabel()}
    >
      <Modal.Header className="gray-bg">
        <Modal.Title id="ModalHeader">
          <strong>{intl("calculation.preexisting.select")}</strong>
          <i
            onClick={actions.hideSelectPreexistingCalculationModal}
            className="fa fa-close pull-right"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="selectCalculationForm">
          <SourcesCalculations
            setCurrentPreexistingCalculation={
              actions.setCurrentPreexistingCalculation
            }
            selectedCalculation={currentPreexistingCalculation}
            sourceType="preexisting"
            label={intl("calculation.variable_type.preexisting_calculation")}
            preexistingCalculations={preexistingCalculations}
          />
        </form>
      </Modal.Body>
      <Modal.Footer className="gray-bg">
        <div className="row">
          <button onClick={handleClose} className="btn modal-button">
            {intl("common.text.cancel_button")}
          </button>
          &nbsp;
          <button
            onClick={editSourceClickHandler}
            className="btn btn-success modal-button m-r-50"
            disabled={
              activeVariable &&
              activeVariable.datasource_object_id ===
                currentPreexistingCalculation.id
            }
          >
            {intl("common.text.replace")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

SelectPreexistingCalculation.propTypes = {
  currentPreexistingCalculation: PropTypes.object.isRequired,
  isSelectPreexistingCalculationModalShowed: PropTypes.bool.isRequired,
  activeVariable: PropTypes.object,
  intl: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    hideSelectPreexistingCalculationModal: PropTypes.func.isRequired,
    setCalculationVariable: PropTypes.func.isRequired,
    setCurrentPreexistingCalculation: PropTypes.func.isRequired
  })
};

export default InjectIntl(SelectPreexistingCalculation);
