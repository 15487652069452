export const ADD_INPUTS_MEASURE = '@@INPUT/ADD_INPUTS_MEASURE';
export const ADD_INPUTS_MEASURE_SUCCESS = '@@INPUT/ADD_INPUTS_MEASURE_SUCCESS';
export const ADD_INPUTS_MEASURE_FAILURE = '@@INPUT/ADD_INPUTS_MEASURE_FAILURE';

export const SHOW_INPUT_FIELD = '@@INPUT/SHOW_INPUT_FIELD';
export const HIDE_INPUT_FIELD = '@@INPUT/HIDE_INPUT_FIELD';
export const SAVE_MEASURE_NAME = '@@INPUT/SAVE_MEASURE_NAME';
export const CHANGE_INPUT_MEASURE_NAME = '@@INPUT/CHANGE_INPUT_MEASURE_NAME';

function addInputsMeasure() {
  return { type: ADD_INPUTS_MEASURE }
}

function showMeasureNameInputField() {
  return { type: SHOW_INPUT_FIELD }
}

function hideMeasureNameInputField() {
  return { type: HIDE_INPUT_FIELD }
}

function changeInputMeasureName(
  measureName,
  measureId,
  newMeasureDescription,
  stakeholderId
) {
  return {
    type: CHANGE_INPUT_MEASURE_NAME,
    measureName,
    measureId,
    newMeasureDescription,
    stakeholderId,
  };
}

export default {
  addInputsMeasure,
  showMeasureNameInputField,
  hideMeasureNameInputField,
  changeInputMeasureName
}