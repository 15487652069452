import React from 'react';
import PropTypes from 'prop-types';
import { InjectIntl } from "../../common/InjectIntl";
import SelectBox from "../../common/SelectBox";
import { EDIT } from "../../members/constants";

class SourcesWorkspaces extends React.Component {

  static propTypes = {
    workspaces: PropTypes.array.isRequired,
    currentDataSource: PropTypes.object.isRequired,
    activeVariable: PropTypes.object,
    shouldGetUpdate: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      setWorkspace: PropTypes.func.isRequired,
      changeCurrentWorkspaceId: PropTypes.func.isRequired,
      dataSourceInitialState: PropTypes.func.isRequired,
      clearSourceDataExcept: PropTypes.func.isRequired,
    })
  };

  getActiveVariableWorkSpaceId=()=> {
    if (!!this.props.activeVariable) {
      if (this.props.activeVariable.datasource_object) {
        return this.props.activeVariable.datasource_object.workspace_id;
      }
    }
  };

  componentDidMount() {
    if (this.props.shouldGetUpdate) {
      const workspaceId = this.getActiveVariableWorkSpaceId();
      if (workspaceId) this.setWorkspaceId(workspaceId);
    }
  }

  setWorkspaceId = (workspaceId) => {
    this.props.actions.changeCurrentWorkspaceId(workspaceId);
  };

  changeWorkspace = (format) => {
    this.props.actions.clearSourceDataExcept('workspaceId');
    const workspaceId = format.value;
    this.setWorkspaceId(workspaceId);
    this.props.actions.setDataSourceShouldUpdate(false);
    this.props.actions.setConditionInitialState()

  };

  render() {
    const { workspaces, currentDataSource, activeVariable  } = this.props;
    const workspaceId = currentDataSource.workspaceId ? currentDataSource.workspaceId : 'null';

    const selectOptions = workspaces.map((workspace) => {
        return { value: workspace.id, label: workspace.title };
    });

    const isReadOnlyPermission = localStorage.getItem("permission") !== EDIT;

    return (
      <div className='select-workspace row' id='select-workspace'>
        <div className={isReadOnlyPermission ? "hide col-md-5" : "col-md-5"}>
          <SelectBox
            options={selectOptions}
            label={this.props.intl('source.workspace')}
            onHeaderChange={this.changeWorkspace}
            locale={this.props.intlLocale}
            selectedOption={parseInt(workspaceId)}
            placeholder={this.props.intl('source.select_workspace')}
            labelClassName={"col-md-4"}
            disabled={isReadOnlyPermission}
          />
        </div>
        {isReadOnlyPermission &&
          <div className={"col-md-5"}>
            <input
              value={activeVariable.tableLayout && activeVariable.tableLayout.workspaceName ? activeVariable.tableLayout.workspaceName: ''}
              className={"form-control success"}
              disabled={isReadOnlyPermission}
            />
          </div>
        }
        <div className="col-md-5 col-md-offset-1">
          {currentDataSource.workspaceId &&
            <div>
              {this.props.children}
            </div>
          }
        </div>
      </div>
    );
  }
}

export default InjectIntl(SourcesWorkspaces);