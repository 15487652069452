import merge from 'xtend';
import createReducer from './create-reducer';

import { SHOW_SPINNER, HIDE_SPINNER } from "../actions/spinner";
import { FETCH_LOGIC_MODEL } from "../actions/measure/measure";

const INITIAL_STATE = {
  showSpinner: false
};

export default createReducer({
  [FETCH_LOGIC_MODEL]: (state, action) => merge(state, {
    showSpinner: true,
  }),
  [SHOW_SPINNER]: (state, actions) => merge(state, {showSpinner: true}),
  [HIDE_SPINNER]: (state, action) => merge(state, {showSpinner: false})
}, INITIAL_STATE)