import React, { useState, useEffect } from "react";

export const CreateThemeModal = ({
  frameworkId,
  intl,
  themeName,
  saveTheme,
  actions,
}) => {
  const [theme, setTheme] = useState({
    name: "",
    description: "",
  });
  useEffect(() => {
    if (themeName) {
      setTheme({
        name: themeName,
      });
    }
  }, [themeName]);
  const handleInputChange = (e, name) => {
    setTheme({
      ...theme,
      [name]: e.target.value,
    });
  };
  const handleSubmit = () => {
    actions.createTheme(theme.name, theme.description, frameworkId);
    saveTheme();
  };
  const isSubmitButtonDisabled = !theme.name;
  return (
    <div>
      <div className="row">
        <input
          type="text"
          value={theme.name}
          style={{ fontSize: "13px" }}
          className="form-control success form-control-updated"
          onChange={(e) => handleInputChange(e, "name")}
          placeholder={intl("editTheme.placeholder.enterName")}
        />
      </div>
      <div className="row">
        <input
          type="text"
          value={theme.description}
          style={{ fontSize: "13px" }}
          className="form-control success form-control-updated"
          onChange={(e) => handleInputChange(e, "description")}
          placeholder={intl("editTheme.placeholder.enterDescription")}
        />
      </div>
      <div className="row">
        <button className="btn btn-outline" onClick={() => saveTheme()}>
          {intl("common.text.back_button")}
        </button>
        <button
          disabled={isSubmitButtonDisabled}
          className="btn btn-success"
          onClick={handleSubmit}
        >
          {intl("common.text.save_button")}
        </button>
      </div>
    </div>
  );
};
