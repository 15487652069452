import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import manageMeasureActions from "../../../actions/measure/manage-measure";
import Stakeholder from "../../../components/measure/Stakeholder";
import measureActions from "../../../actions/measure/measure";
import ontologyActions from "../../../actions/ontology";
import demographicActions from "../../../actions/stakeholder-items/demographics";
import modalDeleteItem from "../../../actions/modal-delete-item";

function mapStateToProps(state, ownProps) {
  return {
    ...state.measurePage,
    ...state.dataSource,
    ...state.intl,
    ...state.ontology,
    activeStakeholderId: ownProps.activeStakeholderId,
    stakeholder: ownProps.stakeholder,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...manageMeasureActions,
      ...measureActions,
      ...ontologyActions,
      ...demographicActions,
      ...modalDeleteItem
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stakeholder)