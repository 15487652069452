import React from "react";
import PropTypes from "prop-types";
import { InjectIntl } from "../../common/InjectIntl";
import { getFormTitle, checkHasNoResponses } from "../../../assets/sam-utils";
import SelectBox from "../../common/SelectBox";
import {
  NO_RESPONSES,
  UNPUBLISHED,
  DELETED_COLUMN
} from "./source-table-layout/constants";
import { EDIT } from "../../members/constants";

class SourceColumn extends React.Component {
  static propTypes = {
    activeVariable: PropTypes.object.isRequired,
    currentDataSource: PropTypes.object.isRequired,
    headers: PropTypes.array.isRequired,
    answers: PropTypes.array.isRequired,
    actions: PropTypes.shape({
      saveDataSource: PropTypes.func.isRequired,
      changeCurrentQuestionId: PropTypes.func.isRequired,
      changeCurrentColumnId: PropTypes.func.isRequired,
      changeCurrentOptionId: PropTypes.func.isRequired,
      setHeaderId: PropTypes.func.isRequired,
      createVariable: PropTypes.func.isRequired,
      setShouldGetTranformation: PropTypes.func.isRequired,
      getTransformationValues: PropTypes.func.isRequired
    }).isRequired
  };

  state = {
    isSelectedOptionHidden: false
  };

  componentDidMount() {
    if (this.props.activeVariable.source_statuses.length) {
      if (this.props.activeVariable.source_statuses.includes(DELETED_COLUMN)) {
        this.setState({ isSelectedOptionHidden: true });
      } else if (checkHasNoResponses(this.props.activeVariable)) {
        this.props.actions.setShouldGetTranformation(true);
      } else {
        this.props.actions.setShouldGetTranformation(false);
      }
    }
  }

  save = optionId => {
    this.props.actions.changeCurrentOptionId(optionId);
    this.props.actions.saveDataSource();
  };

  changeHeaderSource = format => {
    if (this.props.currentDataSource.sourceName === "Survey")
      this.props.actions.changeCurrentQuestionId(format.value);
    else this.props.actions.changeCurrentColumnId(format.value);
    this.props.actions.setHeaderId(format.value);
    this.saveVariableSource(format.value);
  };

  resetTransformation = () => {
    this.props.actions.setDataSourceShouldUpdate(false);
    if (!this.props.activeVariable.source_statuses.includes(UNPUBLISHED)) {
      const transformation = null;
      this.props.actions.changeCurrentTransformation(transformation);
    }
  };

  changeColumnClickHandler = format => {
    this.resetTransformation();
    this.props.actions.markHeaderAsReChanged(true);
    this.changeHeaderSource(format);
    this.setState({ isSelectedOptionHidden: false });
    this.props.actions.setShouldGetTranformation(true);
    this.props.actions.setConditionInitialState();
  };

  saveVariableSource = id => {
    let variableName = "";
    this.props.headers.map(header => {
      if (header.id == id) {
        if (header.title_en || header.title_fr) {
          variableName = getFormTitle(header, this.props.intlLocale);
        } else {
          variableName = header.label;
        }
      }
    });

    if (!!this.props.activeVariable && !!this.props.activeVariable.name) {
      if (!this.props.activeVariable.dataset) {
        const payload = { name: variableName };
        this.props.actions.setVariable(payload, this.props.activeVariable.id);
      } else {
        if (!this.props.isHeaderReChanged) {
          const payload = {
            name: this.props.activeVariable.name,
            variable: this.props.activeVariable.variable
          };
          this.props.actions.setVariable(payload, this.props.activeVariable.id);
        }
      }
    } else {
      this.props.actions.markHeaderAsReChanged(false);
      this.props.actions.createVariable(variableName);
    }

    this.props.actions.getTransformationValues();
  };

  componentDidUpdate(prevProps) {
    if (
      !!this.props.activeVariable.id &&
      this.props.shouldGetUpdate &&
      typeof this.props.currentDataSource.transformation !== "object" &&
      this.props.answers.length === 0
    ) {
      const { currentDataSource, activeVariable } = this.props;
      let objId = null;
      if (!!activeVariable && currentDataSource.sourceName === "Survey")
        objId = activeVariable.datasource_object.question_id;
      else objId = activeVariable.datasource_object.column_id;
      const format = {
        value: objId,
        label: activeVariable.datasource_type.name
      };
      if (!this.props.activeVariable.source_statuses.includes(NO_RESPONSES)) {
        this.resetTransformation();
      } else {
        this.props.actions.setDataSourceShouldUpdate(false);
      }
      this.changeHeaderSource(format);
    }
  }

  render() {
    const { headers, currentDataSource } = this.props;

    const filteredOptions = headers.filter(
      header =>
        !(
          header.question_type &&
          header.question_type.type === "file_upload"
        )
    );

    const selectOptions = filteredOptions.map(option => {
      if (currentDataSource.sourceName === "Survey") {
        return {
          value: option.id,
          label: getFormTitle(option, this.props.intlLocale)
        };
      } else if (currentDataSource.sourceName === "Upload")
        return { value: option.id, label: option.label };
    });

    const selectedOption = this.state.isSelectedOptionHidden
      ? null
      : parseInt(currentDataSource.headerId);

    return (
      <div className="source-select-column" id="source-select-column">
        {
          <SelectBox
            options={selectOptions}
            label={this.props.intl("source.column")}
            onHeaderChange={this.changeColumnClickHandler}
            locale={this.props.intlLocale}
            selectedOption={selectedOption}
            placeholder={this.props.intl("source.select_column")}
            labelClassName={"col-md-4"}
            SelectBoxClassNames={"col-md-8"}
            disabled={localStorage.getItem("permission") !== EDIT}
          />
        }
      </div>
    );
  }
}

export default InjectIntl(SourceColumn);
