import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap-modal";
import { InjectIntl } from "../common/InjectIntl";
import CreatableSelectBox from "../common/CreatableSelect";
import { CreateThemeModal } from "./CreateThemeModal";

const generateOptions = (array) => {
  return array
    ? array.map(({ id, name }) => {
        return {
          value: id,
          label: name,
        };
      })
    : [];
};

const EditFrameworkModal = (props) => {
  const {
    actions,
    intl,
    frameworks,
    isOutcomeSettingsModalOpen,
    themes,
    selectedThemes,
    measureName,
    measureDescription,
    measureId,
    stakeholderId,
    isProcessing,
  } = props;
  const [selectedOption, setSelectedOption] = useState({
    framework: undefined,
    theme: [],
  });
  const [measure, setMeasure] = useState({
    measureName: undefined,
    measureDescription: undefined,
  });
  const [frameworkAdded, setFrameworkAdded] = useState(null);
  const [themeAdded, setThemeAdded] = useState(null);
  const [createThemeModalIsOpen, setCreateThemeModalIsOpen] = useState(false);
  const [isThemeCreatable, setThemeCreatable] = useState(true);
  useEffect(() => {
    setMeasure({
      measureName,
      measureDescription,
    });
  }, [measureName, measureDescription]);
  useEffect(() => {
    if (selectedThemes && selectedThemes.length) {
      const themeOptionsProps = selectedThemes.map(({ id, name }) => {
        return {
          value: id,
          label: name,
        };
      });
      setSelectedOption({
        ...selectedOption,
        theme: themeOptionsProps,
      });
    } else {
      setSelectedOption({
        ...selectedOption,
        theme: [],
      });
    }
  }, [selectedThemes]);
  const handleCancel = () => {
    actions.setOutcomeFrameworkModal(false);
    setCreateThemeModalIsOpen(false);
  };
  const handleSubmit = () => {
    const themesIDs = selectedOption.theme.map((x) => x.value);
    actions.changeInputMeasureName(
      measure.measureName,
      measureId,
      measure.measureDescription,
      stakeholderId
    );
    actions.setMeasureTheme(themesIDs);
    actions.measureNameSave();
  };
  const frameworksOptions = generateOptions(frameworks);
  const themeOptions =
    themes.length > 0 && selectedOption.framework
      ? themes
          .filter(
            ({ framework_id }) =>
              framework_id === selectedOption.framework.value
          )
          .map(({ id, name }) => {
            return {
              value: id,
              label: name,
            };
          })
      : [];
  const handleSelectFramework = (data, name) => {
    setSelectedOption({
      ...selectedOption,
      [name]: data,
    });
    const currentFramework = frameworks.find((x) => x.id === data.value);
    if (currentFramework) setThemeCreatable(currentFramework.user_id !== null);
  };
  const handleSelectTheme = (data, name) => {
    setSelectedOption({
      ...selectedOption,
      [name]: data,
    });
  };
  const handleInputChange = (e, name) => {
    setMeasure({
      ...measure,
      [name]: e.target.value,
    });
  };
  const handleCreateFramework = (value) => {
    actions.createFramework(value);
    setFrameworkAdded(value);
  };
  const handleCreateTheme = (value) => {
    if (value) {
      setThemeAdded(value);
    }
    setCreateThemeModalIsOpen(!createThemeModalIsOpen);
  };
  useEffect(() => {
    if (frameworkAdded) {
      const currentFramework = frameworks.find(
        (x) => x.name === frameworkAdded
      );
      if (currentFramework) {
        setSelectedOption({
          ...selectedOption,
          framework: {
            label: currentFramework.name,
            value: currentFramework.id,
          },
        });
        setFrameworkAdded(null);
      }
    }
  }, [frameworks, frameworkAdded]);
  useEffect(() => {
    const currentTheme = themes && themes.find((x) => x.name === themeAdded);
    if (themeAdded && currentTheme) {
      const themesArray = selectedOption.theme;
      const currentThemeFormatted = {
        value: currentTheme.id,
        label: currentTheme.name,
      };
      themesArray.push(currentThemeFormatted);
      setSelectedOption({
        ...selectedOption,
        themes: themesArray,
      });
      setThemeAdded(null);
    }
  }, [themes, themeAdded]);
  const isSubmitButtonDisabled =
    !measure.measureName ||
    !measure.measureDescription ||
    !selectedOption.framework;
  return (
    <Modal
      dialogClassName={"modal-lg"}
      show={isOutcomeSettingsModalOpen}
      id={"foreground_display"}
    >
      <Modal.Header>
        <Modal.Title>
          {intl("collaborators.permissions.edit")}{" "}
          {intl("stakeholder.items.outcome")}
        </Modal.Title>
        <Modal.Body>
          {createThemeModalIsOpen ? (
            <CreateThemeModal
              saveTheme={handleCreateTheme}
              intl={intl}
              actions={actions}
              frameworkId={selectedOption.framework.value}
              themeName={themeAdded}
            />
          ) : (
            <>
              <div className="row">
                <input
                  className="form-control success form-control-updated"
                  type="text"
                  value={measure.measureName}
                  placeholder={intl(
                    "changeMeasureName.modal.input.namePlaceholder"
                  )}
                  style={{ fontSize: "13px" }}
                  onChange={(e) => handleInputChange(e, "measureName")}
                />
              </div>
              <div style={{ height: "15px" }} />
              <div className="row">
                <input
                  className="form-control success form-control-updated"
                  type="text"
                  value={measure.measureDescription}
                  placeholder={intl(
                    "changeMeasureName.modal.title.descriptionPlaceholder"
                  )}
                  style={{ fontSize: "13px" }}
                  onChange={(e) => handleInputChange(e, "measureDescription")}
                />
              </div>
              <div style={{ height: "15px" }} />
              <CreatableSelectBox
                options={frameworksOptions}
                label={intl("editFramework.modal.frameworkNamePlaceholder")}
                placeholder={intl(
                  "editFramework.modal.frameworkNamePlaceholder"
                )}
                onHeaderChange={(data) =>
                  handleSelectFramework(data, "framework")
                }
                selectedOption={selectedOption.framework}
                handleCreateOption={handleCreateFramework}
                disabled={isProcessing}
                isCreatable
              />
              <div style={{ height: "15px" }} />
              <CreatableSelectBox
                options={themeOptions}
                label={intl("editFramework.modal.themeNamePlaceholder")}
                placeholder={intl("editFramework.modal.themeNamePlaceholder")}
                onHeaderChange={(data) => handleSelectTheme(data, "theme")}
                selectedOption={selectedOption.theme}
                disabled={!selectedOption.framework}
                multiSelect
                handleCreateOption={handleCreateTheme}
                isCreatable={isThemeCreatable}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline" onClick={handleCancel}>
            {intl("common.text.cancel_button")}
          </button>
          <button
            className="btn btn-success"
            disabled={isSubmitButtonDisabled}
            onClick={handleSubmit}
          >
            {intl("common.text.save_button")}
          </button>
        </Modal.Footer>
      </Modal.Header>
    </Modal>
  );
};

export default InjectIntl(EditFrameworkModal);
