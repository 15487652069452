import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LoginActions from '../actions/login';
import Invitation from "../components/Invitation";
import manageCollaboratorsAction from "../actions/manage-callaborator";
import LogicModelsActions from "../actions/logic-models";

function mapStateToProps(state, ownProps) {
  return {
    ...state.login,
    accessToken: ownProps.params.access_token,
    invitationId: ownProps.params.id ? ownProps.params.id : ownProps.params.splat,
    isAuth: state.application.isAuthenticated,
    invitationEmail: ownProps.location.query.email
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...LoginActions,
      ...manageCollaboratorsAction,
      ...LogicModelsActions
    }, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(Invitation)