import React from "react";
import PropTypes from "prop-types";
import { BROKEN, INCOMPLETE } from "../sources/source-table-layout/constants";

export const VariableStatus = ({ status }) => {
  const classNames = {
    [BROKEN]: "fa fa-warning broken-variable-status",
    [INCOMPLETE]: "fa fa-circle-o-notch incomplete-variable-status",
    default: "fa fa-check-circle-o complete-variable-status"
  };

  return (
    <td className="text-center align-middle status-column">
      <i className={classNames[status] || classNames["default"]} />
    </td>
  );
};

VariableStatus.propTypes = {
  status: PropTypes.string.isRequired
};

export default VariableStatus;
