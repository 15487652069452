import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import manageMeasureActions from "../../../../actions/measure/manage-measure";
import financialActions from "../../../../actions/stakeholder-items/financialProxy";
import ontologyActions from "../../../../actions/ontology";
import measureActions from "../../../../actions/measure/measure";
import FinancialProxy from "../../../../components/logic-models/stakeholder-items/add-financial-proxy/FinancialProxy";


function mapStateToProps(state, ownProps) {
  return {
    ...state.measurePage,
    ...state.dataSource,
    ...state.activities,
    ...state.ontology,
    ...state.financialProxy,
    financialProxy: ownProps.financialProxy,
    stakeholder: ownProps.stakeholder,
    intl: state.intl
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...manageMeasureActions,
      ...measureActions,
      ...financialActions,
      ...ontologyActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialProxy)