import React from "react";
import { InjectIntl } from "../common/InjectIntl";


class LogicModelCreate extends React.Component {

  handleChangeName = (event) => this.props.actions.changeLogicModelNameFormCreate(event.target.value);

	renderErrors = (errors) => {
		const hasErrors = errors.length > 0;

		if (!hasErrors) return false;

		return (
			errors.map((error, index) => (
				<span key={index} className="helper-text text-danger">{error}</span>
			))
		)
	};

	render() {
		const {errors} = this.props;
		return (
			<div>
				<form className="form"
				      id="createLogicModelForm"
				>
					<div className="form-group">
						<label>{this.props.intl('create_logic_model.name')}</label>

						<input type="text"
						       className="form-control success"
						       name="name"
						       value={this.props.name}
						       onChange={this.handleChangeName}
						       placeholder={this.props.intl('create_logic_model.placeholder')}
						       required
						/>
						{this.renderErrors(errors)}
					</div>

				</form>
			</div>
		)
	}
}

export default InjectIntl(LogicModelCreate);