import React from "react";
import PropTypes from "prop-types";

const ShowTotal = ({ total }) => {
  return (
    <div id="total-records-number">
      <span>
        No. of Records <strong>{total}</strong>
      </span>
    </div>
  );
};

ShowTotal.propTypes = {
  total: PropTypes.number.isRequired
};

export default ShowTotal;
