export const ADD_OUTCOMES_MEASURE = '@@OUTCOMES/ADD_OUTCOMES_MEASURE';
export const ADD_OUTCOMES_MEASURE_SUCCESS = '@@OUTCOMES/ADD_OUTCOMES_MEASURE_SUCCESS';
export const ADD_OUTCOMES_MEASURE_FAILURE = '@@OUTCOMES/ADD_OUTCOMES_MEASURE_FAILURE';

export const SHOW_OUTCOMES_FIELD = '@@OUTCOMES/SHOW_OUTCOMES_FIELD';
export const HIDE_OUTCOMES_FIELD = '@@OUTCOMES/HIDE_OUTCOMES_FIELD';

export const CHANGE_OUTCOMES_MEASURE_NAME = '@@OUTCOMES/CHANGE_OUTCOMES_MEASURE_NAME';

function addOutcomesMeasure() {
  return { type: ADD_OUTCOMES_MEASURE }
}

function showMeasureNameOutcomesField() {
  return { type: SHOW_OUTCOMES_FIELD }
}

function hideMeasureNameOutcomesField() {
  return { type: HIDE_OUTCOMES_FIELD }
}

function changeOutcomesMeasureName(measureName, measureId) {
  return { type: CHANGE_OUTCOMES_MEASURE_NAME, measureName, measureId }
}

export default {
  addOutcomesMeasure,
  showMeasureNameOutcomesField,
  hideMeasureNameOutcomesField,
  changeOutcomesMeasureName
}