import merge from 'xtend';
import createReducer from './create-reducer';

import { CLEAR_ERROR, SHOW_ERROR } from "../actions/errors";

const INITIAL_STATE = {
  errors: '',
  showErrors: false
};

export default createReducer({
  [SHOW_ERROR]: (state, action) => merge(state, {
    showErrors: true,
    errors: action.errorText
  }),
  [CLEAR_ERROR]: (state, action) => merge(state, INITIAL_STATE)
}, INITIAL_STATE)