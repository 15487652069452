import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LookupValueModal from "../components/logic-models/LookupValueModal";
import lookupValueActions from "../actions/logic-models";

function mapStateToProps(state, ownProps) {
    return {
        ...state.logicModels,
    }
}

function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...lookupValueActions,
        },
        dispatch
      ),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(LookupValueModal);