import React from "react";
import PropTypes from "prop-types";
import { InjectIntl } from "../../../common/InjectIntl";
import AddOutcomeParams from "../../../../containers/logic-models/stakeholders-items/add-outcome/OutcomeItem.container";
import PermissionChecker from "../../../common/PermissionChecker";


class Outcomes extends React.Component {

  static propTypes = {
    stakeholder: PropTypes.object.isRequired,
    outcomes: PropTypes.array.isRequired,
    actions: PropTypes.shape({
      addOutcomesMeasure: PropTypes.func,
      changeStakeholderId: PropTypes.func,
      logicModelLoading: PropTypes.func,
    }.isRequired)
  };

  state = {
    isHovering : false,
  };

  handleMouseHover=()=> {
    this.setState({ isHovering : !this.state.isHovering });
  };

  handleMouseMove = () => {
    if(!this.state.isHovering) {
      this.setState({ isHovering : true });
    }
  };

  addOutcomeHandlerClick = () => {
    this.props.actions.logicModelLoading();
    this.props.actions.changeStakeholderId(this.props.stakeholder.id);
    this.props.actions.addOutcomesMeasure();
  };


  _shouldDisplay() {
    return (this.props.outcomes.length > 0)
  }


  render() {

    const visibility = this.state.isHovering ? "visible" : "hidden";
    return (
      <div className="editable-measures-list" onMouseEnter={this.handleMouseHover}
           onMouseLeave={this.handleMouseHover} onMouseMove={this.handleMouseMove}>
        <div className="text-right add-measure-button-wrap" style={{visibility: visibility}}>
          <PermissionChecker hide={true}>
            <button className="btn btn-success btn-outline" onClick={this.addOutcomeHandlerClick} >
              {this.props.intl('stakeholder.items.add_outcome')}
            </button>
          </PermissionChecker>

        </div>
        { this._shouldDisplay() ?
          this.props.outcomes.map((outcome, index) =>
            <AddOutcomeParams stakeholder={this.props.stakeholder} key={index} measure={outcome}/>
          )
          :
          <PermissionChecker hide={true}>
            <div className="large-add-measure-button m-t" onClick={this.addOutcomeHandlerClick}>
              <i className="fa fa-plus"/> {this.props.intl('stakeholder.items.add_outcome')}
            </div>
          </PermissionChecker>
        }
      </div>

    )
  }
}

export default InjectIntl(Outcomes);