import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import manageMeasureActions from "../../../../actions/measure/manage-measure";
import demographicActions from "../../../../actions/stakeholder-items/demographics";
import MeasureIcons from "../../../../components/logic-models/stakeholder-items/MeasureIcons";
import CalculationActions from "../../../../actions/calculation/calculation-page"

function mapStateToProps(state, ownProps) {
  return {
    ...state.manageMeasure,
    logicModelId: state.measurePage.logicModelId,
    deleteHandler: ownProps.deleteHandler,
    measureId: ownProps.measureId,
    collapseIconShow: ownProps.collapseIconShow,
    adjustment: ownProps.adjustment,
    measureTitle: ownProps.measureTypeTitle,
    intl: ownProps.intl
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...manageMeasureActions,
      ...CalculationActions,
      hideDemographic: demographicActions.hideDemographic
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeasureIcons)

