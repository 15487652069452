const chartColors = [
  '#7CC6E4',
  '#8BC34A',
  '#FF5722',
  '#FFC107',
  '#9C27B0',
  '#009688',
  '#CDDC39',
  '#FF9800',
  '#607D8B',
  '#E91E63',
  '#4CAF50',
  '#3F51B5',
  '#795548'
];

export const getColor = (i) => {
  return chartColors[i%10];
}

export default chartColors;