import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MeasurePage from "../components/measure/MeasurePage";
import measureActions from '../actions/measure/measure';
import manageMeasureActions from '../actions/measure/manage-measure';
import logicModalActions from '../actions/logic-models';
import dataSourceActions from '../actions/calculation/data-source';
import SpinnerActions from "../actions/spinner";
import ErrorsActions from "../actions/errors";


function mapStateToProps(state, ownProps) {
	return {
		...state.measurePage,
		...state.manageMeasure,
		...state.dataSource,
		...state.inputs,
		...state.outcomes,
		...state.activities,
    ...state.logicModels,
    showDemographic: state.demographics.showDemographic,
		showAdjustments: state.financialProxy.showAdjustments,
		id: ownProps.params.id,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			...measureActions,
			...manageMeasureActions,
			...dataSourceActions,
			...logicModalActions,
			...SpinnerActions,
			...ErrorsActions
    }, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MeasurePage)