import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import manageMeasureActions from "../../../../actions/measure/manage-measure";
import measureActions from "../../../../actions/measure/measure";
import outcomeActions from "../../../../actions/stakeholder-items/outcomes";
import Outcomes from "../../../../components/logic-models/stakeholder-items/add-outcomes/Outcomes";


function mapStateToProps(state, ownProps) {
  return {
    ...state.measurePage,
    ...state.dataSource,
    ...state.outcomes,
    outcomes: ownProps.outcomes,
    stakeholder: ownProps.stakeholder
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...manageMeasureActions,
      ...measureActions,
      ...outcomeActions,
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Outcomes)
