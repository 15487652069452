import merge from 'xtend' ;
import createReducer from '../create-reducer';
import {
  FETCH_LOGIC_MODEL,
  FETCH_LOGIC_MODEL_FAILURE,
  FETCH_LOGIC_MODEL_SUCCESS,
  DELETE_STAKEHOLDER,
  DELETE_STAKEHOLDER_SUCCESS,
  DELETE_STAKEHOLDER_FAILURE,
  ADD_STAKEHOLDER_SUCCESS,
  CHANGE_STAKEHOLDER_NAME_INPUT_SHOW,
  CHANGE_STAKEHOLDER_NAME_INPUT_HIDE,
  CHANGE_STAKEHOLDER_NAME,
  STAKEHOLDER_RECOMMENDATIONS_SUCCESS,
  STAKEHOLDER_RECOMMENDATIONS_FAILURE,
  SAVE_STAKEHOLDER_NAME_SUCCESS,
  SAVE_STAKEHOLDER_NAME,
  CHANGE_STAKEHOLDER,
  LOGIC_MODEL_IS_LOADING,
  CHANGE_PARENT_MEASURE_ID,
  SET_INITIAL_STATE,
  SHOW_DEFINITIONS,
  HIDE_DEFINITIONS,
  SET_MEASURE_THEME,
} from "../../actions/measure/measure";
import { CHANGE_INPUT_MEASURE_NAME } from "../../actions/stakeholder-items/inputs";
import { CHANGE_ACTIVITY_MEASURE_NAME } from "../../actions/stakeholder-items/activities";
import { CHANGE_OUTCOMES_MEASURE_NAME } from "../../actions/stakeholder-items/outcomes";
import { CHANGE_OUTPUT_MEASURE_NAME } from "../../actions/stakeholder-items/output";
import { CHANGE_OUTCOME_INDICATOR_MEASURE_NAME } from "../../actions/stakeholder-items/outcomeIndicator";
import { CHANGE_FINANCIAL_PROXY_MEASURE_NAME } from "../../actions/stakeholder-items/financialProxy";
import {
  ADD_MEASURE_SUCCESS,
  SET_MEASURE_ID,
  SET_MEASURE_NAME,
  SET_CHANGE_MEASURE_NAME_MODAL_OPEN,
} from "../../actions/measure/manage-measure";
import { CHANGE_DEMOGRAPHIC_MEASURE_NAME } from "../../actions/stakeholder-items/demographics";
import { EDIT_LOGIC_MODEL_NAME_SUCCESS, EDIT_LOGIC_MODEL_NAME_FAILURE } from "../../actions/logic-models";

const INITIAL_STATE = {
	confirmDeleteModal: false,
  changeNameInputShow: false,
  stakeHolderName: '',
	recommendedStakeholders: [],
	errors: {},
	isLoading: false,
  logicModel: {
    stakeholders: [],
    invitations: [],
  },
	logicModelId: '',
  stakeholderId: '',
	newMeasureName: '',
	newMeasureDescription: '',
  measureId: null,
	parentMeasureId: null,
	isDefinitionsShowed: false,
	changeMeasureNameModalOpen: false,
	measureThemes: []
};

export function changeStakeholder(logicModel, newStakeholder) {
	logicModel.stakeholders.map((stakeholder) => {
		if (stakeholder.id === newStakeholder.id) {
			stakeholder.name = newStakeholder.name;
		}
	});
	return logicModel
}

export function addLogicModelStakeholder(newStakeholder, logicModel) {
	const newStakeholders = [newStakeholder].concat(logicModel.stakeholders);
  logicModel.stakeholders = newStakeholders;
  return logicModel
}

function changeLogicModelStakeholderMeasure(logicModel, newStakeholder) {
	logicModel.stakeholders.forEach(stakeholder => {
    if (stakeholder.id === newStakeholder.id) {
    	stakeholder.measures = newStakeholder.measures;
		}
  });
  return logicModel
}

export default createReducer({
	[CHANGE_STAKEHOLDER]: (state, action) => merge(state, { stakeholderId: action.stakeholderId}),
  [CHANGE_STAKEHOLDER_NAME_INPUT_SHOW]: (state) => merge(state, {changeNameInputShow: true}),
  [EDIT_LOGIC_MODEL_NAME_SUCCESS]: (state, action) => merge(state, {
  	logicModel: action.payload.logic_model
  }),
	[EDIT_LOGIC_MODEL_NAME_FAILURE]: (state, action) => merge(state, {errors: action.errors }),
  [CHANGE_STAKEHOLDER_NAME_INPUT_HIDE]: (state) => merge(state, {changeNameInputShow: false}),
	[CHANGE_STAKEHOLDER_NAME]: (state, action) => merge(state, {
		stakeHolderName: action.name,
		stakeholderId: action.stakeholderId
	}),
	[SAVE_STAKEHOLDER_NAME]: (state) => merge(state, {isLoading: true}),
	[SAVE_STAKEHOLDER_NAME_SUCCESS]: (state, action) => merge(state, {
		isLoading: false,
		logicModel: changeStakeholder(state.logicModel, action.payload.stakeholder)
	}),
	[STAKEHOLDER_RECOMMENDATIONS_SUCCESS]: (state, action) => merge(state, {recommendedStakeholders: action.payload.response}),
	[STAKEHOLDER_RECOMMENDATIONS_FAILURE]: (state, action) => merge(state, {errors: action.errors}),
	[ADD_STAKEHOLDER_SUCCESS]: (state, action) => merge(state, {
		logicModel: addLogicModelStakeholder(action.payload.newStakeholder, state.logicModel)
	}),
	[FETCH_LOGIC_MODEL]: (state, action) => merge(state, {isLoading: true, logicModelId: action.id}),
	[FETCH_LOGIC_MODEL_SUCCESS]: (state, action) => merge(state, {
		isLoading: false,
		logicModel: action.logic_model,
	}),
	[FETCH_LOGIC_MODEL_FAILURE]: (state, action) => merge(state, {isLoading: false, errors: action.payload.errors}),
	[DELETE_STAKEHOLDER]: (state, action) => merge(state, {stakeholderId: action.id, isLoading: true}),
	[DELETE_STAKEHOLDER_SUCCESS]: (state, action) => merge(state, {isLoading: false}),
	[DELETE_STAKEHOLDER_FAILURE]: (state, action) => merge(state, {isLoading: false, errors: action.payload.errors}),
	[LOGIC_MODEL_IS_LOADING]: (state) => merge(state, {isLoading: true}),
	[CHANGE_INPUT_MEASURE_NAME]: (state, action) => merge(state, {newMeasureName: action.measureName, newMeasureDescription: action.newMeasureDescription, measureId: action.measureId, stakeholderId: action.stakeholderId}),
	[CHANGE_ACTIVITY_MEASURE_NAME]: (state, action) => merge(state, {newMeasureName: action.measureName, measureId: action.measureId}),
	[CHANGE_OUTCOMES_MEASURE_NAME]: (state, action) => merge(state, {newMeasureName: action.measureName, measureId: action.measureId}),
	[CHANGE_OUTPUT_MEASURE_NAME]: (state, action) => merge(state, {newMeasureName: action.measureName, measureId: action.measureId}),
	[CHANGE_DEMOGRAPHIC_MEASURE_NAME]: (state, action) => merge(state, {newMeasureName: action.measureName, measureId: action.measureId}),
	[CHANGE_OUTCOME_INDICATOR_MEASURE_NAME]: (state, action) => merge(state, {newMeasureName: action.measureName, measureId: action.measureId}),
	[CHANGE_FINANCIAL_PROXY_MEASURE_NAME]: (state, action) => merge(state, {newMeasureName: action.measureName, measureId: action.measureId}),
	[CHANGE_PARENT_MEASURE_ID]: (state, action) => merge(state, {parentMeasureId: action.parentMeasureId}),
	[SET_MEASURE_NAME]: (state, action) => merge(state, {measureId: action.measureId, newMeasureName: action.name, newMeasureDescription: action.description }),
	[ADD_MEASURE_SUCCESS]: (state, action) => merge(state, {logicModel: changeLogicModelStakeholderMeasure(state.logicModel, action.payload.stakeholder)}),
	[SET_MEASURE_ID]: (state, action) => merge(state, {measureId: action.measureId}),
	[SHOW_DEFINITIONS]: (state, action) => merge(state, {isDefinitionsShowed: true}),
  [HIDE_DEFINITIONS]: (state, action) => merge(state, {isDefinitionsShowed: false}),
	[SET_INITIAL_STATE]: (state, action) => merge(state, INITIAL_STATE),
	[SET_CHANGE_MEASURE_NAME_MODAL_OPEN]: (state, action) => merge(state, {changeMeasureNameModalOpen: action.status}),
	[SET_MEASURE_THEME]: (state,action) => merge(state, {
		measureThemes: action.themes
	})
}, INITIAL_STATE)