import React from 'react';
import App from "./components/App";
import Layout from "./containers/Layout";
import { Route } from "react-router";
import HomePage from "./containers/HomePage.container";
import Login from "./containers/Login.container";
import EditPageIndex from "./containers/MeasurePage.container";
import EditMeasure from "./containers/EditMeasure.container";
import ManageCollaboratorPage from "./containers/collaborators/ManageCollaboratorsPage.container";
import Invitation from "./containers/Invitation.container";
import CalculationsIndex from "./containers/calculations/CalculationsIndex.container";
import NotFound from "./components/NotFound";
import Reports from "./containers/Reports.container";

export default (
  <Route component={App}>
    <Route path="login" component={Login}/>
    <Route path="/" component={Layout}>
	    <Route path="logic-models" component={HomePage}/>
      <Route path="logic-models/:id" component={EditPageIndex}>
        <Route path="edit" component={EditMeasure}/>
        <Route path="collaborators" component={ManageCollaboratorPage}/>
        <Route path="calculations/:measureId" component={CalculationsIndex}/>
      </Route>
      <Route path="invitation/:id/:access_token" component={Invitation}/>
      <Route path="reports" component={Reports} />
    </Route>
    <Route path="*" component={NotFound}/>
  </Route>
)