import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap-modal";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router";
import classNames from "classnames";
import PermissionChecker from "../../common/PermissionChecker";

const MeasureIcons = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDeleteButtonDisabled, setButtonDisabled] = useState(true);
  const {
    adjustment,
    collapsed,
    collapseIconShow,
    deleteHandler,
    adjustmentModalClickHandler,
    logicModelId,
    measureId,
    measureTitle,
    actions,
    intl,
  } = props;
  const arrowClassNames = classNames("fa", {
    "fa-chevron-down": collapsed,
    "fa-chevron-up": !collapsed,
  });

  function arrowHandlerClick(e) {
    e.preventDefault();
    actions.showMeasure(!collapsed, measureId);
  }

  const handleCopyPaste = () => {
    actions.openMeasureModal(measureId, collapseIconShow);
  }

  function calcClick(){
    actions.setMeasureId(measureId);
    if (measureTitle === "Demographics") actions.hideDemographic();
  }
  const handleDeleteModal = (status) => {
    setModalOpen(status);
  };
  const handleInput = (e) => {
    if (e.target.value === "DELETE") {
      setButtonDisabled(false);
    }else{
      setButtonDisabled(true);
    }
  };
  const handleDelete = () => {
    deleteHandler();
    setModalOpen(false);
  }
  const handleSetup = () => {
    actions.setMeasureId(measureId);
    actions.getMeasureCalculations(0);
    actions.setOutcomeFrameworkModal(true)
  }
  
  return (
    <>
      <div>
        <div className="tools">
          <div className="col-md-4">
            <div className="ibox-tools">
              {adjustment && (
                <a href="#!" className="adjustments-link">
                  <i
                    className="fa fa-gear"
                    data-tip="Adjustments"
                    onClick={adjustmentModalClickHandler}
                  />
                  <ReactTooltip />
                </a>
              )}
              {measureTitle === "Outcome" && (
                <Link className="calculation-link">
                  <i className="fa fa-cog" onClick={handleSetup} />
                </Link>
              )}
              {measureTitle !== "Activity" && (
                <Link
                  to={`/logic-models/${logicModelId}/calculations/${measureId}?measureTitle=${measureTitle}`}
                  className="calculation-link"
                >
                  <i className="fa fa-calculator" onClick={calcClick} />
                </Link>
              )}
              <a href="#!" className="collapse-link" onClick={handleCopyPaste}>
                <i className="fa fa-copy" />
              </a>
              {collapseIconShow && (
                <a
                  href="#!"
                  className="collapse-link"
                  onClick={arrowHandlerClick}
                >
                  <i className={arrowClassNames} />
                </a>
              )}
              <PermissionChecker hide={true}>
                <a
                  href="#!"
                  className="close-link"
                  onClick={() => handleDeleteModal(true)}
                >
                  <i className="fa fa-times" />
                </a>
              </PermissionChecker>
            </div>
          </div>
        </div>
      </div>
      <Modal
        id={"foreground_display"}
        dialogClassName={"modal-lg"}
        show={isModalOpen}
      >
        <Modal.Header>
          <Modal.Title>{intl("deleteQuestionModal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{intl("deleteQuestionModal.deleteTypeText")}</p>
          <input
            class="form-control success"
            style={{ paddingTop: 0 }}
            onChange={handleInput}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className=" btn btn-outline"
            onClick={() => handleDeleteModal(false)}
          >
            {intl("common.text.close_button")}
          </button>
          <button
            className=" btn btn-danger"
            onClick={handleDelete}
            disabled={isDeleteButtonDisabled}
          >
            {intl("common.text.delete")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

MeasureIcons.propTypes = {
  deleteHandler: PropTypes.func.isRequired,
  logicModelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  measureTitle: PropTypes.string.isRequired,
  measureId: PropTypes.number.isRequired,
  collapsed: PropTypes.bool.isRequired,
  collapseIconShow: PropTypes.bool.isRequired,
  adjustmentModalClickHandler: PropTypes.func,
  adjustment: PropTypes.bool.isRequired,
  collapsedMeasures: PropTypes.array.isRequired,
  actions: PropTypes.shape(
    {
      showMeasure: PropTypes.func,
      setMeasureId: PropTypes.func,
      hideDemographic: PropTypes.func.isRequired,
    }.isRequired
  ),
};

export default MeasureIcons;
