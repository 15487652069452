import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LogicModelsActions from '../../actions/logic-models';
import CreateLogicModelForm from "../../components/logic-models/CreateLogicModelsForm";
import ErrorsActions from "../../actions/errors";

function mapStateToProps(state, ownProps) {
  return state.logicModels
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...LogicModelsActions,
      ...ErrorsActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLogicModelForm)