import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import Modal from "react-bootstrap-modal";
import DefinitionsItem from "./DefinitionsItem";
import { InjectIntl } from "../../../common/InjectIntl";
import { whiteLabel } from '../../../utils';

const Definitions = ({ isDefinitionsShowed, actions, intl }) => {
  const settings = {
    className: "slider variable-width",
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    focusOnSelect: true
  };

  return (
    <Modal
      dialogClassName="modal-xl"
      show={isDefinitionsShowed}
      id="foreground_display"
      data-brand={whiteLabel()}
    >
      <Modal.Header>
        <Modal.Title id="ModalHeader" className="text-center m-md">
          {intl("definitions.title")}
          <i
            onClick={actions.hideDefinitions}
            className="fa fa-close pull-right"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="gray-bg">
        <div>
          <Slider {...settings}>
            <DefinitionsItem
              title={intl("stakeholder.item")}
              text={intl("definitions.stakeholder")}
            />
            <DefinitionsItem
              title={intl("stakeholder.items.input")}
              text={intl("definitions.input")}
            />
            <DefinitionsItem
              title={intl("stakeholder.items.activity")}
              text={intl("definitions.activity")}
            />
            <DefinitionsItem
              title={intl("stakeholder.items.output")}
              text={intl("definitions.output")}
            />
            <DefinitionsItem
              title={intl("stakeholder.items.outcome")}
              text={intl("definitions.outcome")}
            />
            <DefinitionsItem
              title={intl("stakeholder.items.outcome_indicator")}
              text={intl("definitions.outcome_indicator")}
            />
            <DefinitionsItem
              title={intl("stakeholder.items.financial_proxy")}
              text={intl("definitions.financial_proxy")}
            />
          </Slider>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={actions.hideDefinitions}
          className="btn btn-default modal-button"
        >
          {intl("common.text.exit")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

Definitions.propTypes = {
  isDefinitionsShowed: PropTypes.bool.isRequired,
  intl: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    hideDefinitions: PropTypes.func.isRequired
  }).isRequired
};

export default InjectIntl(Definitions);
