const STYLEFORLINK = {
  backgroundColor: 'white',
  opacity: 0,
  backgroundRepeat: 'no-repeat',
  border: 'none',
  cursor: 'pointer',
  overflow: 'hidden',
  outline: 'none',
  display: 'block',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  className: 'dropdown-toggle'
};

export {STYLEFORLINK}

export const whiteLabel = () => {
  const whiteLabels = ["rbc"];
  const whiteLabel = whiteLabels.find((brand) =>
    window.location.href.includes(brand)
  );
  return whiteLabel;
};
