import React from 'react';
import PropTypes from 'prop-types';
import { InjectIntl } from "../../../common/InjectIntl";
import OutcomeIndicator from "../../../../containers/logic-models/stakeholders-items/add-outcome-indicator/OutcomeIndicator.container";
import MeasureNameComponent from "../MeasureNameComponent";
import PermissionChecker from '../../../common/PermissionChecker';


const AddOutcomesParams = (props) => {

  const { measure, collapsedMeasures, stakeholder, actions, intl, outcomesMeasureNameFieldDisable } = props;

  const closeHandler = () => actions.hideMeasureNameOutcomesField();

  const saveOutcomesMeasureName = (outcomeName) => actions.changeOutcomesMeasureName(outcomeName, measure.id);

  const changeOutcomesMeasure = () => actions.showMeasureNameOutcomesField();

  function handleAddOutcomeIndicator(e) {
    e.preventDefault();
    actions.changeStakeholderId(stakeholder.id);
    actions.changeParentMeasureId(measure.id);
    actions.addOutcomeIndicatorMeasure();
  }

  return (
    <div className="ibox m-t">
      <MeasureNameComponent
        closeHandler={closeHandler}
        saveMeasureName={saveOutcomesMeasureName}
        changeMeasure={changeOutcomesMeasure}
        measureNameFieldDisable={outcomesMeasureNameFieldDisable}
        measureTypeTitle={intl('stakeholder.items.outcome')}
        collapseIconShow={true}
        measureNameClassName={"ibox-title"}
        placeholder={intl('stakeholder.items.outcome.placeholder')}
        {...props}
      />

      {!collapsedMeasures.includes(measure.id) && <div className="ibox-content">
        <div className="text-muted" style={{ display: "none" }}/>

        {measure.outcome_indicators.map((outcomeIndicator, index) =>
          <OutcomeIndicator key={index} outcomeIndicator={outcomeIndicator} stakeholder={stakeholder}/>
        )}
        <PermissionChecker hide={true}>
          <button className="btn btn-success btn-outline btn-block" onClick={handleAddOutcomeIndicator}>
            <i className="fa fa-plus"/> {intl('stakeholder.items.add_outcome_indicator')}
          </button>
        </PermissionChecker>
      </div>
      }
    </div>
  );
};

AddOutcomesParams.propTypes = {
  stakeholder: PropTypes.object.isRequired,
  measure: PropTypes.object.isRequired,
  collapsedMeasures: PropTypes.array.isRequired,
  outcomesMeasureNameFieldDisable: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    changeStakeholderId: PropTypes.func,
    showMeasureNameOutcomesField: PropTypes.func,
    hideMeasureNameOutcomesField: PropTypes.func,
    measureNameSave: PropTypes.func,
    addOutcomeIndicatorMeasure: PropTypes.func,
    changeOntologyQuery: PropTypes.func,
    searchForOntology: PropTypes.func,
    setOntologyInitialState: PropTypes.func
  }.isRequired)
};

export default InjectIntl(AddOutcomesParams);