export const REQUEST = '@@LOGIN/REQUEST';
export const REQUEST_SUCCESS = '@@LOGIN/REQUEST_SUCCESS';
export const REQUEST_FAILURE = '@@LOGIN/REQUEST_FAILURE';
export const LOGOUT = '@@LOGIN/LOGOUT';
export const ALREADY_LOGGED_IN = '@@LOGIN/ALREADY_LOGGED_IN';
export const REDIRECT_TO_ATLAS = '@@LOGIN/REDIRECT_TO_ATLAS';
export const UNAUTHORIZED_REQUEST = '@@LOGIN/UNAUTHORIZED_REQUEST';

function logIn(accessToken, refreshToken, redirectPath, locale='en') {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('locale', locale);
  if (redirectPath) {
    const cleanedRedirectPath = redirectPath.startsWith('/') ? redirectPath.slice(1) : redirectPath // Other places in the code send without prefix, login method epic adds it as prefix
    localStorage.setItem('redirectPath', cleanedRedirectPath);
  }
  return { type: REQUEST }
}

function logout() {
  return { type: LOGOUT }
}

function unauthorizedRequest() {
  return { type: UNAUTHORIZED_REQUEST }
}

function alreadyLoggedIn() {
  return { type: ALREADY_LOGGED_IN }
}

function loadAuth() {
  const token = localStorage.getItem('token');
  const user_id = localStorage.getItem('user_id');
  const first_name = localStorage.getItem('first_name');
  const last_name = localStorage.getItem('last_name');
  const email = localStorage.getItem('email');
  const name = `${first_name} ${last_name}`;
  const hasTokenAndUserId = !!token && !!user_id;
  if (hasTokenAndUserId) {
    return { type: REQUEST_SUCCESS, payload: { token, user_id, first_name, last_name, email, name } }
  } else {
    return { type: REQUEST_FAILURE, errors: [1] }
  }
}

function serviceRedirect(invitationId, accessToken, page, invitationEmail) {
  const atlasBaseUrl = localStorage.getItem('atlas_base_url');
  let interval = setInterval(() => {
    if (atlasBaseUrl) {
      window.location.replace(`${atlasBaseUrl}/${page}?redirectTo=rucksack&redirectPath=invitation/${invitationId}/${accessToken}&email=${invitationEmail}`);
      clearInterval(interval);
      return { type: REDIRECT_TO_ATLAS }
    }
  }, 300)
}

export default { logIn, logout, loadAuth, alreadyLoggedIn, serviceRedirect, unauthorizedRequest }