import merge from 'xtend';
import createReducer from './create-reducer';

import {
  FETCH_LOGIC_MODELS,
  FETCH_LOGIC_MODELS_SUCCESS,
  CREATE_LOGIC_MODEL_DIALOG_PAGE_SHOW,
  CREATE_LOGIC_MODEL_DIALOG_PAGE_HIDE,
  CHANGE_LOGIC_MODEL_NAME,
	ADD_LOGIC_MODEL_FAILURE,
	ADD_LOGIC_MODEL,
	ADD_LOGIC_MODEL_SUCCESS
} from "../actions/logic-models";

const INITIAL_ERRORS = {
  name: [],
  categoryId: []
};

const INITIAL_STATE = {
  logicModels: [],
  categories: [],
  countries: [],
  isLoading: false,
  errors: {},
  creationFormOpen: false,
  name: '',
  categoryId: '1',
  sroi: false,
	createLogicModelErrors: INITIAL_ERRORS,
	isCreated: false
};

export default createReducer({
  [FETCH_LOGIC_MODELS]: (state, action) => merge(state, {
    isLoading: true,
    errors: {}
  }),
  [FETCH_LOGIC_MODELS_SUCCESS]: (state, action) => merge(state, {
    isLoading: false,
    logicModels: action.payload.logic_models,
	  categories: action.payload.categories,
	  countries: action.payload.countries,
	  creationFormOpen: action.payload.logic_models.length === 0
  }),
  [CREATE_LOGIC_MODEL_DIALOG_PAGE_SHOW]: (state, action) => merge(state, {
	  creationFormOpen: true
  }),
  [CREATE_LOGIC_MODEL_DIALOG_PAGE_HIDE]: (state, action) => merge(state, {
    creationFormOpen: false
  }),
  [CHANGE_LOGIC_MODEL_NAME]: (state, action) => merge(state, {
    name: action.name,
	  createLogicModelErrors: INITIAL_ERRORS
  }),
	[ADD_LOGIC_MODEL]: (state, action) => merge(state, {
		isCreated: true,
		createLogicModelErrors: INITIAL_ERRORS
	}),
	[ADD_LOGIC_MODEL_FAILURE]: (state, action) => merge(state, {
		isCreated: false,
		createLogicModelErrors: action.errors
	}),
	[ADD_LOGIC_MODEL_SUCCESS]: (state, action) => merge(state, {
		isCreated: false,
		createLogicModelErrors: INITIAL_ERRORS,
		creationFormOpen: false,
	})
}, INITIAL_STATE)