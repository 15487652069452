import { Observable } from "rxjs/Observable";
import { post } from "../../api";
import "rxjs";
import _, { has, head } from "lodash";

import {
  FETCH_CALCULATIONS,
  CHANGE_ACTIVE_VARIABLE,
  FETCH_SOURCE,
  FETCH_SOURCE_FALIURE,
  FETCH_SOURCE_SUCCESS,
  SET_WORKSPACE,
  UPDATE_CALCULATION_DATA
} from "../../actions/calculation/calculation-page";

import {
  SAVE_DATA_SOURCE,
  SAVE_DATA_SOURCE_SUCCESS,
  SAVE_CALCULATIONS_DATA_SOURCE_SUCCESS,
  UPDATE_CURRENT_DATA_SOURCE,
  CHANGE_CURRENT_SOURCE_NAME,
  SAVE_CALCULATIONS_DATA_SOURCE,
  GET_DATA_SOURCE_RESOURCES_SUCCESS,
  GET_DATA_SOURCE_RESOURCES,
  CHANGE_DATA_FOR_PAGE,
  CHANGE_CURRENT_TRANSFORMATION,
  UPDATE_CURRENT_DATA_SOURCE_SUCCESS,
  SET_DATA_SOURCE_INITIAL_STATE
} from "../../actions/calculation/data-source";

import {
  FINAL_VARIABLE_NAME,
  FETCH_FINAL_SOURCE_DATA,
  SAVE_FINAL_SOURCE,
  SEARCH_IN_UPLOAD,
  SET_QUESTIONS_AND_ANSWERS,
  FETCH_FINAL_SOURCE_DATA_SUCCESS,
  FETCH_FINAL_SOURCE_DATA_FAILURE,
  SET_NEW_TRANSFORMATION_VALUE
} from "../../actions/calculation/source-modals";

import { HIDE_ONE_SOURCE_MODAL } from "../../actions/calculation/selectVariableType";
import { SHOW_ERROR } from "../../actions/errors";
import { typeSources } from "../epic-constants";
import { HIDE_GOOD_JOB } from "../../actions/good-job-alert";
import { SET_CONDITION_INITIAL_STATE } from "../../actions/calculation/conditions";
import { isArrayCalculationReady } from "../../assets/sam-utils";
import { COMPLETE } from "../../components/calculations/sources/source-table-layout/constants";

function fetchCalculationSourcesEpic(action$, store) {
  return action$.ofType(FETCH_SOURCE).mergeMap(action => {
    const token = localStorage.getItem("token");

    return Observable.fromPromise(
      post("/api/datasources/uniform", { token: token }, token)
    )
      .flatMap(({ payload }) => {
        return Observable.of({ type: FETCH_SOURCE_SUCCESS, payload });
      })
      .catch(errors => Observable.of({ type: FETCH_SOURCE_FALIURE, errors }));
  });
}

function saveCalculationDataSourceEpic(action$, store) {
  return action$.ofType(SAVE_CALCULATIONS_DATA_SOURCE).mergeMap(action => {
    const token = localStorage.getItem("token");
    return Observable.fromPromise(
      post("/api/datasources", action.payload, token)
    )
      .flatMap(({ datasource }) => {
        const sourceName = "Calculation";
        const datasourceType = {
          id: 3,
          name: sourceName
        };

        return Observable.concat(
          Observable.of({
            type: SAVE_DATA_SOURCE_SUCCESS,
            payload: { datasource, sourceName, datasourceType }
          }),
          Observable.of({ type: SAVE_CALCULATIONS_DATA_SOURCE_SUCCESS })
        );
      })
      .catch(errors => {
        const errorText = errors.message;

        return Observable.of({ type: SHOW_ERROR, errorText });
      });
  });
}

function saveDataSourceTypeEpic(action$, store) {
  return action$.ofType(SAVE_DATA_SOURCE).mergeMap(action => {
    const token = localStorage.getItem("token");
    const {
      calculations: { activeVariable },
      dataSource: { currentDataSource }
    } = store.getState();

    const sourceName = currentDataSource.sourceName;
    const dataSourceTypeId = typeSources[sourceName];
    const payload = {
      calculation_variable_id: activeVariable.id,
      datasource_type_id: dataSourceTypeId,
      type: sourceName,
      workspace_id: parseInt(currentDataSource.workspaceId)
    };

    if (currentDataSource.sourceName === "Survey") {
      payload.question_id = parseInt(currentDataSource.questionId);
      payload.form_id = parseInt(currentDataSource.formId);
    } else {
      payload.column_id = parseInt(currentDataSource.columnId);
      payload.upload_id = parseInt(currentDataSource.uploadId);
    }

    return Observable.fromPromise(post("/api/datasources", payload, token))
      .flatMap(() => {
        return Observable.of({ type: FETCH_CALCULATIONS });
      })
      .catch(errors => {
        const errorText = errors.message;

        return Observable.of({ type: SHOW_ERROR, errorText });
      });
  });
}

function updateDataSourceEpic(action$, store) {
  return action$.ofType(UPDATE_CURRENT_DATA_SOURCE).flatMap(() => {
    const {
      calculations: { activeVariable },
      dataSource: { currentDataSource }
    } = store.getState();

    if (activeVariable) {
      if (activeVariable.datasource_object) {
        const {
          workspace_id,
          form_id,
          question_id,
          upload_id,
          column_id
        } = activeVariable.datasource_object;

        let { name } = activeVariable.datasource_type;

        const payload = {
          sourceName: name,
          workspaceId: workspace_id,
          uploadId: upload_id ? upload_id : null,
          formId: form_id ? form_id : null,
          questionId: question_id ? question_id : null,
          columnId: column_id ? column_id : null,
          headerId: form_id ? question_id : column_id
        };

        return Observable.concat(
          Observable.of({ type: SET_WORKSPACE, workspaceId: workspace_id }),
          Observable.of({ type: UPDATE_CURRENT_DATA_SOURCE_SUCCESS, payload })
        );
      } else {
        // try to get datasource object from array calculation variable
        if(has(activeVariable, 'array_calculation.array_calculation_variables')) {
          const arrayCalculationVariables = activeVariable.array_calculation.array_calculation_variables;
          if(arrayCalculationVariables.length > 0) {
            // get the first array calculation variable and use its datasource
            // as all array calculation variables of a given array calculation
            // with have the same top form or upload - adjust accordingly if
            // this changes in the future
            const arrayCalculationVariable = head(arrayCalculationVariables);
            if(has(arrayCalculationVariable, 'datasource_object')) {
              const {
                workspace_id,
                form_id,
                question_id,
                upload_id,
                column_id
              } = arrayCalculationVariable.datasource_object;
      
              let { name } = arrayCalculationVariable.datasource_type;
      
              const payload = {
                sourceName: name,
                workspaceId: workspace_id,
                uploadId: upload_id ? upload_id : null,
                formId: form_id ? form_id : null,
                questionId: question_id ? question_id : null,
                columnId: column_id ? column_id : null,
                headerId: form_id ? question_id : column_id
              };
      
              return Observable.concat(
                Observable.of({ type: SET_WORKSPACE, workspaceId: workspace_id }),
                Observable.of({ type: UPDATE_CURRENT_DATA_SOURCE_SUCCESS, payload })
              );
            } // array calculation variable has datasource object
          } // active variable has array calculation variables
        } else {
          const name = currentDataSource.sourceName
            ? currentDataSource.sourceName
            : "";

          return Observable.of({ type: CHANGE_CURRENT_SOURCE_NAME, name });
        }
      }
    }
  });
}

export function getDataSourceResourcesEpic(action$, store) {
  return action$.ofType(GET_DATA_SOURCE_RESOURCES, CHANGE_DATA_FOR_PAGE).mergeMap(action => {
    const token = localStorage.getItem("token");
    const {
      dataSource: { currentDataSource },
      calculations: { activeVariable },
      oneSourceFirstTab: { currentPage, sizePerPage }
    } = store.getState();
    const sourceName = currentDataSource.sourceName;

    const payload = {
      type: sourceName,
      form_id: parseInt(currentDataSource.formId),
      upload_id: parseInt(currentDataSource.uploadId),
      token: token,
      page_number: currentPage,
      size_per_page: sizePerPage
    };

    return Observable.fromPromise(
      post("/api/datasources/resources", payload, token)
    )
      .flatMap(({ payload }) => {
        const headersAndAnswers = getHeadersAndAnswers(payload);

        return Observable.concat(
          Observable.of({ type: GET_DATA_SOURCE_RESOURCES_SUCCESS, payload }),
          Observable.of({
            type: SET_QUESTIONS_AND_ANSWERS,
            payload: { headersAndAnswers }
          }),
          activeVariable.transformation && currentDataSource.headerId
            ? Observable.concat(
                Observable.of({
                  type: FETCH_FINAL_SOURCE_DATA,
                  headerId: currentDataSource.headerId
                }),
                Observable.of({
                  type: CHANGE_CURRENT_TRANSFORMATION,
                  transformation: activeVariable.transformation
                })
              )
            : Observable.empty()
        );
      })
      .catch(errors => {
        const errorText = errors.message;

        return Observable.of({ type: SHOW_ERROR, errorText });
      });
  });
}

export function fetchFinalSourceDataEpic(action$, store) {
  return action$.ofType(FETCH_FINAL_SOURCE_DATA).mergeMap(action => {
    const token = localStorage.getItem("token");
    const {
      dataSource: { currentDataSource },
      oneSourceFinalTab: { finalPageCurrentPage, finalPageSizePerPage },
      conditionPanel: { appliedConditions }
    } = store.getState();
    const sourceName = currentDataSource.sourceName;

    const payload = {
      type: sourceName,
      form_id: parseInt(currentDataSource.formId),
      upload_id: parseInt(currentDataSource.uploadId),
      question_id: parseInt(action.headerId),
      header_id: parseInt(action.headerId),
      page_number: finalPageCurrentPage,
      size_per_page: finalPageSizePerPage,
      conditions: appliedConditions,
      transformation: currentDataSource.transformation,
      source_has_responses: sourceHasResponses(currentDataSource.headerTransformations)
    };

    if (currentDataSource.transformation === null || currentDataSource.transformation === undefined)
      return Observable.empty();


    return Observable.fromPromise(
      post("/api/datasource-get-header", payload, token)
    )
      .flatMap(({ payload }) => {
        const conditionValue = !!(payload.value || payload.value === 0);
        if (!conditionValue)
          localStorage.setItem("final_page_items", JSON.stringify(payload));

        return Observable.concat(
          conditionValue
            ? Observable.of({ type: SET_NEW_TRANSFORMATION_VALUE, payload })
            : Observable.empty(),
          Observable.of({ type: FETCH_FINAL_SOURCE_DATA_SUCCESS, payload })
        );
      })
      .catch(errors => {
        const errorText = errors.message;
        return Observable.concat(
          Observable.of({ type: SHOW_ERROR, errorText }),
          Observable.of({ type: FETCH_FINAL_SOURCE_DATA_FAILURE, errors })
        );
      });
  });
}

function sourceHasResponses(headerTransformations) {
  let condition = true;
  if(!!headerTransformations) {
    headerTransformations.map((transformation) => {
      if (transformation.name === "COUNT" && transformation.value === 0 || transformation.name === "COUNT OF RESPONSE" && transformation.value === 0) {
        condition = false
      }
    })
  }
  return condition
}

function getFormHeadersAndAnswers(form) {
  const result = Object.values(form.pages).reduce((acc1, page, index) => {
    const section = ( Array.isArray(page.sections) ? page.sections:Object.values(page.sections) ).reduce((acc, section) => {
      if (!section.questions.length)
        return acc;
      acc.push(section.questions);
      const answers = [];
      for (let i = 0; i < section.answers[0].length; i++) {
        answers[i] = ( Array.isArray(section.answers) ? section.answers:Object.values(section.answers) ).reduce((acc, curr) => {
          const answer = curr[i];
          if (answer) {
            return {
              ...acc,
              [answer.question_id]: answer.value
            };
          } else
            return {
              ...acc
            };
        }, {});
      }
      acc.push(answers);
      acc.push(section.answers[0].length);
      return acc;
    }, []);
    acc1.push(section);
    return acc1;
  }, []);

  if (result.length > 1 || (result[0] && result[0].length > 3)) {
    const flattenArr = _.flatten(result);
    const chunked = _.chunk(flattenArr, 3);
    let answers = [];
    let questions = [];
    let max = 0;

    for (let i = 0; i < chunked.length; i++) {
      if (chunked[i]) {
        const currentQuestion = chunked[i][0];
        const currentAnswer = chunked[i][1];
        questions.push(currentQuestion);
        answers.push(currentAnswer);
        if (max < chunked[i][2]) max = chunked[i][2];
      }
    }
    const normalizedAnswers = [];
    const normalizedQuestions = _.flatten(questions);

    for (let i = 0; i < max; i++) {
      const normalizedAnswer = answers.reduce((acc, curr) => {
        const answer = curr[i];
        return {
          ...acc,
          ...answer
        };
      }, {});
      normalizedAnswers.push(normalizedAnswer);
    }
    return [normalizedQuestions, normalizedAnswers, max];
  }

  return result[0];
}

function getHeadersAndAnswers(obj) {
  if (Object.keys(obj).length > 0) {
    return obj.headers
      ? [obj.headers, obj.table, obj.total]
      :  getFormHeadersAndAnswers(obj);
  }
}

function searchInUploadEpic(action$, store) {
  return action$.ofType(SEARCH_IN_UPLOAD).mergeMap(action => {
    const token = localStorage.getItem("token");
    const {
      oneSourceFirstTab: { sizePerPage },
      dataSource: { currentDataSource }
    } = store.getState();
    const payload = {
      upload_id: currentDataSource.uploadId,
      token: token,
      size_per_page: sizePerPage,
      search_text: action.text
    };
    return Observable.fromPromise(
      post("/api/search_by_uploads", payload, token)
    )
      .flatMap(({ payload }) => {
        const headersAndAnswers = getHeadersAndAnswers(payload);

        return Observable.concat(
          Observable.of({ type: GET_DATA_SOURCE_RESOURCES_SUCCESS, payload }),
          Observable.of({
            type: SET_QUESTIONS_AND_ANSWERS,
            payload: { headersAndAnswers }
          })
        );
      })
      .catch(errors => {
        const errorText = errors.message;

        return Observable.of({ type: SHOW_ERROR, errorText });
      });
  });
}

export function saveFinalDataSetEpic(action$, store) {
  return action$.ofType(SAVE_FINAL_SOURCE).mergeMap(action => {
    const token = localStorage.getItem("token");
    const {
      oneSourceFinalTab: { finalVariableName },
      calculations: { activeVariable, currentCalculation },
      dataSource: { currentDataSource },
      selectVariableTypeModals: { dataSetName },
      conditionPanel: { appliedConditions },
      oneSourceFirstTab: { newTransformationValue }
    } = store.getState();

    // if working with array calculation
    // then dont call this final data save epic
    // as everything related to array clculations
    // has been saved in the first tab
    if(isArrayCalculationReady(activeVariable) === COMPLETE) {
      return Observable.of({type: UPDATE_CALCULATION_DATA, payload: {
        latex: currentCalculation.latex,
        string: currentCalculation.string,
      }});
    }

    const sourceName = currentDataSource.sourceName;
    const dataSourceTypeId = typeSources[sourceName];

    const conditionValue =
      newTransformationValue || newTransformationValue === 0;
    const payload = {
      dataset: dataSetName,
      id: activeVariable.id,
      name: finalVariableName ? finalVariableName : activeVariable.name,
      transformation: currentDataSource.transformation.label,
      value: conditionValue
        ? newTransformationValue
        : currentDataSource.transformation.optionValue,
      type: currentDataSource.sourceName,
      conditions: appliedConditions
    };
    return Observable.fromPromise(
      post("/api/calculation_variable/save", payload, token)
    )
      .flatMap(({ calculationVariable, datasource }) => {
        const datasourceType = {
          id: dataSourceTypeId,
          name: sourceName
        };

        return Observable.concat(
          Observable.of({ type: CHANGE_ACTIVE_VARIABLE, calculationVariable }),
          Observable.of({
            type: SAVE_DATA_SOURCE_SUCCESS,
            payload: { datasource, sourceName, datasourceType }
          }),
          Observable.of({ type: HIDE_ONE_SOURCE_MODAL }),
          Observable.of({ type: FINAL_VARIABLE_NAME, name: "" }),
          Observable.of({ type: SET_CONDITION_INITIAL_STATE }),
          Observable.timer(1000).flatMap(() =>
            Observable.of({ type: FETCH_CALCULATIONS })
          )
        );
      })
      .catch(errors => {
        const errorText = errors.message ? errors.message : errors.error;
        return Observable.concat(
          Observable.of({ type: SHOW_ERROR, errorText }),
          Observable.of({ type: HIDE_ONE_SOURCE_MODAL }),
          errorText ===
            "One of your underlying formulas is dividing by zero. Please change and save the formula, or change the source of the divisor." ?
            Observable.of({ type: FETCH_CALCULATIONS })
              :
            Observable.empty()
        );
      });
  });
}

function hideGoodJobEpic($actions, store) {
  return $actions.ofType(HIDE_ONE_SOURCE_MODAL).mergeMap(action => {
    const {
      goodJobSweetAlert: { showGoodJob }
    } = store.getState();
    return Observable.concat(
      showGoodJob ?
        Observable.of({ type: HIDE_GOOD_JOB })
        :
        Observable.empty(),
      Observable.of({ type: SET_CONDITION_INITIAL_STATE }),
      Observable.of({ type: SET_DATA_SOURCE_INITIAL_STATE })
    );
  });
}

export default [
  fetchCalculationSourcesEpic,
  saveDataSourceTypeEpic,
  updateDataSourceEpic,
  saveCalculationDataSourceEpic,
  getDataSourceResourcesEpic,
  searchInUploadEpic,
  saveFinalDataSetEpic,
  hideGoodJobEpic,
  fetchFinalSourceDataEpic
];
