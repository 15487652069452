import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap-modal";

import { InjectIntl } from "../../../common/InjectIntl";
import SourcesCalculations from "../SourcesCalculations";
import { whiteLabel } from '../../../utils';

class SelectVariableTypeModal extends React.Component {
  static propTypes = {
    currentCalculation: PropTypes.object.isRequired,
    currentPreexistingCalculation: PropTypes.object.isRequired,
    isSelectedVariableModalShowed: PropTypes.bool,
    intl: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      hideSelectVariableModal: PropTypes.func.isRequired,
      showOneSourceModal: PropTypes.func.isRequired,
      addPreexistingCalculationVariable: PropTypes.func.isRequired,
      changeDataSetName: PropTypes.func.isRequired,
      setCurrentPreexistingCalculation: PropTypes.func.isRequired
    })
  };

  state = { sourceType: "" };

  oneSourceHandlerClick = e => {
    const sourceType = e.target.id;
    this.setState({ sourceType });
  };

  addSourceClickHandler = () => {
    switch (this.state.sourceType) {
      case "one":
        this.props.actions.hideSelectVariableModal();
        this.props.actions.showOneSourceModal();
        this.props.actions.changeDataSetName("Data source one");
        break;
      case "preexisting":
        const payload = {
          name: this.props.currentPreexistingCalculation.name,
          calculation_id: this.props.currentCalculation.id,
          preexisting_calculation_id: this.props.currentPreexistingCalculation.id,
          datasource_type_id: 3,
          dataset: "Preexisting calculation",
          type: "Calculation"
        };
        this.props.actions.addPreexistingCalculationVariable(payload);
        break;
    }

    this.setState({ sourceType: "" });
  };

  handleClose = () => {
    this.setState({ sourceType: "" });
    this.props.actions.setCurrentPreexistingCalculation({});
    this.props.actions.hideSelectVariableModal();
  };

  render() {
    const {
      currentCalculation,
      currentPreexistingCalculation,
      preexistingCalculations,
      isSelectedVariableModalShowed,
      actions,
      intl
    } = this.props;
    const { sourceType } = this.state;

    return (
      <Modal
        dialogClassName="modal-md"
        show={!!isSelectedVariableModalShowed}
        id="foreground_display"
        data-brand={whiteLabel()}
      >
        <Modal.Header className="gray-bg">
          <Modal.Title id="ModalHeader">
            <strong>{intl("calculation.variable_type.select")}</strong>
            <i
              onClick={actions.hideSelectVariableModal}
              className="fa fa-close pull-right"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="col-md">
          <form id="selectVariableTypeForm">
            <label
              htmlFor="one"
              className="block m-t m-b"
              onClick={this.oneSourceHandlerClick}
            >
              <input
                id="one"
                type="radio"
                name="radgroup"
                readOnly={true}
                checked={sourceType === "one"}
              />
              &nbsp;{intl("calculation.variable_type.data_source")}
            </label>
            <label
              htmlFor="preexisting"
              className={`m-t block ${
                currentCalculation.parent !== 1 ? "content-disabled" : ""
              }`}
              onClick={this.oneSourceHandlerClick}
            >
              <input
                id="preexisting"
                type="radio"
                name="radgroup"
                readOnly={true}
                checked={sourceType === "preexisting"}
              />
              &nbsp;{intl("calculation.variable_type.preexisting_calculation")}
            </label>
            <div
              className={`m-l m-b ${
                currentCalculation.parent !== 1 ? "content-disabled" : ""
              }`}
            >
              <p>{intl("calculation.recursive_calculations")}</p>
              <SourcesCalculations
                setCurrentPreexistingCalculation={
                  actions.setCurrentPreexistingCalculation
                }
                selectedCalculation={currentPreexistingCalculation}
                sourceType={sourceType}
                preexistingCalculations={preexistingCalculations}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="gray-bg">
          <div className="row">
            <button onClick={this.handleClose} className="btn modal-button">
              {intl("common.text.cancel_button")}
            </button>
            &nbsp;
            <button
              disabled={
                sourceType === "" ||
                (sourceType === "preexisting" &&
                  !currentPreexistingCalculation.id)
              }
              onClick={this.addSourceClickHandler}
              className="btn btn-success modal-button m-r-50"
            >
              {intl("common.text.add")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default InjectIntl(SelectVariableTypeModal);
