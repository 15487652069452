import React from 'react';
import PropTypes from 'prop-types';
import { InjectIntl } from "../../common/InjectIntl";
import SelectBox from "../../common/SelectBox";
import { EDIT } from "../../members/constants";
const SOURCE_TYPE_OPTIONS = [
  { id: 1, label: 'source.type.form' },
  { id: 2, label: 'source.type.upload' }
];

class SourceTypes extends React.Component {

  static propTypes = {
    currentDataSource: PropTypes.object.isRequired,
    activeVariable: PropTypes.object,
    calculations: PropTypes.array.isRequired,
    actions: PropTypes.shape({
      fetchSource: PropTypes.func,
      changeCurrentSourceName: PropTypes.func,
      setDataSourceShouldUpdate: PropTypes.func,
      changeCalculationForVariableId: PropTypes.func,
      setSourceInitialState: PropTypes.func,
      setError: PropTypes.func.isRequired,
      setConditionInitialState: PropTypes.func.isRequired,
    }.isRequired)
  };

  componentDidMount() {
    if (!!this.props.currentDataSource && this.props.currentDataSource.sourceName) {
      const sourceName = this.props.currentDataSource.sourceName;
      this.props.actions.changeCurrentSourceName(sourceName);
    }
  }

  sourceChangeHandler = (format) => {
    const sourceName = format.label === 'Form' || format.label === 'Forme' ? 'Survey' : 'Upload';
    this.props.actions.changeCurrentSourceName(sourceName);
    this.props.actions.clearSourceDataExcept('sourceName');
    this.props.actions.setConditionInitialState();
    if (!!this.props.activeVariable && this.props.activeVariable.datasource_type_id) {
      this.props.actions.setDataSourceShouldUpdate(false);
    }
  };

  getSourceId=()=>{
    if (this.props.currentDataSource.sourceName)
      return this.props.currentDataSource.sourceName === 'Survey' ? 1 : 2;
    else
      return ''
  };

  render() {
    const selectOptions = SOURCE_TYPE_OPTIONS.map((option) => {
      return { value: option.id, label: this.props.intl(option.label)};
    });

    return (
      <div className='select-source-type' id='select-source-type'>
        <SelectBox
          options={selectOptions}
          label={this.props.intl('common.text.type')}
          onHeaderChange={this.sourceChangeHandler}
          locale={this.props.intlLocale}
          selectedOption={this.getSourceId()}
          placeholder={this.props.intl('source.select_source_type')}
          labelClassName={"col-md-4"}
          disabled={localStorage.getItem("permission") !== EDIT}
        />
      </div>
    );
  }
}

export default InjectIntl(SourceTypes);