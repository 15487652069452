import React from "react";
import PropTypes from "prop-types";
import { InjectIntl } from "../../../../common/InjectIntl";
import ConditionControlButtons from "./ConditionControlButtons";
import ConditionList from "./ConditionList";

const ConditionIndex = props => {
  const {
    actions: { cancelNotAppliedConditions, sendConditions, showErrorHints },
    conditions
  } = props;
  return (
    <React.Fragment>
      <ConditionList {...props} />
      <ConditionControlButtons
        cancelNotAppliedConditions={cancelNotAppliedConditions}
        sendConditions={sendConditions}
        showErrorHints={showErrorHints}
        conditions={conditions}
      />
    </React.Fragment>
  );
};

ConditionIndex.propTypes = {
  intl: PropTypes.func.isRequired,
  activeVariable: PropTypes.object.isRequired,
  conditions: PropTypes.arrayOf(PropTypes.object).isRequired,
  appliedConditions: PropTypes.arrayOf(PropTypes.object).isRequired,
  actions: PropTypes.shape({
    hideConditionPanel: PropTypes.func.isRequired,
    sendConditions: PropTypes.func.isRequired,
    showErrorHints: PropTypes.func.isRequired,
    cancelNotAppliedConditions: PropTypes.func.isRequired,
    setExistingConditions: PropTypes.func.isRequired,
    clearAllConditions: PropTypes.func.isRequired
  })
};

export default InjectIntl(ConditionIndex);
