import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Reports from "../components/reports/Reports";
import reportActions from "../actions/reports";

function mapStateToProps(state, ownProps) {
  return {
    ...state.reports
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...reportActions
    }, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);