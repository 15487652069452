import React from "react";
import PropTypes from "prop-types";
import { InjectIntl } from "../../common/InjectIntl";
import SelectBox from "../../common/SelectBox";

const SourcesCalculations = props => {
  const {
    setCurrentPreexistingCalculation,
    selectedCalculation,
    preexistingCalculations,
    sourceType,
    label,
    intl
  } = props;

  const handlePreexistingCalculationChange = option => {
    const selectedCalculation = preexistingCalculations.find(
      calc => calc.id === option.value
    );
    setCurrentPreexistingCalculation(selectedCalculation);
  };

  const validatePreexistingCalculations = preexistingCalculations => {
    return preexistingCalculations.map(calc => {
      let label;
      let disabled;

      if (!calc.string) {
        label = calc.name + intl("calculation.unsaved_formula");
        disabled = true;
      } else if (calc.condition === "incomplete_variable") {
        label = calc.name + intl("calculation.incomplete_calculation");
        disabled = false;
      } else if (calc.condition === "invalid_formula") {
        label = calc.name + intl("calculation.invalid_formula");
        disabled = true;
      } else {
        label = calc.name;
        disabled = false;
      }

      return { value: calc.id, label, disabled };
    });
  };

  return (
    <div id="preexisting-calculation-select">
      <SelectBox
        selectedOption={!!selectedCalculation && selectedCalculation.id}
        selectClass={`${
          sourceType === "preexisting" ? "" : "content-disabled"
        }`}
        label={label}
        options={validatePreexistingCalculations(preexistingCalculations)}
        onHeaderChange={handlePreexistingCalculationChange}
        placeholder={intl("source.select_calculation")}
        sourceLayout={true}
      />
    </div>
  );
};

SourcesCalculations.propTypes = {
  setCurrentPreexistingCalculation: PropTypes.func.isRequired,
  preexistingCalculations: PropTypes.array.isRequired,
  selectedCalculation: PropTypes.object.isRequired,
  sourceType: PropTypes.string.isRequired,
  intl: PropTypes.func.isRequired,
  label: PropTypes.string
};

export default InjectIntl(SourcesCalculations);
