import createReducer from '../create-reducer';
import merge from 'xtend';
import {
  REQUEST_SUCCESS, REQUEST, CHANGE_SUBJECT,
  CHANGE_RECIPIENTS, CHANGE_MESSAGE, CHANGE_DEFAULT_MESSAGES, REQUEST_FAILURE, SET_ERROR
} from "../../actions/members/invite-collaborators";
import { FETCH_COLLABORATORS_SUCCESS } from "../../actions/manage-callaborator";
import { HIDE_COLLABORATOR_MODAL } from "../../actions/members/invite-modal";

const INITIAL_STATE = {
  members: [],
  isLoading: false,
  invitations: [],
  subject: "",
  message: '',
  isCustomMessage: false,
  recipients: [],
  emails: [],
  isSaving: false,
  isOpen: false,
  isSuccess: false,
  errors: []
};

function getActiveInvitations(emails, invitations) {
  return invitations.reduce((acc, invitation) => {
    if (!emails.includes(invitation.email))
    acc.push(invitation)
    return acc
  }, [])
}

function getCollaboratorsEmails(collaborators) {
  return collaborators.map(member => member.email)
}

export default createReducer({
  [REQUEST]: (state, action) => merge(state, { isLoading: true }),
  [REQUEST_SUCCESS]: (state, action) => merge(state, {
    members: action.data.members,
    invitations: action.data.invitations,
    isLoading: false
  }),
  [REQUEST_FAILURE]: (state, action) => merge(state, {
    isLoading: false,
    errors: [action.error, ...state.errors]
  }),
  [CHANGE_SUBJECT]: (state, action) => merge(state, { subject: action.subject }),
  [CHANGE_MESSAGE]: (state, action) => merge(state, {message: action.msg, isCustomMessage: action.isCustomMessage}),
  [CHANGE_DEFAULT_MESSAGES]: (state, action) => merge(state, { messages: action.msgs }),
  [CHANGE_RECIPIENTS]: (state, action) => merge(state, {
    recipients: action.recipients,
    errors: []
  }),
  [FETCH_COLLABORATORS_SUCCESS]: (state, action) => merge(state, {
    isLoading: false,
    emails: getCollaboratorsEmails(action.collaborators),
    invitations: getActiveInvitations(getCollaboratorsEmails(action.collaborators), action.invitations),
    members: action.collaborators
  }),
  [HIDE_COLLABORATOR_MODAL]: (state, action) => merge(state, {
    errors: [],
    recipients: []
  }),
  [SET_ERROR]: (state, action) => merge(state, {
    errors: [action.error, ...state.errors]
  })
}, INITIAL_STATE)