import React from 'react';
import PropTypes from 'prop-types';

const SubDashboard = ({sample_size, total_input, total_impact, sroi}) => {

  const renderItem = (value, label) => {
    return (
      <div>
        <h2>{value}</h2>
        <p>{label}</p>
      </div>
    );
  }

  return (
    <div className="row sub_dashboard">
      <div className="col-md-3">{renderItem(sample_size, 'Sample size')}</div>
      <div className="col-md-3">{renderItem(`$${total_input}`, 'Input')}</div>
      <div className="col-md-3">{renderItem(`$${total_impact}`, 'Impact')}</div>
      <div className="col-md-3">{renderItem(`$${sroi}`, 'ROI')}</div>
    </div>
  );
}

SubDashboard.propTypes = {
  sample_size: PropTypes.number.isRequired,
  total_input: PropTypes.number.isRequired,
  total_impact: PropTypes.number.isRequired,
  sroi: PropTypes.number.isRequired
}

export default SubDashboard;