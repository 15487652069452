export const CHANGE_DEADWEIGHT_DESCRIPTION = '@@ADJUSTMENTS/CHANGE_DEADWEIGHT_DESCRIPTION';
export const CHANGE_DEADWEIGHT_VALUE = '@@ADJUSTMENTS/CHANGE_DEADWEIGHT_VALUE';
export const CHANGE_DROPOFF_DESCRIPTION = '@@ADJUSTMENTS/CHANGE_DROPOFF_DESCRIPTION';
export const CHANGE_DROPOFF = '@@ADJUSTMENTS/CHANGE_DROPOFF';
export const CHANGE_DISPLACEMENT_DESCRIPTION = '@@ADJUSTMENTS/CHANGE_DISPLACEMENT_DESCRIPTION';
export const CHANGE_DISPLACEMENT = '@@ADJUSTMENTS/CHANGE_DISPLACEMENT';
export const CHANGE_ATTRIBUTION_DESCRIPTION = '@@ADJUSTMENTS/CHANGE_ATTRIBUTION_DESCRIPTION';
export const CHANGE_ATTRIBUTION = '@@ADJUSTMENTS/CHANGE_ATTRIBUTION';
export const CHANGE_DURATION_DESCRIPTION = '@@ADJUSTMENTS/CHANGE_DURATION_DESCRIPTION';
export const CHANGE_DURATION = '@@ADJUSTMENTS/CHANGE_DURATION';
export const CHANGE_STAKEHOLDER_MEASURE_ID = '@@ADJUSTMENTS/CHANGE_STAKEHOLDER_MEASURE_ID';
export const ADJUSTMENTS_UPDATE = '@@ADJUSTMENTS/ADJUSTMENTS_UPDATE';
export const SUCCESS_ADJUSTMENT_UPDATE = '@@ADJUSTMENTS/SUCCESS_ADJUSTMENT_UPDATE';
export const ADJUSTMENT_UPDATE_FAILURE = '@@ADJUSTMENTS/ADJUSTMENT_UPDATE_FAILURE';
export const FETCH_ADJUSTMENTS = '@@ADJUSTMENTS/FETCH_ADJUSTMENTS';

function fetchAdjustmentsData(measure) {
  return { type: FETCH_ADJUSTMENTS, measure }
}

function changeDeadWeightDescription(description) {
  return { type: CHANGE_DEADWEIGHT_DESCRIPTION, description}
}

function changeDeadWeight(deadWeight) {
  return { type: CHANGE_DEADWEIGHT_VALUE, deadWeight}
}

function changeDropOffDescription(dropOffDescription){
  return { type: CHANGE_DROPOFF_DESCRIPTION, dropOffDescription}
}

function changeDropOff(dropOff){
  return { type: CHANGE_DROPOFF, dropOff }
}

function changeDisplacementDescription(displacementDescription){
  return { type: CHANGE_DISPLACEMENT_DESCRIPTION, displacementDescription}
}

function changeDisplacement(displacement) {
  return { type: CHANGE_DISPLACEMENT, displacement }
}

function changeAttributionDescription(attributionDescription) {
  return { type: CHANGE_ATTRIBUTION_DESCRIPTION, attributionDescription }
}

function changeAttribution(attribution) {
  return { type: CHANGE_ATTRIBUTION, attribution }
}

function changeDurationDescription(durationDescription) {
  return { type: CHANGE_DURATION_DESCRIPTION, durationDescription }
}

function changeDuration(duration) {
  return { type: CHANGE_DURATION, duration }
}

function changeStakeHolderMeasureId(stakeholderMeasureId) {
  return { type: CHANGE_STAKEHOLDER_MEASURE_ID, stakeholderMeasureId}
}

function adjustmentsUpdate() {
  return { type: ADJUSTMENTS_UPDATE }
}

export default {
  changeDeadWeightDescription,
  changeDeadWeight,
  changeDropOffDescription,
  changeDropOff,
  changeDisplacementDescription,
  changeDisplacement,
  changeAttributionDescription,
  changeAttribution,
  changeDurationDescription,
  changeDuration,
  changeStakeHolderMeasureId,
  adjustmentsUpdate,
  fetchAdjustmentsData,
}



