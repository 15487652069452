import React from 'react';
import PropTypes from 'prop-types';
import CalculationsControlPanel from "./CalculationsControlPanel";
import CalculationsNamesTabs from "./CalculationsNamesTabs";
import CurrentCalculationsDetails from "../../containers/calculations/CurrentCalculationTab.container";


class MeasureCalculationsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changeNameInputShow: false,
    }
  }

  static propTypes = {
    calculations: PropTypes.array.isRequired,
    measureName: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    currentCalculation: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      addCalculation: PropTypes.func.isRequired
    })
  };

  setCalculation = (calc) => {
    this.setState({ changeNameInputShow: false });
    this.props.actions.setCurrentCalculation(calc);
  };

  clickCalculationNameInputFieldHandler = () => {
    this.setState({ changeNameInputShow: !this.state.changeNameInputShow })
  };

  render() {
    const { changeNameInputShow } = this.state;
    const { measureName, currentCalculation, calculations, actions } = this.props;

    return (
      <div className="ibox calculation-wrap">
        <div className="ibox-content no-hp">
          <div className="row">
            <CalculationsControlPanel closeCalculation={this.props.closeCalculation} addCalculation={actions.addCalculation} measureName={measureName}/>
            <div>
              <CalculationsNamesTabs calculations={calculations}
                                     currentCalculation={currentCalculation}
                                     setCalculation={this.setCalculation}
              />
              <CurrentCalculationsDetails changeNameInputShow={changeNameInputShow}
                                          clickCalculationNameInputFieldHandler={this.clickCalculationNameInputFieldHandler}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MeasureCalculationsDetails;