import { updateIntl } from 'react-intl-redux';

export const EN_LOCALE = 'en';
export const FR_LOCALE = 'fr';
const AVAILABLE_LOCALES = [EN_LOCALE, FR_LOCALE];

export const GET_SERVICES_BASE_URLS = 'GET_SERVICES_BASE_URLS';
export const GET_SERVICES_BASE_URLS_SUCCESS = 'GET_SERVICES_BASE_URLS_SUCCESS';
export const GET_SERVICES_BASE_URLS_FAILURE = 'GET_SERVICES_BASE_URLS_FAILURE';

function updateLocale(locale) {
  const messages = require(`../assets/translations/${locale}.json`);
  return updateIntl({locale, messages});
}

export function setupLocale() {
  let locale = localStorage.getItem('locale');
  if (!locale || AVAILABLE_LOCALES.indexOf(locale) !== -1) {
    locale = 'en';
    localStorage.setItem('locale', locale);
  }
  return updateLocale(locale);
}

export function changeLocale(locale) {
  if (AVAILABLE_LOCALES.indexOf(locale) !== -1) {
    localStorage.setItem('locale', locale);
  } else {
    localStorage.setItem('locale', 'en');
  }
  return updateLocale(locale);
}

export function getAtlasBaseUrl() {
  return { type: GET_SERVICES_BASE_URLS }
}