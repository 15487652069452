import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap-modal";
import { InjectIntl } from "../common/InjectIntl";
import SelectBox from "../common/SelectBox";
import { SOURCE, TARGET, QUESTION_TYPE_RADIO, QUESTION_TYPE_TEXT, VALIDATION_TYPE_NUMBER, MATH_EXPRESSION_SYMBOLS } from "../../constants";

const LookupValueModal = (props) => {
    const {
        intl,
        isLookupModalOpen,
        actions,
        errors,
        workspaces,
        sourceFormData,
        targetFormData
    } = props;

    const [lookupQuestionName, setLookupQuestionName] = useState('');
    const handleLookupQuestionName= (e) => {
      setLookupQuestionName(e.target.value);
    };

    const [selectedSourceWorkspace, setSourceWorkspace] = useState(undefined);
    const [selectedSourceWorkspaceOption, setSourceWorkspaceOption] = useState(undefined);

    const [selectedTargetWorkspace, setTargetWorkspace] = useState(undefined);
    const [selectedTargetWorkspaceOption, setTargetWorkspaceOption] = useState(undefined);

    const [isLookupCalculation, setIsLookupCalculation] = useState(false);
    const handleIsLookupCalculation = (event) => {
      setIsLookupCalculation(event.target.checked);
    };

    const handleSourceWorkspaceChange = (data) => {
      setSourceWorkspaceOption(data);
      setSourceWorkspace(workspaces.find(ws => ws.id === data.value));
    };

    const handleTargetWorkspaceChange = (data) => {
      setTargetWorkspaceOption(data);
      setTargetWorkspace(workspaces.find(ws => ws.id === data.value));
    };

    const [selectedSourceForm, setSourceForm] = useState(undefined);
    const handleSourceFormChange = (data) => {
      setSourceForm(data);
      actions.fetchDataResources(data.value, SOURCE);
    };

    const [selectedTargetForm, setTargetForm] = useState(undefined);
    const handleTargetFormChange = (data) => {
      setTargetForm(data);
      actions.fetchDataResources(data.value, TARGET);
    };

    const [selectedLookupQuestion, setLookupQuestion] = useState(undefined);
    const handleLookupQuestionChange = (data) => {
      setLookupQuestion(data);
    };

    const [selectedLookupCalculationQuestion, setLookupCalculationQuestion] = useState(undefined);
    const handleLookupCalculationQuestionChange = (data) => {
      setLookupCalculationQuestion(data);
    };

    const [selectedCalculationType, setCalculationType] = useState(undefined);
    const handleCalculationTypeChange = (data) => {
      setCalculationType(data);
    };

    const [mappingErrors, setMappingErrors] = useState([]);

    const [questionMapping, setQuestionMapping] = useState([{source: null, target: null}]);

    const addRow = () => {
      setQuestionMapping([...questionMapping, { source: null, target: null }]);
    };
  
    const removeRow = (index) => {
      const newMapping = questionMapping.filter((_, i) => i !== index);
      setQuestionMapping(newMapping);
    };

    const updateMapping = (index, key, value) => {
      const newMapping = questionMapping.map((item, i) =>
        i === index ? { ...item, [key]: value } : item
      );
      setQuestionMapping(newMapping);
    };

    const verifyLookupQuestionData = (sourceFormQuestions = [], targetFormQuestions = []) => {
      var verificationErrors = [];
      if (sourceFormData.id === targetFormData.id){
        verificationErrors.push(intl("errors.same_source_and_target_form"));
      }
      if (lookupQuestionName.trim() == ''){
        verificationErrors.push(intl("errors.lookup_question_title_empty"));
      }
      if (isLookupCalculation == true){
        if (selectedLookupCalculationQuestion == undefined){
          verificationErrors.push(intl("errors.no_calculation_question"));
        }
        if (selectedCalculationType == undefined){
          verificationErrors.push(intl("errors.no_calculation_operator"));
        }
      }
      for (const mapping of questionMapping){
        if (mapping.source == null){
          verificationErrors.push(intl("errors.lookup_condition_missing_data", {dataType: "source"}));
        }
        if (mapping.target == null){
          verificationErrors.push(intl("errors.lookup_condition_missing_data", {dataType: "target"}));
        }
        const sourceQuestion = sourceFormQuestions.find(question => question.id === mapping.source);
        const targetQuestion = targetFormQuestions.find(question => question.id === mapping.target);
        const sourceQuestionOptions = sourceQuestion ? sourceQuestion.options.map(option => option.title_en).slice().sort() : [];
        const targetQuestionOptions = targetQuestion ? targetQuestion.options.map(option => option.title_en).slice().sort() : [];

        const sourceQuestionTitle = sourceQuestion.title_en;
        const targetQuestionTitle = targetQuestion.title_en;

        if (sourceQuestionOptions.length !== targetQuestionOptions.length) {
          verificationErrors.push(intl("errors.lookup_source_target_option_count", {sourceQuestionTitle, targetQuestionTitle}));
          continue;
        }
        for (let i = 0; i < sourceQuestionOptions.length; i++) {
          const sourceOption = sourceQuestionOptions[i];
          const targetOption = targetQuestionOptions[i];
          if (sourceOption !== targetOption) {
            verificationErrors.push(intl("errors.lookup_source_target_option_match_fail", {sourceOption, targetOption, sourceQuestionTitle, targetQuestionTitle}));
          }
        }
      }
      return verificationErrors;
    }

    const handleLookupQuestionSubmit = (sourceFormQuestions, targetFormQuestions) => {
      var verificationErrors = verifyLookupQuestionData(sourceFormQuestions, targetFormQuestions);
      if (verificationErrors.length > 0){
        setMappingErrors(verificationErrors);
      } else {
        if (selectedCalculationType && selectedLookupCalculationQuestion){
          actions.saveLookupQuestion(lookupQuestionName, selectedLookupQuestion.value, questionMapping, selectedCalculationType.value, selectedLookupCalculationQuestion.value);
        } else {
          actions.saveLookupQuestion(lookupQuestionName, selectedLookupQuestion.value, questionMapping, null, null);
        }        
        setQuestionMapping([{source: null, target: null}]);
      }
    }

    const selectWorkspaceOptions = workspaces &&  workspaces.map((workspace) => {
      return { value: workspace.id, label: workspace.title };
    });

    const sourceFormOptions = selectedSourceWorkspace && selectedSourceWorkspace.forms.map((form) => {
      return { value: form.id, label: form.title_en };
    });

    var targetFormOptions = selectedTargetWorkspace && selectedTargetWorkspace.forms.map((form) => {
      return { value: form.id, label: form.title_en };
    });

    if (selectedSourceWorkspace && selectedTargetWorkspace && selectedSourceWorkspace.id === selectedTargetWorkspace.id && selectedSourceForm){
      targetFormOptions = sourceFormOptions.filter(option => option.value != selectedSourceForm.value);
    }
   
    var targetFormQuestions = [];
    if (targetFormData){
      for (const page of Object.values(targetFormData.pages)) {
        for (const section of page.sections) {
          targetFormQuestions.push(...section.questions);
        }
      }
    }

    var sourceFormQuestions = [];
    if (sourceFormData){
      for (const page of Object.values(sourceFormData.pages)) {
        for (const section of page.sections) {
          sourceFormQuestions.push(...section.questions);
        }
      }
    }

    var targetLookupQuestionOptions = targetFormQuestions.filter((question) => {
      return question.question_type.type == QUESTION_TYPE_TEXT && question.validation.type == VALIDATION_TYPE_NUMBER;
    }).map((question) => {
      return { value: question.id, label: question.title_en };
    });

    var sourceLookupCalculationQuestionOptions = sourceFormQuestions.filter((question) => {
      return question.question_type.type == QUESTION_TYPE_TEXT && question.validation.type == VALIDATION_TYPE_NUMBER;
    }).map((question) => {
      return { value: question.id, label: question.title_en };
    });

    var sourceConditionQuestionOptions = sourceFormQuestions.filter((question) => {
      return question.question_type.type == QUESTION_TYPE_RADIO;
    }).map((question) => {
      return { value: question.id, label: question.title_en };
    });

    var targetConditionQuestionOptions = targetFormQuestions.filter((question) => {
      return question.question_type.type == QUESTION_TYPE_RADIO;
    }).map((question) => {
      return { value: question.id, label: question.title_en };
    });

    var isSubmitButtonDisabled = false;
    return (
      <Modal show={isLookupModalOpen} id={"foreground_display"} lg>
        <Modal.Header>
          <Modal.Title>
            {intl("modal.create_lookup_value")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row label-row">
            <div className="col-md-1"></div>
            <div className="col-md-4 label-col">
              <h4>
                {intl("modal.section_label_target_info")}
              </h4>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-4 label-col">
              <h4>
                {intl("modal.section_label_source_info")}
              </h4>
            </div>
            <div className="col-md-1"></div>
          </div>

          <div className="row label-row">
            <div className="col-md-1"></div>
            <div className="col-md-4 label-col">
              <label>
                {intl("modal.label_select_target_workspace")}
              </label>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-4 label-col">
              <label>
                {intl("modal.label_select_source_workspace")}
              </label>
            </div>
            <div className="col-md-1"></div>
          </div>
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-4">
              <SelectBox
                placeholder={intl("modal.select_lookup_workspace")}
                selectedOption={selectedTargetWorkspaceOption}
                options={selectWorkspaceOptions}
                onHeaderChange={handleTargetWorkspaceChange}
                SelectBoxClassNames={"target-workspace-select"}
              />
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-4">
              <SelectBox
                placeholder={intl("modal.select_lookup_workspace")}
                selectedOption={selectedSourceWorkspaceOption}
                options={selectWorkspaceOptions}
                onHeaderChange={handleSourceWorkspaceChange}
                SelectBoxClassNames={"source-workspace-select"}
              />
            </div>
            <div className="col-md-1"></div>
          </div>

          <div className="row label-row">
            <div className="col-md-1"></div>
            <div className="col-md-4 label-col">
            {selectedTargetWorkspaceOption &&
              <label>
                {intl("modal.label_select_target_form")}
              </label>
            }
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-4 label-col">
            {selectedSourceWorkspaceOption &&
              <label>
                {intl("modal.label_select_source_form")}
              </label>
            }
            </div>
            <div className="col-md-1"></div>
          </div>
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-4">
              {selectedTargetWorkspaceOption &&
                <SelectBox
                  placeholder={intl("modal.select_target_form")}
                  selectedOption={selectedTargetForm}
                  options={targetFormOptions}
                  onHeaderChange={handleTargetFormChange}
                  SelectBoxClassNames={"target-form-select"}
                />

              }
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-4">
              {selectedSourceWorkspaceOption &&
                <SelectBox
                  placeholder={intl("modal.select_source_form")}
                  selectedOption={selectedSourceForm}
                  options={sourceFormOptions}
                  onHeaderChange={handleSourceFormChange}
                  SelectBoxClassNames={"source-form-select"}
                />
              }
            </div>
          </div>

          {questionMapping &&
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-4 label-col">
                <h4>{intl("modal.section_label_lookup_question_conditions")}</h4>
              </div>
            </div>
          }

          {questionMapping &&
            <div className="row label-row">
              <div className="col-md-1"></div>
              <div className="col-md-4 label-col">
                <label>Target Form Questions</label>
              </div>
              <div className="col-md-1 text-center">
                <i class="fa fa-arrows-h" aria-hidden="true"></i>
              </div>
              <div className="col-md-4 label-col">
                <label>Source Form Questions</label>
              </div>
              <div className="col-md-2"></div>
            </div>
          }
          {questionMapping &&
            questionMapping.map((mapping, index) => (
              <div key={index} className="row">
                <div className="col-md-1"></div>
                <div className="col-md-4">
                  <SelectBox
                    placeholder={intl("modal.select_target_condition_question")}
                    selectedOption={targetConditionQuestionOptions.find(conditionOption => conditionOption.value == mapping.source)}
                    options={targetConditionQuestionOptions}
                    onHeaderChange={(e) => updateMapping(index, 'target', e.value)}
                    SelectBoxClassNames={`target-question-condition-select-${index}`}
                  />
                </div>

                <div className="col-md-1 text-center" style={{paddingTop: "10px"}}>
                  <i class="fa fa-arrows-h" aria-hidden="true"></i>
                </div>

                <div className="col-md-4">
                  <SelectBox
                    placeholder={intl("modal.select_source_condition_question")}
                    selectedOption={sourceConditionQuestionOptions.find(conditionOption => conditionOption.value == mapping.source)}
                    options={sourceConditionQuestionOptions}
                    onHeaderChange={(e) => updateMapping(index, 'source', e.value)}
                    SelectBoxClassNames={`source-question-condition-select-${index}`}
                  />
                 
                </div>
                <div className="col-md-2">
                  <button className="btn btn-default options-control-button btn-circle" onClick={() => removeRow(index)}>
                    <i className="fa fa-minus" />
                  </button>
                  {index == questionMapping.length - 1 &&
                    <button className="btn btn-default options-control-button btn-circle" onClick={addRow}>
                      <i className="fa fa-plus" />
                    </button>
                  }
                </div>
              </div>
            ))
          }
          {selectedSourceForm &&
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-4"></div>
              <div className="col-md-1"></div>
              <div className="col-md-4 label-col">
                <input
                  type="checkbox"
                  checked={isLookupCalculation}
                  onChange={handleIsLookupCalculation}
                />
                <label style={{paddingLeft: "15px"}}>
                  Is Lookup value a calculation
                </label>
              </div>
              <div className="col-md-1"></div>
            </div>
          }

          <div className="row label-row">
            <div className="col-md-1"></div>
            <div className="col-md-3 label-col">
              {selectedTargetForm &&
                <label>
                  {intl("modal.label_select_lookup_value_question")}
                </label>
              }
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-1 label-col">
              {isLookupCalculation &&
                <label>
                  Operator
                </label>
              }
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-4 label-col">
              {isLookupCalculation &&
                <label>
                  {intl("modal.label_source_calculation_question")}
                </label>
              }
            </div>
            <div className="col-md-1"></div>
          </div>
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-3">
              {selectedTargetForm &&
                <SelectBox
                  placeholder={intl("modal.select_lookup_question")}
                  selectedOption={selectedLookupQuestion}
                  options={targetLookupQuestionOptions}
                  onHeaderChange={handleLookupQuestionChange}
                  SelectBoxClassNames={"lookup-question-select"}
                />
              }
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-1">
              {isLookupCalculation &&
                <SelectBox
                placeholder={intl("modal.select_calculation_type")}
                selectedOption={selectedCalculationType}
                options={MATH_EXPRESSION_SYMBOLS}
                onHeaderChange={handleCalculationTypeChange}
                SelectBoxClassNames={"calculation-type-select"}
                />
              }
            </div>
            <div className="col-md-1"></div>
            {isLookupCalculation &&
              <div className="col-md-4">
                <SelectBox
                  placeholder={intl("modal.select_source_calculation_question")}
                  selectedOption={selectedLookupCalculationQuestion}
                  options={sourceLookupCalculationQuestionOptions}
                  onHeaderChange={handleLookupCalculationQuestionChange}
                  SelectBoxClassNames={"lookup-calculation-question-select"}
                />
              </div>
            }
            <div className="col-md-1"></div>
          </div>

          {selectedTargetForm &&
            <div className="row label-row">
              <div className="col-md-1"></div>
              <div className="col-md-4 label-col"></div>
              <div className="col-md-1"></div>
              <div className="col-md-4 label-col">
                <label>
                  {intl("modal.label_input_lookup_question_title")}
                </label>
              </div>
              <div className="col-md-1"></div>
            </div>
          }
          {selectedTargetForm &&
            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6" style={{ paddingLeft: "0px", paddingRight: "4em" }}>
                {selectedSourceWorkspaceOption &&
                  <input
                    style={{ height: "inherit" }}
                    className="form-control success"
                    placeholder={intl("modal.input_lookup_question_title")}
                    type="text"
                    value={lookupQuestionName}
                    onChange={handleLookupQuestionName}
                  />
                }
              </div>
            </div>
          }

          <div className="row">
            <div className="col-md-8">
            {mappingErrors &&
                Object.entries(mappingErrors).map(([key, value]) => (
                  <React.Fragment key={key}>
                    <p className="text-danger errors-text-modal">
                      {JSON.stringify(value)}
                    </p>
                  </React.Fragment>
                ))
              }
              {errors &&
                Object.entries(errors).map(([key, value]) => (
                  <React.Fragment key={key}>
                    <p className="text-danger errors-text-modal">
                      {JSON.stringify(value)}
                    </p>
                  </React.Fragment>
                ))
              }
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-default" onClick={actions.hideLookupModal}>
            {intl("common.text.cancel_button")}
          </button>
          <button
            disabled={isSubmitButtonDisabled}
            className="btn btn-success"
            onClick={() => handleLookupQuestionSubmit(sourceFormQuestions, targetFormQuestions)}
          >
            {intl("common.text.save_button")}
          </button>
        </Modal.Footer>
      </Modal>
    );
}

export default InjectIntl(LookupValueModal);