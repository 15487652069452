import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap-modal";
import { InjectIntl } from "../common/InjectIntl";

const ModalChangeMeasureName = ({
  intl,
  newMeasureName,
  newMeasureDescription,
  actions,
  measureId,
  stakeholderId,
  changeMeasureNameModalOpen,
}) => {
  const [input, setInput] = useState({
    measureName: "",
    measureDescription: "",
  });
  useEffect(() => {
    setInput({
      measureName: newMeasureName,
      measureDescription: newMeasureDescription,
    });
  }, [newMeasureName, newMeasureDescription]);
  const handleChange = (value, name) => {
    setInput({
      ...input,
      [name]: value,
    });
  };
  const handleCancel = () => {
    actions.setChangeMeasureNameModalOpen(false);
  };
  const handleSubmit = () => {
    actions.changeInputMeasureName(
      input.measureName,
      measureId,
      input.measureDescription,
      stakeholderId
    );
    actions.measureNameSave();
    actions.setChangeMeasureNameModalOpen(false);
  };
  return (
    <Modal
      id={"foreground_display"}
      dialogClassName={"modal-lg"}
      show={changeMeasureNameModalOpen}
    >
      <Modal.Header className="well">
        <Modal.Title id="ModalHeader">
          {intl("changeMeasureName.modal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label htmlFor="measure-name">
          {intl("changeMeasureName.modal.input.namePlaceholder")}
        </label>
        <input
          id="measure-name"
          className="form-control success"
          value={input.measureName}
          onChange={(e) => handleChange(e.target.value, "measureName")}
          placeholder={intl("changeMeasureName.modal.input.namePlaceholder")}
        />
        <label htmlFor="measure-name" style={{ margin: "20px 0 5px 0" }}>
          {intl("changeMeasureName.modal.title.descriptionPlaceholder")}
        </label>
        <input
          id="measure-name"
          className="form-control success"
          value={input.measureDescription}
          onChange={(e) => handleChange(e.target.value, "measureDescription")}
          placeholder={intl(
            "changeMeasureName.modal.title.descriptionPlaceholder"
          )}
        />
      </Modal.Body>
      <Modal.Footer className="well" style={{ marginBottom: "0px" }}>
        <button className="btn btn-outline" onClick={handleCancel}>
          {intl("common.text.cancel_button")}
        </button>
        <button className="btn btn-success" onClick={handleSubmit}>
          {intl("common.text.save_button")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default InjectIntl(ModalChangeMeasureName);
