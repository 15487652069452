import merge from 'xtend';
import createReducer from '../create-reducer';
import {
  ADD_OUTCOMES_MEASURE, ADD_OUTCOMES_MEASURE_SUCCESS, ADD_OUTCOMES_MEASURE_FAILURE, SHOW_OUTCOMES_FIELD, HIDE_OUTCOMES_FIELD
} from "../../actions/stakeholder-items/outcomes";
import { SAVE_MEASURE_NAME_SUCCESS } from "../../actions/measure/measure";

const INITIAL_STATE = {
  measureIsLoading: false,
  errors: {},
  outcomesMeasureNameFieldDisable: false
};


export default createReducer({
  [ADD_OUTCOMES_MEASURE]: (state, action) => merge(state, {measureIsLoading: true}),
  [ADD_OUTCOMES_MEASURE_SUCCESS]: (state, action) => merge(state, {measureIsLoading: false}),
  [ADD_OUTCOMES_MEASURE_FAILURE]: (state, action) => merge(state, {measureIsLoading: false, error: action.payload.errors}),
  [SHOW_OUTCOMES_FIELD]: (state, action) => merge(state, { outcomesMeasureNameFieldDisable: true }),
  [HIDE_OUTCOMES_FIELD]: (state, action) => merge(state, { outcomesMeasureNameFieldDisable: false }),
  [SAVE_MEASURE_NAME_SUCCESS]: (state, action) => merge(state, {outcomesMeasureNameFieldDisable: false})
}, INITIAL_STATE)