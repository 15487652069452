import React from "react";
import PropTypes from "prop-types";
import Variables from "./Variables";
import { InjectIntl } from "../../common/InjectIntl";

const VariableTable = ({ currentCalculation, intl }) => {
  return (
    <div className="col-md-8">
      <div id="variables-table-wrap">
        <table className="table">
          <thead>
            <tr>
              <th className="variable-letter">
                {intl("calculation.variable")}
              </th>
              <th className="p-l-26 variable-name-column">
                {intl("common.text.name")}
              </th>
              <th className="text-center status-column">
                {intl("calculation.source_status")}
              </th>
              <th className="text-left">{intl("calculation.preview_value")}</th>
            </tr>
          </thead>

          {currentCalculation.variables.length > 0 && (
            <Variables variables={currentCalculation.variables} />
          )}
        </table>
        {currentCalculation.variables.length === 0 && (
          <p className="text-center alert variable-block">
            {intl("calculation.empty_variables")}
          </p>
        )}
      </div>
    </div>
  );
};

VariableTable.propTypes = {
  currentCalculation: PropTypes.object.isRequired,
  intl: PropTypes.func.isRequired
};

export default InjectIntl(VariableTable);
