export const FETCH_LOGIC_MODELS = '@@LOGIC_MODELS/FETCH_LOGIC_MODELS';
export const FETCH_LOGIC_MODELS_SUCCESS = '@@LOGIC_MODELS/FETCH_LOGIC_MODELS_SUCCESS';
export const FETCH_LOGIC_MODELS_FAILURE = '@@LOGIC_MODELS/FETCH_LOGIC_MODELS_FAILURE';

export const DELETE_LOGIC_MODEL = '@@LOGIC_MODEL/DELETE_LOGIC_MODEL';
export const DELETE_LOGIC_MODEL_SUCCESS = '@@LOGIC_MODELS/DELETE_LOGIC_MODEL_SUCCESS';
export const DELETE_LOGIC_MODEL_FAILURE = '@@LOGIC_MODELS/DELETE_LOGIC_MODEL_FAILURE';


export const EDIT_LOGIC_MODEL_NAME = '@@LOGIC_MODELS/EDIT_LOGIC_MODEL_NAME';
export const EDIT_LOGIC_MODEL_NAME_SUCCESS = '@@LOGIC_MODELS/EDIT_LOGIC_MODEL_NAME_SUCCESS';

export const EDIT_LOGIC_MODEL_NAME_FAILURE = '@@LOGIC_MODELS/EDIT_LOGIC_MODEL_NAME_FAILURE';
export const CHANGE_LOGIC_MODEL_NAME = '@@LOGIC_MODELS/CHANGE_LOGIC_MODEL_NAME';
export const SHOW_CHANGE_NAME_INPUT = '@@LOGIC_MODELS/SHOW_CHANGE_NAME_INPUT';
export const HIDE_CHANGE_NAME_INPUT = '@@LOGIC_MODELS/HIDE_CHANGE_NAME_INPUT';
export const ADD_LOGIC_MODEL = '@@LOGIC_MODELS/ADD_LOGIC_MODEL';
export const EDIT_LOGIC_MODEL = '@@LOGIC_MODELS/EDIT_LOGIC_MODEL';
export const ADD_LOGIC_MODEL_FAILURE = '@@LOGIC_MODELS/ADD_LOGIC_MODEL_FAILURE';

export const ADD_LOGIC_MODEL_SUCCESS = '@@LOGIC_MODELS/ADD_LOGIC_MODEL_SUCCESS';
export const CREATE_LOGIC_MODEL_DIALOG_PAGE_SHOW = '@@LOGIC_MODELS/CREATE_LOGIC_MODEL_DIALOG_PAGE_SHOW';

export const CREATE_LOGIC_MODEL_DIALOG_PAGE_HIDE = '@@LOGIC_MODELS/CREATE_LOGIC_MODEL_DIALOG_PAGE_HIDE';

export const CHANGE_LOGIC_MODEL_NAME_FORM_CREATE = '@@LOGIC_MODELS/CHANGE_LOGIC_MODEL_NAME_FORM_CREATE';


export const CREATE_LOGIC_MODEL_OPTIONS_SAVE = '@@LOGIC_MODELS/CREATE_LOGIC_MODEL_OPTIONS_SAVE';

export const EXPORT_JSON = '@@LOGIC_MODELS/EXPORT_JSON';
export const EXPORT_JSON_SUCCESS = "@@LOGIC_MODELS/EXPORT_JSON_SUCCESS";
export const EXPORT_JSON_SUCCESS_FAILURE =
         "@@LOGIC_MODELS/EXPORT_JSON_SUCCESS_FAILURE";

export const FETCH_FRAMEWORKS = "@@LOGIC_MODELS/FETCH_FRAMEWORKS";
export const FETCH_FRAMEWORKS_SUCCESS = "@@LOGIC_MODELS/FETCH_FRAMEWORKS_SUCCESS";
export const FETCH_FRAMEWORKS_FAILURE = "@@LOGIC_MODELS/FETCH_FRAMEWORKS_FAILURE";

export const FETCH_THEMES = "@@LOGIC_MODELS/FETCH_THEMES";
export const FETCH_THEMES_SUCCESS = "@@LOGIC_MODELS/FETCH_THEMES_SUCCESS";
export const FETCH_THEMES_FAILURE = "@@LOGIC_MODELS/FETCH_THEMES_FAILURE";

export const CREATE_FRAMEWORK = "@@LOGIC_MODELS/CREATE_FRAMEWORK";
export const CREATE_FRAMEWORK_SUCCESS = "@@LOGIC_MODELS/CREATE_FRAMEWORK_SUCCESS";
export const CREATE_FRAMEWORK_FAILURE = "@@LOGIC_MODELS/CREATE_FRAMEWORK_FAILURE";

export const CREATE_THEME = "@@LOGIC_MODELS/CREATE_THEME";
export const CREATE_THEME_SUCCESS = "@@LOGIC_MODELS/CREATE_THEME_SUCCESS";
export const CREATE_THEME_FAILURE = "@@LOGIC_MODELS/CREATE_THEME_FAILURE";

export const SHOW_LOOKUP_MODAL = "@@LOGIC_MODELS/SHOW_LOOKUP_MODAL";
export const HIDE_LOOKUP_MODAL = "@@LOGIC_MODELS/HIDE_LOOKUP_MODAL";

export const FETCH_WORKSPACE_RESOURCES = "@@LOGIC_MODELS/FETCH_WORKSPACE_RESOURCES";
export const FETCH_WORKSPACE_RESOURCES_SUCCESS = "@@LOGIC_MODELS/FETCH_WORKSPACE_RESOURCES_SUCCESS";
export const FETCH_WORKSPACE_RESOURCES_FAILURE = "@@LOGIC_MODELS/FETCH_WORKSPACE_RESOURCES_FAILURE";

export const FETCH_DATA_RESOURCES = "@@LOGIC_MODELS/FETCH_DATA_RESOURCES";
export const FETCH_SOURCE_DATA_RESOURCES_SUCCESS = "@@LOGIC_MODELS/FETCH_SOURCE_DATA_RESOURCES_SUCCESS";
export const FETCH_TARGET_DATA_RESOURCES_SUCCESS = "@@LOGIC_MODELS/FETCH_TARGET_DATA_RESOURCES_SUCCESS";
export const FETCH_DATA_RESOURCES_FAILURE = "@@LOGIC_MODELS/FETCH_DATA_RESOURCES_FAILURE";

export const SUBMIT_LOOKUP_QUESTION = "@@LOGIC_MODELS/SUBMIT_LOOKUP_QUESTION";
export const SUBMIT_LOOKUP_QUESTION_SUCCESS = "@@LOGIC_MODELS/SUBMIT_LOOKUP_QUESTION_SUCCESS";
export const SUBMIT_LOOKUP_QUESTION_FAILURE = "@@LOGIC_MODELS/SUBMIT_LOOKUP_QUESTION_FAILURE";

function deleteLogicModel(id) {
  return {type: DELETE_LOGIC_MODEL, id}
}

function fetchLogicModels() {
  return { type: FETCH_LOGIC_MODELS }
}

function addLogicModel() {
  return { type: ADD_LOGIC_MODEL }
}

function successDeleteLogicModel() {
  return { type: DELETE_LOGIC_MODEL_SUCCESS }
}

function changeLogicModelName(logicModelName, logicModelId) {
  return { type: CHANGE_LOGIC_MODEL_NAME, logicModelName, logicModelId}
}

function showChangeLogicModelNameInput(logicModelId) {
  return { type: SHOW_CHANGE_NAME_INPUT, logicModelId }
}

function hideChangeLogicModelNameInput() {
  return { type: HIDE_CHANGE_NAME_INPUT }
}

function saveLogicModelName() {
  return { type: EDIT_LOGIC_MODEL_NAME }
}

function createLogicModelDialogPageShow() {
	return {type: CREATE_LOGIC_MODEL_DIALOG_PAGE_SHOW}
}

function createLogicModelDialogPageHide() {
	return {type: CREATE_LOGIC_MODEL_DIALOG_PAGE_HIDE}
}

function changeLogicModelNameFormCreate(name) {
	return {type: CHANGE_LOGIC_MODEL_NAME_FORM_CREATE, name}
}

function saveLogicModelOptions(options) {
  return { type: CREATE_LOGIC_MODEL_OPTIONS_SAVE, options }
}

function exportJSON(modelId, modelName) {
  return { type: EXPORT_JSON, modelId, modelName };
}

function fetchFrameworks() {
  return { type: FETCH_FRAMEWORKS};
}

function fetchThemes() {
  return { type: FETCH_THEMES };
}

function createFramework(name){
  return { type: CREATE_FRAMEWORK, name };
}

function createTheme(name, description, frameworkId){
  return { type: CREATE_THEME, name, description, frameworkId };
}

function showLookupModal(){
  return { type: SHOW_LOOKUP_MODAL };
}

function hideLookupModal(){
  return { type: HIDE_LOOKUP_MODAL };
}

function fetchWorkspaceResources() {
  return { type: FETCH_WORKSPACE_RESOURCES };
}

function fetchDataResources(formId, selectedFormType) {
  return { type: FETCH_DATA_RESOURCES, formId, selectedFormType};
}

function saveLookupQuestion(newQuestionName, lookupQuestionId, questionMapping, calculationType, lookupCalculationQuestion){
  return { type: SUBMIT_LOOKUP_QUESTION, newQuestionName, lookupQuestionId, questionMapping, calculationType, lookupCalculationQuestion};
}

export default {
  fetchLogicModels,
  deleteLogicModel,
  successDeleteLogicModel,
  createLogicModelDialogPageShow,
  createLogicModelDialogPageHide,
  changeLogicModelNameFormCreate,
  saveLogicModelOptions,
  addLogicModel,
  changeLogicModelName,
  showChangeLogicModelNameInput,
  saveLogicModelName,
  hideChangeLogicModelNameInput,
  exportJSON,
  fetchFrameworks,
  fetchThemes,
  createFramework,
  createTheme,
  showLookupModal,
  fetchWorkspaceResources,
  hideLookupModal,
  fetchDataResources,
  saveLookupQuestion
};

