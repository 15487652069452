import merge from 'xtend';
import createReducer from '../create-reducer';
import {
  ADD_OUTPUT_MEASURE,
  ADD_OUTPUT_MEASURE_SUCCESS,
  ADD_OUTPUT_MEASURE_FAILURE,
  SHOW_OUTPUT_FIELD,
  HIDE_OUTPUT_FIELD
} from "../../actions/stakeholder-items/output";
import { SAVE_MEASURE_NAME_SUCCESS } from "../../actions/measure/measure";


const INITIAL_STATE = {
  measureIsLoading: false,
  errors: {},
  outputsMeasureNameFieldDisable: false
};

export default createReducer({
  [ADD_OUTPUT_MEASURE]: (state, action) => merge(state, {measureIsLoading: true }),
  [ADD_OUTPUT_MEASURE_SUCCESS]: (state, action) => merge(state, {measureIsLoading: false }),
  [ADD_OUTPUT_MEASURE_FAILURE]: (state, action) => merge(state, {
    errors: action.payload.errors,
    measureIsLoading: false
  }),
  [SHOW_OUTPUT_FIELD]: (state, actions) => merge(state, {outputsMeasureNameFieldDisable: true }),
  [HIDE_OUTPUT_FIELD]: (state, action) => merge(state, {outputsMeasureNameFieldDisable: false }),
  [SAVE_MEASURE_NAME_SUCCESS]: (state, action) => merge(state, {outputsMeasureNameFieldDisable: false})
}, INITIAL_STATE)