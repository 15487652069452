export const INITIAL_REPORTS_QUERY = '@@REPORTS/INITIAL_REPORTS_QUERY';
export const INITIAL_REPORTS_QUERY_SUCCESS = '@@REPORTS/INITIAL_REPORTS_QUERY_SUCCESS';
export const INITIAL_REPORTS_QUERY_FAILURE = '@@REPORTS/INITIAL_REPORTS_QUERY_FAILURE';
export const CHANGE_LOGIC_MODEL = '@@REPORTS/CHANGE_LOGIC_MODEL';
export const RESULTS_QUERY = '@@REPORTS/RESULTS_QUERY';
export const RESULTS_QUERY_SUCCESS = '@@REPORTS/RESULTS_QUERY_SUCCESS';
export const RESULTS_QUERY_FAILURE = '@@REPORTS/RESULTS_QUERY_FAILURE';
export const CHANGE_TAB = '@@REPORTS/CHANGE_TAB';

const initReports = () => ({
  type: INITIAL_REPORTS_QUERY
});

/**
 * Reports are segmented by logic model
 */
const initReportsSuccess = logic_models => {
  return {
    type: INITIAL_REPORTS_QUERY_SUCCESS,
    logic_models  
  }
};

const initReportsFailure = error => {
  return {
    type: INITIAL_REPORTS_QUERY_FAILURE,
    error
  }
};

const changeLogicModel = logic_model_id => {
  return {
    type: CHANGE_LOGIC_MODEL,
    logic_model_id
  }
};

const resultsQuery = logic_model_id => {
  return {
    type: RESULTS_QUERY,
    logic_model_id
  }
};

const resultsQuerySuccess = results => {
  return {
    type: RESULTS_QUERY_SUCCESS,
    results
  }
}

const resultsQueryFailure = error => {
  return {
    type: RESULTS_QUERY_FAILURE,
    error
  }
}

const changeTab = nextTab => {
  return {
    type: CHANGE_TAB,
    nextTab
  }
}

export default {
  initReports,
  initReportsSuccess,
  initReportsFailure,
  changeLogicModel,
  resultsQuery,
  resultsQuerySuccess,
  resultsQueryFailure,
  changeTab
}

