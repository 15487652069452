export const CHANGE_SERVICE = 'SERVICE/CHANGE_SERVICE';

function selectService(serviceName) {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refresh_token');
  const locale = localStorage.getItem('locale');
  const serviceUrl = localStorage.getItem(`${serviceName}_base_url`);
  let redirectUrl = `${serviceUrl}/login?access_token=${token}&refresh_token=${refreshToken}`;

  var currentUrl = window.location.href.replace('https://', '').replace('http://', '');
  var subdomain = null;

  if(currentUrl.includes("dev")){ //dev, staging
    subdomain = currentUrl.split('.')[1];
  } else if(currentUrl.includes("local")){ // local
  } else { //prod
    subdomain = currentUrl.split('.')[0];
  }
  
  if(serviceName === 'compass') {
    redirectUrl = `${serviceUrl}/?access_token=${token}&refresh_token=${refreshToken}&dom_user=${subdomain}`;
  }

  window.location.assign(`${redirectUrl}&locale=${locale}`);
  return {type: CHANGE_SERVICE }
}

function goToChangePassword() {
  const atlasBaseUrl = localStorage.getItem('atlas_base_url');
  const locale = localStorage.getItem('locale');
  window.location.assign(`${atlasBaseUrl}/password/change?redirectTo=rucksack&locale=${locale}`);
}

export default { selectService, goToChangePassword }