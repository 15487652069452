import React from "react";
import PropTypes from "prop-types";
import {
  capitalize,
  getFormTitle,
  parseValue
} from "../../../../assets/sam-utils";
import WarningMessage from "./WarningMessage";
import {
  UNPUBLISHED,
  DELETED,
  DELETED_COLUMN,
  DELETED_CONDITIONS,
  NO_RESPONSES,
  CHANGED_DATA_TYPE
} from "./constants";

const OneSource = props => {
  const changedDataType = props.activeVariable.source_statuses.includes(
    CHANGED_DATA_TYPE
  );
  return (
    <div>
      <h2 className="inner-title">{props.activeVariable.name}</h2>
      <span className="source-index-label">
        {props.intl("common.text.type")}
      </span>
      <span className="source-index-value">
        {props.activeVariable.tableLayout.type}
      </span>
      <div className="m-t">
        <span className="source-index-label">
          {props.intl("source.workspace")}
        </span>
        <span className="source-index-value">
          {props.activeVariable.tableLayout.workspaceName}
        </span>
      </div>
      <Dataset {...props}>
        <SourceColumn {...props}>
          <Transformation {...{ ...props, changedDataType }} />
        </SourceColumn>
      </Dataset>
      <WarningMessages {...{ ...props, changedDataType }} />
    </div>
  );
};

const Dataset = ({ activeVariable, intl, intlLocale, children }) => (
  <div className="m-t">
    <span className="source-index-label">{intl("source.dataset")}</span>
    {activeVariable.source_statuses.includes(DELETED) ? (
      <WarningMessage danger={true} icon={true}>
        <strong>{getDatasetName(activeVariable, intlLocale)}</strong>
        &nbsp;<span>{intl("source.deleted")}</span>
      </WarningMessage>
    ) : (
      <div>
        <i
          className={`fa datatypeIcon ${
            activeVariable.datasource_type.name === "Upload"
              ? "fa-table"
              : "fa-list-alt"
          }`}
        />
        &nbsp;
        <span className="source-index-value dataset-name">
          {getDatasetName(activeVariable, intlLocale)}
        </span>
        {activeVariable.source_statuses.includes(UNPUBLISHED) && (
          <WarningMessage danger={true}>
            <span>{intl("source.source_is_draft")}</span>
          </WarningMessage>
        )}
        {children}
      </div>
    )}
  </div>
);

const SourceColumn = ({ activeVariable, intl, intlLocale, children }) => {
  const type =
    activeVariable.tableLayout.type === "Survey" ? "question" : "column";

  return (
    <div className="m-t">
      <span className="source-index-label">{intl("source.column")}</span>
      {activeVariable.source_statuses.includes(DELETED_COLUMN) ? (
        <WarningMessage danger={true} icon={true}>
          <span>{intl(`source.${type}_deleted`)}</span>
        </WarningMessage>
      ) : (
        <div>
          <SourceColumnName {...{ activeVariable, intl, intlLocale }} />
          {children}
        </div>
      )}
    </div>
  );
};

const Transformation = ({ activeVariable, intl, changedDataType }) => (
  <div className="m-t">
    <span className="source-index-label">
      {intl("calculation.transformation")}
    </span>

    {!changedDataType && (
      <span className="source-index-value transformation">
        {!!activeVariable.transformation &&
          capitalize(activeVariable.transformation.toLowerCase())}
        &nbsp; {parseValue(activeVariable)}
      </span>
    )}
  </div>
);

const WarningMessages = ({ activeVariable, intl, changedDataType }) => (
  <div>
    {activeVariable.source_statuses.includes(NO_RESPONSES) && (
      <WarningMessage danger={true} icon={true}>
        <span>{intl("source.no_responses_hint")}</span>
      </WarningMessage>
    )}
    {changedDataType && (
      <WarningMessage danger={true} icon={true}>
        <span>{intl("source.changed_data_type_hint")}</span>
      </WarningMessage>
    )}
    {activeVariable.source_statuses.includes(DELETED_CONDITIONS) && (
      <WarningMessage danger={true} icon={true}>
        <span>{intl("source.conditions_deleted")}</span>
      </WarningMessage>
    )}
  </div>
);

const SourceColumnName = ({ activeVariable, intlLocale }) => (
  <span className="source-index-value source-column-name">
    {typeof activeVariable.tableLayout.sourceColumnName === "object" &&
    activeVariable.tableLayout.sourceColumnName !== null
      ? getFormTitle(activeVariable.tableLayout.sourceColumnName, intlLocale)
      : activeVariable.tableLayout.sourceColumnName}
  </span>
);

const getDatasetName = (activeVariable, intlLocale) =>
  typeof activeVariable.tableLayout.datasetName === "object"
    ? getFormTitle(activeVariable.tableLayout.datasetName, intlLocale)
    : activeVariable.tableLayout.datasetName;

OneSource.propTypes = {
  activeVariable: PropTypes.shape({
    source_statuses: PropTypes.array.isRequired
  }).isRequired,
  intl: PropTypes.func.isRequired,
  intlLocale: PropTypes.string
};

export default OneSource;
