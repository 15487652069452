import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Adjustments from "../components/logic-models/stakeholder-items/adjustment/Adjustments";
import adjustmentsActions from "../actions/measure/adjustments";
import financialProxyActions from "../actions/stakeholder-items/financialProxy"

function mapStateToProps(state) {
  return {
    ...state.adjustments,
    ...state.financialProxy,
    ...state.measurePage,
    measureId: state.measurePage.measureId,
    name: state.measurePage.newMeasureName,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...adjustmentsActions,
      ...financialProxyActions,
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Adjustments)