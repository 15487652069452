import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CalculationsActions from "../../actions/calculation/calculation-page";
import CalculationsIndex from "../../components/calculations/CalculationsIndex";
import manageMeasureActions from "../../actions/measure/manage-measure";
import DataSourceActions from "../../actions/calculation/data-source";
import SpinnerActions from "../../actions/spinner";
import SourceModalsActions from "../../actions/calculation/source-modals";

function mapStateToProps(state, ownProps) {
  return {
    ...state.calculations,
    measureId: ownProps.params.measureId,
    logicModelId: ownProps.params.id,
    measureName: state.measurePage.newMeasureName,
    measureDescription: state.measurePage.newMeasureDescription,
    intl: state.intl,
    measureTypeTitle: ownProps.location.query.measureTitle,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...CalculationsActions,
      ...manageMeasureActions,
      ...DataSourceActions,
      ...SpinnerActions,
      setSourceModalInitialState: SourceModalsActions.setSourceModalInitialState
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalculationsIndex)