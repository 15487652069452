import merge from 'xtend';
import createReducer from '../create-reducer';
import {
  ADD_INPUTS_MEASURE,
  ADD_INPUTS_MEASURE_FAILURE,
  ADD_INPUTS_MEASURE_SUCCESS,
  HIDE_INPUT_FIELD,
  SHOW_INPUT_FIELD,
} from "../../actions/stakeholder-items/inputs";
import { SAVE_MEASURE_NAME_SUCCESS } from "../../actions/measure/measure";

const INITIAL_STATE = {
  measureIsLoading: false,
  errors: {},
  inputsMeasureNameFieldDisable: false
};


export default createReducer({
  [ADD_INPUTS_MEASURE]: (state, action) => merge(state, { measureIsLoading: true }),
  [ADD_INPUTS_MEASURE_SUCCESS]: (state, action) => merge(state, { measureIsLoading: false }),
  [ADD_INPUTS_MEASURE_FAILURE]: (state, action) => merge(state, {
    measureIsLoading: false,
    error: action.payload.errors
  }),
  [SHOW_INPUT_FIELD]: (state, action) => merge(state, { inputsMeasureNameFieldDisable: true }),
  [HIDE_INPUT_FIELD]: (state, action) => merge(state, { inputsMeasureNameFieldDisable: false }),
  [SAVE_MEASURE_NAME_SUCCESS]: (state, action) => merge(state, { inputsMeasureNameFieldDisable: false }),
}, INITIAL_STATE)