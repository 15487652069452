export const NO_RESPONSES = "no_responses";
export const UNPUBLISHED = "unpublished";
export const DELETED = "deleted";
export const DELETED_COLUMN = "deleted_column";
export const DELETED_CONDITIONS = "deleted_conditions";
export const CHANGED_DATA_TYPE = "changed_data_type";

export const BROKEN = "broken";
export const COMPLETE = "complete";
export const INCOMPLETE = "incomplete";

export const PREEXISTING_DATASOURCE_TYPE_ID = 3;
