import merge from 'xtend';
import createReducer from './create-reducer';

import { REQUEST_SUCCESS, LOGOUT, REQUEST_FAILURE } from "../actions/login";
import {REGISTER_REQUEST_SUCCESS} from "../actions/register";

const INITIAL_STATE = {
  user_email: '',
  password: '',
  errors: {},
  token: '',
  isAuthenticated: false,
  user_id: '',
  name: '',
  isLoading: false
};

export default createReducer({
  [REQUEST_SUCCESS]: (state, action) => merge(state, {
    token: action.payload.token,
    isAuthenticated: true,
    user_id: action.payload.user_id,
    user_email: action.payload.email,
    name: action.payload.name,
    isLoading: true
  }),
  [LOGOUT]: (state, action) => merge(state, INITIAL_STATE),
  [REQUEST_FAILURE]: (state, action) => merge(state, { errors: action.errors}),
  [REGISTER_REQUEST_SUCCESS]: (state, action) => merge(state, {
	  isAuthenticated: true
  })
}, INITIAL_STATE)