import React from "react";
import PropTypes from "prop-types";
import WarningMessage from "./WarningMessage";
import { parseValue } from "../../../../assets/sam-utils";
import { InjectIntl } from "../../../common/InjectIntl";
import { getPrimaryStatus } from "../../../../assets/sam-utils";
import { NO_RESPONSES, BROKEN } from "./constants";

const PreexistingSource = ({ activeVariable, intl }) => {
  return (
    <div>
      <h2 className="inner-title">{activeVariable.tableLayout.name}</h2>

      {activeVariable.datasource_object.condition === "incomplete_variable" && (
        <WarningMessage>
          <p>{intl("source.modal.incomplete_variables_hint")}</p>
        </WarningMessage>
      )}

      <div className="m-t">
        <span className="source-index-label">
          {intl("calculation.formula")}
        </span>
        <span className="source-index-value">
          {activeVariable.tableLayout.formula}
        </span>
      </div>

      <div className="m-t">
        <span className="source-index-label">{intl("calculation.value")}</span>
        <CalculationValue {...{ activeVariable, intl }} />
      </div>
    </div>
  );
};

const CalculationValue = ({ activeVariable, intl }) => {
  const status = getPrimaryStatus(activeVariable);

  if (
    (status === BROKEN || status === NO_RESPONSES) &&
    activeVariable.tableLayout.value === null
  ) {
    return (
      <div>
        <WarningMessage danger={true} icon={true}>
          <span>{intl("source.broken_variable_hint")}</span>
        </WarningMessage>
        <WarningMessage danger={true} icon={true}>
          <span>{intl("calculation.broken_formula_hint")}</span>
        </WarningMessage>
      </div>
    );
  } else if (status === BROKEN || status === NO_RESPONSES)
    return (
      <WarningMessage danger={true} icon={true}>
        <span>{intl("source.broken_variable_hint")}</span>
      </WarningMessage>
    );
  else if (activeVariable.tableLayout.value === null)
    return (
      <WarningMessage danger={true} icon={true}>
        <span>{intl("calculation.broken_formula_hint")}</span>
      </WarningMessage>
    );
  else
    return (
      <span className="source-index-value">
        {parseValue(activeVariable.tableLayout)}
      </span>
    );
};

PreexistingSource.propTypes = {
  activeVariable: PropTypes.object,
  intl: PropTypes.func.isRequired
};

export default InjectIntl(PreexistingSource);
