import { Observable } from "rxjs/Observable";
import { post, get, put } from "../api";
import {
  FETCH_COLLABORATORS,
  FETCH_COLLABORATORS_SUCCESS, FETCH_COLLABORATORS_FAILURE, ADD_USER_TO_LOGIC_MODEL, DELETE_USER_FROM_LOGIC_MODEL,
  ADD_USER_TO_LOGIC_MODEL_SUCCESS, ADD_USER_TO_LOGIC_MODEL_FAILURE, DELETE_USER_FROM_LOGIC_MODEL_FAILURE,
  DELETE_USER_FROM_LOGIC_MODEL_SUCCESS, UPDATE_COLLABORATING_USER_PERMISSION
} from "../actions/manage-callaborator";
import { HIDE_COLLABORATOR_MODAL } from "../actions/members/invite-modal";
import { REQUEST, REQUEST_SUCCESS, REQUEST_FAILURE } from "../actions/members/invite-collaborators";
import { push } from 'react-router-redux';
import { SHOW_ERROR } from "../actions/errors";
import { HIDE_LOADING } from "../actions/loading-modal";


export function getEmailsFromRecipients(recipients) {
  const emailsSet = recipients.reduce((emails, recipient) => {
    if (recipient.emails) {
      recipient.emails.forEach(email => emails.add(email.title));
    } else {
      emails.add(recipient.value);
    }
    return emails
  }, new Set());
  return Array.from(emailsSet);
}

function inviteCollaboratorsEpic(action$, store) {
  return action$.ofType(REQUEST)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { recipients, subject, messages, isCustomMessage } = store.getState().inviteCollaborators;
      const customMessage = store.getState().inviteCollaborators.message;
      var body = messages;
      if (isCustomMessage){
        body = body +"/"+ customMessage;
      }
      const { logicModel } = store.getState().measurePage;
      const {locale} = store.getState().intl;
      const payload = {
        logic_model_id: logicModel.id,
        emails: recipients,
        body,
        subject,
        language: locale
      };

      return Observable.fromPromise(post(`/api/invitations`, payload, token))
        .flatMap(({ message }) => {
          const data = message;

          return Observable.concat(
            Observable.of({ type: HIDE_LOADING }),
            Observable.of({ type: REQUEST_SUCCESS, data }),
            Observable.of({ type: HIDE_COLLABORATOR_MODAL }),
            Observable.of({ type: FETCH_COLLABORATORS })
          )
        })
        .catch(({error}) => {
          return Observable.concat(
            Observable.of({ type: HIDE_LOADING }),
            Observable.of({ type: REQUEST_FAILURE, error })
          )
        })
    })
}

function fetchCollaboratorsEpic(action$, store) {
  return action$.ofType(FETCH_COLLABORATORS)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { logicModelId } = store.getState().measurePage;
      return Observable.fromPromise(get(`/api/collaborators/${logicModelId}`, token))
        .flatMap(({ collaborators, invitations }) => {

          return Observable.concat(
            Observable.of({ type: HIDE_LOADING }),
            Observable.of({ type: FETCH_COLLABORATORS_SUCCESS, collaborators, invitations })
          )
        })
        .catch((errors) => {
         return Observable.concat(
           Observable.of({ type: HIDE_LOADING }),
           Observable.of({ type: FETCH_COLLABORATORS_FAILURE, errors })
         )
        })
    })
}

function geInvitationLogicModelEpic(action$, store) {
  return action$.ofType(ADD_USER_TO_LOGIC_MODEL)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const id = localStorage.getItem('invitationId');

      return Observable.fromPromise(put(`/api/invitations/${id}`, { token: action.token }, token))
        .flatMap((response) => {

          return Observable.concat(
            Observable.of({ type: ADD_USER_TO_LOGIC_MODEL_SUCCESS }),
            Observable.of(push('/logic-models'))
          )
        })
        .catch((errors) => Observable.of({ type: ADD_USER_TO_LOGIC_MODEL_FAILURE, errors }))
    })
}

function removeCollaboratorEpic(action$, store) {
  return action$.ofType(DELETE_USER_FROM_LOGIC_MODEL)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { logicModelId } = store.getState().measurePage;

      return Observable.fromPromise(post(`/api/collaborators/${logicModelId}/delete`, { user_id: action.userId }, token))
        .flatMap((response) => {

          return Observable.concat(
            Observable.of({ type: HIDE_LOADING }),
            Observable.of({ type: DELETE_USER_FROM_LOGIC_MODEL_SUCCESS }),
            Observable.of({ type: FETCH_COLLABORATORS })
          )
        })
        .catch((error) => {
          const errorText = Object.values(error)[0];
          return Observable.concat(
            Observable.of({ type: HIDE_LOADING }),
            Observable.of({ type: DELETE_USER_FROM_LOGIC_MODEL_FAILURE }),
            Observable.of({ type: SHOW_ERROR, errorText })
          )
        })
    })
}

function updateCollaboratorPermissionEpic(action$, store) {
  return action$.ofType(UPDATE_COLLABORATING_USER_PERMISSION)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { logicModelId } = store.getState().measurePage;
      const payload = { user_permission: action.permission };

      return Observable.fromPromise(put(`/api/collaborators/${logicModelId}/collaborator/${action.userId}`, payload, token))
        .flatMap((response) => {

          return Observable.concat(
            Observable.of({ type: HIDE_LOADING }),
            Observable.of({ type: FETCH_COLLABORATORS })
          )
        })
        .catch((error) => {
          const errorText = Object.values(error)[0];
          return Observable.concat(
            Observable.of({ type: HIDE_LOADING }),
            Observable.of({ type: SHOW_ERROR, errorText })
          )
        })
    })
}

export default [
  inviteCollaboratorsEpic,
  fetchCollaboratorsEpic,
  geInvitationLogicModelEpic,
  removeCollaboratorEpic,
  updateCollaboratorPermissionEpic
]