import {get, post, remove, put } from '../api';
import {
  FETCH_LOGIC_MODELS,
  FETCH_LOGIC_MODELS_SUCCESS,
  FETCH_LOGIC_MODELS_FAILURE,
  DELETE_LOGIC_MODEL_SUCCESS,
  DELETE_LOGIC_MODEL_FAILURE,
  EDIT_LOGIC_MODEL_NAME,
  HIDE_CHANGE_NAME_INPUT,
  ADD_LOGIC_MODEL,
  ADD_LOGIC_MODEL_FAILURE,
  ADD_LOGIC_MODEL_SUCCESS,
  EDIT_LOGIC_MODEL_NAME_SUCCESS,
  EDIT_LOGIC_MODEL_NAME_FAILURE,
  DELETE_LOGIC_MODEL,
  EXPORT_JSON,
  EXPORT_JSON_SUCCESS,
  EXPORT_JSON_SUCCESS_FAILURE,
  FETCH_FRAMEWORKS,
  FETCH_FRAMEWORKS_SUCCESS,
  FETCH_FRAMEWORKS_FAILURE,
  FETCH_THEMES,
  FETCH_THEMES_SUCCESS,
  FETCH_THEMES_FAILURE,
  CREATE_FRAMEWORK,
  CREATE_FRAMEWORK_SUCCESS,
  CREATE_FRAMEWORK_FAILURE,
  CREATE_THEME,
  CREATE_THEME_SUCCESS,
  CREATE_THEME_FAILURE,
  FETCH_WORKSPACE_RESOURCES,
  FETCH_WORKSPACE_RESOURCES_SUCCESS,
  FETCH_WORKSPACE_RESOURCES_FAILURE,
  FETCH_DATA_RESOURCES,
  FETCH_SOURCE_DATA_RESOURCES_SUCCESS,
  FETCH_TARGET_DATA_RESOURCES_SUCCESS,
  FETCH_DATA_RESOURCES_FAILURE,
  SUBMIT_LOOKUP_QUESTION,
  SUBMIT_LOOKUP_QUESTION_SUCCESS,
  SUBMIT_LOOKUP_QUESTION_FAILURE,
} from "../actions/logic-models";
import 'rxjs';
import { Observable } from 'rxjs/Observable';
import { push } from 'react-router-redux';
import {SHOW_ERROR} from "../actions/errors";
import { UNAUTHORIZED_REQUEST } from "../actions/login";
import { SOURCE, TARGET } from '../constants';

function cleanRedirectPath() {
  localStorage.removeItem('redirectPath');
  localStorage.removeItem('invitationId');
  localStorage.removeItem('invitationToken');
}

export function fetchFrameworks(action$) {
  return action$.ofType(FETCH_FRAMEWORKS).mergeMap((action) => {
    const token = localStorage.getItem("token");
    return Observable.fromPromise(get("/api/frameworks", token))
    .flatMap((res) => {
      return Observable.concat(
        Observable.of({ type: FETCH_FRAMEWORKS_SUCCESS, payload: res })
      );
    })
    .catch(errors=>{
      return Observable.concat(
        errors.global
          ? Observable.of({ type: UNAUTHORIZED_REQUEST })
          : Observable.of({ type: FETCH_FRAMEWORKS_FAILURE, errors })
      );
    })
  })
}

export function fetchThemes(action$) {
  return action$.ofType(FETCH_THEMES).mergeMap((action) => {
    const token = localStorage.getItem("token");
    return Observable.fromPromise(get("/api/themes", token))
      .flatMap((res) => {
        return Observable.concat(
          Observable.of({ type: FETCH_THEMES_SUCCESS, payload: res })
        );
      })
      .catch((errors) => {
        return Observable.concat(
          errors.global
            ? Observable.of({ type: UNAUTHORIZED_REQUEST })
            : Observable.of({ type: FETCH_THEMES_FAILURE, errors })
        );
      });
  });
}

export function fetchExportLogic(action$, store) {
  return action$.ofType(EXPORT_JSON).mergeMap((action) => {
    const token = localStorage.getItem("token");
    return Observable.fromPromise(
      get(`/api/measures/exportMeasures?logic_model_id=${action.modelId}`, token)
    )
      .flatMap((res) => {
        var dataStr =
          "data:text/json;charset=utf-8," +
          encodeURIComponent(JSON.stringify(res));
        var dlAnchorElem = document.getElementById("downloadAnchorElem");
        dlAnchorElem.setAttribute("href", dataStr);
        dlAnchorElem.setAttribute("download", `${action.modelName}.json`);
        dlAnchorElem.click();
        return Observable.concat(Observable.of({ type: EXPORT_JSON_SUCCESS }));
      })
      .catch((errors) => {
        return Observable.concat(
          errors.global
            ? Observable.of({ type: UNAUTHORIZED_REQUEST })
            : Observable.of({ type: EXPORT_JSON_SUCCESS_FAILURE, errors })
        );
      });
  });
}

export function fetchLogicModelsEpic(action$, store ) {
  return action$.ofType(FETCH_LOGIC_MODELS)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const invitationToken = localStorage.getItem('invitationToken');
      if (invitationToken) cleanRedirectPath();
      return Observable.fromPromise(get('/api/logic_models', token))
        .flatMap(({logic_models, categories, countries}) => {
          return Observable.concat(
            Observable.of({ type: FETCH_LOGIC_MODELS_SUCCESS, payload: {logic_models, categories, countries}})
          )
        })
        .catch(errors => {

          return Observable.concat(
            errors.global?
              Observable.of({type: UNAUTHORIZED_REQUEST })
              :
              Observable.of({type: FETCH_LOGIC_MODELS_FAILURE, errors})
          )
        })
  })
}

export function deleteLogicModelEpic(action$, store) {
  return action$.ofType(DELETE_LOGIC_MODEL)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      return Observable.fromPromise(remove(`/api/logic_models/${action.id}`, token))
        .flatMap(() => {
          return Observable.concat(
            Observable.of({ type: FETCH_LOGIC_MODELS}),
            Observable.of({ type: DELETE_LOGIC_MODEL_SUCCESS})
          )
        })
        .catch((errors) => {
          const errorText = errors.message;
          return Observable.concat(
            Observable.of({type: DELETE_LOGIC_MODEL_FAILURE, errors }),
            Observable.of({ type: SHOW_ERROR, errorText })
          )
        })
  })
}

export function editLogicModelEpic(action$, store) {
  return action$.ofType(EDIT_LOGIC_MODEL_NAME)
    .mergeMap((action) => {
      const logicModels = store.getState().logicModels;

      const token = localStorage.getItem('token');
      return Observable.fromPromise(put(`/api/logic_models/${logicModels.logicModelId}`, {name: logicModels.newLogicModelName},  token))
        .flatMap(({logic_model}) => {
          return Observable.concat(
            Observable.of({ type: HIDE_CHANGE_NAME_INPUT}),
            Observable.of({ type: EDIT_LOGIC_MODEL_NAME_SUCCESS, payload: {logic_model}}),
          )
        })
        .catch( errors => Observable.of({ type: EDIT_LOGIC_MODEL_NAME_FAILURE, errors }))
    })
}

export function createLogicModalEpic(action$, store) {
	return action$.ofType(ADD_LOGIC_MODEL)
		.mergeMap((action) => {
			const token = localStorage.getItem('token');
			const {name, sroi, logicModelOptions } = store.getState().logicModels;
			const country = logicModelOptions.country;
			const city = logicModelOptions.city;
			const province = logicModelOptions.province;

			return Observable.fromPromise(post('/api/logic_models', {
			  name: name,
        country: country,
        has_sroi: sroi,
        province: province,
        city: city
      }, token))
				.flatMap(({data}) => {
					const id = data.logic_model.id;
					return Observable.concat(
						Observable.of({type: ADD_LOGIC_MODEL_SUCCESS, id}),
						Observable.of(push(`/logic-models/${id}/edit`))
					);
				})
				.catch((errors) => {
					return Observable.of({type: ADD_LOGIC_MODEL_FAILURE, errors})
				});
		})
}

export function createNewFramework(action$) {
  return action$.ofType(CREATE_FRAMEWORK)
    .mergeMap((action) => {
      const token = localStorage.getItem("token");
      return Observable.fromPromise(post('/api/frameworks', {
        name: action.name
      }, token))
      .flatMap((res)=>{
        return Observable.concat(
          Observable.of({ type: CREATE_FRAMEWORK_SUCCESS }),
          Observable.of({ type: FETCH_FRAMEWORKS })
        );
      })
      .catch((errors) => {
        return Observable.of({ type: CREATE_FRAMEWORK_FAILURE, errors });
      })
    })
}

export function createTheme(action$) {
  return action$.ofType(CREATE_THEME)
    .mergeMap((action) => {
      const token = localStorage.getItem("token");
      return Observable.fromPromise(
        post(
          "/api/themes",
          {
            name: action.name,
            description: action.description,
            framework_id: action.frameworkId,
          },
          token
        )
      )
        .flatMap(() => {
          return Observable.concat(
            Observable.of({ type: CREATE_THEME_SUCCESS }),
            Observable.of({ type: FETCH_THEMES })
          );
        })
        .catch((errors) => {
          return Observable.of({ type: CREATE_THEME_FAILURE, errors });
        });
    })
}

export function fetchWorkspaceResourcesEpic(action$, store ) {
  return action$.ofType(FETCH_WORKSPACE_RESOURCES)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      return Observable.fromPromise(post("/api/datasources/uniform", { token: token }, token))
        .flatMap(({payload}) => {
          return Observable.concat(
            Observable.of({ type: FETCH_WORKSPACE_RESOURCES_SUCCESS, payload})
          )
        })
        .catch(errors => {

          return Observable.concat(
            errors.global?
              Observable.of({type: UNAUTHORIZED_REQUEST })
              :
              Observable.of({type: FETCH_WORKSPACE_RESOURCES_FAILURE, errors})
          )
        })
  })
}

export function fetchDataResourcesEpic(action$, store) {
  return action$.ofType(FETCH_DATA_RESOURCES).mergeMap(action => {
    const token = localStorage.getItem("token");

    if (action.formId){
      const payload = {
        type: "Survey",
        form_id: action.formId,
        upload_id: null,
        token: token,
        page_number: 1,
        size_per_page: 5
      };

      return Observable.fromPromise(
        post("/api/datasources/resources", payload, token)
      )
        .flatMap(({ payload }) => {
          const successActionType = action.selectedFormType === SOURCE
          ? FETCH_SOURCE_DATA_RESOURCES_SUCCESS 
          : FETCH_TARGET_DATA_RESOURCES_SUCCESS;

          return Observable.concat(
            Observable.of({ type: successActionType, payload }),
          );
        })
        .catch(errors => {
          return Observable.of({ type: FETCH_DATA_RESOURCES_FAILURE, errors });
        });
    }
  });
}

export function submitLookupQuestionEpic(action$, store){
  return action$.ofType(SUBMIT_LOOKUP_QUESTION).mergeMap(action => {
    const token = localStorage.getItem("token");
    const {sourceFormData} = store.getState().logicModels;
    const {logicModel} = store.getState().measurePage;
    const pagesArray = Object.values(sourceFormData.pages);
    const lastPage = pagesArray[pagesArray.length - 1];
    const lastSection = lastPage.sections[lastPage.sections.length - 1];

    var payload = createLookupQuestionPayload(lastPage.id, lastSection.id, sourceFormData.id, action.newQuestionName, action.lookupQuestionId, logicModel.id, action.questionMapping, action.calculationType, action.lookupCalculationQuestion);
    payload.token = token;
    return Observable.fromPromise(
      post("/api/lookup_question", payload, token)
    )
      .flatMap(({ payload }) => {
        return Observable.concat(
          Observable.of({ type: SUBMIT_LOOKUP_QUESTION_SUCCESS }),
        );
      })
      .catch(errors => {
        return Observable.of({ type: SUBMIT_LOOKUP_QUESTION_FAILURE, errors });
      });
  });
}

function createLookupQuestionPayload(pageId, sectionId, formId, questionTitle, lookupQuestionId, logicModelId, questionMapping, calculationType, lookupCalculationQuestion){
  var lookupMapping = {questionMappings: questionMapping, calculation: {}};
  if (calculationType && lookupCalculationQuestion){
    lookupMapping.calculation.operation = calculationType;
    lookupMapping.calculation.questionForCalculation = lookupCalculationQuestion;
  }
  return  {
    attributes: {
      is_web_question: true,
      lookup_question_id: lookupQuestionId,
      logic_model_id: logicModelId,
      lookup_mapping: JSON.stringify(lookupMapping)
    },
    page_id: pageId,
    section_id: sectionId,
    form_id: formId,
    title_en: questionTitle,
    title_fr: "",
    description_en: "",
    description_fr: "",
    is_optional: false,
    question_type_id: 3,
    question_type: {
      id: 3,
      title_en: "Text",
      title_fr: "Texte",
      type: "text",
      created_at: null,
      updated_at: null
    },
    has_date_range: false,
    has_number_range: false,
    has_custom_regex: false,
    custom_validations: [],
    validation_id: 1,
    validation: {
      id: 1,
      title_en: "Number",
      title_fr: "Nombre",
      type: "number",
      created_at: null,
      updated_at: null
    },
    question_translations: []
  };
}
export default [
  fetchLogicModelsEpic,
  deleteLogicModelEpic,
  editLogicModelEpic,
  createLogicModalEpic,
  fetchExportLogic,
  fetchFrameworks,
  fetchThemes,
  createNewFramework,
  createTheme,
  fetchWorkspaceResourcesEpic,
  fetchDataResourcesEpic,
  submitLookupQuestionEpic
];
