export const SAVE_DATA_SOURCE = '@@DATA_SOURCE/SAVE_DATA_SOURCE';
export const SAVE_DATA_SOURCE_SUCCESS = '@@DATA_SOURCE/SAVE_DATA_SOURCE_SUCCESS';
export const SAVE_DATA_SOURCE_FAILURE = '@@DATA_SOURCE/SAVE_DATA_SOURCE_FAILURE';

export const CHANGE_CURRENT_SOURCE_NAME = '@@DATA_SOURCE/CHANGE_CURRENT_SOURCE_NAME';
export const CHANGE_CURRENT_WORKSPACE_ID = '@@DATA_SOURCE/CHANGE_CURRENT_WORKSPACE_ID';
export const CHANGE_CURRENT_FORM_ID = '@@DATA_SOURCE/CHANGE_CURRENT_FORM_ID';
export const CHANGE_CURRENT_UPLOAD_ID = '@@DATA_SOURCE/CHANGE_CURRENT_UPLOAD_ID';
export const CHANGE_CURRENT_QUESTION_ID = '@@DATA_SOURCE/CHANGE_CURRENT_QUESTION_ID';
export const CHANGE_CURRENT_COLUMN_ID = '@@DATA_SOURCE/CHANGE_CURRENT_COLUMN_ID';
export const CHANGE_CURRENT_OPTION_ID = '@@DATA_SOURCE/CHANGE_CURRENT_OPTION_ID';

export const UPDATE_CURRENT_DATA_SOURCE = '@@DATA_SOURCE/UPDATE_CURRENT_DATA_SOURCE';
export const SET_DATA_SOURCE_INITIAL_STATE = '@@DATA_SOURCE/SET_DATA_SOURCE_INITIAL_STATE';
export const SAVE_CALCULATIONS_DATA_SOURCE = '@@DATA_SOURCE/SAVE_CALCULATIONS_DATA_SOURCE';
export const SAVE_CALCULATIONS_DATA_SOURCE_SUCCESS = '@@DATA_SOURCE/SAVE_CALCULATIONS_DATA_SOURCE_SUCCESS';

export const SHOW_CALCULATION_SOURCE_FOR_VARIABLE = '@@DATA_SOURCE/SHOW_CALCULATION_SOURCE_FOR_VARIABLE';
export const DATA_SOURCE_SHOULD_UPDATE = '@@DATA_SOURCE/DATA_SOURCE_SHOULD_UPDATE';
export const CLEAR_SOURCE_DATA = '@@DATA_SOURCE/CLEAR_SOURCE_DATA';

export const GET_DATA_SOURCE_RESOURCES = '@@DATA_SOURCE/GET_DATA_SOURCE_RESOURCES';
export const GET_DATA_SOURCE_RESOURCES_SUCCESS = '@@DATA_SOURCE/GET_DATA_SOURCE_RESOURCES_SUCCESS';
export const CHANGE_DATA_FOR_PAGE = '@@DATA_SOURCE/CHANGE_DATA_FOR_PAGE';
export const CHANGE_HEADER_ID_FOR_TRANSFORMATION = '@@DATA_SOURCE/CHANGE_HEADER_ID_FOR_TRANSFORMATION';
export const CHANGE_CURRENT_TRANSFORMATION = '@@DATA_SOURCE/CHANGE_CURRENT_TRANSFORMATION';


export const UPDATE_CURRENT_DATA_SOURCE_SUCCESS = '@@DATA_SOURCE/UPDATE_CURRENT_DATA_SOURCE_SUCCESS';
export const SHOW_DATASOURCES = '@@DATA_SOURCE/SHOW_DATASOURCES';


export const SAVE_HEADER_TRANSFORMATION = '@@DATA_SOURCE/SAVE_HEADER_TRANSFORMATION';
export const MARK_AS_RECHANGED_HEADER = '@@DATA_SOURCE/MARK_AS_RECHANGED_HEADER';

export const GET_TRANSFORMATION_VALUES = '@@DATA_SOURCE/GET_TRANSFORMATION_VALUES';
export const GET_TRANSFORMATION_VALUES_SUCCESS = '@@DATA_SOURCE/GET_TRANSFORMATION_VALUES_SUCCESS';
export const GET_TRANSFORMATION_VALUES_FAILURE = '@@DATA_SOURCE/GET_TRANSFORMATION_VALUES_FAILURE';

export const EDITED_VARIABLE_SAVE = '@@DATA_SOURCE/EDITED_VARIABLE_SAVE';
export const SET_SHOULD_GET_TRANSFORMATION = '@@DATA_SOURCE/SET_SHOULD_GET_TRANSFORMATION';
export const SET_SHOULD_GET_DATASOURCES = '@@DATA_SOURCE/SET_SHOULD_GET_DATASOURCES';



function changeCurrentFormId(formId) {
  return { type: CHANGE_CURRENT_FORM_ID, formId }
}

function saveDataSource() {
  return { type: SAVE_DATA_SOURCE }
}

function changeCurrentQuestionId(questionId) {
  return { type: CHANGE_CURRENT_QUESTION_ID, questionId }
}

function changeCurrentOptionId(optionId) {
  return { type: CHANGE_CURRENT_OPTION_ID, optionId }
}

function changeCurrentColumnId(columnId) {
  return { type: CHANGE_CURRENT_COLUMN_ID, columnId }
}

function changeCurrentUploadId(uploadId) {
  return { type: CHANGE_CURRENT_UPLOAD_ID, uploadId }
}

function changeCurrentSourceName(name) {
  return { type: CHANGE_CURRENT_SOURCE_NAME, name }
}

function changeCurrentWorkspaceId(workspaceId) {
  return { type: CHANGE_CURRENT_WORKSPACE_ID, workspaceId }
}

function updateDataSource() {
  return { type: UPDATE_CURRENT_DATA_SOURCE }
}

function dataSourceInitialState() {
  return { type: SET_DATA_SOURCE_INITIAL_STATE }
}

function saveCalculation(payload) {
  return { type: SAVE_CALCULATIONS_DATA_SOURCE, payload }
}

function changeCalculationForVariableId(variableId) {
  return { type: SHOW_CALCULATION_SOURCE_FOR_VARIABLE, variableId }
}

function setDataSourceShouldUpdate(status) {
  return { type: DATA_SOURCE_SHOULD_UPDATE, status }
}

function clearSourceDataExcept(sourceItem) {
  return { type: CLEAR_SOURCE_DATA, sourceItem}
}

function getDatasourceResources() {
  return { type: GET_DATA_SOURCE_RESOURCES }
}

function setHeaderId(headerId) {
  return { type: CHANGE_HEADER_ID_FOR_TRANSFORMATION, headerId }
}

function changeCurrentTransformation(transformation) {
  return { type: CHANGE_CURRENT_TRANSFORMATION, transformation }
}

function showDataSources(status, variableId) {
  return { type: SHOW_DATASOURCES, status, variableId }
}

function markHeaderAsReChanged(status) {
  return { type: MARK_AS_RECHANGED_HEADER, status }
}

function getTransformationValues() {
  return { type: GET_TRANSFORMATION_VALUES }
}

function setShouldGetTranformation(status) {
  return { type: SET_SHOULD_GET_TRANSFORMATION, status };
}

function setShouldGetDatasources(status) {
  return { type: SET_SHOULD_GET_DATASOURCES, status };
}

function editedVariableSave() {
  return { type: EDITED_VARIABLE_SAVE }
}


export default {
  changeCurrentQuestionId,
  changeCurrentUploadId,
  changeCurrentColumnId,
  changeCurrentOptionId,
  changeCurrentFormId,
  changeCurrentSourceName,
  changeCurrentWorkspaceId,
  dataSourceInitialState,
  clearSourceDataExcept,
  setDataSourceShouldUpdate,

  saveDataSource,
  updateDataSource,
  saveCalculation,

  changeCalculationForVariableId,

  getDatasourceResources,
  setHeaderId,
  changeCurrentTransformation,
  showDataSources,
  markHeaderAsReChanged,
  getTransformationValues,
  editedVariableSave,
  setShouldGetTranformation,
  setShouldGetDatasources
}
