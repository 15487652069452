import merge from 'xtend';
import createReducer from '../create-reducer';
import {
  SHOW_DEMOGRAPHIC,
  HIDE_DEMOGRAPHIC, SHOW_DEMOGRAPHICS_MEASURE_NAME, HIDE_DEMOGRAPHICS_MEASURE_NAME
} from "../../actions/stakeholder-items/demographics";

const INITIAL_STATE = {
  measureIsLoading: false,
  errors: {},
  demographicsMeasureNameFieldDisabled: false,
  showDemographic: false
};

export default createReducer({
  [SHOW_DEMOGRAPHIC]: (state, action) => merge(state, {showDemographic: true}),
  [HIDE_DEMOGRAPHIC]: (state, action) => merge(state, {showDemographic: false}),
  [SHOW_DEMOGRAPHICS_MEASURE_NAME]: (state, action) => merge(state, { demographicsMeasureNameFieldDisabled: true }),
  [HIDE_DEMOGRAPHICS_MEASURE_NAME]: (state, action) => merge(state, { demographicsMeasureNameFieldDisabled: false }),
}, INITIAL_STATE)