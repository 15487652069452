import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import manageMeasureActions from "../../../../actions/measure/manage-measure";
import measureActions from "../../../../actions/measure/measure";
import activityActions from "../../../../actions/stakeholder-items/activities";
import Activities from "../../../../components/logic-models/stakeholder-items/add-activity/Activities";


function mapStateToProps(state, ownProps) {
  return {
    ...state.measurePage,
    ...state.dataSource,
    ...state.activities,
    activities: ownProps.activities,
    stakeholder: ownProps.stakeholder
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...manageMeasureActions,
      ...measureActions,
      ...activityActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Activities)
