import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { syncHistoryWithStore } from 'react-router-redux';
import { browserHistory } from 'react-router';
import configureStore from './store';
import { getAtlasBaseUrl, setupLocale } from './actions/application';
import Root from './components/Root';
import { addLocaleData } from 'react-intl';
import * as frLocaleData from 'react-intl/locale-data/fr';
import LoginActions from './actions/login';
import Raven from 'raven-js';
import fetchIntercept from 'fetch-intercept';

if (process.env.NODE_ENV === 'production')
  Raven.config('https://efcc6775d2c648f4ba308e8971e93e55@sentry.io/1246440').install();

addLocaleData([
  ...frLocaleData
]);

const store = configureStore();
const history = syncHistoryWithStore(browserHistory, store);

const unregister = fetchIntercept.register({
  response: function(response) {
    if(response.status === 401) {
      store.dispatch(LoginActions.unauthorizedRequest());
    }
    return response;
  }
});

const token = localStorage.getItem('token');
store.dispatch(setupLocale());

store.dispatch(getAtlasBaseUrl());

if (token) {
  store.dispatch(LoginActions.loadAuth())
}

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <Component store={store} history={history} />
    </AppContainer>,
    document.getElementById('root')
  )
};

render(Root);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    render(Root)
  })
}