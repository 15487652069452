import React from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import SourceAnswersDataTable from "./SourceAnswersDataTable";
import EmptyDataTable from "./EmptyDataTable";
import { InjectIntl } from "../../../common/InjectIntl";
import classNames from "classnames";

const SourceModalIndexTable = props => {
  const {
    answers,
    headers,
    data,
    sizePerPage,
    currentPage,
    searchTxt,
    totalDataSize,
    resourcesIsLoading,
    currentDataSource,
    actions,
    intl
  } = props;

  const onPageChange = (page, sizePerPage) => {
    if (currentDataSource.sourceName === "Upload") {
      actions.changeDataForPage(
        page,
        sizePerPage,
        currentDataSource.uploadId,
        searchTxt
      );
      actions.getDatasourceResources();
    }
  };

  const onSizePerPageList = sizePerPage => {
    if (currentDataSource.sourceName === "Upload") {
      actions.changeDataForPage(
        currentPage,
        sizePerPage,
        currentDataSource.uploadId,
        searchTxt
      );
      actions.getDatasourceResources();
    }
  };

  const onSearchChange = searchText => {
    const text = searchText.trim();
    if (currentDataSource.sourceName === "Upload") {
      if (text === "") {
        actions.changeDataForPage(1, sizePerPage, currentDataSource.uploadId);
        actions.resetSearchTxt();
      }
      actions.search(text, currentDataSource.uploadId, sizePerPage);
    }
  };

  const hasContent = !(data.headers || data.pages);
  const tableClassName = classNames({
    wrap: true,
    "content-disabled": hasContent
  });

  return (
    <div className={tableClassName}>
      {headers.length > 0 && !resourcesIsLoading ? (
        <SourceAnswersDataTable
          onPageChange={onPageChange.bind(this)}
          onSizePerPageList={onSizePerPageList.bind(this)}
          sizePerPage={sizePerPage}
          currentPage={currentPage}
          data={answers}
          totalDataSize={totalDataSize}
          headers={headers}
          searchTxt={searchTxt}
          onSearchChange={debounce(onSearchChange.bind(this), 1000)}
          remote={currentDataSource.sourceName === "Upload"}
        />
      ) : (
        <EmptyDataTable intl={intl} isLoading={resourcesIsLoading} />
      )}
      <div>{props.children}</div>
    </div>
  );
};

SourceModalIndexTable.propTypes = {
  currentDataSource: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  searchTxt: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  resourcesIsLoading: PropTypes.bool.isRequired,
  totalDataSize: PropTypes.number,
  actions: PropTypes.shape({
    changeDataForPage: PropTypes.func,
    getDatasourceResources: PropTypes.func,
    resetSearchTxt: PropTypes.func,
    search: PropTypes.func
  })
};

export default InjectIntl(SourceModalIndexTable);
