import merge from 'xtend';
import createReducer from '../create-reducer';
import {
  ADD_FINANCIAL_PROXY_MEASURE,
  ADD_FINANCIAL_PROXY_MEASURE_SUCCESS,
  ADD_FINANCIAL_PROXY_MEASURE_FAILURE,
  SHOW_FINANCIAL_PROXY_FIELD,
  HIDE_FINANCIAL_PROXY_FIELD, SHOW_ADJUSTMENTS, HIDE_ADJUSTMENTS
} from "../../actions/stakeholder-items/financialProxy";
import { SAVE_MEASURE_NAME_SUCCESS } from "../../actions/measure/measure";


const INITIAL_STATE = {
  measureIsLoading: false,
  errors: {},
  financialProxyMeasureNameFieldDisable: false,
  showAdjustments: false,

};

export default createReducer({
  [ADD_FINANCIAL_PROXY_MEASURE]: (state, action) => merge(state, {measureIsLoading: true }),
  [ADD_FINANCIAL_PROXY_MEASURE_SUCCESS]: (state, action) => merge(state, {measureIsLoading: false }),
  [ADD_FINANCIAL_PROXY_MEASURE_FAILURE]: (state, action) => merge(state, {
    errors: action.payload.errors,
    measureIsLoading: false
  }),
  [SHOW_FINANCIAL_PROXY_FIELD]: (state, actions) => merge(state, {financialProxyMeasureNameFieldDisable: true }),
  [HIDE_FINANCIAL_PROXY_FIELD]: (state, action) => merge(state, {financialProxyMeasureNameFieldDisable: false }),
  [SAVE_MEASURE_NAME_SUCCESS]: (state, action) => merge(state, {financialProxyMeasureNameFieldDisable: false}),
  [SHOW_ADJUSTMENTS]: (state, action) => merge(state, {showAdjustments: true}),
  [HIDE_ADJUSTMENTS]: (state, action) => merge(state, {showAdjustments: false}),
}, INITIAL_STATE)