import { forEach, has, head } from 'lodash';
import swal from 'sweetalert2'
import {
  NO_RESPONSES,
  UNPUBLISHED,
  DELETED,
  DELETED_COLUMN,
  BROKEN,
  COMPLETE,
  INCOMPLETE,
  PREEXISTING_DATASOURCE_TYPE_ID,
  CHANGED_DATA_TYPE,
  DELETED_CONDITIONS
} from "../components/calculations/sources/source-table-layout/constants";
let timeOut = null;

window.samUtils = {

    spinner: function(text, title) {

        if(!title) {
            title = '<i class="fa fa-gear fa-spin" style="font-size: 95px;"></i>';
        }

        if(!text) {

            text = 'Working. Hangon...';
        }

        swal({
            title: title,
            text: text,
            // html: true,
            showConfirmButton: false
        });
    },

    successFlash: function(text, title) {
        var text = text || 'Action completed successfully';
        var title = title || 'Done!';
        swal({
            title: title,
            text: text,
            type: 'success',
            showConfirmButton: false,
            timer: 1500
        });
    },

    ontologyRequest: function(func) {
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {
        func();
        clearTimeout(timeOut);
      }, 1000)
    }
}

window.inputs = [
    {
        name:'Funds',
        parent: ''
    },
    {
        name:'Contributions',
        parent: 'Funds'
    },
    {
        name:'Donations',
        parent: 'Funds'
    },
    {
        name:'Grant',
        parent: 'Funds'
    },
    {
        name:'Investment',
        parent: 'Funds'
    },
    {
        name:'Debt',
        parent: 'Investment'
    },
    {
        name:'Convertible debt',
        parent: 'Debt'
    },
    {
        name:'LOC',
        parent: 'Debt'
    },
    {
        name:'Term loan',
        parent: 'Debt'
    },
    {
        name:'Equity',
        parent: 'Investment'
    },
    {
        name:'In-kind input',
        parent: ''
    },
    {
        name:'Expertise',
        parent: 'In-kind input'
    },
    {
        name:'In-kind equipment contributions',
        parent: 'In-kind input'
    },
    {
        name:'In-kind space contributions',
        parent: 'In-kind input'
    }
];

// browser detect
var BrowserDetect = {
  init: function() {
    this.browser = this.searchString(this.dataBrowser) || "An unknown browser";
    this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "an unknown version";
    this.OS = this.searchString(this.dataOS) || "an unknown OS";
  },
  searchString: function(data) {
    for (var i = 0; i < data.length; i++) {
      var dataString = data[i].string;
      var dataProp = data[i].prop;
      this.versionSearchString = data[i].versionSearch || data[i].identity;
      if (dataString) {
        if (dataString.indexOf(data[i].subString) != -1) return data[i].identity;
      } else if (dataProp) return data[i].identity;
    }
  },
  searchVersion: function(dataString) {
    var index = dataString.indexOf(this.versionSearchString);
    if (index == -1) return;
    return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
  },
  dataBrowser: [{
    string: navigator.userAgent,
    subString: "Chrome",
    identity: "Chrome"
  }, {
    string: navigator.userAgent,
    subString: "OmniWeb",
    versionSearch: "OmniWeb/",
    identity: "OmniWeb"
  }, {
    string: navigator.vendor,
    subString: "Apple",
    identity: "Safari",
    versionSearch: "Version"
  }, {
    prop: window.opera,
    identity: "Opera",
    versionSearch: "Version"
  }, {
    string: navigator.vendor,
    subString: "iCab",
    identity: "iCab"
  }, {
    string: navigator.vendor,
    subString: "KDE",
    identity: "Konqueror"
  }, {
    string: navigator.userAgent,
    subString: "Firefox",
    identity: "Firefox"
  }, {
    string: navigator.vendor,
    subString: "Camino",
    identity: "Camino"
  }, { // for newer Netscapes (6+)
    string: navigator.userAgent,
    subString: "Netscape",
    identity: "Netscape"
  }, {
    string: navigator.userAgent,
    subString: "MSIE",
    identity: "Explorer",
    versionSearch: "MSIE"
  }, {
    string: navigator.userAgent,
    subString: "Gecko",
    identity: "Mozilla",
    versionSearch: "rv"
  }, { // for older Netscapes (4-)
    string: navigator.userAgent,
    subString: "Mozilla",
    identity: "Netscape",
    versionSearch: "Mozilla"
  }],
  dataOS: [{
    string: navigator.platform,
    subString: "Win",
    identity: "Windows"
  }, {
    string: navigator.platform,
    subString: "Mac",
    identity: "Mac"
  }, {
    string: navigator.userAgent,
    subString: "iPhone",
    identity: "iPhone/iPod"
  }, {
    string: navigator.platform,
    subString: "Linux",
    identity: "Linux"
  }]

};
BrowserDetect.init();

///// mobile
var isMobile = {
  Android: function() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function() {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};

export default BrowserDetect;


function toDateTime(secs) {
  let t = new Date(1970, 0, 1);
  t.setSeconds(secs);
  return t;
}

const DATE_FORMAT = ["AVG DATE OF RESPONSE"];

export const parseValue = (transformation) => {
  const transformationName = transformation.label ? transformation.label : transformation.transformation;
  const transformationValue = transformation.optionValue || transformation.optionValue === 0  ? transformation.optionValue : transformation.value;

  if (transformationValue === 0 || transformationValue === "0.000000") {
    return 0;
  }
  if (DATE_FORMAT.includes(transformationName)) {
    try {
      parseInt(transformationValue);
      const time = toDateTime(transformationValue);
      return time.toDateString().split(' ').slice(1).join(' ');
    }
    catch {
      return transformationValue
    }
  }
  return parseFloat(transformationValue).toFixed(2);
};

export const parseValueForVariable = (transformation) => {
  const transformationName = transformation.label ? transformation.label : transformation.transformation;
  const transformationValue = transformation.optionValue;

  if (transformationValue === 0) {
    return transformationValue;
  }

  if (DATE_FORMAT.includes(transformationName)) {
    try {
      parseInt(transformationValue);
      const time = toDateTime(transformationValue);
      return time.toDateString().split(' ').slice(1).join(' ');
    }
    catch {
      return transformationValue
    }
  }
  return parseFloat(transformationValue).toFixed(2);
};

export const capitalize = (s) => {
  return s[0].toUpperCase() + s.slice(1);
};

export const getFormTitle = (row, intlLocale) => {
  if (row === null)
    return null;
  else if (row[`title_${intlLocale}`] && row[`title_${intlLocale}`].length > 0)
    return row[`title_${intlLocale}`];
  else if (row[`name_${intlLocale}`] && row[`name_${intlLocale}`].length > 0)
    return row[`name_${intlLocale}`];
  else
    return intlLocale === 'en' ? 'No title': 'Pas de tire'
};

export const getPrimaryStatus = variable => {
  let status;

  if (variable.datasource_type_id === PREEXISTING_DATASOURCE_TYPE_ID) {
    status = checkPreexistingVariableStatus(variable);
  } else if(!!variable.use_array_calculation) {
    status = isArrayCalculationReady(variable);
  } else {
    status = checkVariableStatus(variable);
  }

  return status;
};

const checkPreexistingVariableStatus = parentVariable => {
  let status = COMPLETE;
  let hasBrokenVariable = false;
  let hasEmptyVariable = false;
  let innerVariables = parentVariable.preexisting_variables || [];

  innerVariables.forEach(variable => {
    if (checkIsBroken(variable)) hasBrokenVariable = true;
    else if (checkHasNoResponses(variable)) hasEmptyVariable = true;
    else if (checkIsIncomplete(variable)) status = INCOMPLETE;
  });

  if (hasBrokenVariable) status = BROKEN;
  else if (hasEmptyVariable) status = NO_RESPONSES;
  return status;
};

const checkVariableStatus = variable => {
  // if(has(variable, 'array_calculation.array_calculation_variables')) {
  //   if(variable.array_calculation.array_calculation_variables.length > 0) {
  //     const arrayCalculationVariable = head(variable.array_calculation.array_calculation_variables);
  //     if(arrayCalculationVariable) {
  //       if(!!arrayCalculationVariable.datasource_object) {
  //         return COMPLETE;
  //       }
  //     }
  //   }
  // }
  if (checkIsBroken(variable)) {
    return BROKEN;
  } else if (checkHasNoResponses(variable)) {
    return NO_RESPONSES;
  } else if (checkIsIncomplete(variable)) {
    return INCOMPLETE;
  } else if (checkIsUnpublished(variable)) {
    return UNPUBLISHED;
  } else {
    return COMPLETE;
  }
};

export const isSourceDeleted = variable =>
  variable.source_statuses &&
  (variable.source_statuses.includes(DELETED) ||
    variable.source_statuses.includes(DELETED_COLUMN));

export const checkIsBroken = variable =>
  variable.source_statuses &&
  (variable.source_statuses.includes(DELETED) ||
    variable.source_statuses.includes(DELETED_COLUMN) ||
    variable.source_statuses.includes(CHANGED_DATA_TYPE));

export const checkHasNoResponses = variable =>
  variable.source_statuses &&
  (variable.source_statuses.includes(NO_RESPONSES) ||
    variable.source_statuses.includes(DELETED_CONDITIONS));

export const checkIsIncomplete = (variable) => {
  if(variable.use_array_calculation) {
    return isArrayCalculationReady(variable) !== COMPLETE;
  }
  return !variable.dataset;
}

export const checkDeletedConditions = variable =>
  variable.source_statuses &&
  variable.source_statuses.includes(DELETED_CONDITIONS);

export const checkChangedDataType = variable =>
  variable.source_statuses &&
  variable.source_statuses.includes(CHANGED_DATA_TYPE);

export const checkIsUnpublished = variable =>
  variable.source_statuses && variable.source_statuses.includes(UNPUBLISHED);

export const nextChar = c=>c?String.fromCharCode(c.charCodeAt(0)+1):'a';
  
export const nextCol = s=>s.replace(/([^z]?)(z*)$/, (_,a,z)=>nextChar(a) + z.replace(/z/g,'a'));

export const variableLetters = (limit=702) => {
  const lettersArr = [];

  for(var i=0, s=''; i<702; i++){
    s = nextCol(s);
    lettersArr.push(s);
  }

  return lettersArr;
}

export const isArrayCalculationReady = (activeVariable) => {
  if(activeVariable.use_array_calculation) {
    if(has(activeVariable, 'array_calculation.array_calculation_variables')) {
      const { array_calculation_variables } = activeVariable.array_calculation;
      if(!array_calculation_variables) return INCOMPLETE;
      if(array_calculation_variables.length > 0) {
        // for array calculation to be considered ready
        // all its variables should have datasources
        array_calculation_variables.forEach(variable => {
          if(!has(variable, 'datasource_object')) {
            return INCOMPLETE;
          }
        });
      }
      if(has(activeVariable, 'array_calculation.string')) {
        const { latex } = activeVariable.array_calculation;
        if(!latex) {
          return INCOMPLETE;
        }
      }

      return COMPLETE;
    }
  }
  return INCOMPLETE;
}