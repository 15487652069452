import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ModalCopyPaste from "../components/common/ModalCopyPaste";
import modalCopyPasteActions from "../actions/measure/manage-measure";
import demographicsActions from "../actions/stakeholder-items/demographics";

function mapStateToProps(state, ownProps) {
  const { measure_id } = state.manageMeasure;
  let measureName = undefined;
  if (measure_id) {
    const { logicModel } = state.measurePage;
    const measures = logicModel.stakeholders.reduce(
      (prev, next) => prev.concat(next.measures),
      []
    );
    measureName = measures.find((x) => x.id === measure_id);
  }
  return {
    ...ownProps,
    ...state.manageMeasure,
    ...state.measurePage,
    errorsMeasures: state.manageMeasure.errors,
    measureName,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalCopyPasteActions,
        ...demographicsActions,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCopyPaste);
