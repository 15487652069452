import React from "react";
import PropTypes from "prop-types";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import ReactTooltip from "react-tooltip";
import ActionDropDown from "../common/ActionsDropDown";
import { InjectIntl } from "../common/InjectIntl";
import { PERMISSIONS, READ, EDIT } from "./constants";
import SelectBox from "../common/SelectBox";

const MembersTable = ({
  members,
  intl,
  logicModel,
  onPermissionChange,
  onSavePermission,
  onDeleteMember,
  forInvitations,
  currentCollaborator
}) => {
  const getDisableAndTooltipText = member => {
    const isOwner = logicModel.user_id === member.id;
    const isSameUser = localStorage.getItem("user_id") === String(member.id);
    const viewOnlyPermission = currentCollaborator.permission === READ;
    const isDisabled =
      isOwner || isSameUser || forInvitations || viewOnlyPermission;

    let tooltipText = "";
    if (isSameUser) {
      tooltipText = intl("members_table.cannot_delete_yourself");
    } else if (isOwner) {
      tooltipText = intl("members_table.cannot_delete_owner");
    } else if (viewOnlyPermission) {
      tooltipText = intl("members_table.cannot_delete_permission_denied");
    }

    return [isDisabled, tooltipText];
  };
  
  const enablePermissionEdit = rowId => {
    if (rowId){
      if (document.getElementById(`permission-select-${rowId}`)) document.getElementById(`permission-select-${rowId}`).className='';
      if (document.getElementById(`save-action-panel-${rowId}`)) document.getElementById(`save-action-panel-${rowId}`).className='';

      if (document.getElementById(`permission-label-${rowId}`)) document.getElementById(`permission-label-${rowId}`).className='hide';
      if (document.getElementById(`base-action-panel-${rowId}`)) document.getElementById(`base-action-panel-${rowId}`).className='hide';
    }
  }

  const disablePermissionEdit = rowId => {
    if (rowId){
      if (document.getElementById(`permission-select-${rowId}`)) document.getElementById(`permission-select-${rowId}`).className='hide';
      if (document.getElementById(`save-action-panel-${rowId}`)) document.getElementById(`save-action-panel-${rowId}`).className='hide';

      if (document.getElementById(`permission-label-${rowId}`)) document.getElementById(`permission-label-${rowId}`).className='';
      if (document.getElementById(`base-action-panel-${rowId}`)) document.getElementById(`base-action-panel-${rowId}`).className='';
    }
  }

  const renderActionMenu = (cell, row) => {
    const [isDisabled, tooltipText] = getDisableAndTooltipText(row);
    return (
      <React.Fragment key={`action-key-${row.id}`}>
      {!isDisabled &&
        <div>
          <div id={`base-action-panel-${row.id}`} className="">
            <button style={{marginRight: '10px'}} onClick={() => enablePermissionEdit(row.id)} disabled={isDisabled}>
              <i className="fa fa-pencil"></i>
            </button>
            <button onClick={() => {if (!isDisabled) onDeleteMember(row.id);}} data-tip={tooltipText} disabled={isDisabled}>
              <i className="fa fa-trash"></i>
            </button>
            <ReactTooltip id="owner-tooltip" type="light" effect="solid" border />
          </div>  
          <div id={`save-action-panel-${row.id}`} className="hide">
            <button style={{marginRight: '10px'}} onClick={() => {onSavePermission(row.id); disablePermissionEdit(row.id);}} disabled={isDisabled}>
              <i className="fa fa-save"></i>
            </button>
          </div> 
        </div> 
      }
      </React.Fragment>
    );
  };


  const renderPermissionContent = (cell, row) => {
    const [isDisabled, tooltipText] = getDisableAndTooltipText(row);

    const permissionOptions = [
      {value: READ , label:intl(`collaborators.permissions.${READ}`)},
      {value: EDIT, label:intl(`collaborators.permissions.${EDIT}`)}
    ];

    return(
      <React.Fragment key={`permission-key-${row.id}`}>
        <div id={`permission-select-${row.id}`} className="hide">
          <SelectBox
            placeholder="Permission Select"
            options={permissionOptions}
            SelectBoxClassNames={"col-md-12"}
            onHeaderChange={(e) => onPermissionChange(row.id, e)}
            selectedOption={row.permission}
            disabled={isDisabled}
          />
        </div>
        <label id={`permission-label-${row.id}`} className="" style={{fontWeight: 'normal'}}>
          {row.permission ? intl(`collaborators.permissions.${row.permission}`) : ""}
        </label>
      </React.Fragment>
    );
  };

  const permissionColumnProps={
    dataFormat: (cell, row) => renderPermissionContent(cell, row),
    columnClassName: "allow-overflow",
    tdStyle: { position: "relative" },
    width: "200",
    dataAlign: "left"
  };

  const actionMenuColumnProps = {
    dataFormat: (cell, row) => renderActionMenu(cell, row),
    columnClassName: "allow-overflow",
    tdStyle: { position: "relative" },
    width: "100",
    dataAlign: "center"
  };

  const prepareMembersData = () => {
    const membersData = members;
    if (!forInvitations) {
      membersData.forEach(member => {
        member.fullName = `${member.last_name}, ${member.first_name}`;
        if (PERMISSIONS.includes(member.permission))
          member.permissionIntl = intl(
            `collaborators.permissions.${member.permission}`
          );
      });
    }
    return membersData;
  };

  const renderPermissionColumn = () =>
    !forInvitations && (
      <React.Fragment>
        <i
          data-tip={intl("collaborators.permissions.tooltip")}
          data-for="permission-tooltip"
          className="permissions-info fa fa-info-circle pull-right"
        />
        <ReactTooltip
          id="permission-tooltip"
          type="light"
          border
          multiline={true}
        />
      </React.Fragment>
    );

  const isDisabled = forInvitations ? "content-disabled" : "";
  var membersData = prepareMembersData();
  const noDataText = forInvitations
    ? intl("invitations_table.no_data")
    : intl("members_table.no_data");

  return (
    <BootstrapTable data={membersData} options={{ noDataText }} hover>
      <TableHeaderColumn dataField="id" isKey hidden>
        Id
      </TableHeaderColumn>
      <TableHeaderColumn dataField="fullName" className={isDisabled}>
        {intl("common.text.name")}
      </TableHeaderColumn>
      <TableHeaderColumn dataField="email">
        {intl("common.text.email")}
      </TableHeaderColumn>
      <TableHeaderColumn
        {...permissionColumnProps}
        width="200"
        className={isDisabled}
      >
        {intl("collaborators.table.permission")}
        {renderPermissionColumn()}
      </TableHeaderColumn>
      <TableHeaderColumn {...actionMenuColumnProps}>
        {intl("collaborators.table.actions")}
      </TableHeaderColumn>
    </BootstrapTable>
  );
};

MembersTable.propTypes = {
  members: PropTypes.array.isRequired,
  logicModel: PropTypes.object.isRequired,
  onPermissionChange: PropTypes.func.isRequired,
  onSavePermission: PropTypes.func.isRequired, 
  onDeleteMember: PropTypes.func.isRequired,
  intl: PropTypes.func.isRequired,
  forInvitations: PropTypes.bool,
  currentCollaborator: PropTypes.object
};

export default InjectIntl(MembersTable);
