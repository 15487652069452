import React from "react";
import PropTypes from "prop-types";
import { InjectIntl } from "../../common/InjectIntl";
import ActionsDropDown from "../../common/ActionsDropDown";
import VariableItemWrapper from "../../../containers/calculations/VariableItemWrapper.container";
import OutsideClickDetection from "../../common/OutsideClickDetection";
import VariableStatus from "./VariableStatus";
import VariableTransformation from "./VariableTransformation";
import InnerVariableItem from "./InnerVariableItem";
import { getPrimaryStatus } from "../../../assets/sam-utils";
import { PREEXISTING_DATASOURCE_TYPE_ID } from "../sources/source-table-layout/constants";
import { EDIT } from "../../members/constants";
import PermissionChecker from "../../common/PermissionChecker";

class VariableItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      variableNameInputShow: false,
      name: "",
      id: ""
    };
  }

  static propTypes = {
    variableItem: PropTypes.object.isRequired,
    activeVariable: PropTypes.object.isRequired,
    currentCalculation: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      setVariableName: PropTypes.func.isRequired,
      editCalculationVariable: PropTypes.func.isRequired,
      showDataSources: PropTypes.func.isRequired,
      showDeleteModalItem: PropTypes.func.isRequired,
      setCurrentPreexistingCalculation: PropTypes.func.isRequired,
      showSelectPreexistingCalculationModal: PropTypes.func.isRequired,
      deleteVariable: PropTypes.func.isRequired,
      setDeleteParams: PropTypes.func.isRequired,
      setError: PropTypes.func.isRequired
    })
  };

  componentDidMount() {
    const { activeVariable, variableItem, actions } = this.props;
    if (activeVariable.id === variableItem.id)
      actions.changeActiveVariable(variableItem);
      if (variableItem && variableItem.name) {
        this.setState({
          name: variableItem.name,
        });
      }
  }

  changeVariableName = e => {
    e.preventDefault();
    const variableName = e.target.value;
    this.setState({ name: variableName });
  };

  clickVariableNameHandler = () =>
    this.setState({
      variableNameInputShow: true,
      id: this.props.variableItem.id
    });

  closeInputField = () => this.setState({ variableNameInputShow: false });

  changeVariable = e => {
    const { variableItem } = this.props;
    const variableName = e.target.value;
    const payload = { name: variableItem.name, variable: variableName };
    this.props.actions.setVariableName(payload, variableItem.id);
  };

  saveChanges = () => {
    const payload = { name: this.state.name };
    this.setState({ id: "" });
    this.props.actions.setVariableName(payload, this.props.variableItem.id);
  };

  removeVariable = () => {
    const { variableItem, intl, actions } = this.props;

    if (variableItem.is_used === 1) {
      actions.setError(intl("calculation.cannot_remove_variable_hint"));
    } else {
      actions.setDeleteParams(
        intl("calculation.remove_variable_hint"),
        actions.deleteVariable,
        variableItem.id
      );
      actions.showDeleteModalItem();
    }
  };

  editVariable = () => {
    const { variableItem, actions } = this.props;

    if (variableItem.datasource_type_id === PREEXISTING_DATASOURCE_TYPE_ID) {
      actions.setCurrentPreexistingCalculation(variableItem.datasource_object);
      actions.showSelectPreexistingCalculationModal();
    } else {
      actions.editCalculationVariable();
    }
  };

  arrowHandlerClick = e => {
    e.preventDefault();
    this.props.actions.showDataSources(
      !this.props.collapsed,
      this.props.variableItem.id
    );
  };

  dropDownActions = () => {
    if (localStorage.getItem("permission") === EDIT){
      return [
        {
          title: "common.text.edit",
          onClick: this.editVariable
        },
        {
          title: "common.text.delete",
          onClick: this.removeVariable
        }
      ];
    } else {
      return [
        {
          title: "common.text.read",
          onClick: this.editVariable
        },
        
      ];
    }
  }

  render() {
    const { variableNameInputShow, id } = this.state;
    const {
      variableItem,
      currentCalculation,
      collapsedDatasources,
      intl
    } = this.props;
    const {
      changeVariable,
      saveChanges,
      clickVariableNameHandler,
      changeVariableName,
      closeInputField,
      arrowHandlerClick
    } = this;

    const freeVariables = variableItem.variable
      ? [variableItem.variable].concat(
          currentCalculation.available_variable_letters
        )
      : currentCalculation.available_variable_letters;

    if (variableItem.datasource_type_id === PREEXISTING_DATASOURCE_TYPE_ID) {
      var isVariableCollapsed =
        collapsedDatasources.indexOf(variableItem.id) > -1;
      var arrowStyle = isVariableCollapsed
        ? "fa fa-chevron-down"
        : "fa fa-chevron-up";
      var innerVariables = variableItem.preexisting_variables || [];
    }
    const status = getPrimaryStatus(variableItem);
    const editableClass =
      variableItem.datasource_type_id !== PREEXISTING_DATASOURCE_TYPE_ID
        ? "editable_name"
        : null;
    return (
      <React.Fragment>
        <VariableItemWrapper
          variableItem={variableItem}
          status={status}
          isParent={true}
        >
          <td className="variable-letter">
            <PermissionChecker>
              <select
                className="form-control success select-variable-letter"
                onChange={changeVariable}
                value={variableItem.variable || ""}
                title={
                  variableItem.is_used ? "variable is used in formula" : null
                }
                disabled={variableItem.is_used}
              >
                {freeVariables.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </PermissionChecker>
          </td>
          <td className="align-middle variable-name-column">
            {variableNameInputShow &&
            id === variableItem.id &&
            variableItem.datasource_type_id !==
              PREEXISTING_DATASOURCE_TYPE_ID ? (
              <OutsideClickDetection hideAction={closeInputField}>
                <div className="input-group">
                  <input
                    type="text"
                    autoFocus="autoFocus"
                    placeholder={intl("calculation.enter_variable_name")}
                    className="form-control success"
                    onChange={changeVariableName}
                    value={this.state.name}
                  />
                  <span className="input-group-btn">
                    <button
                      type="button"
                      title="Save"
                      className="btn btn-success"
                      onClick={saveChanges}
                    >
                      <i className="fa fa-check" />
                    </button>
                  </span>
                </div>
              </OutsideClickDetection>
            ) : (
              <div className="inline-flex">
                {variableItem.datasource_type_id ===
                  PREEXISTING_DATASOURCE_TYPE_ID && (
                  <a
                    href="#!"
                    className="collapse-link"
                    onClick={arrowHandlerClick}
                  >
                    <i className={arrowStyle} />
                  </a>
                )}
                <PermissionChecker>
                  <strong
                    className={`center-block padding-top-7 ${editableClass} ${
                      arrowStyle ? "m-l-6" : "m-l-20"
                    }`}
                    onClick={clickVariableNameHandler}
                  >
                    {variableItem.name}
                  </strong>
                </PermissionChecker>
              </div>
            )}
          </td>
          <VariableStatus status={status} />
          <VariableTransformation status={status} variableItem={variableItem} />
          <td className="allow-overflow position-relative align-middle w-20">
            <PermissionChecker hide={false}>
              <ActionsDropDown
                actions={this.dropDownActions()}
                checkPermissions={true}
              />
            </PermissionChecker>
          </td>
        </VariableItemWrapper>
        {isVariableCollapsed &&
          innerVariables.map((item, index) => (
            <InnerVariableItem
              innerVariableItem={item}
              key={index}
              parent={false}
            />
          ))}
      </React.Fragment>
    );
  }
}

export default InjectIntl(VariableItem);
