import { Observable } from "rxjs/Observable";
import {post} from "../../api";
import 'rxjs';
import { ADD_INPUTS_MEASURE, ADD_INPUTS_MEASURE_SUCCESS, ADD_INPUTS_MEASURE_FAILURE } from "../../actions/stakeholder-items/inputs";
import { FETCH_LOGIC_MODEL } from "../../actions/measure/measure";

export function addInputsMeasureEpic(action$, store) {
  return action$.ofType(ADD_INPUTS_MEASURE)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { logicModelId, stakeholderId } = store.getState().measurePage;
      const INPUT_MEASURE_ID = 2;
      const payload = {name: "Unnamed Input", measure_type_id: INPUT_MEASURE_ID, stakeholder_id: stakeholderId};
      const id = logicModelId;
      return Observable.fromPromise(post(`/api/measures`, payload, token))
        .flatMap(({ data }) => {
          const measure = data.measure;
          const logic_model = data.logic_model;

          return Observable.concat(
            Observable.of({type: ADD_INPUTS_MEASURE_SUCCESS, payload: { logic_model, measure }}),
            Observable.of({type: FETCH_LOGIC_MODEL, id })
          )
        })
        .catch(errors => Observable.of({type: ADD_INPUTS_MEASURE_FAILURE, payload: { errors }}))
    })
}

export default [addInputsMeasureEpic]