import React from 'react';
import PropTypes from 'prop-types';

import { InjectIntl } from "../../../common/InjectIntl";
import MeasureNameComponent from "../MeasureNameComponent";

const DemographicItem = (props) => {
  const { demographic, actions, demographicsMeasureNameFieldDisabled, intl } = props;

  const closeHandler = () => actions.hideMeasureNameDemographicField();

  const saveDemographicsMeasureName = (measureName) => actions.changeDemographicMeasureName(measureName, demographic.id);

  const changeDemographicMeasure = () => actions.showMeasureNameDemographicField();

  return (
    <div className="editable-demographic-item">
      <MeasureNameComponent
        closeHandler={closeHandler}
        saveMeasureName={saveDemographicsMeasureName}
        changeMeasure={changeDemographicMeasure}
        measureNameFieldDisable={demographicsMeasureNameFieldDisabled}
        measureTypeTitle={intl('stakeholder.items.demographics')}
        placeholder={intl('stakeholder.items.demographics.placeholder')}
        measure={demographic}
        {...props}
      />
    </div>
  );
};

DemographicItem.propTypes = {
  demographic: PropTypes.object.isRequired,
  demographicsMeasureNameFieldDisabled: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  ontologySearching: PropTypes.bool.isRequired,
  ontologyResult: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    logicModelLoading: PropTypes.func,
    deleteMeasure: PropTypes.func,
    showMeasureNameDemographicField: PropTypes.func,
    hideMeasureNameDemographicField: PropTypes.func,
    changeDemographicMeasureName: PropTypes.func,
  }.isRequired)
};


export default InjectIntl(DemographicItem);