import React from 'react';
import PropTypes from 'prop-types';
import CreateLogicModelForm from "../containers/logic-models/CreateLogicModelForm.container";
import LogicModels from "../containers/logic-models/LogicModels.container";
import { InjectIntl } from "./common/InjectIntl";
import ErrorNotification from "../containers/ErrorNotification.container";
import ModalDeleteItem from "../containers/ModalDeleteItem.container";


class HomePage extends React.Component {
  static propTypes = {
    confirmDelete: PropTypes.bool,
	  isProcessing: PropTypes.bool.isRequired,
	  name: PropTypes.string.isRequired,
    isLogicModelLoading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      fetchLogicModels: PropTypes.func,
      createLogicModelDialogPageShow: PropTypes.func,
    }.isRequired)
  };

  componentDidMount() {
    this.props.actions.fetchLogicModels();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isProcessing !== this.props.isProcessing) window.samUtils.spinner();
  }

  render() {
    const { createLogicModelDialogPageShow } = this.props.actions;
	  if (this.props.isLogicModelLoading) {
	    return (<div className="sk-spinner sk-spinner-rotating-plane" />)
    }

    return (
      <div className="container">
        <div className="ibox">
          <div className="ibox-content">
            <div className="row">
              <div className="col-md-6 title text-left">
                <h1>{this.props.intl("topMenu.items.logicModels")}</h1>
              </div>
              <div className="col-md-6 text-right">
                <a href="#!" data-toggle="modal" data-target="#createLogicModelModal"
                   className="btn btn-success" onClick={createLogicModelDialogPageShow}>{this.props.intl("logic_models_index_table.create")}</a>
              </div>
            </div>
            <div className="row m-t-sm">
              <div className="col-lg-12">
                <div className="panel blank-panel">
                  <div className="panel-body">
                    <LogicModels handelOpenCreateLogicModelsForm={createLogicModelDialogPageShow}/>
                    <CreateLogicModelForm/>
                    <ModalDeleteItem/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorNotification/>
      </div>
    );
  }
}

export default InjectIntl(HomePage);