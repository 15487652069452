import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import AnswersOnQuestions from "./AnswersOnQuestions";
import LogicModelForm from "./LogicModelForm";
import LogicModelCreate from "./LogicModelCreate";
import { InjectIntl } from "../common/InjectIntl";
import { whiteLabel } from '../utils';

class CreateLogicModelForm extends React.Component {
	static propTypes = {
		errors: PropTypes.object.isRequired,
		name: PropTypes.string.isRequired,
		sroi: PropTypes.bool.isRequired,
		countries: PropTypes.array.isRequired,
		creationFormOpen: PropTypes.bool.isRequired,
		actions: PropTypes.shape({
		  changeLogicModelNameFormCreate: PropTypes.func,
		  createLogicModelDialogPageHide: PropTypes.func,
		  addLogicModel: PropTypes.func,
		  saveLogicModelOptions: PropTypes.func,
		  setError: PropTypes.func,
		}.isRequired)

	};

	constructor(props) {
		super(props);
		this.state = {
			defaultContent: true,
			answerOnQuestions: false,
			createLogicModel: false,
			showFooter: true,
			createForm: false,
			hasError: false,
			errors: props.errors
		}
	}

	handelShowCreateForm = () => {
		this.setState({
			defaultContent: false,
			answerOnQuestions: false,
			createLogicModel: false,
			showFooter: true,
			createForm: true
		});

	};

	renderContent = () => {
		const {defaultContent, answerOnQuestions, createLogicModel, createForm} = this.state;
    	const {categories, actions, name, categoryId, countries, errors} = this.props;
		if (defaultContent) {
			return (
				<div>
					<h2>{this.props.intl('create_logic_model.greeting_title')}</h2>
					<p>{this.props.intl('create_logic_model.greeting_text')}</p>
				</div>
			)
		} else if (answerOnQuestions) {
			return (
				<AnswersOnQuestions/>
			)
		} else if (createLogicModel) {
			return (
				<LogicModelForm
					countries={countries}
					openForm={this.handelShowCreateForm}
					saveLogicModelOptions={actions.saveLogicModelOptions}
					errors={this.state.errors}
				/>
			)
		} else if (createForm) {

			return (
				<LogicModelCreate
					categories={categories}
					actions={actions}
					name={name}
					categoryId={categoryId}
					errors={errors}
				/>
			)
		}
	};

	handleClickMoreQuestions = () => {
		this.setState({defaultContent: false, answerOnQuestions: true, createLogicModel: false});
		this.renderContent()
	};

	handleClickCreate = () => {
		this.setState({defaultContent: false, answerOnQuestions: false, createLogicModel: true, showFooter: false});
	};

	closeDialogue = () => {
		this.props.actions.createLogicModelDialogPageHide();
		this.setState({
			defaultContent: true,
			answerOnQuestions: false,
			createLogicModel: false,
			showFooter: true
		})
	};

	contentButton = (yesOrCancel, confirm, nameYesOrCancel, confirmName) => {
		return (
			<Modal.Footer>
				<button className="btn btn-success" onClick={yesOrCancel}>
					{nameYesOrCancel}
				</button>
				<button className="btn btn-success" onClick={confirm}>
					{confirmName}
				</button>
			</Modal.Footer>
		)
	};

	handleCreateLogicModel = () => {
    if (this.props.name === '') {
    	const emptyNameFieldError = this.props.intl('errors.create_logic_model.name_empty');
      this.props.actions.setError(emptyNameFieldError)
    } else {
			this.props.actions.addLogicModel();
		}
	};

	renderButton = () => {
		const {defaultContent, answerOnQuestions, createForm} = this.state;
		if (defaultContent) {
			return this.contentButton(this.handleClickCreate, this.handleClickMoreQuestions,
				this.props.intl('create_logic_model.yes_button'), this.props.intl('create_logic_model.no_button'))
		} else if (answerOnQuestions) {
			return this.contentButton(this.closeDialogue, this.handleClickCreate,
				this.props.intl('common.text.cancel_button'), this.props.intl('create_logic_model.get_started_button'));
		} else if (createForm) {
			return this.contentButton(this.closeDialogue, this.handleCreateLogicModel,
				this.props.intl('create_logic_model.never_mind_button'), this.props.intl('create_logic_model.create_button'))
		}
	};


	render() {
		const {creationFormOpen, actions} = this.props;
		return (
			<Modal
				show={creationFormOpen}
				id={'foreground_display'}
				data-brand={whiteLabel()}
			>
				<Modal.Header>
					<Modal.Title id='ModalHeader' className={"text-center m-md"}>{this.props.intl('create_logic_model.create_logic_model')}
            <i className="fa fa-close pull-right" onClick={actions.createLogicModelDialogPageHide}/>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="gray-bg">
					{this.renderContent()}
				</Modal.Body>
				{this.state.showFooter && this.renderButton()}
			</Modal>
		)
	}
}

export default InjectIntl(CreateLogicModelForm);