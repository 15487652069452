import { Observable } from "rxjs/Observable";
import {get, post, remove, put} from "../api";
import {
  FETCH_LOGIC_MODEL, FETCH_LOGIC_MODEL_FAILURE, FETCH_LOGIC_MODEL_SUCCESS, DELETE_STAKEHOLDER,
  DELETE_STAKEHOLDER_SUCCESS, DELETE_STAKEHOLDER_FAILURE,
  ADD_STAKEHOLDER, ADD_STAKEHOLDER_SUCCESS, ADD_STAKEHOLDER_FAILURE, CHANGE_STAKEHOLDER_NAME,
  STAKEHOLDER_RECOMMENDATIONS_SUCCESS, STAKEHOLDER_RECOMMENDATIONS_FAILURE, SAVE_STAKEHOLDER_NAME,
  SAVE_STAKEHOLDER_NAME_SUCCESS, SAVE_STAKEHOLDER_NAME_FAILURE, SAVE_MEASURE_NAME, SAVE_MEASURE_NAME_SUCCESS, SAVE_MEASURE_NAME_FAILURE
} from "../actions/measure/measure"
import {
  DELETE_MEASURE_FAILURE,
  DELETE_MEASURE_SUCCESS,
  DELETE_MEASURE,
  SUBMIT_COPY_MEASURE,
  SUBMIT_COPY_MEASURE_SUCCESS,
  SUBMIT_COPY_MEASURE_FAILURE,
  SET_OUTCOME_FRAMEWORK_MODAL
} from "../actions/measure/manage-measure";
import {
  ONTOLOGY_SEARCH, ONTOLOGY_SEARCH_FAILURE,
  ONTOLOGY_SEARCH_SUCCESS, ONTOLOGY_SET_INITIAL_STATE
} from "../actions/ontology";
import { SHOW_ERROR } from "../actions/errors";
import { HIDE_SPINNER } from "../actions/spinner";
import { UNAUTHORIZED_REQUEST } from "../actions/login";


export function fetchLogicModelEpic(action$, store) {
  return action$.ofType(FETCH_LOGIC_MODEL)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { logicModelId } = store.getState().measurePage;

      return Observable.fromPromise(get('/api/logic_models/' + logicModelId, token))
        .flatMap(({logic_model, measureTypes}) => {
          setUserPermissionToCurrentLogicModel(logic_model);
          if (logic_model.stakeholders) {
            logic_model.stakeholders = logic_model.stakeholders.reverse();
          }
          return Observable.concat(
            Observable.of({ type: FETCH_LOGIC_MODEL_SUCCESS, logic_model, measureTypes }),
            Observable.of({ type: HIDE_SPINNER }),
          )
        })
        .catch((errors) => {
          const errorText = errors.message;

          return Observable.concat(
            errors.global?
              Observable.of({type: UNAUTHORIZED_REQUEST })
              :
              Observable.concat(
                Observable.of({ type: SHOW_ERROR, errorText }),
                Observable.of({ type: FETCH_LOGIC_MODEL_FAILURE, errors })
              )
          )
        })
    })
}

export function deleteStakeholderEpic(action$, store) {
  return action$.ofType(DELETE_STAKEHOLDER)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const {logicModelId, stakeholderId} = store.getState().measurePage;
      let id = logicModelId;
      return Observable.fromPromise(remove(`/api/stakeholders/${stakeholderId}`, token))
        .flatMap((res) => {
          return Observable.concat(
            Observable.of({type: DELETE_STAKEHOLDER_SUCCESS}),
            Observable.of({type: FETCH_LOGIC_MODEL, id})
          )
        })
        .catch((errors) => {
          const errorText = errors.message;
          return Observable.concat(
            Observable.of({type: SHOW_ERROR, errorText}),
            Observable.of({type: DELETE_STAKEHOLDER_FAILURE, payload: errors})
          )
        })
    })
}

export function addStakeholderEpic(action$, store) {
	return action$.ofType(ADD_STAKEHOLDER)
		.mergeMap((action) => {
			const token = localStorage.getItem('token');
			const { logicModelId } = store.getState().measurePage;
			const payload = {logic_model_id: logicModelId, name: "Unnamed Stakeholder"};

			return Observable.fromPromise(post(`/api/stakeholders`, payload, token))
				.flatMap(({ data }) => {
				  const newStakeholder = data.stakeholder;
				  const id = data.stakeholder.logic_model_id;

					return Observable.concat(
					  Observable.of({ type: ADD_STAKEHOLDER_SUCCESS, payload: { newStakeholder } }),
            Observable.of({type: FETCH_LOGIC_MODEL, id})
          )
				})
				.catch(errors => Observable.of({ type: ADD_STAKEHOLDER_FAILURE, errors }))
		})
}

export function fetchRecommendedStakeholdersEpic(action$, store) {
  return action$.ofType(CHANGE_STAKEHOLDER_NAME)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { stakeHolderName } = store.getState().measurePage;
      const payload = { keyword: stakeHolderName };

      return Observable.fromPromise(post('/api/ontology/search', payload, token))
        .flatMap((response) => {
          return Observable.of({type: STAKEHOLDER_RECOMMENDATIONS_SUCCESS, payload: { response } })
        })
        .catch(errors => Observable.of({ type: STAKEHOLDER_RECOMMENDATIONS_FAILURE, errors }))
    })
}


 function searchOntologyEpic(action$, store) {
  return action$.ofType(ONTOLOGY_SEARCH)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { query } = store.getState().ontology;
      const payload = { keyword: query };

      return Observable.fromPromise(post('/api/ontology/search', payload, token))
        .flatMap((response) => {
          response = response.reverse();
          return Observable.of({type: ONTOLOGY_SEARCH_SUCCESS, payload: { response } })
        })
        .catch(errors => Observable.of({ type: ONTOLOGY_SEARCH_FAILURE, errors }))
    })
}


export function saveStakeholderName(action$, store) {
  return action$.ofType(SAVE_STAKEHOLDER_NAME)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { stakeHolderName, logicModelId, stakeholderId } = store.getState().measurePage;
      const payload = {name: stakeHolderName, logic_model_id: logicModelId};

      return Observable.fromPromise(put(`/api/stakeholders/${stakeholderId}`, payload, token))
        .flatMap(({stakeholder}) => {
          return Observable.concat(
            Observable.of({type: SAVE_STAKEHOLDER_NAME_SUCCESS, payload: {stakeholder} } ),
            Observable.of({ type: ONTOLOGY_SET_INITIAL_STATE })
          )
        })
        .catch(errors => Observable.of({ type: SAVE_STAKEHOLDER_NAME_FAILURE, errors }))
    })
}


function saveCopyMeasure(action$, store) {
  return action$.ofType(SUBMIT_COPY_MEASURE)
    .mergeMap((action) => {
      const token = localStorage.getItem("token");
      const { copyLogicModel } = store.getState().manageMeasure;
      const {
        newMeasureName,
        targetParentMeasureId,
        targetStakeholderId,
        copy_measure_type_id,
      } = copyLogicModel;
      const payload = {
        newMeasureName,
        targetParentMeasureId,
        targetStakeholderId,
        copy_measure_type_id
      };
      return Observable.fromPromise(
        post(`/api/measures/${copyLogicModel.measureId}/copy`, payload, token)
      )
        .flatMap((res) => {
          const id = res.data.targetStakeholder.logic_model_id;
          return Observable.concat(
            Observable.of({ type: SUBMIT_COPY_MEASURE_SUCCESS }),
            Observable.of({ type: FETCH_LOGIC_MODEL, id })
          );
        })
        .catch((err) => {
          return Observable.concat(
            Observable.of({ type: SUBMIT_COPY_MEASURE_FAILURE, err })
          );
        });
    })
}

function deleteMeasureEpic(action$, store) {
  return action$.ofType(DELETE_MEASURE)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { stakeholderId } = store.getState().measurePage;
      const { deleteMeasureId } = store.getState().manageMeasure;

      return Observable.fromPromise(post(`/api/measures/${deleteMeasureId}/delete`, {stakeholder_id: stakeholderId}, token))
        .flatMap(( response ) => {
          const id = response.logic_model_id;

          return Observable.concat(
            Observable.of({ type: FETCH_LOGIC_MODEL, id }),
            Observable.of({ type: DELETE_MEASURE_SUCCESS })
          )
        })
        .catch(errors => Observable.of({type: DELETE_MEASURE_FAILURE, errors}))
    })
}

export function changeMeasureName(action$, store) {
  return action$.ofType(SAVE_MEASURE_NAME)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const {
        stakeholderId,
        newMeasureName,
        newMeasureDescription,
        measureId,
        measureThemes,
        logicModel,
      } = store.getState().measurePage;
      let stakeholderID = stakeholderId
      if(!stakeholderID){
        stakeholderID = logicModel.stakeholders.find((x) => x.measures.find((y) => y.id == measureId)).id;
      }
      let data = {
        category: action.category,
        name: newMeasureName,
        description: newMeasureDescription,
        stakeholder_id: stakeholderID,
        themes: measureThemes,
      };
      return Observable.fromPromise(
        put(
          `/api/measures/${measureId}`,
          data,
          token
        )
      )
        .flatMap(({ stakeholder, measure }) => {
          const id = stakeholder.logic_model_id;
          return Observable.concat(
            Observable.of({ type: SET_OUTCOME_FRAMEWORK_MODAL, status: false }),
            Observable.of({ type: SAVE_MEASURE_NAME_SUCCESS }),
            Observable.of({ type: FETCH_LOGIC_MODEL, id }),
            Observable.of({ type: ONTOLOGY_SET_INITIAL_STATE })
          );
        })
        .catch((errors) =>
          Observable.of({ type: SET_OUTCOME_FRAMEWORK_MODAL, status: true }),
        );
    })
}

function setUserPermissionToCurrentLogicModel(logicModel) {
  const userId = localStorage.getItem('user_id');
  const currentUser = logicModel.users.find(user => String(user.id) === userId);
  localStorage.setItem("permission", currentUser.permission);
}

export default [
  addStakeholderEpic,
  fetchLogicModelEpic,
  fetchRecommendedStakeholdersEpic,
  saveStakeholderName,
  deleteMeasureEpic,
  deleteStakeholderEpic,
  changeMeasureName,
  saveCopyMeasure,
  searchOntologyEpic
];