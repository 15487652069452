import merge from 'xtend';
import createReducer from './create-reducer';
import {UPDATE as LANGUAGE_CHANGE} from 'react-intl-redux';
import { REQUEST_SUCCESS } from "../actions/login";


const LOCALE_LANGUAGE_MAP = {
  en: 'English',
  fr: 'Français'
};

const INITIAL_STATE = {
  isAuthenticated: false,
  language: null,
};

export default createReducer({
  [LANGUAGE_CHANGE]: (state, action) => merge(state, {
    language: LOCALE_LANGUAGE_MAP[action.payload.locale]
  }),
  [REQUEST_SUCCESS]: (state, action) => merge(state, {
    isAuthenticated: true
  })
  // [GET_BUILD_NUMBER_SUCCESS]: (state, action) => merge(state, {buildNumber: action.buildNumber}),
  // [GET_BUILD_NUMBER_FAILURE]: (state, action) => merge(state, {buildNumber: null})
}, INITIAL_STATE);
