import React from 'react';
import { InjectIntl } from "../../../common/InjectIntl";
import PropTypes from 'prop-types';
import SpinnerWrapper from "../../../common/Spinner/SpinnerWrapper";

const EmptyDataTable = ({ intl, isLoading }) => (

  <SpinnerWrapper isActive={isLoading}>
    <div className="react-bs-table-container">

      <div className="react-bs-table-tool-bar ">
        <div className="row">
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-8">
            <div className="btn-group btn-group-sm" role="group"/>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4">
            <div className="form-group form-group-sm react-bs-table-search-form">
              <div>
                <div className="form-group has-feedback has-feedback-left">
                  <div className="input-group input-group-sm">
                    <span className="input-group-addon">
                    <i className="fa fa-search"/></span>
                    <input className="form-control success" type="text" readOnly={true} placeholder="Search" value=""/>
                  </div>
                </div>
              </div>
              <span className="input-group-btn"/>
            </div>
          </div>
        </div>
      </div>
      <div className="react-bs-table-pagination">
        <div className="row" style={{ marginTop: "15px" }}>
          <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
            <div id="total-records-number"><span>No. of Records </span></div>
            <span className="dropdown   react-bs-table-sizePerPage-dropdown">
                <button className="btn btn-default btn-secondary dropdown-toggle" id="pageDropDown"
                        data-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                  &nbsp;
                  <span><span className="caret"/></span>
                </button>
                <ul className="dropdown-menu " role="menu" aria-labelledby="pageDropDown"/></span></div>
          <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6" style={{ display: "block" }}>
            <ul className="react-bootstrap-table-page-btns-ul pagination"/>
          </div>
        </div>
      </div>
      <div className="react-bs-table react-bs-table-bordered" style={{ height: "200px" }}>
        <div className="text-center">
          <h2 className="text-muted"> {intl('source.modal.please_select_dataset_table_hint')} </h2>
        </div>
      </div>
      <div className="s-alert-wrapper"/>
    </div>
  </SpinnerWrapper>
);

EmptyDataTable.propTypes = {
  intl: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default InjectIntl(EmptyDataTable);