import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import manageMeasureActions from "../../../../actions/measure/manage-measure";
import inputsActions from "../../../../actions/stakeholder-items/inputs";
import financialProxyActions from "../../../../actions/stakeholder-items/financialProxy";
import measureActions from "../../../../actions/measure/measure";
import ontologyActions from "../../../../actions/ontology";
import AddInputParams from "../../../../components/logic-models/stakeholder-items/add-input/InputItem";

function mapStateToProps(state, ownProps) {
  return {
    ...state.measurePage,
    ...state.dataSource,
    ...state.inputs,
    ...state.ontology,
    ...state.manageMeasure,
    inputs: ownProps.inputs,
    stakeholder: ownProps.stakeholder,
    measure: ownProps.measure,
    intl: state.intl
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...manageMeasureActions,
      ...measureActions,
      ...inputsActions,
      ...financialProxyActions,
      ...ontologyActions,
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddInputParams)