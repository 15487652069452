import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ManageCollaboratorPage from "../../components/collaborators/ManageCollaboratorsPage";
import manageCollaboratorActions from '../../actions/manage-callaborator';
import inviteModalActions from "../../actions/members/invite-modal";
import inviteCollaboratorsAction from "../../actions/members/invite-collaborators";
import measureActions from "../../actions/measure/measure";

function mapStateToProps(state, ownProps) {
  return {
		...state.measurePage,
		...state.inviteModal,
		...state.inviteCollaborators,
		logicModelId: ownProps.params.id
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
      ...measureActions,
			...manageCollaboratorActions,
			...inviteModalActions,
			...inviteCollaboratorsAction
		}, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCollaboratorPage)