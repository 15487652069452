import { Observable } from "rxjs/Observable";
import {get, post} from "../api";
import {
  INITIAL_REPORTS_QUERY,
  INITIAL_REPORTS_QUERY_SUCCESS,
  INITIAL_REPORTS_QUERY_FAILURE,
  CHANGE_LOGIC_MODEL,
  RESULTS_QUERY,
  RESULTS_QUERY_SUCCESS,
  RESULTS_QUERY_FAILURE,
  CHANGE_TAB
} from '../actions/reports';
import { DASHBOARD } from '../reducers/reports';


export function fetchInitialReportsEpic(action$, store) {
  return action$.ofType(INITIAL_REPORTS_QUERY)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');

      let asyncPromise = get('/api/reports', token);

      return Observable.fromPromise(asyncPromise)
        .flatMap(resp => {
          const { logic_models, results } = resp;
          return Observable.concat(
            Observable.of({ type: INITIAL_REPORTS_QUERY_SUCCESS, logic_models}),
            Observable.of({type: RESULTS_QUERY_SUCCESS, results})
          )
        })
        .catch(({error}) => {
          return Observable.concat(
            Observable.of({ type: INITIAL_REPORTS_QUERY_FAILURE, error })
          )
        })
    })
}

export function changeLogicModelEpic(action$, store) {
  return action$.ofType(CHANGE_LOGIC_MODEL)
    .mergeMap((action) => {
      return Observable.of({type: RESULTS_QUERY, logic_model_id: action.logic_model_id});
    })
}

export function resultsQueryEpic(action$, store) {
  return action$.ofType(RESULTS_QUERY)
    .mergeMap(action => {
      const token = localStorage.getItem('token');
      const url = `/api/reports/${action.logic_model_id}`;
      let asyncPromise = post(url, {}, token);

      return Observable.fromPromise(asyncPromise)
        .flatMap(resp => {
          const { results } = resp;
          return Observable.concat(
            Observable.of({type: RESULTS_QUERY_SUCCESS, results}),
            Observable.of({type: CHANGE_TAB, nextTab: DASHBOARD}),
          )
        })
        .catch(({error}) => {
          return Observable.concat(
            Observable.of({ type: RESULTS_QUERY_FAILURE, error })
          )
        })
    });
}

export default [
  fetchInitialReportsEpic,
  changeLogicModelEpic,
  resultsQueryEpic
];