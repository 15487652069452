import { Observable } from "rxjs/Observable";
import { post} from "../../api";
import 'rxjs';
import { ADD_ACTIVITY_MEASURE, ADD_ACTIVITY_MEASURE_SUCCESS, ADD_ACTIVITY_MEASURE_FAILURE } from "../../actions/stakeholder-items/activities";
import { FETCH_LOGIC_MODEL } from "../../actions/measure/measure";

export function addActivityMeasureEpic(action$, store) {
  return action$.ofType(ADD_ACTIVITY_MEASURE)
    .mergeMap((action) => {
      const token = localStorage.getItem('token');
      const { logicModelId, stakeholderId } = store.getState().measurePage;
      const ACTIVITY_MEASURE_ID = 3;
      const payload = {name: "Unnamed Activity", measure_type_id: ACTIVITY_MEASURE_ID, stakeholder_id: stakeholderId};
      const id = logicModelId;

      return Observable.fromPromise(post(`/api/measures`, payload, token))
        .flatMap(({ data }) => {

          const measure = data.measure;
          const logic_model = data.logic_model;

          return Observable.concat(
            Observable.of({type: ADD_ACTIVITY_MEASURE_SUCCESS, payload: { logic_model, measure }}),
            Observable.of({type: FETCH_LOGIC_MODEL, id })
          )
        })
        .catch(errors => Observable.of({type: ADD_ACTIVITY_MEASURE_FAILURE, payload: { errors }}))
    })
}

export default [addActivityMeasureEpic]