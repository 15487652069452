import React from 'react';
import PropTypes from 'prop-types';

class TopMenuDropDown extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    name: PropTypes.string,
    id: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggleDropDown = (event) => {
    event.preventDefault();
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { isOpen } = this.state;
    const { name, children, id } = this.props;
    const dropDownClassNames = `dropdown ${isOpen ? 'open' : ''} success`;

    return (
      <li className={dropDownClassNames} id={id} onClick={this.toggleDropDown}>
        <a href="#!" className="dropdown-toggle"> {name} <span className="caret" /></a>
        <ul className="dropdown-menu">
          {children}
        </ul>
      </li>
    );
  }
}

export default TopMenuDropDown;
