import merge from "xtend";
import createReducer from './create-reducer';
import { HIDE_LOADING, SHOW_LOADING } from "../actions/loading-modal";
import {REQUEST} from "../actions/members/invite-collaborators";
import { FETCH_COLLABORATORS, DELETE_USER_FROM_LOGIC_MODEL, ADD_USER_TO_LOGIC_MODEL } from "../actions/manage-callaborator";

const INITIAL_STATE = {
  showLoading: false
};

export default createReducer({
  [SHOW_LOADING]: (state) => merge(state, {showLoading: true}),
  [HIDE_LOADING]: (state) => merge(state, {showLoading: false}),
  [FETCH_COLLABORATORS]: (state, action) => merge(state, {showLoading: true}),
  [DELETE_USER_FROM_LOGIC_MODEL]: (state, action) => merge(state, {showLoading: true}),
  [ADD_USER_TO_LOGIC_MODEL]: (state, action) => merge(state, {showLoading: true}),
  [REQUEST]: (state, action) => merge(state, {showLoading: true})
}, INITIAL_STATE)