export const SHOW_ERROR = '@ERROR/SHOW_ERROR';
export const CLEAR_ERROR = '@ERROR/CLEAR_ERROR';

function clearErrors() {
  return { type: CLEAR_ERROR }
}

function setError(errorText) {
  return { type: SHOW_ERROR, errorText }
}

export default {
  clearErrors,
  setError
}