import React from 'react';
import PropTypes from 'prop-types';
import SpinnerWrapper from "./common/Spinner/SpinnerWrapper";
import Error from "./Error";

class Login extends React.Component {
  static propTypes = {
    access_token: PropTypes.string.isRequired,
    refresh_token: PropTypes.string.isRequired,
    locale: PropTypes.string,
    logicModelId: PropTypes.string,
    redirect: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      logIn: PropTypes.func.isRequired
    })
  };

  componentDidMount() {
    const { access_token, refresh_token, redirect, locale } = this.props;
    const lang = locale !== 'null'? locale : 'en';
    if (access_token) {
      this.props.actions.logIn(access_token, refresh_token, redirect, lang);
    }
  }

  render() {
    const { isLoading, errors } = this.props;
    return (
      <div className="page-wrapper">
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-md-12">
              {errors.length > 0 ?
                <Error errorCode="401"
                       errorText={"Something went wrong..."}
                       errorDescription={"It seems, that you have invalid auth credentials."}
                />
                :
                <div className="middle-box">
                  <SpinnerWrapper isActive={isLoading}/>
                </div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;