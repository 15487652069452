import React from 'react';
import PropTypes from 'prop-types';
import { InjectIntl } from "../../../common/InjectIntl";
import MeasureNameComponent from "../MeasureNameComponent";


const Outputs = (props) => {
  const { output, outputsMeasureNameFieldDisable, actions, intl } = props;

  const closeHandler = () => actions.hideMeasureNameOutputField();

  const saveOutputMeasureName = (outputName) => actions.changeOutputMeasureName(outputName, output.id);

  const changeOutputMeasure = () => actions.showMeasureNameOutputField();

  return (
    <div className="nested-measure-item">
      <MeasureNameComponent
        closeHandler={closeHandler}
        saveMeasureName={saveOutputMeasureName}
        changeMeasure={changeOutputMeasure}
        measureNameFieldDisable={outputsMeasureNameFieldDisable}
        collapseIconShow={false}
        measureTypeTitle={intl('stakeholder.items.output')}
        placeholder={intl('stakeholder.items.output.placeholder')}
        measure={output}
        {...props}
      />
    </div>
  );
};

Outputs.propTypes = {
  stakeholder: PropTypes.object.isRequired,
  output: PropTypes.object.isRequired,
  query: PropTypes.string.isRequired,
  outputsMeasureNameFieldDisable: PropTypes.bool.isRequired,
  ontologySearching: PropTypes.bool.isRequired,
  ontologyResult: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    showMeasureNameOutputField: PropTypes.func,
    hideMeasureNameOutputField: PropTypes.func,
    changeOutputMeasureName: PropTypes.func,
    changeStakeholderId: PropTypes.func,
    deleteMeasure: PropTypes.func,
    logicModelLoading: PropTypes.func,
    measureNameSave: PropTypes.func,
    setOntologyInitialState: PropTypes.func,
  }.isRequired)
};

export default InjectIntl(Outputs);