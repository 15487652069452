import React from 'react';
import PropTypes from 'prop-types';
import { InjectIntl } from "../../../common/InjectIntl";
import FinancialProxy from "../../../../containers/logic-models/stakeholders-items/add-financial-proxy/FinancialProxy.container";
import SelectBox from "../../../common/SelectBox";
import MeasureNameComponent from "../MeasureNameComponent";
import PermissionChecker from '../../../common/PermissionChecker';

const AddInputParams = (props) => {

  const { measure, collapsedMeasures, stakeholder, actions, intl, inputsMeasureNameFieldDisable } = props;

  function handleAddFinancialProxyClick(e) {
    e.preventDefault();
    actions.changeStakeholderId(stakeholder.id);
    actions.changeParentMeasureId(measure.id);
    actions.addFinancialProxyMeasure();
  }

  function changeInputTypeItem(format) {
    actions.changeStakeholderId(stakeholder.id);
    actions.changeInputMeasureName(measure.name, measure.id, "", "");
    const typeValue = format.value;
    actions.measureNameSave(typeValue);
  }

  const selectOptions = [
    intl('stakeholder.items.input.select.option.select_money'),
    intl('stakeholder.items.input.select.option.select_time'),
    intl('stakeholder.items.input.select.option.select_market')
  ].map((item) => {
    return { value: item, label: item }
  });

  const closeHandler = () => actions.hideMeasureNameInputField();

  const saveInputMeasureName = (measureName) => actions.changeInputMeasureName(measureName, measure.id);

  const changeInputMeasure = () => actions.showMeasureNameInputField();

  return (
    <div className="ibox m-t">
      <MeasureNameComponent
        closeHandler={closeHandler}
        saveMeasureName={saveInputMeasureName}
        changeMeasure={changeInputMeasure}
        measureTypeTitle={intl('stakeholder.items.input')}
        measureNameFieldDisable={inputsMeasureNameFieldDisable}
        collapseIconShow={true}
        placeholder={intl('stakeholder.items.input.placeholder')}
        measureNameClassName={"ibox-title"}
        {...props}
      />
      {!collapsedMeasures.includes(measure.id) &&
      <div className="ibox-content">
        <div className="text-muted" style={{ display: "none" }}/>
        <div className="form-group">
          <PermissionChecker blur={true}>
            <SelectBox
              options={selectOptions}
              placeholder={intl('stakeholder.items.input.select.option_hint')}
              onHeaderChange={changeInputTypeItem}
              selectedOption={measure.category}
              sourceLayout
            />
          </PermissionChecker>
        </div>

        {measure.financial_proxies.map((finProxy, index) =>
          <FinancialProxy financialProxy={finProxy} key={index} stakeholder={stakeholder} parentType="Input"/>
        )}

        <div className="sub-nested-measure-item">
          <PermissionChecker hide={true}>
            <button className="btn btn-success btn-outline btn-block" onClick={handleAddFinancialProxyClick}>
              <i className="fa fa-plus"/> {intl('stakeholder.items.add_financial_proxy')}
            </button>
          </PermissionChecker>
        </div>

      </div>
      }
    </div>
  );
};

AddInputParams.propTypes = {
  stakeholder: PropTypes.object.isRequired,
  measure: PropTypes.object.isRequired,
  collapsedMeasures: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    changeStakeholderId: PropTypes.func,
    measureNameSave: PropTypes.func,
    addFinancialProxyMeasure: PropTypes.func,
    changeParentMeasureId: PropTypes.func,
    hideMeasureNameInputField: PropTypes.func,
    changeInputMeasureName: PropTypes.func,
    ontologyRequest: PropTypes.func,
  }.isRequired)
};

export default InjectIntl(AddInputParams);