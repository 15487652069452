export const SHOW_COLLABORATOR_MODAL = '@@COLLABORATOR_MODAL/SHOW_COLLABORATOR_MODAL';
export const HIDE_COLLABORATOR_MODAL = '@@COLLABORATOR_MODAL/HIDE_COLLABORATOR_MODAL';


function showCollaboratorModal() {
  return { type: SHOW_COLLABORATOR_MODAL }
}

function hideCollaboratorModal() {
  return { type: HIDE_COLLABORATOR_MODAL }
}

export default {
  showCollaboratorModal,
  hideCollaboratorModal
}