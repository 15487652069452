import merge from "xtend";
import createReducer from '../create-reducer';
import {
  SAVE_DATA_SOURCE,
  CHANGE_CURRENT_COLUMN_ID,
  SHOW_CALCULATION_SOURCE_FOR_VARIABLE,
  DATA_SOURCE_SHOULD_UPDATE,
  SAVE_DATA_SOURCE_SUCCESS,
  CHANGE_CURRENT_OPTION_ID,
  CLEAR_SOURCE_DATA,
  GET_DATA_SOURCE_RESOURCES_SUCCESS,
  CHANGE_HEADER_ID_FOR_TRANSFORMATION,
  CHANGE_CURRENT_TRANSFORMATION,
  UPDATE_CURRENT_DATA_SOURCE_SUCCESS,
  SET_DATA_SOURCE_INITIAL_STATE,
  SHOW_DATASOURCES,
  SAVE_HEADER_TRANSFORMATION, MARK_AS_RECHANGED_HEADER,
  SET_SHOULD_GET_TRANSFORMATION,
  SET_SHOULD_GET_DATASOURCES
} from "../../actions/calculation/data-source";
import {
  FETCH_CALCULATIONS_SUCCESS,
  ADD_CALCULATION_VARIABLES_SUCCESS
} from "../../actions/calculation/calculation-page";
import {
  CHANGE_CURRENT_FORM_ID, CHANGE_CURRENT_QUESTION_ID, CHANGE_CURRENT_SOURCE_NAME, CHANGE_CURRENT_UPLOAD_ID,
  CHANGE_CURRENT_WORKSPACE_ID
} from "../../actions/calculation/data-source";
import { HIDE_ONE_SOURCE_MODAL } from "../../actions/calculation/selectVariableType";

const DATA_SOURCE_INITIAL_STATE = {
  sourceName: '',
  workspaceId: null,
  formId: null,
  uploadId: null,
  questionId: null,
  columnId: null,
  optionId: null,
  headerId: null,
  transformation: {},
  collapsed: false,
  collapsedDatasources: [],
  headerTransformations: []
};

const INITIAL_STATE = {
  calculationVariableId: null,
  dataSourceTypes: [],
  columnId: '',
  currentDataSource: DATA_SOURCE_INITIAL_STATE,
  showCalculationSourceForVariableId: null,
  shouldGetUpdate: true,
  changeSourceTypeModalShow: false,
  collapsed: false,
  collapsedDatasources: [],
  isHeaderReChanged: false,
  allowGetTransformation: true,
  allowGetDatasources: true
};

function clearSourceData(currentDataSource, sourceItem) {
  Object.keys(currentDataSource).forEach((item) => {
    if (sourceItem === 'sourceName' && item !== 'sourceName') {
      currentDataSource[item] = null;
    }if (sourceItem === 'workspaceId' && item !== 'sourceName') {
      currentDataSource[item] = null;
    }
    if (sourceItem === 'form' && item !== 'workspaceId' && item !== 'formId' && item !== 'sourceName') {
      currentDataSource[item] = null;
    }
  });
  return currentDataSource
}

function updateCurrentDataSources(currentDataSource, dataType, newValue) {
  if (dataType === "transformation" && typeof newValue === "string" && currentDataSource.headerTransformations) {
    currentDataSource.headerTransformations.forEach((header) => {
      if (header.name === newValue) {
        newValue = {
          optionValue: header.value,
          value: header.id,
          label: header.name
        }
      }
    });
  }
  return {
    ...currentDataSource,
    [dataType]: newValue
  };
}

function collapsedHandler(collapsedDatasources, status, variableId) {
  if (collapsedDatasources.includes(variableId)) {
    const deleteIndex = collapsedDatasources.indexOf(variableId);
    collapsedDatasources.splice(deleteIndex, 1)
  } else {
    collapsedDatasources.push(variableId)
  }
  return collapsedDatasources
}


export default createReducer({
  [FETCH_CALCULATIONS_SUCCESS]: (state, action) => merge(state, {
    dataSourceTypes: action.payload.datasourceTypes
  }),
  [SAVE_DATA_SOURCE]: (state, action) => merge(state, {
    columnId: action.objId,
    questionId: action.objId
  }),
  [ADD_CALCULATION_VARIABLES_SUCCESS]: (state, action) => merge(state, {
    calculationVariableId: action.calculationVariable.id
  }),
  [CHANGE_CURRENT_SOURCE_NAME]: (state, action) => merge(state, {
    currentDataSource: updateCurrentDataSources(state.currentDataSource, 'sourceName', action.name)
  }),
  [CHANGE_CURRENT_WORKSPACE_ID]: (state, action) => merge(state, {
    currentDataSource: updateCurrentDataSources(state.currentDataSource, 'workspaceId', action.workspaceId)
  }),
  [CHANGE_CURRENT_FORM_ID]: (state, action) => merge(state, {
    currentDataSource: updateCurrentDataSources(state.currentDataSource, 'formId', action.formId)
  }),
  [CHANGE_CURRENT_UPLOAD_ID]: (state, action) => merge(state, {
    currentDataSource: updateCurrentDataSources(state.currentDataSource, 'uploadId', action.uploadId)
  }),
  [CHANGE_CURRENT_QUESTION_ID]: (state, action) => merge(state, {
    currentDataSource: updateCurrentDataSources(state.currentDataSource, 'questionId', action.questionId)
  }),
  [CHANGE_CURRENT_COLUMN_ID]: (state, action) => merge(state, {
    currentDataSource: updateCurrentDataSources(state.currentDataSource, 'columnId', action.columnId)
  }),
  [CHANGE_CURRENT_OPTION_ID]: (state, action) => merge(state, {
    currentDataSource: updateCurrentDataSources(state.currentDataSource, 'optionId', action.optionId),
    optionsIsLoading: false,
  }),
  [UPDATE_CURRENT_DATA_SOURCE_SUCCESS]: (state, action) => merge(state, {
    currentDataSource: action.payload
  }),
  [SHOW_CALCULATION_SOURCE_FOR_VARIABLE]: (state, action) => merge(state, {
    showCalculationSourceForVariableId: action.variableId
  }),
  [DATA_SOURCE_SHOULD_UPDATE]: (state, action) => merge(state, {
    shouldGetUpdate: action.status,
  }),
  [SAVE_DATA_SOURCE_SUCCESS]: (state, action) => merge(state, {
    currentDataSource: updateCurrentDataSources(state.currentDataSource, 'sourceName', action.payload.sourceName)
  }),
  [CLEAR_SOURCE_DATA]: (state, action) => merge(state, {
    currentDataSource: clearSourceData(state.currentDataSource, action.sourceItem),
    optionsIsLoading: false,
  }),
  [CHANGE_HEADER_ID_FOR_TRANSFORMATION]: (state, action) => merge(state, {
    currentDataSource: updateCurrentDataSources(state.currentDataSource, 'headerId', action.headerId)
  }),
  [CHANGE_CURRENT_TRANSFORMATION]: (state, action) => merge(state, {
    currentDataSource: updateCurrentDataSources(state.currentDataSource, 'transformation', action.transformation)
  }),
  [GET_DATA_SOURCE_RESOURCES_SUCCESS]: (state, action) => merge(state, {
    shouldGetUpdate: true
  }),
  [HIDE_ONE_SOURCE_MODAL]: (state, action) => merge(state, {
    shouldGetUpdate: true
  }),
  [SET_DATA_SOURCE_INITIAL_STATE]: (state, action) => merge(state, INITIAL_STATE),
  [SHOW_DATASOURCES]: (state, action) => merge(state, {
    collapsed: action.status,
    collapsedDatasources: collapsedHandler(state.collapsedDatasources, action.status, action.variableId)
  }),
  [SAVE_HEADER_TRANSFORMATION]: (state, action) => merge(state, {
    currentDataSource: updateCurrentDataSources(state.currentDataSource, 'headerTransformations', action.payload.transformation)
  }),
  [MARK_AS_RECHANGED_HEADER]: (state, action) => merge(state, {
    isHeaderReChanged: action.status
  }),
  [SET_SHOULD_GET_TRANSFORMATION]: (state, action) => merge(state, {
    allowGetTransformation: action.status
  }),
  [SET_SHOULD_GET_DATASOURCES]: (state, action) => merge(state, {
    allowGetDatasources: action.status
  })
}, INITIAL_STATE)