import createReducer from '../create-reducer';
import merge from 'xtend';
import { SHOW_COLLABORATOR_MODAL, HIDE_COLLABORATOR_MODAL } from "../../actions/members/invite-modal";

const INITIAL_STATE = {
  collaboratorModalShow: false
};

export default createReducer({
  [SHOW_COLLABORATOR_MODAL]: (state, action) => merge(state, {collaboratorModalShow: true}),
  [HIDE_COLLABORATOR_MODAL]: (state, action) => merge(state, {collaboratorModalShow: false})
}, INITIAL_STATE)