import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Stakeholder from "../../containers/logic-models/logic-model-edit/Stakeholder.container";
import { InjectIntl } from "../common/InjectIntl";
import EditAndCollaboratorNav from "./EditAndCollaboratorNav";
import RenderSpinnerSweetAlert from "../../containers/RenderSpinnerSweetAlert";
import PermissionChecker from "../common/PermissionChecker";
import EditFrameworkModal from "../../containers/EditFrameworkModal.container"
import LookupValueModal from "../../containers/LookupValueModal.container";

const EditIndexPage = props => {
  useEffect(()=>{
    props.actions.fetchFrameworks()
    props.actions.fetchThemes()
    props.actions.fetchWorkspaceResources()
  },[])
  const renderStakeholders = logicModel => {
    if (!props.hasStakeholders && logicModel) {
      return (
        <div className="col-md-12">
          {logicModel.stakeholders.map((stakeholder, index) => (
            <div
              key={index}
              className="ibox"
              style={{ border: "1px solid #F3F3F4" }}
            >
              <Stakeholder
                activeStakeholderId={stakeholder.id}
                stakeholder={stakeholder}
              />
            </div>
          ))}
        </div>
      );
    }
  };
  return (
    <div>
      <div className="row  m-b-lg">
        <div className="col-md-12 text-right">
          <EditAndCollaboratorNav logicModel={props.logicModel} />
          <PermissionChecker hide={true}>
            <div className="col-md-4 text-right">
              <button
                className="btn btn-success"
                onClick={props.actions.showLookupModal}
              >
                <i className="fa fa-plus" />
                &nbsp;
                {props.intl("edit_page.create_lookup_value")}
              </button>
            </div>

            <div className="col-md-2 text-right">
              <button
                className="btn btn-success"
                onClick={props.actions.addStakeHolder}
              >
                <i className="fa fa-plus" />
                &nbsp;
                {props.intl("edit_page.add_stakeholder")}
              </button>
            </div>
          </PermissionChecker>
        </div>
      </div>

      <div className="row">{renderStakeholders(props.logicModel)}</div>
      <RenderSpinnerSweetAlert />
      <EditFrameworkModal />
      <LookupValueModal/>
    </div>
  );
};

EditIndexPage.propTypes = {
  logicModel: PropTypes.object.isRequired,
  hasStakeholders: PropTypes.bool,
  actions: PropTypes.shape(
    {
      addStakeHolder: PropTypes.func.isRequired
    }.isRequired
  ),
  intl: PropTypes.func.isRequired
};

export default InjectIntl(EditIndexPage);
