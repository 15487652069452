export const FETCH_CALCULATIONS = '@@CALCULATION_PAGE/FETCH_CALCULATIONS';
export const FETCH_CALCULATIONS_SUCCESS = '@@CALCULATION_PAGE/FETCH_CALCULATIONS_SUCCESS';
export const FETCH_CALCULATIONS_FAILURE = '@@CALCULATION_PAGE/FETCH_CALCULATIONS_FAILURE';

export const CHANGE_CALCULATION_NAME = '@@CALCULATION_PAGE/CHANGE_CALCULATION_NAME';
export const UPDATE_CALCULATION = '@@CALCULATION_PAGE/UPDATE_CALCULATION';
export const SAVE_CALCULATION_NAME_SUCCESS = '@@CALCULATION_PAGE/SAVE_CALCULATION_NAME_SUCCESS';
export const UPDATE_CALCULATION_FAILURE = '@@CALCULATION_PAGE/UPDATE_CALCULATION_FAILURE';

export const ADD_CALCULATION = '@@CALCULATION_PAGE/ADD_CALCULATION';
export const ADD_CALCULATION_SUCCESS = '@@CALCULATION_PAGE/ADD_CALCULATION_SUCCESS';
export const ADD_CALCULATION_FAILURE = '@@CALCULATION_PAGE/ADD_CALCULATION_FAILURE';

export const SET_CURRENT_CALCULATION = '@@CALCULATION_PAGE/SET_CURRENT_CALCULATION';
export const CHANGE_HIERARCHY = '@@CALCULATION_PAGE/CHANGE_HIERARCHY';

export const CREATE_CALCULATION_VARIABLE = '@@CALCULATION_PAGE/CREATE_CALCULATION_VARIABLE';
export const CREATE_CALCULATION_VARIABLE_SUCCESS = '@@CALCULATION_PAGE/CREATE_CALCULATION_VARIABLE_SUCCESS';
export const CREATE_CALCULATION_VARIABLE_FAILURE = '@@CALCULATION_PAGE/CREATE_CALCULATION_VARIABLE_FAILURE';

export const ADD_PREEXISTING_CALCULATION_VARIABLE = '@@CALCULATION_PAGE/ADD_PREEXISTING_CALCULATION_VARIABLE';
export const ADD_CALCULATION_VARIABLES_SUCCESS = '@@CALCULATION_PAGE/ADD_CALCULATION_VARIABLES_SUCCESS';
export const ADD_CALCULATION_VARIABLES_FAILURE = '@@CALCULATION_PAGE/ADD_CALCULATION_VARIABLES_FAILURE';

export const EDIT_CALCULATION_VARIABLE = '@@CALCULATION_PAGE/EDIT_CALCULATION_VARIABLE';

export const SET_VARIABLE = '@@CALCULATION_PAGE/SET_VARIABLE';
export const SET_CALCULATION_VARIABLE = '@@CALCULATION_PAGE/SET_CALCULATION_VARIABLE';
export const SET_VARIABLE_NAME = '@@CALCULATION_PAGE/SET_VARIABLE_NAME';
export const SET_VARIABLE_SUCCESS = '@@CALCULATION_PAGE/SET_VARIABLE_SUCCESS';
export const SET_VARIABLE_FAILURE = '@@CALCULATION_PAGE/SET_VARIABLE_FAILURE';

export const DELETE_VARIABLE = '@@CALCULATION_PAGE/DELETE_VARIABLE';
export const DELETE_VARIABLE_SUCCESS = '@@CALCULATION_PAGE/DELETE_VARIABLE_SUCCESS';
export const DELETE_VARIABLE_FAILURE = '@@CALCULATION_PAGE/DELETE_VARIABLE_FAILURE';

export const DELETE_CALCULATION = '@@CALCULATION_PAGE/DELETE_CALCULATION';
export const DELETE_CALCULATION_SUCCESS = '@@CALCULATION_PAGE/DELETE_CALCULATION_SUCCESS';
export const DELETE_CALCULATION_VARIABLE_SUCCESS = '@@CALCULATION_PAGE/DELETE_CALCULATION_VARIABLE_SUCCESS';
export const DELETE_CALCULATION_VARIABLE_FAILURE = '@@CALCULATION_PAGE/DELETE_CALCULATION_VARIABLE_FAILURE';

export const ADD_SOURCE = '@@CALCULATION/ADD_SOURCE';
export const ADD_SOURCE_SUCCESS = '@@CALCULATION/ADD_SOURCE_SUCCESS';
export const ADD_SOURCE_FALIURE = '@@CALCULATION/ADD_SOURCE_FALIURE';

export const FETCH_SOURCE = '@@CALCULATION/FETCH_SOURCE';
export const FETCH_SOURCE_SUCCESS = '@@CALCULATION/FETCH_SOURCE_SUCCESS';
export const FETCH_SOURCE_FALIURE = '@@CALCULATION/FETCH_SOURCE_FALIURE';

export const SET_WORKSPACE = '@@CALCULATION/SET_WORKSPACE';

export const SET_SOURCE_INITIAL_STATE = '@@CALCULATION/SET_SOURCE_INITIAL_STATE';
export const CHANGE_DATA_SOURCE_TYPE = '@@CALCULATION/CHANGE_DATA_SET_NAME';

export const UPDATE_CALCULATION_DATA = '@@CALCULATION/UPDATE_CALCULATION_DATA';
export const UPDATE_CALCULATION_DATA_SUCCESS = '@@CALCULATION/UPDATE_CALCULATION_DATA_SUCCESS';


export const CHANGE_ACTIVE_VARIABLE = '@@CALCULATION/CHANGE_ACTIVE_VARIABLE';
export const CHANGE_CALCULATION_FORMULA = '@@CALCULATION/CHANGE_CALCULATION_FORMULA';
export const SAVE_FORMULA = '@@CALCULATION/SAVE_FORMULA';
export const SAVE_FORMULA_SUCCESS = '@@CALCULATION/SAVE_FORMULA_SUCCESS';
export const SAVE_FORMULA_FAILURE = '@@CALCULATION/SAVE_FORMULA_FAILURE';

export const SET_PREEXISTING_CALCULATIONS = '@@CALCULATION/SET_PREEXISTING_CALCULATIONS';
export const SET_CURRENT_PREEXISTING_CALCULATION = '@@CALCULATION/SET_CURRENT_PREEXISTING_CALCULATION';

/** Array calculation related actions */
export const CREATE_ARRAY_CALCULATION_VARIABLE_WITH_NEW_CALCULATION_VARIABLE = '@@CALCULATION/CREATE_ARRAY_CALCULATION_VARIABLE_WITH_NEW_CALCULATION_VARIABLE';

export const CREATE_ARRAY_CALCULATION = '@@CALCULATION/CREATE_ARRAY_CALCULATION';
export const CREATE_ARRAY_CALCULATION_SUCCESS = '@@CALCULATION/CREATE_ARRAY_CALCULATION_SUCCESS';

export const CREATE_ARRAY_CALCULATION_VARIABLE = '@@CALCULATION/CREATE_ARRAY_CALCULATION_VARIABLE';
export const CREATE_ARRAY_CALCULATION_VARIABLE_SUCCESS = '@@CALCULATION/CREATE_ARRAY_CALCULATION_VARIABLE_SUCCESS';

export const DELETE_ARRAY_CALCUATION_VARIABLE  = '@@CALCULATION/DELETE_ARRAY_CALCUATION_VARIABLE';

export const UPDATE_ARRAY_CALCULATION = '@@CALCULATION/UPDATE_ARRAY_CALCULATION';
export const UPDATE_ARRAY_CALCULATION_SUCCESS = '@@CALCULATION/UPDATE_ARRAY_CALCULATION_SUCCESS';
export const UPDATE_ARRAY_CALCULATION_FAILURE = '@@CALCULATION/UPDATE_ARRAY_CALCULATION_FAILURE';

export const UPDATE_ARRAY_CALCULATION_VARIABLE = '@@CALCULATION/UPDATE_ARRAY_CALCULATION_VARIABLE';
export const UPDATE_ARRAY_CALCULATION_VARIABLE_SYMBOL = '@@CALCULATION/UPDATE_ARRAY_CALCULATION_VARIABLE_SYMBOL';

export const SAVE_ARRAY_CALCULATION_FINAL = '@@CALCULATION/SAVE_ARRAY_CALCULATION_FINAL';

export const SET_CALCULATIONS_SNAPSHOTS =
         "@@CALCULATION/SET_CALCULATIONS_SNAPSHOTS";
         
export const SAVE_SNAPSHOT_COMMENT = "@@CALCULATION/SAVE_SNAPSHOT_COMMENT";
export const SAVE_SNAPSHOT_COMMENT_SUCCESS = "@@CALCULATION/SAVE_SNAPSHOT_COMMENT_SUCCESS";
export const SAVE_SNAPSHOT_COMMENT_FAILURE = "@@CALCULATION/SAVE_SNAPSHOT_COMMENT_FAILURE";

function getMeasureCalculations(shouldRedoCalculations) {
  return { type: FETCH_CALCULATIONS, shouldRedoCalculations }
}

function changeCalculationName(name, calculationId) {
  return { type: CHANGE_CALCULATION_NAME, name, calculationId }
}

function save() {
  return { type: UPDATE_CALCULATION }
}

function addCalculation() {
  return { type: ADD_CALCULATION }
}

function setCurrentCalculation(calculation) {
  return { type: SET_CURRENT_CALCULATION, calculation }
}

function changeHierarchy(changeParent) {
  return { type: CHANGE_HIERARCHY, changeParent }
}

function addPreexistingCalculationVariable(payload) {
  return { type: ADD_PREEXISTING_CALCULATION_VARIABLE, payload }
}

function createVariable(variableName) {
  return { type: CREATE_CALCULATION_VARIABLE, variableName }
}

function editCalculationVariable() {
  return { type: EDIT_CALCULATION_VARIABLE }
}

function setVariableName(payload, id) {
  return { type: SET_VARIABLE_NAME, payload, id }
}

function setVariable(payload, id) {
  return { type: SET_VARIABLE, payload, id }
}

function setCalculationVariable(payload, id) {
  return { type: SET_CALCULATION_VARIABLE, payload, id }
}

function deleteVariable(variableId) {
  return { type: DELETE_VARIABLE, variableId }
}

function deleteCalculation(calcId) {
  return { type: DELETE_CALCULATION, calcId }
}

function fetchSource() {
  return { type: FETCH_SOURCE }
}

function setWorkspace(workspaceId) {
  return { type: SET_WORKSPACE, workspaceId }
}

function setSourceInitialState() {
  return { type: SET_SOURCE_INITIAL_STATE }
}

function changeActiveVariable(calculationVariable) {
  return { type: CHANGE_ACTIVE_VARIABLE, calculationVariable }
}

function changeFormula(latex, string) {
  return { type: CHANGE_CALCULATION_FORMULA, latex, string }
}

function saveFormula() {
  return { type: SAVE_FORMULA }
}

function setCurrentPreexistingCalculation(calculation) {
  return { type: SET_CURRENT_PREEXISTING_CALCULATION, calculation }
}

// create a array_calculation_variable when adding a new calculation_variable
function createArrayCalculationVariableWithNewCalculationVariable(payload) {
  return { type: CREATE_ARRAY_CALCULATION_VARIABLE_WITH_NEW_CALCULATION_VARIABLE, payload };
}

function createArrayCalculation(payload) {
  return { type: CREATE_ARRAY_CALCULATION, payload};
}

function updateArrayCalculation(payload, id) {
  return { type: UPDATE_ARRAY_CALCULATION, payload, id };
}

function createArrayCalculationSuccess(payload) {
  return { type: CREATE_ARRAY_CALCULATION_SUCCESS, payload };
}

// no need for payload here as the UI does not give 
// the user a way to select a symbol before creating the variable
// the backend automatically assigns a character
function  createArrayCalculationVariable() {
  return { type: CREATE_ARRAY_CALCULATION_VARIABLE};
}

function deleteArrayCalculationVariable(id) {
  return { type: DELETE_ARRAY_CALCUATION_VARIABLE, id};
}

function updateArrayCalculationVariable(payload, id) {
  return { type: UPDATE_ARRAY_CALCULATION_VARIABLE, payload, id}
}

function updateArrayCalculationVariableSymbol(payload, id) {
  return { type: UPDATE_ARRAY_CALCULATION_VARIABLE_SYMBOL, payload, id}
}

// payload and id available in store and used in epic
function saveArrayCalculationFinal() {
  return { type: SAVE_ARRAY_CALCULATION_FINAL }
}

function setCalculationsSnapshots(status){
  return { type: SET_CALCULATIONS_SNAPSHOTS, status };
}

function saveSnapshotComment(snapshotId, comment){
  return { type: SAVE_SNAPSHOT_COMMENT, snapshotId, comment };
}

export default {
  deleteVariable,
  changeActiveVariable,

  fetchSource,
  setWorkspace,
  setSourceInitialState,
  changeFormula,
  save,
  getMeasureCalculations,
  setVariable,
  setCalculationVariable,
  setVariableName,
  addCalculation,
  addPreexistingCalculationVariable,
  createVariable,

  editCalculationVariable,
  changeHierarchy,
  saveFormula,
  deleteCalculation,
  changeCalculationName,
  setCurrentCalculation,
  setCurrentPreexistingCalculation,

  createArrayCalculationVariableWithNewCalculationVariable,
  createArrayCalculationSuccess,
  updateArrayCalculation,
  createArrayCalculationVariable,
  deleteArrayCalculationVariable,
  updateArrayCalculationVariable,
  updateArrayCalculationVariableSymbol,
  saveArrayCalculationFinal,
  setCalculationsSnapshots,
  saveSnapshotComment,
};