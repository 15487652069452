import merge from 'xtend';
import createReducer from './create-reducer';

import {SHOW_DELETE_MODAL_ITEM, HIDE_DELETE_MODAL_ITEM, SET_DELETE_PARAMS} from '../actions/modal-delete-item';

const INITIAL_STATE = {
  showModal: false,
  text: "",
  handleDelete: null,
  itemId: null
};

export default createReducer({
  [SHOW_DELETE_MODAL_ITEM]: (state, action) => merge(state, { showModal: true }),
  [HIDE_DELETE_MODAL_ITEM]: (state, action) => merge(state, { showModal: false }),
  [SET_DELETE_PARAMS]: (state, action) => merge(state,
    {
      text: action.text,
      handleDelete: action.handleDelete,
      itemId: action.itemId
    })
}, INITIAL_STATE)
