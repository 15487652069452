import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router";

class Error extends React.Component {
  static propTypes = {
    errorCode: PropTypes.string.isRequired,
    errorText: PropTypes.string.isRequired,
    errorDescription: PropTypes.string.isRequired,
    btnText: PropTypes.string,
    btnLink: PropTypes.string,
  };

  render() {
    const {
      errorCode,
      errorText,
      errorDescription,
      btnText, btnLink
    } = this.props;
    return (
      <div className="middle-box text-center animated fadeInDown">
        <h1>{errorCode}</h1>
        <h3 className="font-bold">{errorText}</h3>
        <div className="error-desc">
          {errorDescription}
          <br/>
          {btnText && btnLink ?
            <Link to={btnLink}>
              <button className="m-t-md btn btn-success">{btnText}</button>
            </Link>
            :
            false
          }
        </div>
      </div>
    );
  }
}


export default Error;
