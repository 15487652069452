import React from 'react';
import PropTypes from 'prop-types';

const NumericWidget = ({ value, label, money, message }) => {
  let valueClassName = message ? 'number_value_text has_message' : 'number_value_text';
  let formattedValue = `${money ? '$' : ''}${value}`;
  if(message) {
    formattedValue = message;
  }
  return (
      <div className="numeric_value_widget">
        <div className={valueClassName}>
          {formattedValue}
        </div>
        <div className="numeric_value_widget_label">
          {label}
        </div>
      </div>
  );
}

NumericWidget.propTypes = { 
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  label: PropTypes.string.isRequired,
  money: PropTypes.bool.isRequired
}

export default NumericWidget;