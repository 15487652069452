import React from "react";
import { InjectIntl } from "../../common/InjectIntl";
import Tooltip from "../../common/Tooltip/Tooltip";
import TooltipElement from "../../common/Tooltip/TooltipElement";

const FrameworkThemeTitle = (props) => {
  const { measureTitle, themes, frameworks, intl, children } = props;
  const frameworksTitlesArray = [
    ...new Set(
      themes.map(({ framework_id }) => {
        return framework_id;
      })
    ),
  ];
  const tooltipElement = (
    <>
      <p className="framework-title">
        {intl("editFramework.card.frameworks")}:
        {frameworksTitlesArray.map((framework_id) => (
          <span key={framework_id}>
            {frameworks.length > 0 &&
              frameworks.find((x) => x.id === framework_id).name}
            ;
          </span>
        ))}
      </p>
      <p className="framework-title">
        {intl("editFramework.card.themes")}:
        {themes.map(({ name, description }) => (
          <span>{name};</span>
        ))}
      </p>
    </>
  );
  if (measureTitle === "Outcome" && themes.length) {
    return (
      <Tooltip
        tooltipElement={
          <TooltipElement title={tooltipElement}></TooltipElement>
        }
      >
        <i className="fa fa-question-circle" />
      </Tooltip>
    );
  }
  return null;
};

export default InjectIntl(FrameworkThemeTitle);
