import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import LogicModelDropdown from './LogicModelDropdown';
import Results from './Results';

const Reports = props => {

  useEffect(() => {
    props.actions.initReports();
  }, []);

  const spinner = () => <div className="sk-loading sk-spinner sk-spinner-rotating-plane" style={{"display": "block"}} />;

  const renderLogicModelDropdown = () => {
    const { logic_models } = props;
    return logic_models.length > 0
      ? <LogicModelDropdown
            logic_models={logic_models}
            changeLogicModel={props.actions.changeLogicModel}
          />
      : '';
  }

  const renderResults = () => {
    const { results, resultLoading, tabs, activeTab } = props;
    const { changeTab } = props.actions;
    
    if(resultLoading) {
      return spinner();
    }
    return !isEmpty(results)
      ? <Results results={results} tabs={tabs} activeTab={activeTab} changeTab={changeTab} />
      : 'No Result';
  }

  const renderReports = () => {
    if(props.isLoading) {
      return spinner();
    }

    return (
      <div className="wrapper wrapper-content">
        <div className="row">
          <div className="col-lg-10 reports_wrap">
            <div className="ibox">
              <div className="ibox-content">
                <h1 className="report_title">Impact report</h1>
                { renderResults() }
              </div>
            </div>
          </div>
          <div className="col-lg-2 filters_wrap">
            <div className="ibox">
              <div className="ibox-content">
                { renderLogicModelDropdown() }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } // EO renderReports

  return renderReports();
  
}

Reports.propTypes = {
  logic_models: PropTypes.array.isRequired,
  results: PropTypes.object.isRequired,
  resultLoading: PropTypes.bool.isRequired,
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    initReports: PropTypes.func.isRequired,
    changeLogicModel: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired
  }).isRequired
}

export default Reports;