import React from "react";
import PropTypes from "prop-types";
import {
  NUMBER_OR_DATE_TYPES,
  CHECKBOXES_TYPES
} from "../../../../../constants";
import { InjectIntl } from "../../../../common/InjectIntl";
import { checkMultiSelectQuestionType } from "./ConditionFilter";
import { EDIT } from "../../../../members/constants";

export function isValidData(conditions, showErrorHints) {
  function checkErrorHints(errorHints, field) {
    return errorHints[field] === null || errorHints.notation === null
      ? true
      : errorHints[field].length === 0 && errorHints.notation.length === 0;
  }

  let validated = true;
  conditions.some(condition => {
    const { chosenData, errorHints, columnHeader } = condition;
    if (CHECKBOXES_TYPES.includes(chosenData)) {
      if (checkMultiSelectQuestionType(columnHeader.obj.validation))
        validated = checkErrorHints(errorHints, "numberField");
      else
        validated =
          errorHints.optionsField === null
            ? true
            : errorHints.optionsField.length === 0;
    } else if (NUMBER_OR_DATE_TYPES.includes(chosenData)) {
      if (chosenData !== "date")
        validated =
          checkErrorHints(errorHints, "numberField");
      else validated = checkErrorHints(errorHints, "dateField");
    } else {
      if (conditions.length === 1) {
        if (errorHints.columnHeader.length > 0) {
          showErrorHints();
        }
      }
      validated = false;
    }
    if (!validated) {
      return true;
    }
  });

  return validated;
}

const ConditionControlButtons = ({
  cancelNotAppliedConditions,
  sendConditions,
  conditions,
  showErrorHints,
  intl
}) => {
  function getTransformationValueWithCondition() {
    if (isValidData(conditions, showErrorHints)) sendConditions();
    else showErrorHints(true);
  }

  return (
    <div id="condition-control-panel" className="m-t">
      <button className="btn btn-default" onClick={cancelNotAppliedConditions}>
        {intl("common.text.close_button")}
      </button>
      {localStorage.getItem("permission") === EDIT && 
        <button
          className="btn btn-success"
          onClick={getTransformationValueWithCondition}
        >
          OK
        </button>
      }
    </div>
  );
};

ConditionControlButtons.propTypes = {
  cancelNotAppliedConditions: PropTypes.func.isRequired,
  sendConditions: PropTypes.func.isRequired,
  showErrorHints: PropTypes.func.isRequired,
  conditions: PropTypes.arrayOf(PropTypes.object).isRequired,
  intl: PropTypes.func.isRequired
};

export default InjectIntl(ConditionControlButtons);
