import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import inviteCollaboratorsActions from "../../actions/members/invite-collaborators";
import inviteModalActions from "../../actions/members/invite-modal";
import InviteCollaboratorsModal from "../../components/members/InviteMembersModal";

function mapStateToProps(state, ownProps) {
  return {
    ...state.inviteCollaborators,
    ...state.inviteModal
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...inviteCollaboratorsActions,
      ...inviteModalActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteCollaboratorsModal)