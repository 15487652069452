import merge from "xtend";
import createReducer from "../../create-reducer";
import {
  CHANGE_NAV_BUTTONS_MODAL,
  FINAL_VARIABLE_NAME,
  SET_SOURCE_MODAL_INITIAL_STATE,
  FETCH_FINAL_SOURCE_DATA_SUCCESS,
  CHANGE_DATA_FOR_FINAL_PAGE
} from "../../../actions/calculation/source-modals";
import { CLEAR_SOURCE_DATA } from "../../../actions/calculation/data-source";
import { HIDE_ONE_SOURCE_MODAL } from "../../../actions/calculation/selectVariableType";

const INITIAL_STATE = {
  finalPageCurrentPage: 1,
  finalPageSizePerPage: 5,
  finalPageTotalDataSize: null,
  finalSourceColumnVisible: false,
  finalData: {},
  finalVariableName: "",
  errors: {}
};

export default createReducer(
  {
    [CHANGE_DATA_FOR_FINAL_PAGE]: (state, action) =>
      merge(state, {
        finalPageCurrentPage: action.page,
        finalPageSizePerPage: action.sizePerPage
      }),
    [CHANGE_NAV_BUTTONS_MODAL]: (state, action) =>
      merge(state, {
        finalSourceColumnVisible: action.name === "final"
      }),
    [CLEAR_SOURCE_DATA]: (state, action) =>
      merge(state, {
        finalData: {}
      }),
    [FETCH_FINAL_SOURCE_DATA_SUCCESS]: (state, action) =>
      merge(state, {
        finalData: action.payload,
        finalPageTotalDataSize: action.payload.total
      }),
    [FINAL_VARIABLE_NAME]: (state, action) =>
      merge(state, {
        finalVariableName: action.name
      }),
    [SET_SOURCE_MODAL_INITIAL_STATE]: (state, action) =>
      merge(state, INITIAL_STATE),
    [HIDE_ONE_SOURCE_MODAL]: (state, action) =>
      merge(state, {
        finalSourceColumnVisible: false
      })
  },
  INITIAL_STATE
);
