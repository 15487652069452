import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import manageMeasureActions from "../../../../actions/measure/manage-measure";
import demographicActions from "../../../../actions/stakeholder-items/demographics";
import FrameworkThemeTitle from "../../../../components/logic-models/stakeholder-items/FrameworkThemeTitle";
import CalculationActions from "../../../../actions/calculation/calculation-page";

function mapStateToProps(state, ownProps) {
  let themes = [];
  const stakeholder = state.measurePage.logicModel.stakeholders.find((x) =>
    x.outcomes.find((x) => x.id === ownProps.measureId)
  );
  if (stakeholder) {
    themes = stakeholder.outcomes.find((x) => x.id === ownProps.measureId).themes;
  }
  return {
    ...state.manageMeasure,
    measureTitle: ownProps.measureTypeTitle,
    intl: ownProps.intl,
    stakeholder: stakeholder,
    themes,
    frameworks: state.logicModels.frameworks
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...manageMeasureActions,
        ...CalculationActions,
        hideDemographic: demographicActions.hideDemographic,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FrameworkThemeTitle);
