import React from "react";
import { EDIT } from "../../components/members/constants";
import PropTypes from "prop-types";
import classNames from "classnames";

const PermissionChecker = ({ hide, blur, children }) => {
  const permission = localStorage.getItem("permission");
  const viewOnly = permission !== EDIT;

  const className = child =>
    classNames(child.props.className, {
      "content-disabled disable-hover": viewOnly,
      "default-opacity": !blur
    });

  if (!viewOnly) {
    return children;
  } else if (hide) {
    return null;
  } else {
    return React.createElement(
      React.Fragment,
      {},
      React.Children.map(children, child =>
        React.cloneElement(child, {
          disabled: viewOnly,
          className: className(child),
          onClick: () => {}
        })
      )
    );
  }
};

PermissionChecker.propTypes = {
  hide: PropTypes.bool,
  blur: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired
};

export default PermissionChecker;
