import merge from "xtend";
import createReducer from "../../create-reducer";
import {
  SHOW_SELECT_VARIABLE_TYPE_MODAL,
  HIDE_SELECT_VARIABLE_TYPE_MODAL,
  SHOW_SELECT_PREEXISTING_CALCULATION_MODAL,
  HIDE_SELECT_PREEXISTING_CALCULATION_MODAL,
  CHANGE_DATA_SET_NAME,
  SHOW_ONE_SOURCE_MODAL,
  HIDE_ONE_SOURCE_MODAL
} from "../../../actions/calculation/selectVariableType";

const INITIAL_STATE = {
  isSelectedVariableModalShowed: false,
  isSelectPreexistingCalculationModalShowed: false,
  oneSourceModalVisible: false,
  dataSetName: ""
};

export default createReducer(
  {
    [SHOW_SELECT_VARIABLE_TYPE_MODAL]: (state, action) =>
      merge(state, {
        isSelectedVariableModalShowed: true
      }),
    [HIDE_SELECT_VARIABLE_TYPE_MODAL]: (state, action) =>
      merge(state, {
        isSelectedVariableModalShowed: false
      }),
    [SHOW_SELECT_PREEXISTING_CALCULATION_MODAL]: (state, action) =>
      merge(state, {
        isSelectPreexistingCalculationModalShowed: true
      }),
    [HIDE_SELECT_PREEXISTING_CALCULATION_MODAL]: (state, action) =>
      merge(state, {
        isSelectPreexistingCalculationModalShowed: false
      }),
    [SHOW_ONE_SOURCE_MODAL]: (state, action) =>
      merge(state, {
        oneSourceModalVisible: true
      }),
    [HIDE_ONE_SOURCE_MODAL]: (state, action) =>
      merge(state, {
        oneSourceModalVisible: false
      }),
    [CHANGE_DATA_SET_NAME]: (state, action) =>
      merge(state, {
        dataSetName: action.name
      })
  },
  INITIAL_STATE
);
