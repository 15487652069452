import React from "react";

const DefinitionsItem = ({ title, text }) => {
  return (
    <div className="definition-item">
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  );
};

export default DefinitionsItem;
