import React from 'react';
import PropTypes from 'prop-types';
import { InjectIntl } from "../../../common/InjectIntl";
import Outputs from "../../../../containers/logic-models/stakeholders-items/add-output/Output.container";
import MeasureNameComponent from "../MeasureNameComponent";
import PermissionChecker from '../../../common/PermissionChecker';


const AddActivityParams = (props) => {

  const { measure, collapsedMeasures, stakeholder, actions, intl, activityMeasureNameFieldDisable } = props;

  const closeHandler = () => actions.hideMeasureNameActivityField();

  const saveActivityMeasureName = (activityName) => actions.changeActivityMeasureName(activityName, measure.id);

  const changeActivityMeasure = () => actions.showMeasureNameActivityField();

  function handleOutputClick (e) {
    e.preventDefault();
    actions.changeStakeholderId(stakeholder.id);
    actions.changeParentMeasureId(measure.id);
    actions.addOutputMeasure();
  }

  return (
    <div className="ibox m-t" id="output" >
      <MeasureNameComponent
        closeHandler={closeHandler}
        saveMeasureName={saveActivityMeasureName}
        changeMeasure={changeActivityMeasure}
        measureNameFieldDisable={activityMeasureNameFieldDisable}
        measureTypeTitle={intl('stakeholder.items.activity')}
        collapseIconShow
        placeholder={intl('stakeholder.items.activity.placeholder')}
        measureNameClassName={"ibox-title"}
        {...props}
      />
      {!collapsedMeasures.includes(measure.id) && <div className="ibox-content">
        <div className="text-muted" style={{ display: "none" }}/>

        {measure.outputs.map((output, index) =>
          <Outputs output={output} key={index} stakeholder={stakeholder}/>
        )}
        <PermissionChecker hide={true}>
          <button className="btn btn-success btn-outline btn-block" onClick={handleOutputClick}>
            <i className="fa fa-plus"/> {intl('stakeholder.items.activity.add_output')}
          </button>
        </PermissionChecker>
      </div>}
    </div>
  );
};

AddActivityParams.propTypes = {
  stakeholder: PropTypes.object.isRequired,
  measure: PropTypes.object.isRequired,
  activityMeasureNameFieldDisable: PropTypes.bool.isRequired,
  collapsedMeasures: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    changeStakeholderId: PropTypes.func,
    hideMeasureNameActivityField: PropTypes.func,
    showMeasureNameActivityField: PropTypes.func,
    measureNameSave: PropTypes.func,
    changeOntologyQuery: PropTypes.func,
    searchForOntology: PropTypes.func,
    setOntologyInitialState: PropTypes.func,
    changeParentMeasureId: PropTypes.func,
    addOutputMeasure: PropTypes.func,
    changeActivityMeasureName: PropTypes.func,
  }.isRequired)
};

export default InjectIntl(AddActivityParams);