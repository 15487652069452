import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CalculationsActions from "../../../../actions/calculation/calculation-page";
import ManageMeasureActions from "../../../../actions/measure/manage-measure";
import DataSourceActions from "../../../../actions/calculation/data-source";
import SourceModalsActions from "../../../../actions/calculation/source-modals";
import SourceModalFinalTab from "../../../../components/calculations/sources/modals/SourceModalFinalTab";

function mapStateToProps(state, ownProps) {
  return {
    ...state.calculations,
    ...state.dataSource,
    ...state.oneSourceFirstTab,
    ...state.oneSourceFinalTab,
    conditions: state.conditionPanel.conditions,
    intl: state.intl,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...CalculationsActions,
      ...ManageMeasureActions,
      ...DataSourceActions,
      ...SourceModalsActions
    }, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SourceModalFinalTab)