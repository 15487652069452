import React from "react";
import PropTypes from "prop-types";
import SelectBox from "../../../../common/SelectBox";
import ConditionFilter from "./ConditionFilter";
import { InjectIntl } from "../../../../common/InjectIntl";
import {
  CHECKBOXES_TYPES,
  NUMBER_OR_DATE_TYPES
} from "../../../../../constants";
import classNames from "classnames";
import { checkMultiSelectQuestionType } from "./ConditionFilter";
import { EDIT } from "../../../../members/constants";

function checkData(condition) {
  const { optionValue, columnHeader, chosenData } = condition;
  if (CHECKBOXES_TYPES.includes(chosenData)) {
    if (checkMultiSelectQuestionType(columnHeader.obj.validation))
      return optionValue.notation.length > 0 && parseFloat(optionValue.inputValue);
    else return optionValue.options.length > 0;
  } else if (NUMBER_OR_DATE_TYPES.includes(chosenData)) {
    if (["number", "integer", "float"].includes(chosenData))
      return (
        optionValue.notation.length > 0 && parseFloat(optionValue.inputValue)
      );
    else return optionValue.notation.length > 0 && optionValue.startDate;
  }
  return false;
}

const RenderActionButtons = ({
  removeConditionRow,
  addConditionRow,
  condition,
  showErrorHints,
  lastConditionId,
  conditionsLength
}) => {
  const isDataCorrect = checkData(condition);

  const removeItem = e => {
    e.preventDefault();
    removeConditionRow(condition.id);
  };

  const addCondition = () => {
    if (isDataCorrect && condition.id === lastConditionId) {
      addConditionRow();
    } else if (!isDataCorrect) showErrorHints(true);
  };

  const addButtonClassName = classNames(
    "btn btn-default options-control-button btn-circle",
    {
      disabled:
        !isDataCorrect ||
        (conditionsLength > 1 && condition.id !== lastConditionId)
    }
  );

  return (
    <div id="condition-actions">
      <button
        className="btn btn-default options-control-button btn-circle"
        onClick={removeItem}
      >
        <i className="fa fa-minus disable-hover" />
      </button>
      <button className={addButtonClassName} onClick={addCondition}>
        <i className="fa fa-plus disable-hover" />
      </button>
    </div>
  );
};

function ConditionItem(props) {
  const {
    condition: { columnHeader, errorHints },
    intl,
    selectOptions,
    intlLocale,
    changeColumnHandler,
    showError,
    actions: { conditionRowRemove, conditionRowAdd, showErrorHints },
    condition,
    lastConditionId,
    conditionsLength
  } = props;

  return (
    <React.Fragment>
      <div className="condition-block">
        <div className="column-options" id="select-condition-column">
          <SelectBox
            options={selectOptions}
            onHeaderChange={e => changeColumnHandler(e, condition.id)}
            locale={intlLocale}
            selectedOption={columnHeader.value}
            placeholder={intl("source.select_condition_column")}
            SelectBoxClassNames={"col-md-12"}
            labelClassName={"col-md-12"}
            disabled={localStorage.getItem("permission") !== EDIT}
          />
          {showError &&
            !!errorHints.columnHeader &&
            errorHints.columnHeader.length > 1 && (
              <small className="text-danger">{intl("condition_error_notification.columnHeader")}</small>
            )}
        </div>
        {localStorage.getItem("permission") === EDIT && 
          <RenderActionButtons
            removeConditionRow={conditionRowRemove}
            addConditionRow={conditionRowAdd}
            showErrorHints={showErrorHints}
            condition={condition}
            lastConditionId={lastConditionId}
            conditionsLength={conditionsLength}
          />
        }
        
      </div>
      <div id="condition-filter-block">
        <ConditionFilter {...props} />
      </div>
    </React.Fragment>
  );
}

ConditionItem.propTypes = {
  showError: PropTypes.bool.isRequired,
  lastConditionId: PropTypes.number.isRequired,
  conditionsLength: PropTypes.number.isRequired,
  condition: PropTypes.shape({
    columnHeader: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      obj: PropTypes.object.isRequired
    }).isRequired,
    optionValue: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.array,
      startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      inputValue: PropTypes.string
    }).isRequired,
    chosenData: PropTypes.string.isRequired,
    errorHints: PropTypes.object.isRequired
  }).isRequired,
  actions: PropTypes.shape({
    conditionRowRemove: PropTypes.func.isRequired,
    conditionRowAdd: PropTypes.func.isRequired,
    showErrorHints: PropTypes.func.isRequired
  })
};

export default InjectIntl(ConditionItem);
