import merge from "xtend";
import createReducer from "../../create-reducer";
import {
  CHANGE_DATA_FOR_PAGE,
  CHANGE_NAV_BUTTONS_MODAL,
  SET_QUESTIONS_AND_ANSWERS,
  FETCH_FINAL_SOURCE_DATA,
  SET_SOURCE_MODAL_INITIAL_STATE,
  FETCH_FINAL_SOURCE_DATA_SUCCESS,
  FETCH_FINAL_SOURCE_DATA_FAILURE,
  CHANGE_DATA_FOR_FINAL_PAGE,
  SET_NEW_TRANSFORMATION_VALUE
} from "../../../actions/calculation/source-modals";
import {
  CHANGE_HEADER_ID_FOR_TRANSFORMATION,
  CLEAR_SOURCE_DATA,
  GET_DATA_SOURCE_RESOURCES,
  GET_DATA_SOURCE_RESOURCES_SUCCESS,
  SAVE_DATA_SOURCE,
  SAVE_DATA_SOURCE_SUCCESS
} from "../../../actions/calculation/data-source";
import { HIDE_ONE_SOURCE_MODAL } from "../../../actions/calculation/selectVariableType";
import {
  SET_CONDITION_INITIAL_STATE,
  ERASE_ALL_CONDITIONS
} from "../../../actions/calculation/conditions";

const INITIAL_STATE = {
  data: {},
  currentPage: 1,
  sizePerPage: 5,
  totalDataSize: null,
  searchTxt: "",
  transformations: [],
  firstColumnVisible: true,
  headers: [],
  answers: [],
  resourcesIsLoading: false,
  finalDataIsLoading: false,
  oneSourceDataLoading: false,
  errors: {},
  newTransformationValue: null
};

export default createReducer(
  {
    [GET_DATA_SOURCE_RESOURCES]: (state, action) =>
      merge(state, {
        oneSourceDataLoading: true,
        resourcesIsLoading: true
      }),
    [GET_DATA_SOURCE_RESOURCES_SUCCESS]: (state, action) =>
      merge(state, {
        totalDataSize: action.payload.total,
        data: action.payload,
        transformations: action.payload.transformations,
        oneSourceDataLoading: false,
        resourcesIsLoading: false
      }),
    [CHANGE_DATA_FOR_PAGE]: (state, action) =>
      merge(state, {
        currentPage: action.page,
        sizePerPage: action.sizePerPage,
        searchTxt: action.searchTxt
      }),
    [CHANGE_DATA_FOR_FINAL_PAGE]: (state, action) =>
      merge(state, {
        searchTxt: action.searchTxt
      }),
    [CHANGE_HEADER_ID_FOR_TRANSFORMATION]: (state, action) =>
      merge(state, {
        finalDataIsLoading: true
      }),
    [HIDE_ONE_SOURCE_MODAL]: (state, action) =>
      merge(state, {
        headers: [],
        answers: [],
        firstColumnVisible: true
      }),
    [CHANGE_NAV_BUTTONS_MODAL]: (state, action) =>
      merge(state, {
        firstColumnVisible: action.name === "first",
        oneSourceDataLoading: false
      }),
    [SET_QUESTIONS_AND_ANSWERS]: (state, action) =>
      merge(state, {
        headers: action.payload.headersAndAnswers[0],
        answers: action.payload.headersAndAnswers[1],
        totalDataSize: action.payload.headersAndAnswers[2]
      }),
    [CLEAR_SOURCE_DATA]: (state, action) =>
      merge(state, {
        headers: [],
        answers: [],
        data: {},
        oneSourceDataLoading: false
      }),
    [FETCH_FINAL_SOURCE_DATA]: (state, action) =>
      merge(state, {
        finalDataIsLoading: true
      }),
    [FETCH_FINAL_SOURCE_DATA_SUCCESS]: (state, action) =>
      merge(state, {
        finalDataIsLoading: false
      }),
    [FETCH_FINAL_SOURCE_DATA_FAILURE]: (state, action) =>
      merge(state, {
        errors: action.errors,
        finalDataIsLoading: false
      }),
    [SAVE_DATA_SOURCE]: (state, action) =>
      merge(state, {
        finalDataIsLoading: true
      }),
    [SAVE_DATA_SOURCE_SUCCESS]: (state, action) =>
      merge(state, {
        finalDataIsLoading: false
      }),
    [SET_NEW_TRANSFORMATION_VALUE]: (state, action) =>
      merge(state, {
        newTransformationValue: action.payload.value
      }),
    [ERASE_ALL_CONDITIONS]: (state, action) =>
      merge(state, {
        newTransformationValue: null
      }),
    [SET_CONDITION_INITIAL_STATE]: (state, action) =>
      merge(state, {
        newTransformationValue: null
      }),
    [SET_SOURCE_MODAL_INITIAL_STATE]: (state, action) =>
      merge(state, INITIAL_STATE)
  },
  INITIAL_STATE
);
