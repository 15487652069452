import React from 'react';
import EmailAddr from './EmailAddr';
import './emailStyle.scss';
import PropTypes from "prop-types";


class MultiEmailBox extends React.PureComponent {

    state = {
        isEdit: false,
        newEmails: '',
        isEditChildren: false,
    };

    onEditedEmail = (currentIndex)=>{
        const {emails, onChange} = this.props;
        return (editedEmail)=>{
            if(editedEmail === emails[currentIndex])return;
            const newEmails = emails.map((email, index)=>{
                return currentIndex === index ? editedEmail : email;
            });
            onChange(newEmails);
        };
    };

    onEditChildren = (isEdit)=>{
        this.setState({isEditChildren: isEdit});
    };

    onRemove = (currentIndex)=>{
        const {emails, onChange} = this.props;
        const {isEdit, isEditChildren} = this.state;
        if(isEditChildren || isEdit) {
            return ()=>{};
        }
        return ()=>{
            const newEmails = emails.filter((email, index)=>currentIndex !== index);
            onChange(newEmails);
        };
    };

    setInputSize = ()=>{
        const {emailDiv, addEmailInput} = this.refs;
        if(addEmailInput){
            addEmailInput.style.width = emailDiv.clientWidth + 15 + 'px';
        }
    };

    setFocus =()=>{
        const {addEmailInput} = this.refs;
        const {newEmails} = this.state;
        addEmailInput.focus();
        addEmailInput.selectionStart = `${newEmails}`.length;
        addEmailInput.selectionEnd = `${newEmails}`.length;
    };

    addEventListener () {
        document.addEventListener('click', this.handleOutsideClick, false);
    }

    removeEventListener () {
        document.removeEventListener('click', this.handleOutsideClick, false);
    }

    handleOutsideClick = (e)=>{
        const {edit, addEmailInput} = this.refs;
        if(!edit) return;
        if(addEmailInput === e.target){
            return;
        }
        if(edit === e.target){
            this.setFocus();
            return;
        }
        this.removeEventListener();
        this.setView();
        this.addEmail();
    };

    setEdit = ()=>{
        const {isEdit} = this.state;
        if(!isEdit){
            this.setState((state, props)=>({isEdit:  true, newEmails: ''}), ()=>{
                this.addEventListener();
                this.setInputSize();
                this.setFocus();
            });
            return;
        }
        this.setInputSize();
    };

    setView = ()=>{
        this.setState((state, props)=>({isEdit:  false}));
    };

    onChange = (e)=>{
        const {value} = e.target;
        this.setState(()=>({newEmails: `${value}`.trim()}), this.setInputSize)
    };

    onEnter = (e)=>{
        const enterKeys = [' '];
        if(e.key === 'Enter'){
            this.addEmail();
            this.setView();
        }
        if(enterKeys.includes(e.key) || (e.ctrlKey && e.keyCode === 86)){
            this.addEmail();
            this.setEdit();
        }
    };

    onBackspace = (e)=>{
        if(e.key === 'Backspace'){
            this.removeLastEmail();
        }
    };

    removeLastEmail = ()=>{
        const {newEmails} = this.state;
        const {onChange, emails} = this.props;
        if(!`${newEmails}`.length && emails.length){
            onChange(emails.slice(0,-1));
        }
    };

    addEmail(){
        const {newEmails} = this.state;
        const {emails, onChange} = this.props;
        const addEmails = this.strToArray(newEmails);
        if(addEmails.length){
            this.setState((state, props)=>({newEmails: ''}), ()=>{
                this.setInputSize();
                onChange([...emails, ...addEmails]);
            });
        }
    }

    strToArray(emailStr){
        const symbols = /(\s{2,}|\n)/g;
        return `${emailStr}`.replace(symbols, ' ')
            .trim()
            .split(' ')
            .filter((email)=>`${email}`.length);
    }

    onClear = ()=>{
        const {onChange} = this.props;
        this.setState((state, props)=>({newEmails: ''}), ()=>{onChange([]);});
    };

    renderEmails(emails){
        return <div className='multi-email-wrapper' onClick={this.setEdit} ref='edit'>
            {(emails || []).map((email,index)=>{
                return <EmailAddr email={email} key={`${email}-${index}`}
                                  onRemove={this.onRemove(index)}
                                  onEdit={this.onEditChildren}
                                  onChange={this.onEditedEmail(index)}/>;
            })}
            {this.renderInputEmails()}
        </div>;
    }

    renderInputEmails(){
        const {isEdit, newEmails} = this.state;
        return <div className='email-add'>
            {isEdit && <textarea value={newEmails}
                   ref='addEmailInput'
                   onChange={this.onChange}
                   onKeyUp={this.onEnter}
                   onKeyDown={this.onBackspace}/>}
            <div className='hidden-slave' ref='emailDiv'>{newEmails}</div>
        </div>
    }

    renderButtonClear(){
        return <div className='button-email-clear' onClick={this.onClear}>
            <div>X</div>
        </div>;
    }

    render(){
        const {emails} = this.props;
        return <div className='multi-email'>
            {this.renderEmails(emails)}
            {this.renderButtonClear()}
        </div>
    }
}

MultiEmailBox.propTypes = {
    emails: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default MultiEmailBox;