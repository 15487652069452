export const ADD_OUTCOME_INDICATOR_MEASURE = '@@OUTCOME_INDICATOR/ADD_OUTCOME_INDICATOR_MEASURE';
export const ADD_OUTCOME_INDICATOR_MEASURE_SUCCESS = '@@OUTCOME_INDICATOR/ADD_OUTCOME_INDICATOR_MEASURE_SUCCESS';
export const ADD_OUTCOME_INDICATOR_MEASURE_FAILURE = '@@OUTCOME_INDICATOR/ADD_OUTCOME_INDICATOR_MEASURE_FAILURE';
export const SHOW_OUTCOME_INDICATOR_FIELD = '@@OUTCOME_INDICATOR/SHOW_OUTCOME_INDICATOR_FIELD';
export const HIDE_OUTCOME_INDICATOR_FIELD = '@@OUTCOME_INDICATOR/HIDE_OUTCOME_INDICATOR_FIELD';
export const CHANGE_OUTCOME_INDICATOR_MEASURE_NAME = '@@OUTCOME_INDICATOR/CHANGE_OUTCOME_INDICATOR_MEASURE_NAME';

function addOutcomeIndicatorMeasure() {
  return { type: ADD_OUTCOME_INDICATOR_MEASURE }
}

function showMeasureNameOutcomeIndicatorField() {
  return { type: SHOW_OUTCOME_INDICATOR_FIELD }
}

function hideMeasureNameOutcomeIndicatorField() {
  return { type: HIDE_OUTCOME_INDICATOR_FIELD }
}

function changeOutcomeIndicatorMeasureName(measureName, measureId) {
  return { type: CHANGE_OUTCOME_INDICATOR_MEASURE_NAME, measureName, measureId }
}

export default {
  addOutcomeIndicatorMeasure,
  showMeasureNameOutcomeIndicatorField,
  hideMeasureNameOutcomeIndicatorField,
  changeOutcomeIndicatorMeasureName
}